import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { nutzerUnternehmenSelectionActions } from './nutzer-unternehmen-selection.actions';
import { Injectable, inject } from '@angular/core';
import { exhaustMap, of, switchMap, map, filter } from 'rxjs';
import { authActions } from '../auth/auth.actions';
import { UnternehmenszuordnungApiService } from '../../service/api/service/unternehmenszuordnung-api.service';
import { PersistenceService } from '../../service/persistence.service';
import { formularActionsInt } from '../formular/formular.actions';
import { Store } from '@ngrx/store';
import { selectActiveUnternehmenId } from './nutzer-unternehmen-selection.selectors';

@Injectable()
export class NutzerUnternehmenSelectionEffects {
    private actions$ = inject(Actions);
    private unternehmenszuordnungService = inject(UnternehmenszuordnungApiService);
    private persistenceService = inject(PersistenceService);
    private store = inject(Store);

    setNutzerUnternehmenData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nutzerUnternehmenSelectionActions.updateNutzerUnternehmenSelectionList, formularActionsInt.changeCreateUnternehmenSuccess),
            switchMap(() =>
                this.unternehmenszuordnungService.getUnternehmenszuordnungen().pipe(
                    map((unternehmenszuordnung) =>
                        nutzerUnternehmenSelectionActions.changeNutzerUnternehmenSelectionList({
                            loadedUnternehmenSelectionList: unternehmenszuordnung,
                        }),
                    ),
                ),
            ),
        )
    });

    postUserSelectedUnternehmen$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nutzerUnternehmenSelectionActions.postChangeSelectedUnternehmen),
            switchMap((action) => {
                this.persistenceService.writeToBus(authActions.updateLoginDetailsAndCompany({ newlySelectedUnternehmen: action.newlySelectedUnternehmen }));
                this.persistenceService.removeFromBus(authActions.updateLoginDetails.type);
                return of(nutzerUnternehmenSelectionActions.changeSelectedUnternehmen({ newlySelectedUnternehmen: action.newlySelectedUnternehmen }));
            }),
        );
    });


    informAuthAboutSelection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nutzerUnternehmenSelectionActions.changeSelectedUnternehmen),
            exhaustMap((data) => of(authActions.changeUserRole({ role: data.newlySelectedUnternehmen.userRole }))),
        );
    });

    ifOnlyOneUnternehmenSelectThis$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nutzerUnternehmenSelectionActions.changeNutzerUnternehmenSelectionList),
            concatLatestFrom(() => [this.store.select(selectActiveUnternehmenId)]),
            filter(([unternehmenList, activeUnternehmenId]) =>
                unternehmenList.loadedUnternehmenSelectionList.length === 1
                && unternehmenList.loadedUnternehmenSelectionList[0].active
                && +unternehmenList.loadedUnternehmenSelectionList[0].unternehmenId !== activeUnternehmenId),
            exhaustMap(([unternehmenList]) => {
                this.persistenceService.writeToBus(authActions.updateLoginDetailsAndCompany({ newlySelectedUnternehmen: unternehmenList.loadedUnternehmenSelectionList[0] }));
                this.persistenceService.removeFromBus(authActions.updateLoginDetails.type);
                return of(nutzerUnternehmenSelectionActions.changeSelectedUnternehmen({ newlySelectedUnternehmen: unternehmenList.loadedUnternehmenSelectionList[0] }));
            })
        );
    });

}
