import { inject, Injectable } from "@angular/core";
import { PagePostkorbNachrichtDto, PostkorbNachrichtUnternehmenControllerService } from "../generated";
import { Observable, map } from "rxjs";
import { PagePostkorbNachricht } from "../../../model/postkorb.model";
import { mapPagePostkorbNachricht } from "../mapper/postkorb/PagePostkorbNachrichtDto.mapper";

@Injectable({ providedIn: 'root' })
export class PostkorbUnternehmenApiService {
    private postkorbUnternehmenControllerService = inject(PostkorbNachrichtUnternehmenControllerService);

    getAllInboxMessages(unternehmenId: number, page: number, size: number, sort: string[], filter: Record<string, boolean>, searchparameter?: string,): Observable<PagePostkorbNachricht> {
        return this.postkorbUnternehmenControllerService.getPostkorbNachricht({ unternehmenId, page, size, sort, searchparameter, ...filter })
            .pipe(map((pagePostkorbNachrchrichtDto) => mapPagePostkorbNachricht(pagePostkorbNachrchrichtDto as Required<PagePostkorbNachrichtDto>)));
    }

    updateGelesenStatus(postkorbNachrichtId: number, unternehmenId: number, gelesenStatus: boolean): Observable<boolean> {
        return this.postkorbUnternehmenControllerService.updateGelesenStatus({ postkorbNachrichtId, unternehmenId, requestBody: gelesenStatus });
    }
}