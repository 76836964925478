import { Component, DestroyRef, EventEmitter, inject, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DataTableConfig, QuickFilterMenuItem, TableAction } from '../../../model/data-table.model';
import { SortParameter } from '../../../model/store.model';
import { selectDokumenteUnternehmenIsLoading, selectDokumenteUnternehmenPage, selectDokumenteUnternehmenSize, selectDokumenteUnternehmenSort, selectDokumenteUnternehmenTableActions, selectDokumenteUnternehmenTableContent, selectDokumenteUnternehmenTotalElements } from '../../../store/dokumente-unternehmen/dokumente-unternehmen.selectors';
import { dokumenteUnternehmenActions } from '../../../store/dokumente-unternehmen/dokumente-unternehmen.actions';
import { FormControl, FormGroup } from '@angular/forms';
import { DokumenteTabelFilter, DokumentUnternehmen } from '../../../model/dokumente-unternehmen.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-dokumente-view-tab',
    templateUrl: './dokumente-view-tab.component.html',
    styleUrl: './dokumente-view-tab.component.scss',
})
export class DokumenteViewTabComponent implements OnInit {
    private store = inject(Store);
    private destroyRef = inject(DestroyRef);


    tableConfig: DataTableConfig;
    tableActions$: Observable<TableAction[][]>;
    tableContent$: Observable<Record<string, string | number | boolean>[]>;
    tableContent: DokumentUnternehmen[];
    tableIsLoading$: Observable<boolean>;
    tableSortingArray$: Observable<SortParameter[]>;
    tableDisplayedSize$: Observable<number>;
    tableDisplayedPage$: Observable<number>;
    tableTotalElements$: Observable<number>;
    pageElement = { page: 0, size: 20 };
    searchValue = '';
    bescheidFilter = '';

    quickFilterBescheidEvent: EventEmitter<string> = new EventEmitter<string>();
    quickFilterBescheidForm = new FormGroup({
        bescheidFilterControl: new FormControl('null'),
    })

    quickFilterMenuItems: QuickFilterMenuItem[] = [{
        name: 'Dokumentenart',
        emitter: this.quickFilterBescheidEvent,
        formControllerName: 'bescheidFilterControl',
        formGroup: this.quickFilterBescheidForm,
        selectionList: ['Bescheid', 'Ungelesener Bescheid', 'Kein Bescheid'],
    }]

    /**
     * get the observables from the store that are needed to configure the table
     */
    ngOnInit(): void {
        this.tableActions$ = this.store.select(selectDokumenteUnternehmenTableActions);
        this.tableContent$ = this.store.select(selectDokumenteUnternehmenTableContent);
        this.tableIsLoading$ = this.store.select(selectDokumenteUnternehmenIsLoading);
        this.tableSortingArray$ = this.store.select(selectDokumenteUnternehmenSort);
        this.tableDisplayedSize$ = this.store.select(selectDokumenteUnternehmenSize);
        this.tableDisplayedPage$ = this.store.select(selectDokumenteUnternehmenPage);
        this.tableTotalElements$ = this.store.select(selectDokumenteUnternehmenTotalElements);

        this.tableContent$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((content) => {
            this.tableContent = content as DokumentUnternehmen[];
        })

        this.configChange();


        this.quickFilterBescheidEvent.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((eventValue) => {
            this.bescheidFilterHandler(eventValue);
        });
    }


    /**
     * Configure the table with the store data
     */
    configChange(): void {
        this.tableConfig = {
            columns: [
                { columnKey: 'dokument.erstelltAm', display: 'Datum' },
                { columnKey: 'dokument.name', display: 'Dokument' },
                { columnKey: 'dokument.tagType', display: 'Art des Dokuments' },
                { columnKey: 'antrag.antragsnummer', display: 'Zur Antragsnummer' },
            ],
            headerIsSticky: true,
            tableDetailId: '',
            tableDataObjectsName: 'Dokumente',
            tableLabel: 'Tabelle der FFA Dokumente zum ausgewählten Förderantrag',
            tableColumnClass: 'dokumente-view-tab',
            tableActions$: this.tableActions$,
            paginationNames: {
                itemsPerPageLabel: 'Dokumente pro Seite',
                nextPageLabel: 'Nächste Seite',
                previousPageLabel: 'Vorherige Seite',
                firstPageLabel: 'Erste Seite',
                lastPageLabel: 'Letzte Seite',
            },
            tableContent$: this.tableContent$,
            tableIsLoading$: this.tableIsLoading$,
            tableSortingArray$: this.tableSortingArray$,
            tableDisplayedSize$: this.tableDisplayedSize$,
            tableDisplayedPage$: this.tableDisplayedPage$,
            tableTotalElements$: this.tableTotalElements$,
            tableRowClassFunction: this.rowClassHandler,
        };
    }

    /**
     * Handle the row class for the table depending on the row the table ask about.
     *
     * @param index The index of the row that is asked for.
     * @returns the css class for the row.
     */
    rowClassHandler = (index: number): string => {
        if (this.tableContent.length > 0 && this.tableContent[index].type === 'FFA_BESCHEID' && !this.tableContent[index].gelesen) {
            return 'data-table__dokumente-view-tab__table-row__ungelesen';
        }
        return '';
    }

    /**
     * Handles the quick filter Bescheid selection
     *
     * @param event holds the string with the Bescheid filter
     */
    bescheidFilterHandler(event: string): void {
        if (this.bescheidFilter !== event && event !== 'null') {
            this.store.dispatch(dokumenteUnternehmenActions.changeTableDokumenteFilter({ newDokumenteFilter: event as DokumenteTabelFilter }));
            this.bescheidFilter = event;
            this.pageElement.page = 0;
        } else if (this.bescheidFilter !== '' && event === 'null') {
            this.store.dispatch(dokumenteUnternehmenActions.changeTableDokumenteFilter({ newDokumenteFilter: 'Alle' }));
            this.bescheidFilter = '';
            this.pageElement.page = 0;
        }
    }

    /**
     * Handle the search of the table
     * @param searchEvent The search value
     */
    searchHandler(searchEvent: string): void {
        if (this.searchValue !== searchEvent) {
            this.searchValue = searchEvent;
            this.store.dispatch(dokumenteUnternehmenActions.changeTableSearchParameter({ newSearchParameter: searchEvent }));
            this.pageElement.page = 0;
        }
    }

    /**
     * Handle the sorting of a table column
     *
     * @param sortEvent holds the new sortParameter and if the sort parameter should be added to the end of the sorting array or if it should create a new list
     */
    sortHandler(sortEvent: { sort: SortParameter; isMultiSort: boolean }): void {
        this.store.dispatch(
            dokumenteUnternehmenActions.changeTableSort({ newSortingParameter: sortEvent.sort, isMultiSort: sortEvent.isMultiSort }),
        );
        this.pageElement.page = 0;
    }

    /**
     *  Handle the use of the paginatior. The paginator triggers a new event for every change so only one of the two looked out values will change at a time.
     *
     * @param pageEvent holds the size and the active page of the paginator return.
     */
    pageHandler(pageEvent: PageEvent): void {
        if (pageEvent.pageSize !== this.pageElement.size) {
            this.store.dispatch(dokumenteUnternehmenActions.changeTableSize({ newSize: pageEvent.pageSize }));
            this.pageElement.size = pageEvent.pageSize;
            this.pageElement.page = 0;
        } else if (pageEvent.pageIndex !== this.pageElement.page) {
            this.store.dispatch(dokumenteUnternehmenActions.changeTablePage({ newPage: pageEvent.pageIndex }));
            this.pageElement.page = pageEvent.pageIndex;
        }
    }
}
