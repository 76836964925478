<section class="all-antraege--top-section">
    <h1>Anträge stellen und verwalten</h1>
    <app-button
        *ngIf="hasFoerderbereiche$ | async"
        [buttonLabel]="'Neuer Förderungsantrag'"
        targetLink="antragerstellen"></app-button>
</section>
<section class="all-antraege--bottom-section">
    <app-card>
        <div class="all-antraege--bottom-section__card-content">
            <div class="all-antraege--bottom-section__card-content--top">
                <app-quick-filter
                    class="all-antraege--bottom-section__card-content--top__filter"
                    [quickFilterMenuItems]="quickFilterMenuItems"></app-quick-filter>
                <app-search
                    class="all-antraege--bottom-section__card-content--top__search"
                    (searchEvent)="searchHandler($event)"></app-search>
            </div>
            <app-data-table
                [tableConfig]="tableConfig"
                (sortEvent)="sortHandler($event)"
                (pageEvent)="pageHandler($event)"
                (rowClickEvent)="rowClickHandler($event)"></app-data-table>
        </div>
    </app-card>
</section>
