import { inject, Injectable } from '@angular/core';
import { BeantragungserlaubnisUnternehmenControllerService } from '../generated';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RightManagementFoerderbereicheService {
    private beantragungserlaubnisUnternehmenControllerService = inject(BeantragungserlaubnisUnternehmenControllerService);

    postBeantragungserlaubnis(nutzerIdpId: string, unternehmenId: number, requestBody: number[]): Observable<boolean> {
        return this.beantragungserlaubnisUnternehmenControllerService.createBeantragungserlaubnisse({ nutzerIdpId, unternehmenId, requestBody });
    }

    deleteBeantragungserlaubnis(nutzerIdpId: string, unternehmenId: number, requestBody: number[]): Observable<boolean> {
        return this.beantragungserlaubnisUnternehmenControllerService.deleteBeantragungserlaubnisse({ nutzerIdpId, unternehmenId, requestBody });
    }
}
