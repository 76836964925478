<button
    class="quick-filter__menu-trigger"
    type="button"
    data-testid="uiQuickFilter-menuTrigger"
    [matMenuTriggerFor]="quickFilterMenu">
    <p>Filter</p>
    <mat-icon
        class="text-text-dark"
        fontIcon="filter_list"></mat-icon>
</button>

<mat-menu
    #quickFilterMenu="matMenu"
    ariaLabel="Aktionsmenü"
    class="quick-filter__menu__panel">
    <ng-container *ngFor="let menuItem of quickFilterMenuItems">
        <div mat-menu-item>
            <mat-form-field
                class="quick-filter__menu__form"
                [formGroup]="menuItem.formGroup"
                (click)="$event.stopPropagation()">
                <mat-label>
                    {{ menuItem.name }}
                </mat-label>
                <mat-select
                    panelClass="quick-filter__selection__panel"
                    class="!text-text-dark"
                    [attr.data-testid]="'uiQuickFilter-' + menuItem.name + 'Feld'"
                    [formControlName]="menuItem.formControllerName"
                    (selectionChange)="triggerFilter(menuItem.formGroup, menuItem.formControllerName, menuItem.emitter)">
                    <mat-option class="!text-small !text-text-dark"> -- </mat-option>
                    <mat-option
                        *ngFor="let menuSelectionOption of menuItem.selectionList"
                        class="!text-small !text-text-dark"
                        [value]="menuSelectionOption"
                        [attr.data-testid]="'uiQuickFilter-' + menuItem.name + menuSelectionOption + 'Option'">
                        {{ menuSelectionOption }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </ng-container>
</mat-menu>
