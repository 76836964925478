import { createReducer, on } from '@ngrx/store';
import { UnternehmenSelectionStore } from '../../model/nutzer-unternehmen-selection.model';
import { nutzerUnternehmenSelectionActions } from './nutzer-unternehmen-selection.actions';
import { PortalRole } from '../../model/auth.model';
import { authActions } from '../auth/auth.actions';

export const nutzerUnternehmenInitialState: UnternehmenSelectionStore = {
    unternehmenSelectionList: [],
    selectedUnternehmen: { name: 'Unternehmen auswählen', unternehmenId: '0', userRole: PortalRole.COMPANY_SELECT, active: false },
};

export const nutzerUnternehmenSelectionReducer = createReducer(
    nutzerUnternehmenInitialState,
    on(nutzerUnternehmenSelectionActions.setInitialState, (): UnternehmenSelectionStore => nutzerUnternehmenInitialState),
    on(nutzerUnternehmenSelectionActions.changeSelectedUnternehmen, (state, action): UnternehmenSelectionStore => ({ ...state, selectedUnternehmen: action.newlySelectedUnternehmen })),
    on(nutzerUnternehmenSelectionActions.changeNutzerUnternehmenSelectionList, (state, action): UnternehmenSelectionStore => ({
        ...state,
        unternehmenSelectionList: action.loadedUnternehmenSelectionList,
    })),
    on(authActions.changeLoginDetailsAndCompanyNoRedirect, (state, action): UnternehmenSelectionStore => ({ ...state, selectedUnternehmen: action.newlySelectedUnternehmen })),
    on(authActions.changeUserToLoggedOut, (): UnternehmenSelectionStore => ({ ...nutzerUnternehmenInitialState })),
);
