import { createReducer, on } from '@ngrx/store';
import { AllUnternehmenNutzerStore } from '../../model/all-unternehmen-nutzer.model';
import {
    allUnternehmenNutzerActions,
    nutzerRightManagementFoerderantragViewActions,
    nutzerRightManagementNutzerViewActions,
    nutzerRolleManagement,
    nutzerHinzufuegenActions,
} from './all-unternehmen-nutzer.actions';
import { SortDir, SortParameter } from '../../model/store.model';
import { authActions } from '../auth/auth.actions';
import { nutzerUnternehmenSelectionActions } from '../nutzer-unternehmen-selection/nutzer-unternehmen-selection.actions';
import { PortalRole } from '../../model/auth.model';

export const allUnternehmenNutzerInitialState: AllUnternehmenNutzerStore = {
    allUnternehmenNutzerTable: {
        data: [],
        totalElements: 0,
        isLoading: false,
        sortingArray: [{ column: 'role', sortDirection: SortDir.desc }],
        size: 20,
        page: 0,
        searchParameter: '',
        roleFilter: null,
        activeFilter: null,
        tableActions: [],
        foerderbereichFilter: null,
    },
    rightManagementFoerderantrag: {
        nutzerFoerderbereichList: [],
        isFoerderbereichRightManagement: true,
        newRights: [],
        removedRights: [],
        nutzerFoerderantraegeList: [],
        nutzerId: '',
    },
    rightManagementNutzer: {
        newNutzer: [],
        removedNutzer: [],
        foerderantragNutzerList: [],
        foerderantragId: 0,
    },
    rolleManagement: {
        nutzerId: '',
        rolle: PortalRole.COMPANY_SELECT,
        newRolle: PortalRole.COMPANY_SELECT,
    },
    newNutzer: {
        email: '',
        rolle: PortalRole.COMPANY_USER,
    },
};

export const allUnternehmenNutzerReducer = createReducer(
    allUnternehmenNutzerInitialState,
    on(
        allUnternehmenNutzerActions.changeAllUnternehmenNutzerSuccess,
        (state, action): AllUnternehmenNutzerStore => ({
            ...state,
            allUnternehmenNutzerTable: {
                ...state.allUnternehmenNutzerTable,
                data: action.newAllUnternehmenNutzer.allUnternehmenNutzer,
                totalElements: action.newAllUnternehmenNutzer.totalElements,
                isLoading: false,
            },
        }),
    ),
    on(
        allUnternehmenNutzerActions.changeAllUnternehmenNutzer,
        (state): AllUnternehmenNutzerStore => ({
            ...state,
            allUnternehmenNutzerTable: {
                ...state.allUnternehmenNutzerTable,
                isLoading: true,
            },
        }),
    ),
    on(allUnternehmenNutzerActions.changeSorting, (state, action): AllUnternehmenNutzerStore => {
        let sorting: SortParameter[];
        if (action.isMultiSort) {
            sorting = [...state.allUnternehmenNutzerTable.sortingArray];
            sorting.push(action.newSort);
        } else {
            sorting = [action.newSort];
        }
        return {
            ...state,
            allUnternehmenNutzerTable: {
                ...state.allUnternehmenNutzerTable,
                sortingArray: sorting,
            },
        };
    }),
    on(
        allUnternehmenNutzerActions.changePage,
        (state, action): AllUnternehmenNutzerStore => ({
            ...state,
            allUnternehmenNutzerTable: {
                ...state.allUnternehmenNutzerTable,
                page: action.newPage,
            },
        }),
    ),
    on(
        allUnternehmenNutzerActions.changeSize,
        (state, action): AllUnternehmenNutzerStore => ({
            ...state,
            allUnternehmenNutzerTable: {
                ...state.allUnternehmenNutzerTable,
                size: action.newSize,
            },
        }),
    ),
    on(
        allUnternehmenNutzerActions.changeSearchParameter,
        (state, action): AllUnternehmenNutzerStore => ({
            ...state,
            allUnternehmenNutzerTable: {
                ...state.allUnternehmenNutzerTable,
                searchParameter: action.newSearchParameter,
            },
        }),
    ),
    on(
        allUnternehmenNutzerActions.changeRoleFilter,
        (state, action): AllUnternehmenNutzerStore => ({
            ...state,
            allUnternehmenNutzerTable: {
                ...state.allUnternehmenNutzerTable,
                roleFilter: action.newRoleFilter,
            },
        }),
    ),
    on(
        allUnternehmenNutzerActions.changeActiveFilter,
        (state, action): AllUnternehmenNutzerStore => ({
            ...state,
            allUnternehmenNutzerTable: {
                ...state.allUnternehmenNutzerTable,
                activeFilter: action.newActiveFilter,
            },
        }),
    ),
    on(
        allUnternehmenNutzerActions.changeAllFoerderbereicheFilter,
        (state, action): AllUnternehmenNutzerStore => ({
            ...state,
            allUnternehmenNutzerTable: {
                ...state.allUnternehmenNutzerTable,
                foerderbereichFilter: action.newAllFoerderbereicheFilter,
            },
        }),
    ),
    on(allUnternehmenNutzerActions.resetAllUnternehmenNutzerStore, (): AllUnternehmenNutzerStore => ({ ...allUnternehmenNutzerInitialState })),
    on(nutzerUnternehmenSelectionActions.changeSelectedUnternehmen, (): AllUnternehmenNutzerStore => ({ ...allUnternehmenNutzerInitialState })),
    on(authActions.changeUserToLoggedOut, (): AllUnternehmenNutzerStore => ({ ...allUnternehmenNutzerInitialState })),
    on(
        allUnternehmenNutzerActions.setTableAction,
        (state, action): AllUnternehmenNutzerStore => ({
            ...state,
            allUnternehmenNutzerTable: {
                ...state.allUnternehmenNutzerTable,
                tableActions: action.newTableAction,
            },
        }),
    ),
    on(
        allUnternehmenNutzerActions.changeUserStatus,
        (state): AllUnternehmenNutzerStore => ({
            ...state,
            allUnternehmenNutzerTable: {
                ...state.allUnternehmenNutzerTable,
                isLoading: true,
            },
        }),
    ),

    //nutzerHinzufuegenActions

    on(
        nutzerHinzufuegenActions.setSelectedRolle,
        (state, action): AllUnternehmenNutzerStore => ({
            ...state,
            newNutzer: {
                ...state.newNutzer,
                rolle: action.rolle,
            },
        }),
    ),
    on(
        nutzerHinzufuegenActions.setSelectedEmail,
        (state, action): AllUnternehmenNutzerStore => ({
            ...state,
            newNutzer: {
                ...state.newNutzer,
                email: action.email,
            },
        }),
    ),
    on(
        nutzerHinzufuegenActions.resetNewNutzer,
        (state): AllUnternehmenNutzerStore => ({
            ...state,
            newNutzer: {
                ...allUnternehmenNutzerInitialState.newNutzer,
            },
        }),
    ),

    // Rightmanagement Dialog Foerderantraege View
    on(
        nutzerRightManagementFoerderantragViewActions.setNutzerFoerderbereichList,
        (state, action): AllUnternehmenNutzerStore => ({
            ...state,
            rightManagementFoerderantrag: {
                ...state.rightManagementFoerderantrag,
                nutzerFoerderbereichList: action.nutzerFoerderbereichList,
            },
        }),
    ),
    on(nutzerRightManagementFoerderantragViewActions.changeRightId, (state, action): AllUnternehmenNutzerStore => {
        const { newRights, removedRights, isFoerderbereichRightManagement, nutzerFoerderbereichList, nutzerFoerderantraegeList } =
            state.rightManagementFoerderantrag;

        let updatedNewRights = [...newRights];
        let updatedRemovedRights = [...removedRights];

        if (isFoerderbereichRightManagement) {
            const isFoerderbereich = nutzerFoerderbereichList.some((foerderbereich) => foerderbereich.id === action.rightId);
            if (isFoerderbereich) {
                updatedRemovedRights = action.isSelected
                    ? removedRights.filter((value) => value !== action.rightId)
                    : [...removedRights, action.rightId];
            } else {
                updatedNewRights = action.isSelected ? [...newRights, action.rightId] : newRights.filter((value) => value !== action.rightId);
            }
        } else {
            const isFoerderantrag = nutzerFoerderantraegeList.some((foerderantrag) => foerderantrag.id === action.rightId);
            if (isFoerderantrag) {
                updatedRemovedRights = action.isSelected
                    ? removedRights.filter((value) => value !== action.rightId)
                    : [...removedRights, action.rightId];
            } else {
                updatedNewRights = action.isSelected ? [...newRights, action.rightId] : newRights.filter((value) => value !== action.rightId);
            }
        }

        return {
            ...state,
            rightManagementFoerderantrag: {
                ...state.rightManagementFoerderantrag,
                newRights: updatedNewRights,
                removedRights: updatedRemovedRights,
            },
        };
    }),
    on(nutzerRightManagementFoerderantragViewActions.changeNutzerFoerderbereichList, (state, action): AllUnternehmenNutzerStore => {
        return {
            ...state,
            rightManagementFoerderantrag: { ...state.rightManagementFoerderantrag, nutzerId: action.nutzerId, isFoerderbereichRightManagement: true },
        };
    }),
    on(
        nutzerRightManagementFoerderantragViewActions.setNutzerFoerderantraegeList,
        (state, action): AllUnternehmenNutzerStore => ({
            ...state,
            rightManagementFoerderantrag: {
                ...state.rightManagementFoerderantrag,
                nutzerFoerderantraegeList: action.newNutzerFoerderantraege,
                isFoerderbereichRightManagement: false,
            },
        }),
    ),
    on(
        nutzerRightManagementFoerderantragViewActions.resetRightLists,
        (state): AllUnternehmenNutzerStore => ({
            ...state,
            rightManagementFoerderantrag: { ...state.rightManagementFoerderantrag, newRights: [], removedRights: [] },
        }),
    ),

    // Right Management Dialog Nutzer View

    on(nutzerRightManagementNutzerViewActions.updateFoerderantragNutzerList, (state, action): AllUnternehmenNutzerStore => {
        return {
            ...state,
            rightManagementNutzer: { ...state.rightManagementNutzer, foerderantragId: action.foerderantragId },
        };
    }),

    on(
        nutzerRightManagementNutzerViewActions.setFoerderantragNutzerList,
        (state, action): AllUnternehmenNutzerStore => ({
            ...state,
            rightManagementNutzer: {
                ...state.rightManagementNutzer,
                foerderantragNutzerList: action.newFoerderantragNutzerList,
            },
        }),
    ),
    on(nutzerRightManagementNutzerViewActions.changeNutzerId, (state, action): AllUnternehmenNutzerStore => {
        const { newNutzer, removedNutzer, foerderantragNutzerList } = state.rightManagementNutzer;

        let updatedNewNutzer = [...newNutzer];
        let updatedRemovedNutzer = [...removedNutzer];

        const isNutzer = foerderantragNutzerList.some((nutzer) => nutzer.idpId === action.nutzerId);
        if (isNutzer) {
            updatedRemovedNutzer = action.isSelected
                ? removedNutzer.filter((value) => value !== action.nutzerId)
                : [...removedNutzer, action.nutzerId];
        } else {
            updatedNewNutzer = action.isSelected ? [...newNutzer, action.nutzerId] : newNutzer.filter((value) => value !== action.nutzerId);
        }

        return {
            ...state,
            rightManagementNutzer: {
                ...state.rightManagementNutzer,
                newNutzer: updatedNewNutzer,
                removedNutzer: updatedRemovedNutzer,
            },
        };
    }),
    on(
        nutzerRightManagementNutzerViewActions.resetNutzerLists,
        (state): AllUnternehmenNutzerStore => ({
            ...state,
            rightManagementNutzer: { ...state.rightManagementNutzer, newNutzer: [], removedNutzer: [] },
        }),
    ),
    on(
        nutzerRolleManagement.setNutzerData,
        (state, action): AllUnternehmenNutzerStore => ({
            ...state,
            rolleManagement: {
                ...state.rolleManagement,
                nutzerId: action.newNutzer.id,
                rolle: action.newNutzer.role as PortalRole.COMPANY_ADMIN | PortalRole.COMPANY_USER,
            },
        }),
    ),
    on(
        nutzerRolleManagement.changeNewRolle,
        (state, action): AllUnternehmenNutzerStore => ({ ...state, rolleManagement: { ...state.rolleManagement, newRolle: action.newRolle } }),
    ),
    on(
        nutzerRolleManagement.resetRolleManagement,
        (state): AllUnternehmenNutzerStore => ({ ...state, rolleManagement: { ...allUnternehmenNutzerInitialState.rolleManagement } }),
    ),
    on(nutzerRolleManagement.changeRolle, (state): AllUnternehmenNutzerStore =>
        ({ ...state, allUnternehmenNutzerTable: { ...state.allUnternehmenNutzerTable, isLoading: true } })
    ),
);
