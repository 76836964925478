import { Action } from "@ngrx/store";

/**
 * A function that compares two ngrx actions to determin if they are equal
 * @param action1 the first action
 * @param action2 the second action
 * @returns a boolean if they are equal or not
 */
export const areActionsEqual = (action1: Action, action2: Action): boolean => {
    // Compare action types
    if (action1.type !== action2.type) {
        return false;
    }

    // Extract props if they exist
    const action1Props = extractProps(action1);
    const action2Props = extractProps(action2);

    // Compare props if they exist
    if (action1Props && action2Props) {
        return JSON.stringify(action1Props) === JSON.stringify(action2Props);
    }

    // If both actions have no props, they are equal
    if (!action1Props && !action2Props) {
        return true;
    }

    // If one action has props and the other doesn't, they are not equal
    return false;
}

/**
 * Helper function to extract props from an action
 * @param action The action to extract props from
 * @returns the props of the action or null if there are no props
 */
function extractProps(action: Action): unknown {
    // Clone the action to remove the type property 
    // disable EsLint, because the type will be dropped but should be filtered out of the action
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { type, ...props } = action;

    // Return props if they exist, otherwise return null
    return Object.keys(props).length > 0 ? props : null;
}