import { Injectable, inject } from '@angular/core';
import { UnternehmenszuordnungCommonControllerService, UnternehmenszuordnungUnternehmenControllerService } from '../generated';
import { Observable, map } from 'rxjs';
import { UnternehmenSelectionItem } from '../../../model/nutzer-unternehmen-selection.model';
import { mapUnternehmenSelectionItems, mapUnternehmenszuordnung } from '../mapper/unternehmenszuordnung/unternehmenszuordnung-dto.mapper';
import { PostUnternehmenszuordnungReqBody, PutUnternehmenszuordnungReqBody, Unternehmenzuordnung } from '../../../model/all-unternehmen-nutzer.model';

@Injectable({
    providedIn: 'root',
})
/**
 * service to call UnternehmenszuordnungCommonControllerService and map dtos
 */
export class UnternehmenszuordnungApiService {
    private unternehmenzuordnungApi = inject(UnternehmenszuordnungCommonControllerService);
    private UnternehmenszuordnungUnternehmenControllerApi = inject(UnternehmenszuordnungUnternehmenControllerService);

    /**
     * fetches all company mappings for the logged in user
     * @returns List of companies the user is associated with
     */
    getUnternehmenszuordnungen(): Observable<UnternehmenSelectionItem[]> {
        return this.unternehmenzuordnungApi.getAktiveUnternehmenszuordnungenForNutzer().pipe(map((dtoList) => mapUnternehmenSelectionItems(dtoList)));
    }

    deleteNutzerUnternehmenZuordnung(unternehmenId: number, nutzerIdpId: string): Observable<boolean> {
        return this.UnternehmenszuordnungUnternehmenControllerApi.removeUnternehmenszuordnung({ unternehmenId, nutzerIdpId });
    }

    putNutzerUnternehmenZuordnung(unternehmenId: number, requestBody: PutUnternehmenszuordnungReqBody): Observable<Unternehmenzuordnung> {
        return this.UnternehmenszuordnungUnternehmenControllerApi.updateUnternehmenszuordnung({ unternehmenId, requestBody }).pipe(
            map((unternehmenszuordnungsDto) => mapUnternehmenszuordnung(unternehmenszuordnungsDto)),
        );
    }

    postUnternehmenszuordnungen(unternehmenId: number, requestBody: PostUnternehmenszuordnungReqBody): Observable<boolean> {
        return this.UnternehmenszuordnungUnternehmenControllerApi.createUnternehmenszuordnung({ unternehmenId, requestBody });
    }
}
