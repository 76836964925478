import { Observable, map } from 'rxjs';
import { NebenantraegeContentPage, NebenantraegeTableModifiers, Nebenantragsart } from '../../../model/nebenantraege.model';
import {
    NebenantragFfaControllerService,
    NebenantragsartFfaControllerService,
    PageNebenantragDto,
} from '../generated';
import { Injectable, inject } from '@angular/core';
import { Status } from '../../../model/foerderantraege.model';
import { mapNebenantragCountInfo } from '../mapper/nebenantraege/nebenantragsart-count-dto.mapper';
import { mapNebenantragInfo } from '../mapper/nebenantraege/nebenantragsart-dto.mapper';
import { mapNebenantraegePage } from '../mapper/nebenantraege/page-nebenantrag-dto.mapper';

@Injectable({
    providedIn: 'root',
})
/**
 * The service for the FFA user to get the nebenantraege data
 */
export class FfaNebenantraegeAPIService {

    private nebenantragsartFfaControllerService = inject(NebenantragsartFfaControllerService);
    private nebenantragFfaControllerService = inject(NebenantragFfaControllerService);


    /**
     * get the nebenantragsarten that are createable for the given foerderantrag
     * @param foerderantragId the id of the foerderantrag for which the nebebnantraege should be returned
     * @returns a list of nebenantragsarten that can be created for the foerderantrag
     */
    getPermittedNebenantragsart(foerderantragId: number): Observable<Nebenantragsart[]> {
        return this.nebenantragsartFfaControllerService
            .findPermittedNebenantragsart1({
                foerderantragId: foerderantragId,
            })
            .pipe(map((nebenantragsartDtoArray) => nebenantragsartDtoArray.map((nebenantragsartDto) => mapNebenantragInfo(nebenantragsartDto))));
    }

    /**
     * get the nebenantragsarten that are existing on the given foerderantrag
     * @param foerderantragId the id of the foerderantrag for which the nebebnantraege should be returned
     * @returns a list of nebenantragsarten that are existing on the foerderantrag
     */
    getFilledNebenantragsart(foerderantragId: number): Observable<Nebenantragsart[]> {
        return this.nebenantragsartFfaControllerService
            .findNebenantragsartCountDto1({
                foerderantragId: foerderantragId,
            })
            .pipe(
                map((nebenantragsartDtoArray) =>
                    nebenantragsartDtoArray.map((nebenantragsartDto) => mapNebenantragCountInfo(nebenantragsartDto)),
                ),
            );
    }

    /**
     * get one page of one kind of nebenantragart for the given request modifiers
     * @param nebenantraegeTableModifiers the modifiers for the query that should be added
     * @param foerderantragId the id of the foerderantrag for that the nebenantraege should be looked for
     * @returns one page of the nebenantraege for a nebenantraegeart for a foerderantrag 
     */
    getNebenantraegePage(nebenantraegeTableModifiers: NebenantraegeTableModifiers, foerderantragId: number): Observable<NebenantraegeContentPage> {
        const { nebenantragsartId, searchParameter, sortingArray, size, page, statusFilter } = nebenantraegeTableModifiers;
        const params: {
            searchparameter?: string;
            sort: string[];
            status?: Status;
        } = { sort: sortingArray.map((sortingElement) => sortingElement.column + ',' + sortingElement.sortDirection) };

        searchParameter !== '' ? (params.searchparameter = searchParameter) : '';
        statusFilter !== null ? (params.status = statusFilter) : '';

        return this.nebenantragFfaControllerService
            .findNebenantragReferencingFoerderantrag1({ foerderantragId, nebenantragsartId, size, page, ...params })
            .pipe(map((nebenantraegePageDto) => mapNebenantraegePage(nebenantraegePageDto as Required<PageNebenantragDto>)));
    }


}
