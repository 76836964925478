import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { ErrorDefaultInput, ErrorInformation, LogLevel, LogMessage } from "../../model/error-store.model";

export const errorActions = createActionGroup({
    source: 'Error',
    events: {
        updateLog: props<{ message: string, logLevel: LogLevel }>(),
        setLog: props<{ logMessage: LogMessage }>(),
        updateShownError: props<{ newError: ErrorDefaultInput, }>(),
        setShownError: props<{ newError: ErrorInformation, logMessage: LogMessage }>(),
        resetShownError: emptyProps(),
        resetErrorStore: emptyProps(),
        setLogTimer: props<{ intervalId: number }>(),
        resetLogTimer: emptyProps(),
    }
})