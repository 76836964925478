import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { DokumenteTabelFilter, DokumenteUnternehmenPage } from "../../model/dokumente-unternehmen.model";
import { SortParameter } from "../../model/store.model";
import { TableAction } from "../../model/data-table.model";

/**
 * The action that are in direct connection with the table
 */
export const dokumenteUnternehmenActions = createActionGroup({
    source: 'Dokumente Unternehmen',
    events: {
        changeDokumenteUnternehmenPage: emptyProps(),
        setDokumenteUnternehmenPage: props<{ newDokumenteUnternehmenPage: DokumenteUnternehmenPage, newTableActions: TableAction[][] }>(),
        changeTablePage: props<{ newPage: number }>(),
        changeTableSize: props<{ newSize: number }>(),
        changeTableSort: props<{ newSortingParameter: SortParameter, isMultiSort: boolean }>(),
        changeTableDokumenteFilter: props<{ newDokumenteFilter: DokumenteTabelFilter }>(),
        changeTableSearchParameter: props<{ newSearchParameter: string }>(),
        resetTable: emptyProps(),
    }
});

/**
 * The actions that are in direct connection with the download of the Dokument
 */
export const dokumentContentActions = createActionGroup({
    source: 'Dokument Content',
    events: {
        updateDownloadAntragDokument: props<{ antragId: number }>(),
        changeDokumentContent: props<{ dokumentId: number, directDownload: boolean }>(),
        setDokumentContent: props<{ dokumentURL: string }>(),
        resetDokumentContent: emptyProps(),
        changeBescheidLesebestaetigung: props<{ dokumentId: number }>(),
        changeHraOrGewerbescheinDownload: props<{ dokumentId: number, antragsId: number }>(),
    }
});