import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DokumenteUnternehmenDataTable, DokumenteUnternehmenStore } from "../../model/dokumente-unternehmen.model";
import { DEFAULT_DATE_STRING_CONFIG } from "../../model/data-table.model";

export const selectDokumenteUnternehmenStore = createFeatureSelector<DokumenteUnternehmenStore>('dokumenteUnternehmenStore');

export const selectDokumenteUnternehmenTableData = createSelector(
    selectDokumenteUnternehmenStore,
    (state: DokumenteUnternehmenStore) => state.dataTable
);

export const selectDokumenteUnternehmenTableContent = createSelector(
    selectDokumenteUnternehmenTableData,
    (dataTable: DokumenteUnternehmenDataTable) => dataTable.data.map((dokument) => ({
        ...dokument,
        'dokument.erstelltAm': new Date(dokument['dokument.erstelltAm']).toLocaleString('de-DE', DEFAULT_DATE_STRING_CONFIG),
        'dokument.tagType': dokument.type === 'FFA_DOCUMENT' ? 'FFA Dokument' : 'FFA Bescheid'
    })))

export const selectDokumenteUnternehmenIsLoading = createSelector(
    selectDokumenteUnternehmenTableData,
    (dataTable: DokumenteUnternehmenDataTable) => dataTable.isLoading
);

export const selectDokumenteUnternehmenTableActions = createSelector(
    selectDokumenteUnternehmenTableData,
    (dataTable: DokumenteUnternehmenDataTable) => dataTable.tableActions
);

export const selectDokumenteUnternehmenSize = createSelector(
    selectDokumenteUnternehmenTableData,
    (dataTable: DokumenteUnternehmenDataTable) => dataTable.size
);

export const selectDokumenteUnternehmenPage = createSelector(
    selectDokumenteUnternehmenTableData,
    (dataTable: DokumenteUnternehmenDataTable) => dataTable.page
);

export const selectDokumenteUnternehmenTotalElements = createSelector(
    selectDokumenteUnternehmenTableData,
    (dataTable: DokumenteUnternehmenDataTable) => dataTable.totalElements
);

export const selectDokumenteUnternehmenDokumentenFilterObj = createSelector(
    selectDokumenteUnternehmenTableData,
    (dataTable: DokumenteUnternehmenDataTable) => {
        let returnObj = {};
        switch (dataTable.dokumentenFilter) {
            case 'Bescheid':
                returnObj = { onlyBescheide: true };
                break;
            case 'Ungelesene Bescheide':
                returnObj = { onlyUngeleseneBescheide: true };
                break;
            case 'Kein Bescheid':
                returnObj = { noBescheide: true };
                break;
            default:
                break;
        }
        return returnObj;
    }
);

export const selectDokumenteUnternehmenSort = createSelector(
    selectDokumenteUnternehmenTableData,
    (dataTable: DokumenteUnternehmenDataTable) => dataTable.sortingArray
);

export const selectDokumenteUnternehmenSortStringArray = createSelector(
    selectDokumenteUnternehmenTableData,
    (dataTable: DokumenteUnternehmenDataTable) => dataTable.sortingArray.map(sort => sort.column + ',' + sort.sortDirection)
);

export const selectDokumenteUnternehmenTableQuerryParams = createSelector(selectDokumenteUnternehmenTableData, selectDokumenteUnternehmenDokumentenFilterObj, selectDokumenteUnternehmenSortStringArray, (data, filterObj, sortingArray) => {
    return {
        page: data.page,
        size: data.size,
        sort: sortingArray,
        searchparameter: data.searchParameter !== '' ? data.searchParameter : undefined,
        ...filterObj
    }
});

export const selectDokumentContent = createSelector(selectDokumenteUnternehmenStore, (state: DokumenteUnternehmenStore) => state.dokumentContent);

export const selectDokumentContentURL = createSelector(selectDokumentContent, (dokumentContent) => dokumentContent.dokumentURL);

export const selectDokumentContentIsLoading = createSelector(selectDokumentContent, (dokumentContent) => dokumentContent.isLoading);
