import { FormField } from "../../../../model/formular.model"
import { FormFieldDto } from "../../generated"

export const formFieldMapper = (formField: Required<FormFieldDto>): FormField => {
    return {
        id: formField.id,
        value: formField.value,
        type: formField.type,
        readonly: formField.readonly,
        hId: formField.hId,
        hGroupId: formField.hGroupId
    }
}
