import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { InitializeRequest, InitializeResponse, GetPresignedUrlsRequest, GetPresignedUrlsResponse, FinalizeRequest, PartEtag, EmptyBodyResponse, FormFileType } from '../../../model/aws-service.model';
import { environment } from '../../../../environments/environment';
import { Store } from '@ngrx/store';
import { errorActions } from '../../../store/error-store/error.actions';
import { ErrorTitle } from '../../../model/error-store.model';
import { GlobalExceptionHandlerService } from './global-exception-handler.service';

@Injectable({
    providedIn: 'root'
})
export class AwsControllerService {
    private apiGatewayUrl = environment.AWS.apiGatewayUrl;

    private http = inject(HttpClient);
    private store = inject(Store);
    private golobalExceptionHandler = inject(GlobalExceptionHandlerService);

    /**
     * Service function to initialize the upload of a file
     * @param fileName The name of the file to upload
     * @returns returns an Observable of the API call containing fileId and fileKey
     */
    initializeUpload(fileName: string): Observable<InitializeResponse> {
        const url = `${this.apiGatewayUrl}/upload/initialize`;
        return this.http.post<InitializeResponse>(url, { name: fileName } satisfies InitializeRequest).pipe(
            catchError(error => {
                if (error.status === 401) {
                    this.golobalExceptionHandler.handleForm401Error(error);
                } else {
                    this.store.dispatch(errorActions.updateShownError({
                        newError: {
                            status: 2,
                            detail: 'Multipart Hochladen Initialisierung fehlgeschlagen',
                            instance: url,
                            title: ErrorTitle[2],
                            error,
                        }
                    }));
                }
                return throwError(() => new Error('Initialization failed'));
            })
        );
    }

    /**
     * Service function to get presigned URLs for the parts of a file
     * @param fileKey the name of the file to upload
     * @param fileId the id returned by the initialize call
     * @param parts the number of parts the file is split into
     * @returns returns an Observable of the API call containing the presigned URLs
     */
    getPresignedUrls(fileKey: string, fileId: string, parts: number): Observable<GetPresignedUrlsResponse> {
        const url = `${this.apiGatewayUrl}/upload/getpresignedurls`;
        const requestBody = { fileKey, fileId, parts } satisfies GetPresignedUrlsRequest;
        return this.http.post<GetPresignedUrlsResponse>(url, requestBody).pipe(
            catchError(error => {
                if (error.status === 401) {
                    this.golobalExceptionHandler.handleForm401Error(error);
                } else {
                    this.store.dispatch(errorActions.updateShownError({
                        newError: {
                            status: 2,
                            detail: 'Fehler beim Laden der Presigned Urls für das Hochladen',
                            instance: url,
                            title: ErrorTitle[2],
                            error,
                        }
                    }));
                }
                return throwError(() => new Error('Failed to get presigned URLs'));
            })
        );
    }

    /**
     * Serve function to upload a part of a file to S3
     * @param presignedUrl is the URL to upload the part to
     * @param filePart is the part of the file to upload
     * @returns returns an Observable of the API call containing an empty body and the ETag in the response header
     */
    uploadPartToS3(presignedUrl: string, filePart: Blob): Observable<HttpResponse<EmptyBodyResponse>> {
        // We authorize using the presigned URL, so we need to avoid sending the cogntio token
        // otherwise S3 will reject the request with "Only one auth mechanism allowed"
        const headers = new HttpHeaders().set('Skip-Auth-Interceptor', 'true');
        return this.http.put<EmptyBodyResponse>(presignedUrl, filePart, { headers, observe: 'response' }).pipe(
            catchError((error) => {
                if (error.status === 401) {
                    this.golobalExceptionHandler.handleForm401Error(error);
                } else {
                    this.store.dispatch(errorActions.updateShownError({
                        newError: {
                            status: 2,
                            detail: 'Fehler beim Hochladen eines Parts der Datei',
                            instance: presignedUrl,
                            title: ErrorTitle[2],
                            error
                        }
                    }));
                }
                return throwError(() => new Error(error));
            })
        );
    }

    /**
     * Service function to finalize the upload of a file after all parts have been uploaded
     * @param formFiletype specifies if the file is an attachment, form-json or final-pdf
     * @param antragsnummer id referencing the Antrag the file belongs to
     * @param fileKey the name of the file uploaded
     * @param fileId the id returned by the initialize call
     * @param parts the ETags and part numbers of the parts uploaded
     * @returns returns an Observable of the API call containing an empty body
     */
    finalizeUpload(formFiletype: FormFileType, antragsnummer: string, fileKey: string, fileId: string, parts: PartEtag[], htmlFieldId: string, status?: string): Observable<EmptyBodyResponse> {
        const url = `${this.apiGatewayUrl}/upload/finalize`;
        const requestBody = { fileKey, fileId, parts, antragsnummer, type: formFiletype, htmlFieldId, status } satisfies FinalizeRequest;
        return this.http.post<EmptyBodyResponse>(url, requestBody).pipe(
            catchError(error => {
                if (error.status === 401) {
                    this.golobalExceptionHandler.handleForm401Error(error);
                } else {
                    this.store.dispatch(errorActions.updateShownError({
                        newError: {
                            status: 2,
                            detail: 'Fehler beim finalisieren des Hochladens',
                            instance: url,
                            title: ErrorTitle[2],
                            error
                        }
                    }));
                }
                return throwError(() => new Error('Finalization failed'));
            })
        );
    }
}