import { Component, Input, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectFormularStoreProgressBarProgress } from '../../../store/formular/formular.selectors';

@Component({
    selector: 'app-upload-progress',
    templateUrl: './upload-progress.component.html',
    styleUrl: './upload-progress.component.scss',
})
/**
 * The component that displays the progress of an upload
 */
export class UploadProgressComponent implements OnInit {
    private store = inject(Store);

    @Input() title = "Lädt hoch...";

    progress$: Observable<number>;


    ngOnInit(): void {
        this.progress$ = this.store.select(selectFormularStoreProgressBarProgress);
    }
}
