<div
    class="dialog-panel"
    [class]="data.panelClass">
    <mat-dialog-content class="dialog-content">
        <h1>
            {{ data.title }}
        </h1>
        <ng-container #dialogContent></ng-container>
    </mat-dialog-content>

    <mat-dialog-actions
        class="dialog-actions"
        [class.dialog-actions-buttons]="data.contentTemplateName">
        <ng-container *ngFor="let action of data.actions">
            <app-button
                [buttonLabel]="action.title"
                (clickEvent)="action.clickFunction(dialogRef)"
                [class.cancel-button]="action.title === 'Abbrechen'"
                [class.liste-button]="action.title === 'Liste der Förderanträge' || action.title === 'Liste der Förderbereiche'"
                [buttonColor]="
                    action.title === 'Abbrechen' || action.title === 'Weiter bearbeiten' || action.title === 'Weiter anpassen' ? 'primary' : 'accent'
                "
                [isButton]="true"
                buttonClass="dialog-button"></app-button>
        </ng-container>
    </mat-dialog-actions>
</div>
<ng-template #rightManagementTemplate>
    <app-right-management-foerderbereich></app-right-management-foerderbereich>
</ng-template>
<ng-template #foerderantragTemplate>
    <app-right-management-foerderantrag></app-right-management-foerderantrag>
</ng-template>

<ng-template #postkorbNachrichtDialogTemplate>
    <app-postkorb-nachricht-dialog class="dialog-content__template-content"></app-postkorb-nachricht-dialog>
</ng-template>

<ng-template #allantraegeTemplate>
    <app-right-management-allantraege></app-right-management-allantraege>
</ng-template>
<ng-template #rolleManagementTemplate>
    <app-rolle-management></app-rolle-management>
</ng-template>
<ng-template #lesebestaetigungTemplate>
    <app-lesebestaetigung-setzen class="dialog-content__template-content"></app-lesebestaetigung-setzen>
</ng-template>
<ng-template #addNewUserTemplate>
    <app-add-new-benutzer></app-add-new-benutzer>
</ng-template>
<ng-template #downloadSpinnerTemplate>
    <mat-spinner class="dialog-content__download-spinner"></mat-spinner>
</ng-template>
