import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Observable, exhaustMap, map, of, switchMap } from 'rxjs';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectIsUnternehmenRole } from '../auth/auth.selectors';
import { foerderantragHistoryTableActions } from './foerderantrag-history-table.actions';
import { selectFoerderantragHistoryTableModifiers } from './foerderantrag-history-table.selectors';
import { selectDisplayedDetailsFoerderantragFoerderantragsId } from '../foerderantraege/foerderantraege.selectors';
import { FfaFoerderantragHistoryTableApiService } from '../../service/api/service/foerderantrag-history-table-ffa.service';
import { UnternehmenFoerderantragHistoryTableApiService } from '../../service/api/service/foerderantrag-history-table-unternehmen.service';
import { selectActiveUnternehmenId } from '../nutzer-unternehmen-selection/nutzer-unternehmen-selection.selectors';
import { FoerderantragHistoryTableApiReturn } from '../../model/foerderantrag-history-table.model';

@Injectable()
/**
 * The effects corresponding to the foerderantraege Store
 */
export class FoerderantragHistoryTableEffects {
    private actions$ = inject(Actions);
    private store = inject(Store);
    private ffaFoerderantragHistoryTableApiService = inject(FfaFoerderantragHistoryTableApiService);
    private unternehmenFoerderantragHistoryTableApiService = inject(UnternehmenFoerderantragHistoryTableApiService);

    /**
     * The effect that determens if the company or the ffa endpoint should be called and then calling them for foerderantragHistoryTableContent and the total elements.
     */
    updateFoerderantragHistoryTable$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(foerderantragHistoryTableActions.changeFoerderantragHistoryTableContent),
            concatLatestFrom(() => [
                this.store.select(selectFoerderantragHistoryTableModifiers),
                this.store.select(selectIsUnternehmenRole),
                this.store.select(selectDisplayedDetailsFoerderantragFoerderantragsId),
                this.store.select(selectActiveUnternehmenId),
            ]),
            switchMap(([, tableModifers, userHasUnternehmenRole, foerderantragId, unternehmenId]) => {
                let returnObservable$: Observable<FoerderantragHistoryTableApiReturn>;
                if (userHasUnternehmenRole) {
                    returnObservable$ = this.unternehmenFoerderantragHistoryTableApiService
                        .getFoerderantragHistoryTableContent(foerderantragId, unternehmenId, tableModifers);
                } else {
                    returnObservable$ = this.ffaFoerderantragHistoryTableApiService.getFoerderantragHistoryTableContent(foerderantragId, tableModifers);
                }
                return returnObservable$.pipe(
                    map(({ totalElements, foerderantragHistoryTableContent }) =>
                        foerderantragHistoryTableActions.setFoerderantragHistoryTableContent({
                            newTotalElements: totalElements,
                            newFoerderantragHistoryTableContent: foerderantragHistoryTableContent,
                        }),
                    ),
                );
            }),
        );
    });

    /**
     * When these actions occurre the foerderantragHistoryTableContent should be reloaded
     */
    changeIsLoading$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                foerderantragHistoryTableActions.changeSortingArray,
                foerderantragHistoryTableActions.changeTablePage,
                foerderantragHistoryTableActions.changeTableSize,
            ),
            exhaustMap(() => {
                return of(foerderantragHistoryTableActions.changeFoerderantragHistoryTableContent());
            }),
        );
    });
}
