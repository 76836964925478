import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { FoerderantraegeStore } from '../../model/foerderantraege.model';
import { Link } from '../../model/app-routing.model';
import { selectCurrentUrl } from '../../store/router-store/router-store.selectors';
import { take } from 'rxjs';
import { selectNebenantragsartenCreateableAndExisting } from '../../store/nebenantraege/nebenantraege.selectors';

/**
 * the resolver for the alle-antraege/details/:id page that genereates the breadcrumb data for the page
 * @param route gets the active route snapshot from the router to get the id from it
 * @returns returns the breadcrumb data object that is used in the breadcrumb component to generate the link and display
 */
export const NebenantragsDetailResolver: ResolveFn<Link> = async (route: ActivatedRouteSnapshot) => {
    return new Promise((resolve, reject) => {
        const breadcrumb: Link = { label: '', link: '' };
        breadcrumb.link = 'nebenantragsart/' + route.params['id'];
        const store = inject(Store<{ foerderantraegeStore: FoerderantraegeStore }>);

        store
            .select(selectCurrentUrl)
            .pipe(take(1))
            .subscribe((currentUrl) => {
                if (currentUrl.endsWith(breadcrumb.link)) {
                    store
                        .select(selectNebenantragsartenCreateableAndExisting)
                        .pipe(take(1))
                        .subscribe(({ nebenantragsartenCreateable, nebenantragsartenExisting }) => {
                            //reject if there are no nebenantraege in either list.
                            if (nebenantragsartenCreateable.length === 0 && nebenantragsartenExisting.length === 0) {
                                reject();
                            }
                            //if the createable list has values
                            else if (nebenantragsartenCreateable.length !== 0) {
                                //find the nebenantrag is in the list of createable nebenantraegen
                                const createable = nebenantragsartenCreateable.find(
                                    (nebenantragsart) => nebenantragsart.id === +route.params['id'],
                                );
                                //if it was found use the bezeichnung and resolve
                                if (createable) {
                                    breadcrumb.label = 'Nebenantragsart: "' + createable.bezeichnung + '"';
                                    resolve(breadcrumb);
                                }
                                //else check if the nebenantrag is in the list of existing nebenantraegen
                                else {
                                    const existing = nebenantragsartenExisting.find(
                                        (nebenantragsart) => nebenantragsart.id === +route.params['id'],
                                    );
                                    if (existing) {
                                        breadcrumb.label = 'Nebenantragsart: "' + existing.bezeichnung + '"';
                                        resolve(breadcrumb);
                                    }
                                    else {
                                        reject();
                                    }
                                }
                            }
                            // here only the existing list has length
                            else {
                                const existing = nebenantragsartenExisting.find(
                                    (nebenantragsart) => nebenantragsart.id === +route.params['id'],
                                );
                                if (existing) {
                                    breadcrumb.label = 'Nebenantragsart: "' + existing.bezeichnung + '"';
                                    resolve(breadcrumb);
                                }
                                else {
                                    reject();
                                }
                            }
                        });
                } else {
                    reject('');
                }
            });
    });
};
