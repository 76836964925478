import { inject } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, of, switchMap } from "rxjs";

import { GlobalExceptionHandlerService } from "../../service/api/service/global-exception-handler.service";
import { errorActions } from "./error.actions";
import { LogLevel } from "../../model/error-store.model";
import { SnackBarService } from "../../service/snack-bar.service";
import { selectErrorStoreLogList } from "./error.selectors";

export class ErrorEffects {
    private store = inject(Store);
    private actions = inject(Actions);
    private globalExceptionHandlerService = inject(GlobalExceptionHandlerService);
    private snackBar = inject(SnackBarService);

    logMessage$ = createEffect(() => {
        return this.actions.pipe(
            ofType(errorActions.updateLog),
            switchMap((action) => {
                const logMessage = this.globalExceptionHandlerService.createLog(action.message, action.logLevel);
                return of(errorActions.setLog({ logMessage }));
            }),
        );
    });

    errorInformationCreation$ = createEffect(() => {
        return this.actions.pipe(
            ofType(errorActions.updateShownError),
            concatLatestFrom(() => [
                this.store.select(selectErrorStoreLogList),
            ]),
            switchMap(([action, logList]) => {
                const logListCopy = [...logList];
                const logMessage = this.globalExceptionHandlerService.createLog(JSON.stringify(action.newError.error), LogLevel.ERROR);
                const errorInformation = this.globalExceptionHandlerService.createErrorInformation(action.newError);
                if (errorInformation.shouldLogDirectly) {
                    logListCopy.push(logMessage);
                    this.globalExceptionHandlerService.logAllToServer(logListCopy);
                }
                if (errorInformation.status === 401) {
                    this.snackBar.openLogoutSnackBar();
                }
                else if (errorInformation.status !== 0) {
                    this.snackBar.openErrorSnackBar();
                }
                return of(errorActions.setShownError({ newError: errorInformation, logMessage }));
            }),
            catchError(() => {
                return of();
            })
        );
    });


}