import { Component, OnInit, inject } from '@angular/core';
import { Nebenantragsart } from '../../../model/nebenantraege.model';
import { Store } from '@ngrx/store';
import {
    selectDisplayedNebenantragsarten,
    selectNebenantragsartenCreateableIds
} from '../../../store/nebenantraege/nebenantraege.selectors';
import { Observable } from 'rxjs';
import { formularActionsInt } from '../../../store/formular/formular.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { AntragsType } from '../../../model/formular.model';
import { nebenantraegeActions } from '../../../store/nebenantraege/nebenantraege.actions';
import { AntragHelperService } from '../../../service/helper/antrag.helper.service';

@Component({
    selector: 'app-nebenantrag-list-tab',
    templateUrl: './nebenantrag-list-tab.component.html',
    styleUrl: './nebenantrag-list-tab.component.scss',
})
/**
 * The tab component for the second tab of the antrags detail page.
 */
export class NebenantragListTabComponent implements OnInit {
    private store = inject(Store);
    private router = inject(Router);
    private activatedRoute = inject(ActivatedRoute);
    private antragHelperService = inject(AntragHelperService);

    displayedNebenantragsarten$: Observable<Nebenantragsart[]>;
    nebenantragsartenCreateableIds$: Observable<number[]>;

    /**
     * Get the needed Observables from the store
     */
    ngOnInit(): void {
        this.nebenantragsartenCreateableIds$ = this.store.select(selectNebenantragsartenCreateableIds);

        this.displayedNebenantragsarten$ = this.store.select(selectDisplayedNebenantragsarten);
    }

    /**
     * The function to navigate to the details of a Nebenantrag.
     * @param nebenantragsart the nebenantragsart to which detail page should be navigated
     */
    navigateToNebenantragsDetails(nebenantragsart: Nebenantragsart): void {
        this.store.dispatch(nebenantraegeActions.setDisplayedNebenantrag({ newNebenantrag: nebenantragsart }))
        this.router.navigate(['nebenantragsart', nebenantragsart.id], { relativeTo: this.activatedRoute });
    }

    /**
     * dispatches the action that creates the formular link and then navigates to the formular
     * @param nebenantragsart the nebenantragsart for which the formular should be opened
     */
    navigateToFormular(nebenantragsart: Nebenantragsart): void {
        this.store.dispatch(formularActionsInt.postChangeCreateNewFormular({
            shortName: nebenantragsart.gatewayShortname,
            antragsType: AntragsType.Nebenantrag,
            antragsArtId: nebenantragsart.id,
            antragsart: nebenantragsart.bezeichnung.replaceAll(" ", "")
        }));
    }

    /**
     * Get the label for the create button
     * @param nebenantragsart the nebenantragsart for which the label should be created
     * @returns the label
     */
    getNewAntragLabel(nebenantragsart: Nebenantragsart): string {
        return this.antragHelperService.nebenantragCreateNewButtonLabel(nebenantragsart);
    }
}
