<section class="all-unternehmen-nutzer--top-section">
    <h1>Alle Benutzer*innen</h1>
    <p>Als Admin können Sie Antragsteller*innen und Sub-Admins zu Ihrem Unternehmen hinzufügen und deren Rechte im FFA-Serviceportal verwalten.</p>
    <p>
        Bitte beachten Sie, dass standardmäßig Admins und Benutzer*innen nur Anträge einsehen und bearbeiten können, die sie selbst erstellt haben.
        Zugriffsrechte für weitere Benutzer*innen können Sie hier vergeben.
    </p>
    <p>Bitte beachten Sie, dass Benutzer*innen sich initial am Serviceportal registrieren müssen.</p>
    <app-button
        class="all-unternehmen-nutzer--top-section__button"
        [buttonLabel]="'Benutzer*in hinzufügen'"
        (click)="openAddUserDialog()">
    </app-button>
</section>

<section class="all-unternehmen-nutzer--bottom-section">
    <app-card>
        <div class="all-unternehmen-nutzer--bottom-section__card-content">
            <div class="all-unternehmen-nutzer--bottom-section__card-content--top">
                <app-quick-filter
                    [quickFilterMenuItems]="quickFilterMenuItems"
                    class="all-unternehmen-nutzer--bottom-section__card-content--top__filter">
                </app-quick-filter>
                <app-search
                    class="all-unternehmen-nutzer--bottom-section__card-content--top__search"
                    (searchEvent)="searchHandler($event)"></app-search>
            </div>
            <app-data-table
                [tableConfig]="tableConfig"
                (sortEvent)="sortHandler($event)"
                (pageEvent)="pageHandler($event)"
                (rowClickEvent)="rowClickHandler($event)"></app-data-table>
        </div>
    </app-card>
</section>
