import { Foerderantrag, Status } from '../../../../model/foerderantraege.model';
import { FoerderantragDto } from '../../generated';

/**
 * Maps the generated FoerderantragDto to the store used Foerderantrag
 * @param foerderantrag the API returned foerderantrag
 * @returns returns the store friendly foerderantrag
 */
export const mapFoerderantrag = (foerderantrag: Required<FoerderantragDto>): Foerderantrag => {
    return {
        id: foerderantrag.id,
        status: Status[foerderantrag.status],
        antragsteller: foerderantrag.antragsteller,
        gatewayId: foerderantrag.gatewayId,
        formularShortname: foerderantrag.formularShortname,
        fmsUuid: foerderantrag.fmsUuid,
        antragsnummer: foerderantrag.antragsnummer,
        foerdernummer: foerderantrag.foerdernummer ?? 'Wird noch übermittelt',
        aktualisiertAm: foerderantrag.aktualisiertAm,
        eingereichtAm: foerderantrag.eingereichtAm ?? '',
        aktualisiertVon: foerderantrag.aktualisiertVon,
        'foerderart.bezeichnung': foerderantrag.foerderartBezeichnung,
        projektname: foerderantrag.projektname,
        fristFreischaltung: foerderantrag.fristFreischaltung ?? undefined,
        fristEnde: foerderantrag.fristEnde ?? undefined,
    };
};
