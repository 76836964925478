import { inject, Injectable } from "@angular/core";
import { NutzerCommonControllerService, NutzerDto } from "../generated";
import { map, Observable } from "rxjs";
import { NutzerProfile } from "../../../model/nutzer-profile.model";
import { mapNutzerProfile } from "../mapper/nutzer/nutzer-dto.mapper";

/**
 * Service for Nutzer Common API
 */
@Injectable({
    providedIn: 'root',
})
export class NutzerCommonApiService {
    private nutzerCommonControllerService = inject(NutzerCommonControllerService);

    /**
     * The function to get the user profile for the current user
     * @returns the user profile for the current user
     */
    getNutzerProfileData(): Observable<NutzerProfile> {
        return this.nutzerCommonControllerService.findCurrentNutzer().pipe(map((nutzer) => mapNutzerProfile(nutzer as Required<NutzerDto>)));
    }

    /**
     * The function to update the user profile for the current user
     * @param requestBody the changed user profile data
     * @returns the new user profile data
     */
    putNutzerProfileChanges(requestBody: NutzerProfile): Observable<NutzerProfile> {
        return this.nutzerCommonControllerService.updateNutzer({ requestBody }).pipe(map((nutzer) => mapNutzerProfile(nutzer as Required<NutzerDto>)));
    }
}