<div
    *ngIf="newRolle$ | async; let newRolle"
    class="rolle-management-dialog-container">
    <app-button
        buttonLabel="Admin"
        [isButton]="true"
        [ngClass]="{ selected: isSelected(newRolle, PortalRole.COMPANY_ADMIN) }"
        (clickEvent)="toggleButton(PortalRole.COMPANY_ADMIN, newRolle)"
        buttonClass="dialog-button"></app-button>
    <app-button
        buttonLabel="Mitarbeiter*in"
        [isButton]="true"
        [ngClass]="{ selected: isSelected(newRolle, PortalRole.COMPANY_USER) }"
        (clickEvent)="toggleButton(PortalRole.COMPANY_USER, newRolle)"
        buttonClass="dialog-button"></app-button>
</div>
