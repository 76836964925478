import { createReducer, on } from '@ngrx/store';
import { rechtlicheInformationenActions } from './rechtliche-informationen.action';
import { RenderedTemplateDto } from '../../service/api/generated';
import { authActions } from '../auth/auth.actions';

export interface RechtlicheInformationenStore {
    impressumHtml: RenderedTemplateDto | null;
    error: Error | null;
}

export const rechtlicheInformationenInitialState: RechtlicheInformationenStore = {
    impressumHtml: null,
    error: null,
};

export const rechtlicheInformationenReducer = createReducer(
    rechtlicheInformationenInitialState,

    on(
        rechtlicheInformationenActions.setImpressumSuccess,
        (state, { impressumHtml }): RechtlicheInformationenStore => ({
            ...state,
            impressumHtml,
            error: null,
        }),
    ),

    on(
        rechtlicheInformationenActions.updateFailure,
        (state, { error }): RechtlicheInformationenStore => ({
            ...state,
            error,
        }),
    ),

    on(authActions.changeUserToLoggedOut, (): RechtlicheInformationenStore => ({ ...rechtlicheInformationenInitialState })),
);
