import { Injectable, inject } from '@angular/core';
import { RegistrationControllerService } from '../generated';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
/**
 */
export class RegistrationApiService {
    private registrationApiController = inject(RegistrationControllerService);

    public confirmRegistration(email: string): Observable<boolean> {
        return this.registrationApiController.confirmRegistration({ requestBody: email });
    }
}
