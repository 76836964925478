<section class="stammdaten--top-section">
    <h1>Stammdaten</h1>
    <p>Korrekturen und Aktualisierungen der im FFA-Serviceportal hinterlegten Stammdaten Ihres Unternehmens müssen von der FFA autorisiert werden.</p>

    <p
        *ngIf="nutzerIsAdmin$ | async; else mitarbeiterRolle"
        data-testid="pNutzerAdmin">
        Bitte benutzen Sie für Änderungen dieses
        <a
            class="stammdaten--top-section__link"
            (click)="$event.stopPropagation(); linkClickHandler()">
            Online-Formular</a
        >.
    </p>
</section>
<section class="stammdaten--bottom-section">
    <ng-container *ngIf="(isLoadingStammdaten$ | async) !== true; else loadingSpinner">
        <ng-container *ngIf="stammdaten$ | async; let stammdaten">
            <div
                class="stammdaten--bottom-section__labeled-area"
                aria-labelledby="generalLabel">
                <h2
                    class="text-h3 text-text-dark"
                    id="generalLabel">
                    Allgemeines
                </h2>
                <app-card>
                    <div
                        class="stammdaten--bottom-section__card"
                        [class]="
                            stammdaten.juristischePerson
                                ? 'stammdaten--bottom-section__card__general--long'
                                : 'stammdaten--bottom-section__card__general--short'
                        "
                        aria-label="Allgemeine Informationen zum Unternehmen">
                        <div class="stammdaten--bottom-section__card__content">
                            <p class="text-small">Name des Unternehmens</p>
                            <p>{{ stammdaten.unternehmensname }}</p>
                        </div>
                        <div class="stammdaten--bottom-section__card__content">
                            <p class="text-small">FFA Id</p>
                            <p>{{ stammdaten.ffaid }}</p>
                        </div>
                        <ng-container *ngIf="stammdaten.juristischePerson">
                            <div
                                class="stammdaten--bottom-section__card__content"
                                data-testid="stammdatenKmu">
                                <p class="text-small">Klein- oder mittelständiges Unternehmen</p>
                                <p>{{ stammdaten.kmu ? 'Ja' : 'Nein' }}</p>
                            </div>
                            <div
                                class="stammdaten--bottom-section__card__content"
                                data-testid="stammdatenVorsteuerabzugsberechtigt">
                                <p class="text-small">Vorsteuerabzugsberechtigt</p>
                                <p>{{ stammdaten.vorsteuerabzugsberechtigt ? 'Ja' : 'Nein' }}</p>
                            </div>
                        </ng-container>
                    </div>
                </app-card>
            </div>
            <div
                class="stammdaten--bottom-section__labeled-area"
                aria-labelledby="adressLabel">
                <h2
                    class="text-h3 text-text-dark"
                    id="adressLabel">
                    Adresse
                </h2>
                <app-card>
                    <div
                        class="stammdaten--bottom-section__card"
                        [class]="
                            stammdaten.adresse.isDeutscheAdresse
                                ? 'stammdaten--bottom-section__card__adresse'
                                : 'stammdaten--bottom-section__card__auslands-adresse'
                        ">
                        <ng-container
                            [ngTemplateOutlet]="stammdaten.adresse.isDeutscheAdresse ? adresseDeutschlandTemplate : adresseAuslandTemplate"
                            [ngTemplateOutletContext]="{ adresse: stammdaten.adresse }">
                        </ng-container>
                    </div>
                </app-card>
            </div>
            <div
                class="stammdaten--bottom-section__labeled-area"
                aria-labelledby="geschaeftsfuehrerLabel">
                <h2
                    class="text-h3 text-text-dark"
                    id="geschaeftsfuehrerLabel">
                    {{ stammdaten.geschaeftsfuehrer.length === 1 ? 'Geschäftsführer*in' : 'Geschäftsführer*innen' }}
                </h2>
                <ng-container *ngFor="let geschaeftsfuehrer of stammdaten.geschaeftsfuehrer">
                    <app-card data-testid="geschaeftsfuehrer">
                        <div class="stammdaten--bottom-section__card stammdaten--bottom-section__card__geschaeftsfuehrer">
                            <div class="stammdaten--bottom-section__card__content">
                                <p class="text-small">Anrede</p>
                                <p>{{ geschaeftsfuehrer.anrede }}</p>
                            </div>
                            <div class="stammdaten--bottom-section__card__content">
                                <p class="text-small">Titel</p>
                                <p>{{ geschaeftsfuehrer.titel || '-' }}</p>
                            </div>
                            <div class="stammdaten--bottom-section__card__content">
                                <p class="text-small">Vorname</p>
                                <p>{{ geschaeftsfuehrer.vorname }}</p>
                            </div>
                            <div class="stammdaten--bottom-section__card__content">
                                <p class="text-small">Name</p>
                                <p>{{ geschaeftsfuehrer.nachname }}</p>
                            </div>
                        </div>
                    </app-card>
                </ng-container>
            </div>
            <div
                class="stammdaten--bottom-section__rechtliches"
                aria-label="Rechtliche Informationen zu dem Unternehmen">
                <ng-container
                    [ngTemplateOutlet]="stammdaten.juristischePerson ? rechtlichesJuristischePersonTemplate : rechtlichesNatuerlichePersonTemplate"
                    [ngTemplateOutletContext]="{ stammdaten: stammdaten }">
                </ng-container>
            </div>
            <div
                class="stammdaten--bottom-section__labeled-area"
                aria-labelledby="niederlassungenLabel"
                *ngIf="stammdaten.niederlassungen && stammdaten.niederlassungen.length > 0">
                <h2
                    class="text-h3 text-text-dark"
                    id="niederlassungenLabel">
                    {{ stammdaten.niederlassungen.length === 1 ? 'Niederlassung' : 'Niederlassungen' }}
                </h2>
                <ng-container *ngFor="let niederlassung of stammdaten.niederlassungen">
                    <app-card>
                        <div class="stammdaten--bottom-section__card stammdaten--bottom-section__card__niederlassung">
                            <div class="stammdaten--bottom-section__card__content">
                                <p class="text-small">Name der Niederlassung</p>
                                <p>{{ niederlassung.name }}</p>
                            </div>
                            <ng-container *ngIf="niederlassung.adresse">
                                <ng-container
                                    [ngTemplateOutlet]="niederlassung.adresse.isDeutscheAdresse ? adresseDeutschlandTemplate : adresseAuslandTemplate"
                                    [ngTemplateOutletContext]="{ adresse: stammdaten.adresse }">
                                </ng-container>
                            </ng-container>
                        </div>
                    </app-card>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
</section>

<ng-template #mitarbeiterRolle>
    <p data-testid="pNutzerMitarbeiter">Für Änderungen wenden Sie sich bitte an eine*n Admin ihres Unternehmens.</p>
</ng-template>

<ng-template #loadingSpinner>
    <div
        class="stammdaten--bottom-section__loadingSpinner"
        data-testid="stammdatenLoadingSpinner">
        <mat-spinner></mat-spinner>
        <p>Die Daten werden geladen</p>
    </div>
</ng-template>

<ng-template
    #adresseDeutschlandTemplate
    let-adresse="adresse">
    <div
        class="stammdaten--bottom-section__card__content"
        data-testid="deutscheAdresse">
        <p class="text-small">Straße</p>
        <p>{{ adresse.strasse }}</p>
    </div>
    <div class="stammdaten--bottom-section__card__content">
        <p class="text-small">Hausnummer</p>
        <p>{{ adresse.hausnummer }}</p>
    </div>
    <div
        *ngIf="adresse.zusatz"
        class="stammdaten--bottom-section__card__content">
        <p class="text-small">Zusatz</p>
        <p>{{ adresse.zusatz }}</p>
    </div>
    <div class="stammdaten--bottom-section__card__content">
        <p class="text-small">Postleitzahl</p>
        <p>{{ adresse.plz }}</p>
    </div>
    <div class="stammdaten--bottom-section__card__content">
        <p class="text-small">Ort</p>
        <p>{{ adresse.ort }}</p>
    </div>
    <div class="stammdaten--bottom-section__card__content">
        <p class="text-small">Bundesland</p>
        <p>{{ adresse.bundesland }}</p>
    </div>
    <div class="stammdaten--bottom-section__card__content">
        <p class="text-small">Land</p>
        <p>{{ adresse.land }}</p>
    </div>
</ng-template>

<ng-template
    #adresseAuslandTemplate
    let-adresse="adresse">
    <div
        class="stammdaten--bottom-section__card__content"
        data-testid="auslaendischeAdresse">
        <p class="text-small">Land</p>
        <p>{{ adresse.land }}</p>
    </div>
    <div class="stammdaten--bottom-section__card__content">
        <p class="text-small">Adresse</p>
        <p>{{ adresse.anschriftFreitext }}</p>
    </div>
</ng-template>

<ng-template
    #rechtlichesJuristischePersonTemplate
    let-stammdaten="stammdaten">
    <div
        class="stammdaten--bottom-section__labeled-area"
        aria-labelledby="rechtsformLabel"
        data-testid="rechtsformLabel">
        <h2
            class="text-h3 text-text-dark"
            id="rechtsformLabel">
            Rechtsform
        </h2>
        <app-card>
            <div class="stammdaten--bottom-section__card stammdaten--bottom-section__card__section__rechtliches">
                <p>{{ stammdaten.andereRechtsform ? stammdaten.andereRechtsform : stammdaten.rechtsform }}</p>
            </div>
        </app-card>
    </div>
    <div
        class="stammdaten--bottom-section__labeled-area"
        aria-labelledby="umsatzsteuerIdLabel">
        <h2
            class="text-h3 text-text-dark"
            id="umsatzsteuerIdLabel">
            Umsatzsteuer-ID
        </h2>
        <app-card>
            <div class="stammdaten--bottom-section__card stammdaten--bottom-section__card__section__rechtliches">
                <p>{{ stammdaten.umsatzsteuerId }}</p>
            </div>
        </app-card>
    </div>
    <div
        class="stammdaten--bottom-section__labeled-area"
        aria-labelledby="handelsregisterauszugLabel"
        *ngIf="stammdaten.dokumentIdForHraOrGewerbeschein">
        <h2
            class="text-h3 text-text-dark"
            id="handelsregisterauszugLabel">
            Handelsregisterauszug
        </h2>
        <ng-container
            [ngTemplateOutlet]="(nutzerIsAdmin$ | async) ? adminDownloadButton : mitarbeiterDokumentName"
            [ngTemplateOutletContext]="{ stammdaten }">
        </ng-container>
    </div>
</ng-template>

<ng-template
    #rechtlichesNatuerlichePersonTemplate
    let-stammdaten="stammdaten">
    <div
        class="stammdaten--bottom-section__labeled-area"
        aria-labelledby="sinLabel"
        data-testid="sinLabel">
        <h2
            class="text-h3 text-text-dark"
            id="sinLabel">
            Steueridentifikationsnummer
        </h2>
        <app-card>
            <div class="stammdaten--bottom-section__card stammdaten--bottom-section__card__section__rechtliches">
                <p>{{ stammdaten.steueridentifikationsnummer }}</p>
            </div>
        </app-card>
    </div>
    <div
        class="stammdaten--bottom-section__labeled-area"
        aria-labelledby="hewerbescheinLabel"
        *ngIf="stammdaten.dokumentIdForHraOrGewerbeschein">
        <h2
            class="text-h3 text-text-dark"
            id="hewerbescheinLabel">
            Gewerbeschein
        </h2>
        <ng-container
            [ngTemplateOutlet]="(nutzerIsAdmin$ | async) ? adminDownloadButton : mitarbeiterDokumentName"
            [ngTemplateOutletContext]="{ stammdaten }">
        </ng-container>
    </div>
</ng-template>

<ng-template
    #adminDownloadButton
    let-stammdaten="stammdaten">
    <app-button
        class="stammdaten--bottom-section__rechtliches__download-button"
        [buttonLabel]="'Download ' + stammdaten.dokumentnameForHraOrGewerbeschein"
        [isButton]="true"
        (clickEvent)="downloadHraOrGewerbeschein(stammdaten.dokumentIdForHraOrGewerbeschein, stammdaten.idOfRegistrationForm)">
    </app-button>
</ng-template>

<ng-template
    #mitarbeiterDokumentName
    let-stammdaten="stammdaten">
    <app-card>
        <div class="stammdaten--bottom-section__card stammdaten--bottom-section__card__section__rechtliches">
            <p>{{ stammdaten.dokumentnameForHraOrGewerbeschein }}</p>
        </div>
    </app-card>
</ng-template>
