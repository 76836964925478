import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export function passwordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value: string = control.value || '';

        if (!value) {
            return null; // Don't validate empty value
        }

        const hasNumber = /\d/.test(value);
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasSpecialChar = /[^\w\s]/.test(value); // special characters: ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ ` + = `

        const validLength = value.length >= 8;

        const passwordValid = hasNumber && hasUpperCase && hasLowerCase && hasSpecialChar && validLength;

        return !passwordValid
            ? {
                passwordStrength: {
                    rules: {
                        hasNumber,
                        hasUpperCase,
                        hasLowerCase,
                        hasSpecialChar,
                        validLength,
                    },
                },
            }
            : null;
    };
}

export const passwordMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control as FormGroup; // Cast control to FormGroup to access form controls

    const newPassword = formGroup.get('newPassword')?.value;
    const newPasswordRepeat = formGroup.get('newPasswordRepeat')?.value;

    if (newPassword && newPasswordRepeat && newPassword !== newPasswordRepeat) {
        return { passwordMismatch: true };
    }
    return null;
};