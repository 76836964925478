import { createFeatureSelector, createSelector } from "@ngrx/store";
import { NutzerProfileStore } from "../../model/nutzer-profile.model";

export const selectNutzerProfileStore = createFeatureSelector<NutzerProfileStore>('nutzerProfileStore');

export const selectNutzerProfile = createSelector(selectNutzerProfileStore, (nutzerProfileStore) => nutzerProfileStore.profile);

export const selectNutzerProfileIsLoading = createSelector(selectNutzerProfileStore, (nutzerProfileStore) => nutzerProfileStore.isLoading);

export const selectNutzerProfileChanges = createSelector(selectNutzerProfileStore, (nutzerProfileStore) => nutzerProfileStore.changes);

export const selectPasswordChange = createSelector(selectNutzerProfileStore, (nutzerProfileStore) => nutzerProfileStore.passwordChange);

export const selectPasswordChangePasswordChanged = createSelector(selectPasswordChange, (passwordChange) => passwordChange.passwordChanged);

export const selectPasswordChangeError = createSelector(selectPasswordChange, (passwordChange) => passwordChange.error);