<img
    src="/assets/images/svgs/person-svgrepo-com.svg"
    [matMenuTriggerFor]="userMenus"
    class="user-menu__svg"
    alt="Nutzermenü öffnen"
    aria-label="Nutzermenüknopf"
    data-testid="user-menu-button" />
<mat-menu
    #userMenus="matMenu"
    [ariaLabel]="'Nutzermenüliste'">
    <ng-container>
        <p
            mat-menu-item
            disabled
            class="user-menu__menu__userinfo"
            (click)="$event.stopPropagation()">
            {{ loggedInUser?.vorname + ' ' + loggedInUser?.nachname }}
        </p>
        <div
            class="user-menu__horizontal-line"
            (click)="$event.stopPropagation()"></div>
    </ng-container>
    <ng-container *ngFor="let menuItem of userMenu">
        <a
            mat-menu-item
            class="user-menu__menu__item"
            [routerLink]="[menuItem.link]"
            data-testid="userMenuItem">
            {{ menuItem.label }}
        </a>
        <div class="user-menu__horizontal-line"></div>
    </ng-container>
</mat-menu>
