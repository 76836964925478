<mat-form-field
    class="search-form"
    [formGroup]="searchForm">
    <mat-label class="search-form__label"> Suche </mat-label>
    <input
        matInput
        class="text-placeholder !text-text-dark"
        type="search"
        name="Sucheingabe"
        formControlName="searchControl"
        data-testid="uiSearch-search-field"
        (keydown.enter)="triggerSearch()" />
    <img
        src="/assets/images/svgs/search-svgrepo-com.svg"
        class="search-form__prefix-icon"
        alt="Auslöser für die Suche"
        data-testid="uiSearch-triggerIcon"
        matPrefix
        (click)="triggerSearch()" />
    <img
        *ngIf="searchForm.value.searchControl !== ''"
        src="/assets/images/svgs/cancle-svgrepo-com.svg"
        class="search-form__suffix-icon"
        alt="Löschen der Sucheingabe"
        data-testid="uiSearch-clearIcon"
        matSuffix
        (click)="clearSearch()" />
</mat-form-field>
