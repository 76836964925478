import { AllUnternehmenNutzerStore, AllUnternehmenNutzer } from '../../model/all-unternehmen-nutzer.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectFoerderantraegeStoreAllFoerderantraegeContent } from '../foerderantraege/foerderantraege.selectors';
import { PortalRole } from '../../model/auth.model';

export const selectAllUnternehmenNutzerStore = createFeatureSelector<AllUnternehmenNutzerStore>('allUnternehmenNutzerStore');

export const selectAllUnternehmenNutzer = createSelector(selectAllUnternehmenNutzerStore, (allUnternehmenNutzerStore: AllUnternehmenNutzerStore) => {
    return allUnternehmenNutzerStore.allUnternehmenNutzerTable.data.map((allUnternehmenNutzer: AllUnternehmenNutzer) => ({
        ...allUnternehmenNutzer,
        statusString: allUnternehmenNutzer.status ? 'Aktiv' : 'Inaktiv',
    }));
});

export const selectAllUnternehmenNutzerTable = createSelector(
    selectAllUnternehmenNutzerStore,
    (allUnternehmenNutzerStore) => allUnternehmenNutzerStore.allUnternehmenNutzerTable,
);

export const selectAllUnternehmenNutzerSortingArray = createSelector(
    selectAllUnternehmenNutzerTable,
    (allUnternehmenNutzerTable) => allUnternehmenNutzerTable.sortingArray,
);

export const selectAllUnternehmenNutzerPage = createSelector(
    selectAllUnternehmenNutzerTable,
    (allUnternehmenNutzerTable) => allUnternehmenNutzerTable.page,
);

export const selectAllUnternehmenNutzerSize = createSelector(
    selectAllUnternehmenNutzerTable,
    (allUnternehmenNutzerTable) => allUnternehmenNutzerTable.size,
);

export const selectAllUnternehmenNutzerSearchParameter = createSelector(
    selectAllUnternehmenNutzerTable,
    (allUnternehmenNutzerTable) => allUnternehmenNutzerTable.searchParameter,
);

export const selectAllUnternehmenNutzerIsLoading = createSelector(
    selectAllUnternehmenNutzerTable,
    (allUnternehmenNutzerTable) => allUnternehmenNutzerTable.isLoading,
);

export const selectAllUnternehmenNutzerAktiveFilter = createSelector(
    selectAllUnternehmenNutzerTable,
    (allUnternehmenNutzerTable) => allUnternehmenNutzerTable.activeFilter,
);

export const selectAllUnternehmenNutzerFoerderbereichFilter = createSelector(
    selectAllUnternehmenNutzerTable,
    (allUnternehmenNutzerTable) => allUnternehmenNutzerTable.foerderbereichFilter,
);

export const selectAllUnternehmenNutzerRoleFilter = createSelector(
    selectAllUnternehmenNutzerTable,
    (allUnternehmenNutzerTable) => allUnternehmenNutzerTable.roleFilter,
);

export const selectAllUnternehmenNutzerTotalElements = createSelector(
    selectAllUnternehmenNutzerTable,
    (allUnternehmenNutzerTable) => allUnternehmenNutzerTable.totalElements,
);

export const selectAllUnternehmenNutzerTableActions = createSelector(
    selectAllUnternehmenNutzerTable,
    (allUnternehmenNutzerTable) => allUnternehmenNutzerTable.tableActions,
);

export const selectNewNutzerRolle = createSelector(selectAllUnternehmenNutzerStore, (state: AllUnternehmenNutzerStore) => state.newNutzer.rolle);

export const selectNewNutzerEmail = createSelector(selectAllUnternehmenNutzerStore, (state: AllUnternehmenNutzerStore) => state.newNutzer.email);

// Right Management - Foerderantraege for Nutzer View

export const selectAllUnternehmenNutzerRightManagement = createSelector(
    selectAllUnternehmenNutzerStore,
    (allUnternehmenNutzerStore) => allUnternehmenNutzerStore.rightManagementFoerderantrag,
);

export const selectAllUnternehmenNutzerRightManagementNutzer = createSelector(
    selectAllUnternehmenNutzerStore,
    (allUnternehmenNutzerStore) => allUnternehmenNutzerStore.rightManagementNutzer,
);

export const selectAllUnternehemenNutzerNutzerFoerderbereichList = createSelector(
    selectAllUnternehmenNutzerRightManagement,
    (rightManagement) => rightManagement.nutzerFoerderbereichList,
);

export const selectAllUnternehemenNutzerNutzerFoerderantraegeList = createSelector(
    selectAllUnternehmenNutzerRightManagement,
    (rightManagement) => rightManagement.nutzerFoerderantraegeList,
);

export const selectAllUnternehemenNutzerNewRights = createSelector(
    selectAllUnternehmenNutzerRightManagement,
    (rightManagement) => rightManagement.newRights,
);

export const selectAllUnternehemenNutzerRemoveRights = createSelector(
    selectAllUnternehmenNutzerRightManagement,
    (rightManagement) => rightManagement.removedRights,
);

export const selectAllUnternehemenNutzerIsFoerderbereichRightManagement = createSelector(
    selectAllUnternehmenNutzerRightManagement,
    (rightManagement) => rightManagement.isFoerderbereichRightManagement,
);

export const selectAllUnternehemenNutzerNutzerId = createSelector(
    selectAllUnternehmenNutzerRightManagement,
    (rightManagement) => rightManagement.nutzerId,
);

export const selectFoerderantraegeStoreAllFoerderantraegeContentRightManagement = createSelector(
    selectFoerderantraegeStoreAllFoerderantraegeContent,
    selectAllUnternehemenNutzerRemoveRights,
    selectAllUnternehemenNutzerNewRights,
    selectAllUnternehemenNutzerNutzerFoerderantraegeList,
    (foerderantraege, removedRights, newRights, nutzerFoerderantraegeList) => {
        return foerderantraege.map((foerderantrag) => {
            let isSelected = false;
            const inNutzerFoerderantraegeList = nutzerFoerderantraegeList.findIndex(
                (nutzerFoerderantrag) => foerderantrag.id === nutzerFoerderantrag.id,
            );
            if (inNutzerFoerderantraegeList !== -1) {
                const inRemovedRights = removedRights.findIndex((id) => foerderantrag.id === id);
                if (inRemovedRights === -1) {
                    isSelected = true;
                }
            } else {
                const inNewRights = newRights.findIndex((id) => foerderantrag.id === id);
                if (inNewRights !== -1) {
                    isSelected = true;
                }
            }

            return {
                ...foerderantrag,
                rightManagementCheckbox: isSelected,
            };
        });
    },
);

// Right Management - Nutzer for Foerderantrag View

/**
 * Selects the list of all Nutzer that have access to the current Foerderantrag
 */
export const selectFoerderantragAllUnternehmenNutzerList = createSelector(
    selectAllUnternehmenNutzerRightManagementNutzer,
    (rightManagementNutzer) => rightManagementNutzer.foerderantragNutzerList,
);

/**
 * Selects the list of Nutzer that have been removed from the current Foerderantrag
 */
export const selectAllUnternehemenNutzerRemoveNutzer = createSelector(
    selectAllUnternehmenNutzerRightManagementNutzer,
    (rightManagementNutzer) => rightManagementNutzer.removedNutzer,
);

/**
 * Selects the list of Nutzer that have been added to the current Foerderantrag
 */
export const selectAllUnternehemenNutzerAddNutzer = createSelector(
    selectAllUnternehmenNutzerRightManagementNutzer,
    (rightManagementNutzer) => rightManagementNutzer.newNutzer,
);

/**
 * Selects the current Foerderantrag ID for which the Nutzer list is displayed
 */
export const selectAllUnternehemenNutzerFoerderantragId = createSelector(
    selectAllUnternehmenNutzerRightManagementNutzer,
    (rightManagementNutzer) => rightManagementNutzer.foerderantragId,
);

/**
 * Selects the list of all Nutzer with the rightManagementCheckbox property for the NutzerList per Foerderantrag view
 */
export const selectAllUnternehmenNutzerStoreFoerderantragContentRightManagement = createSelector(
    selectAllUnternehmenNutzer,
    selectAllUnternehemenNutzerRemoveNutzer,
    selectAllUnternehemenNutzerAddNutzer,
    selectFoerderantragAllUnternehmenNutzerList,
    (allUnternehmenNutzer, removedNutzer, newNutzer, foerderantragNutzerList) => {
        return allUnternehmenNutzer
            .filter((nutzer) => nutzer.role !== PortalRole.COMPANY_ADMIN && nutzer.status === true)
            .map((nutzer) => {
                let isSelected = false;

                // List of Nutzer that have access to the current Foerderantrag
                const inFoerderantragNutzerList = foerderantragNutzerList.findIndex((foerderantragNutzer) => nutzer.id === foerderantragNutzer.idpId);

                if (inFoerderantragNutzerList !== -1) {
                    const inRemovedNutzer = removedNutzer.findIndex((id) => nutzer.id === id);
                    if (inRemovedNutzer === -1) {
                        isSelected = true;
                    }
                } else {
                    const inNewNutzer = newNutzer.findIndex((id) => nutzer.id === id);
                    if (inNewNutzer !== -1) {
                        isSelected = true;
                    }
                }

                return {
                    ...nutzer,
                    rightManagementCheckbox: isSelected,
                };
            });
    },
);

export const selectAllUnternehmenNutzerRolleManagement = createSelector(selectAllUnternehmenNutzerStore, (store) => store.rolleManagement);

export const selectAllUnternehmenNutzerRolleManagementNewRolle = createSelector(
    selectAllUnternehmenNutzerRolleManagement,
    (rolleManagement) => rolleManagement.newRolle,
);

export const selectAllUnternehmenNutzerRolleManagementRolle = createSelector(
    selectAllUnternehmenNutzerRolleManagement,
    (rolleManagement) => rolleManagement.rolle,
);
