import { UnternehmenSelectionItem } from "../../../../model/nutzer-unternehmen-selection.model";
import { UnternehmenszuordnungDto } from "../../generated";
import { mapDtoRoleToPortalRole } from "./role-dto.mapper";
import { Unternehmenzuordnung } from '../../../../model/all-unternehmen-nutzer.model';
import { PortalRole } from '../../../../model/auth.model';

export const mapUnternehmenSelectionItems = (dtos: UnternehmenszuordnungDto[]): UnternehmenSelectionItem[] => {
    const items = new Array<UnternehmenSelectionItem>();
    dtos.forEach((dto) => {
        if (dto.unternehmenId !== undefined && dto.unternehmenName !== undefined && dto.rolle !== undefined && dto.aktiv !== undefined) {
            items.push({
                name: dto.unternehmenName,
                unternehmenId: dto.unternehmenId?.toString(),
                userRole: mapDtoRoleToPortalRole(dto.rolle),
                active: dto.unternehmenAktiv
            });
        }
    });
    return items;
}

export const mapUnternehmenszuordnung = (unternehmenszuordnungExt: Required<UnternehmenszuordnungDto>): Unternehmenzuordnung => {
    const unternehmenszuordnung: Unternehmenzuordnung = {
        active: unternehmenszuordnungExt.aktiv,
        nutzerIdpId: unternehmenszuordnungExt.nutzerIdpId,
        unternehmenId: unternehmenszuordnungExt.unternehmenId,
        unternehmenName: unternehmenszuordnungExt.unternehmenName,
        rolle: PortalRole[unternehmenszuordnungExt.rolle as keyof typeof PortalRole],
    };
    return unternehmenszuordnung;
};