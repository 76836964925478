<div class="nebenantraege-tab">
    <ng-container *ngIf="displayedNebenantragsarten$ | async as displayedNebenantragsarten">
        <ng-container *ngIf="displayedNebenantragsarten.length > 0; else noDataTemplate">
            <ng-container *ngFor="let displayedNebenantragsart of displayedNebenantragsarten">
                <button
                    class="nebenantraege-tab__art"
                    (click)="navigateToNebenantragsDetails(displayedNebenantragsart)"
                    [attr.data-testid]="'nebenantragsart-' + displayedNebenantragsart.id">
                    <app-card>
                        <div class="nebenantraege-tab__art__button-card">
                            <p
                                class="text-accordeon-card-title"
                                style="width: 50%; text-align: left">
                                {{ displayedNebenantragsart.bezeichnung }}
                            </p>
                            <p
                                class="text-plain"
                                style="width: 25%">
                                Anzahl bisheriger gestellten Nebenanträge: {{ displayedNebenantragsart.amount }}
                            </p>
                            <div style="width: 25%">
                                <app-button
                                    *ngIf="(nebenantragsartenCreateableIds$ | async)?.includes(displayedNebenantragsart.id)"
                                    buttonClass="nebenantraege-tab__art__button-card--button-width"
                                    [isButton]="true"
                                    (clickEvent)="$event.stopPropagation(); navigateToFormular(displayedNebenantragsart)"
                                    [buttonLabel]="getNewAntragLabel(displayedNebenantragsart)"
                                    data-testid="create-nebenantrag-button"></app-button>
                            </div>
                        </div>
                    </app-card>
                </button>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
<ng-template #noDataTemplate>
    <div class="nebenantraege-tab__art__no-data">
        <h2 class="nebenantraege-tab__art__no-data--empty">Es sind keine Nebenanträge vorhanden.</h2>
    </div>
</ng-template>
