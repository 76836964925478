import { Component, Input, OnInit, inject } from '@angular/core';
import { TableAction } from '../../../model/data-table.model';
import { DialogData } from '../../../model/dialog.model';
import { DialogHelperService } from '../../../service/helper/dialog.helper.service';

/**
 * Component that creates the table action menu and menu trigger for the given row.
 */
@Component({
    selector: 'app-data-table-action-menu',
    templateUrl: './data-table-action-menu.component.html',
    styleUrl: './data-table-action-menu.component.scss',
})
export class DataTableActionMenuComponent implements OnInit {
    private dialogHelperService = inject(DialogHelperService);
    @Input() tableActions: TableAction[];
    @Input() detailId: number;

    /**
     * add the detail id to the link and set a undefined link to an empty link
     */
    ngOnInit(): void {
        for (const tableAction of this.tableActions) {
            if (tableAction.addId && tableAction.isLink && tableAction.link !== undefined) {
                tableAction.link[tableAction.addId] = this.detailId.toString();
            } else if (tableAction.isLink && tableAction.link === undefined) {
                tableAction.link = [''];
            }
        }
    }

    openDialog = (dialogData: DialogData | undefined): void => {
        const data = dialogData ? dialogData : { title: '', actions: [] };
        this.dialogHelperService.openNewDialog(data);
    };
}
