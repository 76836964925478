import { Stammdaten } from "../../../../model/stammdaten.model";
import { UnternehmenWithRelationsDto } from "../../generated";
import { mapAdresse } from "./adresse.mapper";
import { mapGeschaeftsfuehrer } from "./geschaeftsfuehrer.mapper";
import { mapNiederlassung } from "./niederlassung.mapper";

/**
 * Map the UnternehmenWithRelationsDto to the stammdaten type in the frontend
 * @param stammdaten the dto coming from the backend
 * @returns the stammdaten type
 */
export const mapStammdaten = (stammdaten: UnternehmenWithRelationsDto): Stammdaten => ({
    id: stammdaten.id,
    ffaid: stammdaten.ffaid,
    unternehmensname: stammdaten.unternehmensname,
    rechtsform: stammdaten.rechtsform,
    andereRechtsform: stammdaten.andereRechtsform,
    geschaeftsfuehrer: stammdaten.geschaeftsfuehrer.map((geschaeftsfuehrer) => mapGeschaeftsfuehrer(geschaeftsfuehrer)),
    niederlassungen: stammdaten.niederlassungen?.map((niederlassung) => mapNiederlassung(niederlassung)),
    adresse: mapAdresse(stammdaten.adresse),
    umsatzsteuerId: stammdaten.umsatzsteuerId,
    kmu: stammdaten.kmu,
    vorsteuerabzugsberechtigt: stammdaten.vorsteuerabzugsberechtigt,
    steueridentifikationsnummer: stammdaten.steueridentifikationsnummer,
    juristischePerson: stammdaten.juristischePerson,
    dokumentIdForHraOrGewerbeschein: stammdaten.dokumentIdForHraOrGewerbeschein,
    dokumentnameForHraOrGewerbeschein: stammdaten.dokumentnameForHraOrGewerbeschein,
    idOfRegistrationForm: stammdaten.idOfRegistrationForm,
})
