import { inject, Injectable } from "@angular/core";
import { catchError, forkJoin, map, Observable, of } from "rxjs";

import { ErrorDefaultInput, ErrorDefaultValues, ErrorInformation, ErrorTitle, LogLevel, LogLevelServer, LogMessage } from "../../../model/error-store.model";
import { LoggingCommonControllerService } from "../generated";
import { Store } from "@ngrx/store";
import { errorActions } from "../../../store/error-store/error.actions";
import { DEFAULT_DATE_STRING_CONFIG } from "../../../model/data-table.model";

/* eslint no-console: 0 */ //Deactive the no-console here for 
@Injectable({
    providedIn: 'root'
})
export class GlobalExceptionHandlerService {
    private loggingService = inject(LoggingCommonControllerService);
    private store = inject(Store);

    createLog(message: string, logLevel: LogLevel): LogMessage {
        const logMessage: LogMessage = {
            message,
            level: logLevel,
            timestamp: new Date().toLocaleString('de-DE', DEFAULT_DATE_STRING_CONFIG),
        };
        console[logLevel](logMessage.timestamp, logMessage.message);
        return logMessage;
    }

    writeToServer(logMessage: LogMessage): Observable<boolean> {
        return this.loggingService.createLogEntry({
            level: logMessage.level.toUpperCase() as LogLevelServer,
            message: logMessage.timestamp + ' ' + logMessage.message,
        })
    }

    logAllToServer(logMessageList: LogMessage[]): Observable<boolean> {
        const responseList = logMessageList.map(logMessage => this.writeToServer(logMessage));
        return forkJoin(responseList).pipe(
            map((response) => response.every(value => value === true)),
            catchError((error) => {
                this.handleGeneratedApiError(error)
                return of(false);
            })
        );

    }

    createErrorInformation(newError: ErrorDefaultInput): ErrorInformation {
        const errorCodeDefaults = this.defaultErrorInformation(newError.status);
        const errorInformation: ErrorInformation = {
            ...newError,
            ...errorCodeDefaults,
        };
        return errorInformation;
    }

    private defaultErrorInformation(errorCode: number,): ErrorDefaultValues {
        const errorCodeDefaults: ErrorDefaultValues = {
            shouldLogDirectly: false,
        }
        switch (errorCode) {
            case 400: //Bad Request
                errorCodeDefaults.possibleSolution = 'Sie können entweder nocheinmal versuchen die Aktion durchzuführen oder sie laden neu und versuchen es erneut.';
                break;
            case 401: //Unauthorized
                errorCodeDefaults.possibleSolution = 'Falls der Retry das problem nicht behebt, probieren Sie es mit einem erneuten Login.'
                break;
            case 403: //Forbidden
                errorCodeDefaults.possibleSolution = 'Es tut uns leid, diese Aktion dürfen Sie in Ihrer Rolle nicht durchführen.'
                break;
            case 404: //Not Found
                break;
            case 408: //Request Timeout
                errorCodeDefaults.possibleSolution = 'Es kam zu einer Zeitüberschreitung. Falls der Retry das problem nicht behebt, probieren Sie es in einigen Minuten erneut.'
                break;
            case 500: //Internal Server Error
                errorCodeDefaults.possibleSolution = 'Der Server ist zur Zeit nicht erreichbar. Es wird versucht Ihre Anfrage erneut zu verarbeiten, haben sie ein wenig Gedult. Falls die Retrys nicht helfen versuchen Sie es zu einem späteren Zeitpunkt erneut'
                break;
            case -1: //Unknown Error
                errorCodeDefaults.shouldLogDirectly = true;
                errorCodeDefaults.possibleSolution = 'Es tut uns Leid. Dies ist ein nicht bekannter Fehler. Die notwendigen anonymen Daten wurden an den Server gesendet und der Fehler wird sobald wie möglich untersucht.'
                break;
            case 1: //Form Communication Error
                break;
            case 2: //AWS Upload Error
                errorCodeDefaults.shouldLogDirectly = true;
                break;
            case 4: //Form JSON contains type: "file" with a value
                errorCodeDefaults.possibleSolution = 'Versuchen Sie es erneut. Sollte das Problem weiterhin bestehen, überprüfen Sie bitte die angehängten Dateien und laden Sie diese direkt über den Hochlade-Button neben dem Dateifeld hoch.';
                break;
            case 0:
            default:
                break;
        }
        return errorCodeDefaults
    }

    // Errors can be many different things and on type unknown I can not use the object keys I know will be on the errors we get.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleGeneratedApiError(error: any): void {
        const newError: ErrorDefaultInput = {
            status: error.status,
            detail: error.body.detail,
            instance: error.url,
            title: ErrorTitle[error.status],
            error,
        };
        if (newError.detail === "Validation Failed") {
            newError.possibleSolutionServerSend = Object.entries(error.body.validationHints as Record<string, string[]>).flatMap(([key, values]) =>
                values.map(value => `${key}: ${value}`)
            );
        }
        this.store.dispatch(errorActions.updateShownError({ newError }));
    }

    handleUnknownError(error: Error): void {
        const newError: ErrorDefaultInput = {
            status: -1,
            detail: error.message,
            instance: 'Unknown',
            title: ErrorTitle[-1],
            error,
        };
        this.store.dispatch(errorActions.updateShownError({ newError }));
    }

    handleAWSError(error: Error): void {
        const newError: ErrorDefaultInput = {
            status: 3,
            detail: error.message,
            instance: 'AWS',
            title: ErrorTitle[3],
            error,
        };
        this.store.dispatch(errorActions.updateShownError({ newError }));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleForm401Error(error: any): void {
        const newError: ErrorDefaultInput = {
            status: 401,
            detail: error.message,
            instance: error.url,
            title: ErrorTitle[401],
            error,
        };
        this.store.dispatch(errorActions.updateShownError({ newError }));
    }
}