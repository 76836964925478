import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { foerderbereichActions } from './foerderbereich.actions';
import { forkJoin, map, of, switchMap } from 'rxjs';
import { NutzerFoerderbereicheApiService } from '../../service/api/service/nutzer-foerderbereiche.service';
import { Store } from '@ngrx/store';
import { selectActiveUnternehmenId } from '../nutzer-unternehmen-selection/nutzer-unternehmen-selection.selectors';
import { allUnternehmenNutzerActions, nutzerRightManagementFoerderantragViewActions } from '../all-unternehmen-nutzer/all-unternehmen-nutzer.actions';
import { foerderantraegeActions } from '../foerderantraege/foerderantraege.actions';
import { selectIsUnternehmenRole } from '../auth/auth.selectors';

/**
 * The effects for the foerderbereicheStore that do the service calls to fetch the wanted data and trigger the corresponding set function
 */
@Injectable()
export class FoerderbereichEffects {
    private actions$ = inject(Actions);
    private nutzerFoerderbereicheService = inject(NutzerFoerderbereicheApiService);
    private store = inject(Store);

    getUserFoerderbereiche$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(foerderantraegeActions.changeAllFoerderantraege),
            concatLatestFrom(() => [this.store.select(selectActiveUnternehmenId), this.store.select(selectIsUnternehmenRole)]),
            switchMap(([, unternehmenId, userHasUnternehmenRole]) => {
                if (!userHasUnternehmenRole) {
                    return of(foerderbereichActions.setNutzerFoerderbereich({ newNutzerFoerderbereiche: [] }));
                } else {
                    return this.nutzerFoerderbereicheService
                        .getNutzerFoerderbereiche(unternehmenId)
                        .pipe(map((foerderbereiche) => foerderbereichActions.setNutzerFoerderbereich({ newNutzerFoerderbereiche: foerderbereiche })));
                }
            }),
        );
    });

    getUserFoerderbereicheAndFoerderarten = createEffect(() => {
        return this.actions$.pipe(
            ofType(foerderbereichActions.changeFoerderbereicheUndFoerderarten),
            concatLatestFrom(() => this.store.select(selectActiveUnternehmenId)),
            switchMap(([, unternehmenId]) => {
                return forkJoin({
                    foerderbereiche: this.nutzerFoerderbereicheService.getNutzerFoerderbereiche(unternehmenId),
                    foerderarten: this.nutzerFoerderbereicheService.getNutzerFoerderarten(unternehmenId),
                }).pipe(
                    map(({ foerderbereiche, foerderarten }) =>
                        foerderbereichActions.setFoerderbereicheUndFoerderarten({
                            newNutzerFoerderarten: foerderarten,
                            newNutzerFoerderbereiche: foerderbereiche,
                        }),
                    ),
                );
            }),
        );
    });

    getAllFoerderbereiche$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                allUnternehmenNutzerActions.changeAllUnternehmenNutzer,
                nutzerRightManagementFoerderantragViewActions.changeNutzerFoerderbereichList,
            ),
          concatLatestFrom(() => this.store.select(selectActiveUnternehmenId)),
          switchMap(([, unternehmenId]) =>
                this.nutzerFoerderbereicheService
                    .getAllActiveFoerderbereiche(unternehmenId)
                    .pipe(map((foerderbereiche) => foerderbereichActions.setAllFoerderbereiche({ newAllFoerderbereiche: foerderbereiche }))),
            ),
        );
    });
}
