import { createActionGroup, emptyProps, props } from "@ngrx/store";
import {
    FileUploadDetails,
    FormFileType,
    GetPresignedUrlsResponse,
    InitializeResponse
} from "../../model/aws-service.model";
import { AntragsType, FormField } from "../../model/formular.model";

/**
 * The actions to set the displayed form
 */
export const formularActionsInt = createActionGroup(
    {
        source: 'Formular display',
        events: {
            postChangeCreateNewFormular: props<{
                shortName: string,
                antragsArtId: number,
                antragsType: AntragsType,
                foerderAntragsId?: number,
                antragsart: string
            }>(),
            setUpNewFormular: props<{ shortName: string }>(),
            setActiveAntragsnummer: props<{ antragsnummer: string }>(),
            postChangeExistingFormular: props<{ antragsnummer: string, antragsArt: string, formularShortname: string }>(),
            setExistingFormular: props<{ formularShortname: string, antragsnummer: string }>(),
            resetFormularState: emptyProps(),
            changeFormContent: emptyProps(),
            setFormContent: props<{ formContent: FormField[] }>(),
            changeFormContentFailure: emptyProps(),
            changeCreateUnternehmen: emptyProps(),
            changeCreateUnternehmenSuccess: props<{ antragsnummer: string }>(),
            updateUnternehmensname: props<{ unternehmensname: string }>(),
            setListenersReady: emptyProps(),
            initializeNewTab: emptyProps(),
            changeUnternehmenStammdaten: emptyProps(),
        }
    }
);

/**
 * The actions that are used with the file upload
 */
export const formularActionsExt = createActionGroup(
    {
        source: 'Formular file upload',
        events: {
            changeInitializationFileUpload: props<{ file: File, formFileType: FormFileType, htmlFieldId: string, status?: string, returnMessagePort: MessagePort }>(),
            updateGeneratePresignedUrls: props<{ initializeResponse: InitializeResponse, fileUploadDetails: FileUploadDetails }>(),
            changeGeneratePresignedUrlsUpload: props<{ presignedUrls: GetPresignedUrlsResponse, partsMax: number, fileUploadDetails: FileUploadDetails }>(),
            setFinializeUpload: emptyProps(),
            incrementUploadedParts: emptyProps(),
            setInitializationFileUploadFailure: emptyProps(),
            setGeneratePresignedUrlsFailure: emptyProps(),
            setGeneratePresignedUrlsUploadFailure: emptyProps(),
            setFinializeUploadFailure: emptyProps(),
            resetNumberOfClosedFileUploads: emptyProps(),
            awaitRefresh: emptyProps(),
            setShowProgressBar: props<{ showProgressBar: boolean }>(),
        }
    }
)

