import { createReducer, on } from "@ngrx/store";
import { EMPTYNUTZERPROFILE, NutzerProfileStore } from "../../model/nutzer-profile.model";
import { nutzerProfileActions } from "./nutzer-profile.actions";

export const nutzerProfileInitialState: NutzerProfileStore = {
    profile: EMPTYNUTZERPROFILE,
    isLoading: false,
    changes: EMPTYNUTZERPROFILE,
    passwordChange: {
        passwordChanged: false,
        error: '',
    }
}

export const nutzerProfileReducer = createReducer(
    nutzerProfileInitialState,
    on(nutzerProfileActions.changeNutzerProfile, (state): NutzerProfileStore => ({ ...state, isLoading: true })),
    on(nutzerProfileActions.changeNutzerProfileChanges, (state, action): NutzerProfileStore => ({
        ...state,
        isLoading: true,
        changes: {
            ...state.changes,
            ...action.newNutzerProfileChanges,
            idpId: state.profile.idpId
        }
    })),
    on(nutzerProfileActions.setNutzerProfile, (state, action): NutzerProfileStore => ({
        ...state,
        isLoading: false,
        profile: { ...action.newNutzerProfileData },
        changes: { ...action.newNutzerProfileData }
    })),
    on(nutzerProfileActions.resetChangesToNutzerProfile, (state): NutzerProfileStore => ({ ...state, changes: { ...state.profile } })),
    on(nutzerProfileActions.resetNutzerProfile, (): NutzerProfileStore => ({ ...nutzerProfileInitialState })),
    on(nutzerProfileActions.setNutzerProfilePasswordChangeInitiated, (state): NutzerProfileStore => ({ ...state, passwordChange: { ...nutzerProfileInitialState.passwordChange } })),
    on(nutzerProfileActions.setNutzerProfilePasswordChangeSuccess, (state): NutzerProfileStore => ({ ...state, passwordChange: { passwordChanged: true, error: '' } })),
    on(nutzerProfileActions.setNutzerProfilePasswordChangeFailed, (state, action): NutzerProfileStore => ({ ...state, passwordChange: { passwordChanged: false, error: action.error } }))
)