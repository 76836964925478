// imports
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { AsyncPipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { UnternehmenSelectionComponent } from './header/unternehmen-selection/unternehmen-selection.component';
import { UserMenuComponent } from './header/user-menu/user-menu.component';
import { BreadcrumbComponent } from './breadcrumbs/breadcrumb.component';

// @NgModule decorator with its metadata
@NgModule({
    declarations: [LayoutComponent, HeaderComponent, FooterComponent, UnternehmenSelectionComponent, UserMenuComponent, BreadcrumbComponent],
    imports: [RouterModule, MatFormFieldModule, MatSelectModule, MatMenuModule, AsyncPipe, BrowserModule],
    exports: [LayoutComponent, UnternehmenSelectionComponent],
})
export class LayoutModule { }
