import { Component, DestroyRef, EventEmitter, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { allUnternehmenNutzerActions } from '../../store/all-unternehmen-nutzer/all-unternehmen-nutzer.actions';
import { PortalRole } from '../../model/auth.model';
import { DataTableConfig, QuickFilterMenuItem, TableAction } from '../../model/data-table.model';
import { SortParameter } from '../../model/store.model';
import { PageEvent } from '@angular/material/paginator';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, first } from 'rxjs';
import { selectFoerderbereicheAllFoerderbereicheBezeichnungen } from '../../store/foerderbereich/foerderbereich.selectors';
import {
    selectAllUnternehmenNutzer,
    selectAllUnternehmenNutzerIsLoading,
    selectAllUnternehmenNutzerPage,
    selectAllUnternehmenNutzerSize,
    selectAllUnternehmenNutzerSortingArray,
    selectAllUnternehmenNutzerTableActions,
    selectAllUnternehmenNutzerTotalElements,
} from '../../store/all-unternehmen-nutzer/all-unternehmen-nutzer.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DialogHelperService } from '../../service/helper/dialog.helper.service';

@Component({
    selector: 'app-all-unternehmen-nutzer',
    templateUrl: './all-unternehmen-nutzer.component.html',
    styleUrl: './all-unternehmen-nutzer.component.scss',
})
export class AllUnternehmenNutzerComponent implements OnInit {
    private store = inject(Store);
    private destroyRef = inject(DestroyRef);
    private dialogHelperService = inject(DialogHelperService);

    searchValue = '';
    pageElement = { page: 0, size: 20 };
    activeFilter: boolean | null = null;
    roleFilter: string[] | null = null;
    allFoerderbereichFilter: string | null = null;

    tableConfig: DataTableConfig;
    tableActions$: Observable<TableAction[][]>;
    tableContent$: Observable<Record<string, string | number | boolean>[]>;
    tableIsLoading$: Observable<boolean>;
    tableSortingArray$: Observable<SortParameter[]>;
    tableDisplayedSize$: Observable<number>;
    tableDisplayedPage$: Observable<number>;
    tableTotalElements$: Observable<number>;
    tableActions: TableAction[][];

    quickFilterStatusEvent: EventEmitter<string> = new EventEmitter<string>();
    quickFilterRoleEvent: EventEmitter<string> = new EventEmitter<string>();
    quickFilterAllFoerderbereicheEvent: EventEmitter<string> = new EventEmitter<string>();
    quickFilterStatusForm = new FormGroup({
        statusFilterControl: new FormControl('null'),
    });
    quickFilterRoleForm = new FormGroup({
        roleFilterControl: new FormControl('null'),
    });
    quickFilterAllFoerderbereicheForm = new FormGroup({
        allFoerderbereicheControl: new FormControl('null'),
    });
    quickFilterMenuItems: QuickFilterMenuItem[];

    ngOnInit(): void {
        this.store.dispatch(allUnternehmenNutzerActions.changeAllUnternehmenNutzer());

        this.tableActions$ = this.store.select(selectAllUnternehmenNutzerTableActions);
        this.tableContent$ = this.store.select(selectAllUnternehmenNutzer);
        this.tableIsLoading$ = this.store.select(selectAllUnternehmenNutzerIsLoading);
        this.tableSortingArray$ = this.store.select(selectAllUnternehmenNutzerSortingArray);
        this.tableDisplayedSize$ = this.store.select(selectAllUnternehmenNutzerSize);
        this.tableDisplayedPage$ = this.store.select(selectAllUnternehmenNutzerPage);
        this.tableTotalElements$ = this.store.select(selectAllUnternehmenNutzerTotalElements);

        this.store
            .select(selectFoerderbereicheAllFoerderbereicheBezeichnungen)
            .pipe(first((value) => value.length > 0))
            .subscribe((value) => {
                this.initializeAllUnternehmenNutzerQuickFilters(value);
            });

        this.tableActions$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((content) => {
            this.tableActions = content;
        });

        this.quickFilterStatusEvent.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((eventValue) => {
            this.activeFilterHandler(eventValue);
        });
        this.quickFilterRoleEvent.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((eventValue) => {
            this.roleFilterHandler(eventValue);
        });
        this.quickFilterAllFoerderbereicheEvent.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((eventValue) => {
            this.allFoerderbereichFilterHandler(eventValue);
        });
        this.configChange();
    }

    /** Event handler for the "Benutzer hinzufügen" button */
    openAddUserDialog(): void {
        this.dialogHelperService.openNewDialog(this.dialogHelperService.createNewUserDialogData());
    }

    /** quick Filter */
    initializeAllUnternehmenNutzerQuickFilters(allFoerderbereicheBezeichnungen: string[]): void {
        this.quickFilterMenuItems = [
            {
                name: 'Status',
                emitter: this.quickFilterStatusEvent,
                formControllerName: 'statusFilterControl',
                formGroup: this.quickFilterStatusForm,
                selectionList: ['Aktiv', 'Inaktiv'],
            },
            {
                name: 'Rolle',
                emitter: this.quickFilterRoleEvent,
                formControllerName: 'roleFilterControl',
                formGroup: this.quickFilterRoleForm,
                selectionList: [PortalRole.COMPANY_USER, PortalRole.COMPANY_ADMIN],
            },
            {
                name: 'Berechtigung für',
                emitter: this.quickFilterAllFoerderbereicheEvent,
                formControllerName: 'allFoerderbereicheControl',
                formGroup: this.quickFilterAllFoerderbereicheForm,
                selectionList: [...allFoerderbereicheBezeichnungen],
            },
        ];
    }

    /**
     * Create the table configuration that is passed to the generic table component.
     */
    configChange(): void {
        this.tableConfig = {
            columns: [
                { columnKey: 'statusString', display: 'Status' },
                { columnKey: 'vorname', display: 'Vorname' },
                { columnKey: 'nachname', display: 'Name' },
                { columnKey: 'email', display: 'E-Mail-Adresse' },
                { columnKey: 'role', display: 'Rolle' },
            ],
            tableLabel: 'Tabelle aller Benutzer*innen des Unternehmens.',
            tableColumnClass: 'all-unternehmen-nutzer',
            tableActions$: this.tableActions$,
            tableDataObjectsName: 'Benutzer',
            tableDetailId: 'id',
            paginationNames: {
                itemsPerPageLabel: 'Benutzer*innen pro Seite',
                nextPageLabel: 'Nächste Seite',
                previousPageLabel: 'Vorherige Seite',
                firstPageLabel: 'Erste Seite',
                lastPageLabel: 'Letzte Seite',
            },
            tableContent$: this.tableContent$,
            tableIsLoading$: this.tableIsLoading$,
            tableSortingArray$: this.tableSortingArray$,
            tableDisplayedSize$: this.tableDisplayedSize$,
            tableDisplayedPage$: this.tableDisplayedPage$,
            tableTotalElements$: this.tableTotalElements$,
            headerIsSticky: true,
        };
    }

    /**
     * Handle the search input.
     *
     * @param searchEvent holds the search value
     */
    searchHandler(searchEvent: string): void {
        if (this.searchValue !== searchEvent) {
            this.searchValue = searchEvent;
            this.store.dispatch(allUnternehmenNutzerActions.changeSearchParameter({ newSearchParameter: searchEvent }));
            this.pageElement.page = 0;
        }
    }

    /**
     * Handle the sorting of a table column
     *
     * @param sortEvent holds the new sortParameter and if the sort parameter should be added to the end of the sorting array or if it should create a new list
     */
    sortHandler(sortEvent: { sort: SortParameter; isMultiSort: boolean }): void {
        this.store.dispatch(
            allUnternehmenNutzerActions.changeSorting({
                newSort: sortEvent.sort,
                isMultiSort: sortEvent.isMultiSort,
            }),
        );
        this.pageElement.page = 0;
    }

    /**
     *  Handle the use of the paginatior. The paginator triggers a new event for every change so only one of the two looked out values will change at a time.
     *
     * @param pageEvent holds the size and the active page of the paginator return.
     */
    pageHandler(pageEvent: PageEvent): void {
        if (pageEvent.pageSize !== this.pageElement.size) {
            this.store.dispatch(allUnternehmenNutzerActions.changeSize({ newSize: pageEvent.pageSize }));
            this.pageElement.size = pageEvent.pageSize;
            this.pageElement.page = 0;
        } else if (pageEvent.pageIndex !== this.pageElement.page) {
            this.store.dispatch(allUnternehmenNutzerActions.changePage({ newPage: pageEvent.pageIndex }));
            this.pageElement.page = pageEvent.pageIndex;
        }
    }

    /**
     * Handles the quick filter status selection
     *
     * @param event holds the string with the quick search status value
     */
    activeFilterHandler(event: string): void {
        const activeEvent: boolean | null = event === 'null' ? null : event === 'Aktiv';
        if (this.activeFilter !== activeEvent) {
            this.store.dispatch(allUnternehmenNutzerActions.changeActiveFilter({ newActiveFilter: activeEvent }));
            this.activeFilter = activeEvent;
            this.pageElement.page = 0;
        }
    }

    /**
     * Handles the quick filter --- selection
     *
     * @param event holds the string with the --- filter
     */
    roleFilterHandler(event: string): void {
        const roleEvent: string[] | null = event === 'null' ? null : [event];
        if (
            (this.roleFilter !== null && this.roleFilter.findIndex((data) => data === event) === -1) ||
            (this.roleFilter === null && roleEvent !== null)
        ) {
            this.store.dispatch(allUnternehmenNutzerActions.changeRoleFilter({ newRoleFilter: roleEvent }));
            this.roleFilter = roleEvent;
            this.pageElement.page = 0;
        }
    }

    /**
     * Handles the selection of the allFoerderbereich filter.
     *
     * @param event A string representing the selected allFoerderbereich filter. If 'null', sets the filter to null.
     */
    allFoerderbereichFilterHandler(event: string): void {
        const allFoerderbereicheEvent: string | null = event === 'null' ? null : event;
        if (this.allFoerderbereichFilter !== allFoerderbereicheEvent) {
            this.store.dispatch(allUnternehmenNutzerActions.changeAllFoerderbereicheFilter({ newAllFoerderbereicheFilter: allFoerderbereicheEvent }));
            this.allFoerderbereichFilter = allFoerderbereicheEvent;
            this.pageElement.page = 0;
        }
    }

    /**
     * Handles the row click event to manage rights selection.
     *
     * @param event An object containing the id and the current selection state of the row.
     *              The id is converted to a number and the selection state is toggled.
     */
    rowClickHandler(index: number): void {
        if (this.tableActions.length > 0) {
            const tableAction = this.tableActions[index].find((tableAction) => tableAction.name === 'Rechte verwalten');
            if (tableAction && tableAction.dialogData) {
                this.dialogHelperService.openNewDialog(tableAction.dialogData);
            }
        }
    }
}
