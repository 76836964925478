import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorNameing } from '../../model/data-table.model';

/**
 * Class so that MatPaginatorIntl is not shared between the tables.
 */
@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
    constructor() {
        super();
    }

    /**
     * Function to set the labels of the paginator
     * @param labels the labels that should be used
     */
    setLabels(labels: PaginatorNameing): void {
        this.itemsPerPageLabel = labels.itemsPerPageLabel;
        this.nextPageLabel = labels.nextPageLabel;
        this.previousPageLabel = labels.previousPageLabel;
        this.firstPageLabel = labels.firstPageLabel;
        this.lastPageLabel = labels.lastPageLabel;
        this.getRangeLabel = (page: number, pageSize: number, length: number): string => {
            let calcLength = length;
            if (calcLength === 0 || pageSize === 0) {
                return '0 - 0 von 0';
            }
            calcLength = Math.max(calcLength, 0);
            const startIndex = page * pageSize;
            const endIndex = startIndex < calcLength ? Math.min(startIndex + pageSize, calcLength) : startIndex + pageSize;
            return `${startIndex + 1} - ${endIndex} von ${calcLength}`;
        };

        // Notify changes so that the labels get updated in the UI
        this.changes.next();
    }
}
