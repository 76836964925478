import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FoerderantragHistoryTableStore } from '../../model/foerderantrag-history-table.model';
import { DEFAULT_DATE_STRING_CONFIG } from '../../model/data-table.model';

export const selectFoerderantragHistoryTableStore = createFeatureSelector<FoerderantragHistoryTableStore>('foerderantragHistoryTableStore');

export const selectFoerderantragHistoryTableSize = createSelector(selectFoerderantragHistoryTableStore, (foerderantragHistoryTableStore) => {
    return foerderantragHistoryTableStore.size;
});

export const selectFoerderantragHistoryTablePage = createSelector(selectFoerderantragHistoryTableStore, (foerderantragHistoryTableStore) => {
    return foerderantragHistoryTableStore.page;
});

export const selectFoerderantragHistoryTableIsLoading = createSelector(selectFoerderantragHistoryTableStore, (foerderantragHistoryTableStore) => {
    return foerderantragHistoryTableStore.isLoading;
});

export const selectFoerderantragHistoryTableSortingArray = createSelector(selectFoerderantragHistoryTableStore, (foerderantragHistoryTableStore) => {
    return foerderantragHistoryTableStore.sortingArray;
});

export const selectFoerderantragHistoryTableTotalElements = createSelector(selectFoerderantragHistoryTableStore, (foerderantragHistoryTableStore) => {
    return foerderantragHistoryTableStore.totalElements;
});

export const selectFoerderantragHistoryTableContent = createSelector(selectFoerderantragHistoryTableStore, (foerderantragHistoryTableStore) => {
    return foerderantragHistoryTableStore.foerderantragHistoryTableContent.map(content => ({ ...content, zeitstempel: new Date(content.zeitstempel).toLocaleString('de-DE', DEFAULT_DATE_STRING_CONFIG) }));
});

export const selectFoerderantragHistoryTableModifiers = createSelector(
    selectFoerderantragHistoryTableSize,
    selectFoerderantragHistoryTablePage,
    selectFoerderantragHistoryTableSortingArray,
    (size, page, sortArray) => {
        const sort: string[] = sortArray.map((sort) => sort.column + ',' + sort.sortDirection);
        return { size, page, sort };
    },
);
