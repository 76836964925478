import { Component, DestroyRef, OnInit, TemplateRef, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../../model/dialog.model';
import { Store } from '@ngrx/store';
import { selectDokumentContentIsLoading } from '../../store/dokumente-unternehmen/dokumente-unternehmen.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-open-dialog',
    templateUrl: './open-dialog.component.html',
    styleUrls: ['./open-dialog.component.scss'],
})
export class OpenDialogComponent implements OnInit {
    data: DialogData = inject(MAT_DIALOG_DATA);
    dialogRef: MatDialogRef<OpenDialogComponent> = inject(MatDialogRef);
    private store = inject(Store);
    private destroyRef = inject(DestroyRef);

    @ViewChild('dialogContent', { read: ViewContainerRef, static: true }) dialogContent!: ViewContainerRef;
    @ViewChild('rightManagementTemplate', { static: true }) rightManagementTemplate!: TemplateRef<unknown>;
    @ViewChild('postkorbNachrichtDialogTemplate', { static: true }) postkorbNachrichtDialogTemplate!: TemplateRef<unknown>;
    @ViewChild('foerderantragTemplate', { static: true }) foerderantragTemplate!: TemplateRef<unknown>;
    @ViewChild('allantraegeTemplate', { static: true }) allantraegeTemplate!: TemplateRef<unknown>;
    @ViewChild('addNewUserTemplate', { static: true }) addNewUserTemplate!: TemplateRef<unknown>;
    @ViewChild('rolleManagementTemplate', { static: true }) rolleManagementTemplate!: TemplateRef<unknown>;
    @ViewChild('lesebestaetigungTemplate', { static: true }) lesebestaetigungTemplate!: TemplateRef<unknown>;
    @ViewChild('downloadSpinnerTemplate', { static: true }) downloadSpinnerTemplate!: TemplateRef<unknown>;

    wasLoading: boolean

    ngOnInit(): void {
        this.loadTemplate();
        this.runOnLoadActions();
        if (this.data.contentTemplateName === 'downloadSpinnerTemplate') {
            this.store.select(selectDokumentContentIsLoading).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
                (isLoading) => {
                    if (isLoading) {
                        this.wasLoading = true;
                    } else if (this.wasLoading) {
                        this.dialogRef.close();
                    }
                }
            )
        }
    }

    loadTemplate(): void {
        this.dialogContent.clear();
        let selectedTemplate: TemplateRef<unknown> | null = null;
        switch (this.data.contentTemplateName) {
            case 'rightManagementTemplate':
                selectedTemplate = this.rightManagementTemplate;
                break;
            case 'postkorbNachrichtDialogTemplate':
                selectedTemplate = this.postkorbNachrichtDialogTemplate;
                break;
            case 'foerderantragTemplate':
                selectedTemplate = this.foerderantragTemplate;
                break;
            case 'allantraegeTemplate':
                selectedTemplate = this.allantraegeTemplate;
                break;
            case 'addNewUserTemplate':
                selectedTemplate = this.addNewUserTemplate;
                break;
            case 'rolleManagementTemplate':
                selectedTemplate = this.rolleManagementTemplate;
                break;
            case 'lesebestaetigungTemplate':
                selectedTemplate = this.lesebestaetigungTemplate;
                break;
            case 'downloadSpinnerTemplate':
                selectedTemplate = this.downloadSpinnerTemplate;
                break;
            default:
                break;
        }
        if (selectedTemplate) {
            this.dialogContent.createEmbeddedView(selectedTemplate);
        }
    }

    runOnLoadActions(): void {
        if (this.data.onLoad) {
            this.data.onLoad.forEach((action) => action.onLoad());
        }
    }
}
