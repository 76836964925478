import { inject, Injectable } from "@angular/core";
import { StammdatenUnternehmenAPIService } from "../../service/api/service/stammdaten-unternehmen.service";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { stammdatenActions } from "./stammdaten.actions";
import { Store } from "@ngrx/store";
import { selectActiveUnternehmenId } from "../nutzer-unternehmen-selection/nutzer-unternehmen-selection.selectors";
import { catchError, map, of, switchMap } from "rxjs";
import { GlobalExceptionHandlerService } from "../../service/api/service/global-exception-handler.service";

@Injectable()
export class StammdatenEffects {
    private actions$ = inject(Actions);
    private store = inject(Store);
    private stammdatenUnternehmenAPIService = inject(StammdatenUnternehmenAPIService);
    private globalExceptionHandlerService = inject(GlobalExceptionHandlerService);

    /**
     * Effect to load the Stammdaten of the Unternehmen
     */
    changeLoadStammdaten$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(stammdatenActions.changeLoadStammdaten),
            concatLatestFrom(() => [
                this.store.select(selectActiveUnternehmenId),
            ]),
            switchMap(([, unternehmenId]) =>
                this.stammdatenUnternehmenAPIService.getUnternehmenStammdaten(unternehmenId).pipe(
                    map((stammdaten) => stammdatenActions.setLoadStammdatenSuccess({ newStammdaten: stammdaten })
                    ),
                    catchError(error => {
                        this.globalExceptionHandlerService.handleGeneratedApiError(error);
                        return of(stammdatenActions.resetStammdatenStore());
                    })
                ))
        )
    })
}