import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { DataTableConfig } from '../../../model/data-table.model';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { PageEvent } from '@angular/material/paginator';

import { SortParameter } from '../../../model/store.model';
import {
    selectFoerderantraegeStoreAllFoerderantraegeTotalElements,
    selectFoerderantraegeStoreTableIsLoading,
    selectFoerderantraegeStoreTablePage,
    selectFoerderantraegeStoreTableSize,
    selectFoerderantraegeStoreTableSortingArray,
} from '../../../store/foerderantraege/foerderantraege.selectors';
import { foerderantraegeActions } from '../../../store/foerderantraege/foerderantraege.actions';
import { selectFoerderantraegeStoreAllFoerderantraegeContentRightManagement } from '../../../store/all-unternehmen-nutzer/all-unternehmen-nutzer.selectors';
import { nutzerRightManagementFoerderantragViewActions } from '../../../store/all-unternehmen-nutzer/all-unternehmen-nutzer.actions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-right-management-foerderantrag',
    templateUrl: './right-management-foerderantrag.component.html',
    styleUrl: './right-management-foerderantrag.component.scss',
})
export class RightManagementFoerderantragComponent implements OnInit {
    private store = inject(Store);
    private destroyRef = inject(DestroyRef);

    tableConfig: DataTableConfig;
    tableContent$: Observable<Record<string, string | number | boolean>[]>;
    tableIsLoading$: Observable<boolean>;
    tableSortingArray$: Observable<SortParameter[]>;
    tableDisplayedSize$: Observable<number>;
    tableDisplayedPage$: Observable<number>;
    tableTotalElements$: Observable<number>;
    tableContent: { rightManagementCheckbox: boolean; id: number }[];

    searchValue = '';

    pageElement = { page: 0, size: 20 };

    ngOnInit(): void {
        this.store.dispatch(nutzerRightManagementFoerderantragViewActions.changeAllFoerderantraege());
        this.tableContent$ = this.store.select(selectFoerderantraegeStoreAllFoerderantraegeContentRightManagement);
        this.tableIsLoading$ = this.store.select(selectFoerderantraegeStoreTableIsLoading);
        this.tableSortingArray$ = this.store.select(selectFoerderantraegeStoreTableSortingArray);
        this.tableDisplayedSize$ = this.store.select(selectFoerderantraegeStoreTableSize);
        this.tableDisplayedPage$ = this.store.select(selectFoerderantraegeStoreTablePage);
        this.tableTotalElements$ = this.store.select(selectFoerderantraegeStoreAllFoerderantraegeTotalElements);
        this.tableContent$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((content) => {
            this.tableContent = content as { rightManagementCheckbox: boolean; id: number }[];
        });
        this.configChange();
    }

    configChange(): void {
        this.tableConfig = {
            columns: [
                { columnKey: 'rightManagementCheckbox', display: 'Aktiv' },
                { columnKey: 'antragsnummer', display: 'Antragsnummer' },
                { columnKey: 'projektname', display: 'Projektname' },
            ],
            tableLabel: 'Tabelle aller Benutzer*innen des Unternehmens.',
            tableColumnClass: 'right-management-foerderantrag',
            tableActions$: of([]),
            tableDataObjectsName: 'Benutzer*innen',
            tableDetailId: 'id',
            disableTableActions: true,
            paginationNames: {
                itemsPerPageLabel: 'Anträge pro Seite',
                nextPageLabel: 'Nächste Seite',
                previousPageLabel: 'Vorherige Seite',
                firstPageLabel: 'Erste Seite',
                lastPageLabel: 'Letzte Seite',
            },
            tableRowClassFunction: this.rowClassHandler,
            tableContent$: this.tableContent$,
            tableIsLoading$: this.tableIsLoading$,
            tableSortingArray$: this.tableSortingArray$,
            tableDisplayedSize$: this.tableDisplayedSize$,
            tableDisplayedPage$: this.tableDisplayedPage$,
            tableTotalElements$: this.tableTotalElements$,
            headerIsSticky: true,
        };
    }

    /**
     * Handle the sorting of a table column
     *
     * @param sortEvent holds the new sortParameter and if the sort parameter should be added to the end of the sorting array or if it should create a new list
     */
    sortHandler(sortEvent: { sort: SortParameter; isMultiSort: boolean }): void {
        this.store.dispatch(foerderantraegeActions.changeSortingArray({ newSorting: sortEvent.sort, multiSort: sortEvent.isMultiSort }));
        this.pageElement.page = 0;
    }

    /**
     *  Handle the use of the paginator. The paginator triggers a new event for every change so only one of the two looked out values will change at a time.
     *
     * @param pageEvent holds the size and the active page of the paginator return.
     */
    pageHandler(pageEvent: PageEvent): void {
        if (pageEvent.pageSize !== this.pageElement.size) {
            this.store.dispatch(foerderantraegeActions.changeShownSize({ newSize: pageEvent.pageSize }));
            this.pageElement.size = pageEvent.pageSize;
            this.pageElement.page = 0;
        } else if (pageEvent.pageIndex !== this.pageElement.page) {
            this.store.dispatch(foerderantraegeActions.changeShownPage({ newPage: pageEvent.pageIndex }));
            this.pageElement.page = pageEvent.pageIndex;
        }
    }

    /**
     * Handle row click events.
     *
     * @param event Object containing the row ID and selection state.
     */
    rowClickHandler(index: number): void {
        if (this.tableContent.length > 0) {
            this.store.dispatch(
                nutzerRightManagementFoerderantragViewActions.changeRightId({
                    rightId: this.tableContent[index].id,
                    isSelected: !this.tableContent[index].rightManagementCheckbox,
                }),
            );
        }
    }

    /**
     * Handle the search input.
     *
     * @param searchEvent holds the search value
     */
    searchHandler(searchEvent: string): void {
        if (this.searchValue !== searchEvent) {
            this.searchValue = searchEvent;
            this.store.dispatch(foerderantraegeActions.changeSearchParameter({ newSearchParameter: searchEvent }));
            this.pageElement.page = 0;
        }
    }

    rowClassHandler = (index: number): string => {
        if (this.tableContent.length > 0 && this.tableContent[index].rightManagementCheckbox) {
            return 'data-table__right-management-foerderantrag--selected';
        }
        return '';
    };
}
