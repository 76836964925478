import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { Stammdaten } from "../../model/stammdaten.model";

export const stammdatenActions = createActionGroup({
    source: 'Stammdaten',
    events: {
        changeLoadStammdaten: emptyProps(),
        setLoadStammdatenSuccess: props<{ newStammdaten: Stammdaten }>(),
        resetStammdatenStore: emptyProps(),
    }
})