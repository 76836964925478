<section class="nutzer-profile__top-section">
    <h1>Nutzerprofil</h1>
    <h2 class="text-h2 text-text-dark">Persönliche Daten</h2>
    <span class="nutzer-profile__top-section__edit-button">
        <app-button
            *ngIf="!isEditingNutzerData"
            buttonLabel="Nutzerprofil bearbeiten"
            (click)="nutzerDataEdit()"></app-button>
    </span>
</section>
<section
    class="nutzer-profile__bottom-section"
    [class]="!isEditingNutzerData ? 'nutzer-profile__bottom-section--no-edit' : ''">
    <ng-container *ngIf="!isEditingNutzerData; else editingNutzerProfile">
        <app-card
            aria-label="Nutzerdaten"
            class="nutzer-profile__bottom-section__nutzer-data">
            <div
                aria-labelledby="nutzer-profile-anrede"
                class="nutzer-profile__bottom-section__nutzer-data__content nutzer-profile__bottom-section__nutzer-data__content__anrede">
                <label id="nutzer-profile-anrede">Anrede</label>
                <p>{{ nutzerProfileFormGroup.value.anredeFormController }}</p>
            </div>
            <div
                aria-labelledby="nutzer-profile-titel"
                class="nutzer-profile__bottom-section__nutzer-data__content nutzer-profile__bottom-section__nutzer-data__content__titel">
                <label id="nutzer-profile-titel">Titel</label>
                <p>{{ nutzerProfileFormGroup.value.titelFormController ? nutzerProfileFormGroup.value.titelFormController : '-' }}</p>
            </div>
            <div
                aria-labelledby="nutzer-profile-vorname"
                class="nutzer-profile__bottom-section__nutzer-data__content nutzer-profile__bottom-section__nutzer-data__content__vorname">
                <label id="nutzer-profile-vorname">Vorname</label>
                <p>{{ nutzerProfileFormGroup.value.vornameFormController }}</p>
            </div>
            <div
                aria-labelledby="nutzer-profile-nachname"
                class="nutzer-profile__bottom-section__nutzer-data__content nutzer-profile__bottom-section__nutzer-data__content__nachname">
                <label id="nutzer-profile-nachname">Name</label>
                <p>{{ nutzerProfileFormGroup.value.nachnameFormController }}</p>
            </div>
        </app-card>
        <div
            aria-label="Kontaktdaten"
            class="nutzer-profile__bottom-section__contact-info">
            <app-card class="nutzer-profile__bottom-section__contact-info__email">
                <div
                    aria-labelledby="nutzer-profile-email"
                    class="nutzer-profile__bottom-section__contact-info__email__content nutzer-profile__bottom-section__contact-info__email__content">
                    <label id="nutzer-profile-email">E-Mail</label>
                    <p>{{ nutzerProfileFormGroup.value.emailFormController }}</p>
                </div>
            </app-card>
            <app-card class="nutzer-profile__bottom-section__contact-info__phone">
                <div
                    aria-labelledby="nutzer-profile-phone"
                    class="nutzer-profile__bottom-section__contact-info__phone__content nutzer-profile__bottom-section__contact-info__phone__content">
                    <div
                        aria-label="Mobilnummer Label mit Hinweis"
                        class="nutzer-profile__bottom-section__contact-info__phone__label">
                        <label id="nutzer-profile-phone">Mobilnummer</label>
                        <p
                            class="nutzer-profile__bottom-section__contact-info__phone__hint"
                            tippyClassName="tippy__hint"
                            ngxTippy="{{ this.phoneNumberHintText }}"
                            [tippyProps]="this.phoneNumberHintOptions">
                            <img
                                src="/assets/images/svgs/question-mark-in-a-circle-outline-svgrepo-com.svg"
                                class="nutzer-profile__bottom-section__contact-info__phone__hint__icon"
                                alt="Hinweis zur Mobilnummer" />
                        </p>
                    </div>
                    <p>{{ nutzerProfileFormGroup.value.telefonFormController }}</p>
                </div>
            </app-card>
        </div>
        <app-button
            buttonLabel="Passwort ändern"
            (click)="triggerPasswordChange()"></app-button>
    </ng-container>

    <ng-template #editingNutzerProfile>
        <form
            [formGroup]="nutzerProfileFormGroup"
            class="nutzer-profile__bottom-section__form">
            <app-card
                aria-label="Nutzerdaten"
                class="nutzer-profile__bottom-section__nutzer-data">
                <div
                    aria-labelledby="nutzer-profile-anrede"
                    class="nutzer-profile__bottom-section__nutzer-data__content nutzer-profile__bottom-section__nutzer-data__content__anrede">
                    <label id="nutzer-profile-anrede">Anrede</label>
                    <mat-form-field>
                        <mat-select formControlName="anredeFormController">
                            <mat-option
                                *ngFor="let anrede of anreden"
                                [value]="anrede">
                                {{ anrede }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div
                    aria-labelledby="nutzer-profile-titel"
                    class="nutzer-profile__bottom-section__nutzer-data__content nutzer-profile__bottom-section__nutzer-data__content__titel">
                    <label id="nutzer-profile-titel">Titel</label>
                    <mat-form-field>
                        <input
                            matInput
                            placeholder="-"
                            formControlName="titelFormController" />
                    </mat-form-field>
                </div>

                <div
                    aria-labelledby="nutzer-profile-vorname"
                    class="nutzer-profile__bottom-section__nutzer-data__content nutzer-profile__bottom-section__nutzer-data__content__vorname">
                    <label id="nutzer-profile-vorname">Vorname</label>

                    <mat-form-field>
                        <input
                            matInput
                            formControlName="vornameFormController" />
                        <mat-error
                            *ngIf="
                                nutzerProfileFormGroup.get('vornameFormController')?.hasError('required') &&
                                nutzerProfileFormGroup.get('vornameFormController')?.touched
                            ">
                            Der Vorname darf nicht leer sein
                        </mat-error>
                    </mat-form-field>
                </div>

                <div
                    aria-labelledby="nutzer-profile-nachname"
                    class="nutzer-profile__bottom-section__nutzer-data__content nutzer-profile__bottom-section__nutzer-data__content__nachname">
                    <label id="nutzer-profile-nachname">Name</label>
                    <mat-form-field>
                        <input
                            matInput
                            formControlName="nachnameFormController" />
                        <mat-error
                            *ngIf="
                                nutzerProfileFormGroup.get('nachnameFormController')?.hasError('required') &&
                                nutzerProfileFormGroup.get('nachnameFormController')?.touched
                            ">
                            Der Name darf nicht leer sein
                        </mat-error>
                    </mat-form-field>
                </div>
            </app-card>

            <div class="nutzer-profile__bottom-section__contact-info">
                <app-card class="nutzer-profile__bottom-section__contact-info__email">
                    <div
                        aria-labelledby="nutzer-profile-email"
                        class="nutzer-profile__bottom-section__contact-info__email__content nutzer-profile__bottom-section__contact-info__email__content">
                        <label id="nutzer-profile-email">E-Mail-Adresse</label>
                        <p>{{ nutzerProfileFormGroup.value.emailFormController }}</p>
                    </div>
                </app-card>

                <app-card class="nutzer-profile__bottom-section__contact-info__phone">
                    <div class="nutzer-profile__bottom-section__contact-info__phone__content">
                        <div class="nutzer-profile__bottom-section__contact-info__phone__label">
                            <label>Mobilnummer</label>
                            <p
                                class="nutzer-profile__bottom-section__contact-info__phone__hint"
                                tippyClassName="tippy__hint"
                                ngxTippy="{{ phoneNumberHintText }}"
                                [tippyProps]="phoneNumberHintOptions">
                                <img
                                    src="/assets/images/svgs/question-mark-in-a-circle-outline-svgrepo-com.svg"
                                    class="nutzer-profile__bottom-section__contact-info__phone__hint__icon"
                                    alt="Hinweis zur Mobilnummer" />
                            </p>
                        </div>
                        <mat-form-field>
                            <input
                                matInput
                                formControlName="telefonFormController" />
                            <mat-error
                                *ngIf="
                                    nutzerProfileFormGroup.get('telefonFormController')?.hasError('required') &&
                                    nutzerProfileFormGroup.get('telefonFormController')?.touched
                                ">
                                Die Mobilnummer darf nicht leer sein
                            </mat-error>
                        </mat-form-field>
                    </div>
                </app-card>
            </div>
            <div class="nutzer-profile__bottom-section__edit-actions">
                <app-button
                    class="nutzer-profile__bottom-section__edit-actions__cancle"
                    buttonLabel="Abbrechen"
                    (click)="cancleEdit()"></app-button>
                <app-button
                    class="nutzer-profile__bottom-section__edit-actions__save"
                    buttonLabel="Speichern"
                    [disabled]="!this.nutzerProfileFormGroup.valid"
                    (click)="saveEdit()"></app-button>
            </div>
        </form>
    </ng-template>
</section>
