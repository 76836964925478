import { inject, Injectable } from "@angular/core";
import { Action, Store } from "@ngrx/store";
import { filter, fromEvent, Observable } from "rxjs";
import { areActionsEqual } from "./helper/store.helper.service";
import { authActions } from "../store/auth/auth.actions";



@Injectable({
    providedIn: 'root'
})
export class PersistenceService {
    private store = inject(Store);

    private busKey = "__bus";
    // Observable for storage events
    storageChanges$: Observable<StorageEvent> = fromEvent<StorageEvent>(window, 'storage').pipe(
        // Only handle local storage events with the __bus key (this will hold all actions that should be dispatched)
        filter(event => event.storageArea === localStorage && event.key === this.busKey)
    );

    init(shouldBeLoggedOut: boolean): void {
        this.initPersistenceEventListener(shouldBeLoggedOut);
    }

    writeToBus(action: Action, overWriteAll = false): void {
        let activeBusArray: Action[] = [];
        if (!overWriteAll) {
            activeBusArray = this.getBusArray();
            //Check if the action was already in the bus then remove it
            if (activeBusArray.length > 0) {
                const inBusPosition = activeBusArray.findIndex((storedAction: Action) => storedAction.type === action.type);
                if (inBusPosition >= 0) {
                    activeBusArray.splice(inBusPosition, 1);
                }
            }
        }
        //Set the new action to the end and write it to the store.
        activeBusArray.push(action);
        localStorage.setItem(this.busKey, JSON.stringify(activeBusArray));
    }

    removeFromBus(type: string): void {
        const activeBusArray = this.getBusArray();
        if (activeBusArray.length > 0) {
            const inBusPosition = activeBusArray.findIndex((action: Action) => action.type === type);
            if (inBusPosition >= 0) {
                activeBusArray.splice(inBusPosition, 1);
                localStorage.setItem(this.busKey, JSON.stringify(activeBusArray));
            }
        }
    }

    private getBusArray: () => Action[] = () => {
        let activeBus = localStorage.getItem(this.busKey);
        //Check if the __bus is set
        if (!activeBus) {
            activeBus = '[]'
        }
        //Check if the __bus value is an array
        let activeBusArray: Action[];
        activeBusArray = JSON.parse(activeBus);
        if (!Array.isArray(activeBusArray)) {
            activeBusArray = [];
        }
        return activeBusArray;
    }

    private handleStorageEvent(newValueString: string | null, oldValueString: string | null, shouldBeLoggedOut: boolean): void {
        //Check that the Values are Arrays
        let activeBusArray: Action[];
        let oldBusArray: Action[];
        try {
            activeBusArray = JSON.parse(newValueString ?? '');
            if (!Array.isArray(activeBusArray)) {
                activeBusArray = [];
            }
        } catch {
            activeBusArray = [];
        }
        try {
            oldBusArray = JSON.parse(oldValueString ?? '');
            if (!Array.isArray(oldBusArray)) {
                oldBusArray = [];
            }
        } catch {
            oldBusArray = [];
        }
        if (shouldBeLoggedOut && (activeBusArray.length === 0 || activeBusArray[0].type !== authActions.changeUserToLoggedOut.type)) {
            this.removeCognitoIdentityServiceProviderFromLocalStorage();
            this.store.dispatch(authActions.postChangeUserToLoggedOut({ timeout: true }));
            return;
        }
        activeBusArray.forEach((action: Action) => {
            if (oldBusArray.findIndex((oldStoredAction: Action) => areActionsEqual(oldStoredAction, action)) === -1) {
                this.store.dispatch(action);
            }
        });

    }

    private initPersistenceEventListener(shouldBeLoggedOut: boolean): void {
        const startingStorage = localStorage.getItem(this.busKey);
        if (!startingStorage) {
            localStorage.setItem(this.busKey, '[]');
            if (shouldBeLoggedOut) {
                this.removeCognitoIdentityServiceProviderFromLocalStorage();
                this.store.dispatch(authActions.postChangeUserToLoggedOut({ timeout: true }));
            }
        } else {
            this.handleStorageEvent(startingStorage, null, shouldBeLoggedOut);
        }
        this.storageChanges$.subscribe(event => {
            this.handleStorageEvent(event.newValue, event.oldValue, false);
        });
    }

    private removeCognitoIdentityServiceProviderFromLocalStorage(): void {
        for (const key of Object.keys(localStorage)) {
            if (key.startsWith('CognitoIdentityServiceProvider')) {
                localStorage.removeItem(key);
            }
        }
    }
}