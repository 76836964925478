import { inject, Injectable } from "@angular/core";
import { UnternehmenUnternehmenControllerService } from "../generated";
import { mapStammdaten } from "../mapper/stammdaten/stammdaten.mapper";
import { map, Observable } from "rxjs";
import { Stammdaten } from "../../../model/stammdaten.model";

@Injectable({ providedIn: 'root' })
export class StammdatenUnternehmenAPIService {
    private stammdatenUnternehmenControllerService = inject(UnternehmenUnternehmenControllerService);

    /**
     * API call to load the unternehmen stammdaten for the given unternehmenId
     * @param unternehmenId the id of the unternehmen
     * @returns the stammdaten
     */
    getUnternehmenStammdaten(unternehmenId: number): Observable<Stammdaten> {
        return this.stammdatenUnternehmenControllerService.findUnternehmenWithRelations({ unternehmenId }).pipe(
            map((unternehmenStammdatenDTO) => mapStammdaten(unternehmenStammdatenDTO)),
        )
    }
}