import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { defaultStorage } from 'aws-amplify/utils';
import { environment } from './environments/environment';

import { AppModule } from './app/app.module';

//Here the errors should go to the console. (atleast at the moment)
/* eslint no-console: 0 */

Amplify.configure(environment.cognitoConfig);
cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
