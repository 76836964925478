import { DataTablePageDefaults } from "./data-table.model";

export type PostkorbStore = {
    postkorbTable: PostkorbTable;
    displayedPostkorbNachricht: PostkorbNachricht | null;
}

export interface PostkorbTable extends DataTablePageDefaults<PostkorbNachricht> {
    nachrichtenTypFilter: NachrichtenTyp;
}

/**
 * alle: display all unternehmen and global messages
 * unternehmen: display only the unternehmen messages
 * allgemein: display only the global messages
 */
export enum NachrichtenTyp {
    'alle' = 'alle',
    'unternehmen' = 'unternehmen',
    'allgemein' = 'allgemein'
}

export type PostkorbNachricht = {
    id: number;
    betreff: string;
    nachricht: string;
    zustellzeitpunkt: string;
    gelesen: boolean;
    mitAnhang: boolean;
    mitEmpfangsbestaetigung: boolean;
    'foerderantrag.projektname': string;
    'foerderantrag.antragsnummer': string;
}

export type PagePostkorbNachricht = {
    totalElements: number;
    totalPages: number;
    content: PostkorbNachricht[];
    numberOfElements: number;
    empty: boolean;
}