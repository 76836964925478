import { Injectable, inject } from '@angular/core';
import {
    FoerderartUnternehmenControllerService,
    FoerderbereichUnternehmenControllerService,
} from '../generated';
import { Observable, map } from 'rxjs';
import { Foerderart, Foerderbereich } from '../../../model/foerderbereich.model';
import { mapFoerderarten } from '../mapper/foerderarten/foerderart-dto.mapper';
import { mapFoerderbereiche } from '../mapper/foerderbereiche/foerderbereich-dto.mapper';

/**
 * The service to interact with the foerderart-unternehmen-controller and the foerderbereich-unternehmen-controller.
 * This service uses two controller since they both have only one endpoint and are saved in the same store in the frontend.
 */
@Injectable({
    providedIn: 'root',
})
export class NutzerFoerderbereicheApiService {
    private foerderbereichControllerSerivce = inject(FoerderbereichUnternehmenControllerService);
    private foerderartControllerService = inject(FoerderartUnternehmenControllerService);

    /**
     * Service function to get the foerderbereiche the current user can create an antrag for.
     * @param unternehmenId the id of the selected company
     * @returns returns the Observable<Foerderbereich[]> from the API call mapped to the store friendly objects
     */
    getNutzerFoerderbereiche(unternehmenId: number): Observable<Foerderbereich[]> {
        return this.foerderbereichControllerSerivce
            .findFoerderbereicheForCurrentNutzerAndUnternehmen({ unternehmenId: unternehmenId })
            .pipe(map((foerderbereicheExt) => mapFoerderbereiche(foerderbereicheExt)));
    }

    /**
     * Service function to get the foerderbereiche another user can create an antrag for.
     * @param unternehmenId the id of the selected company
     * @param nutzerId The ID of the user
     * @returns returns the Observable<Foerderbereich[]> from the API call mapped to the store friendly objects
     */
    getOtherNutzerFoerderbereiche(unternehmenId: number, nutzerId: string): Observable<Foerderbereich[]> {
        return this.foerderbereichControllerSerivce
            .findFoerderbereicheForNutzerAndUnternehmen({ unternehmenId: unternehmenId, nutzerIdpId: nutzerId })
            .pipe(map((foerderbereicheExt) => mapFoerderbereiche(foerderbereicheExt)));
    }

    /**
     * Service function to retrieve all funding areas for which a user can create an application.
     * @param unternehmenId The ID of the selected company.
     * @returns An Observable<Foerderbereich[]> returned by the API call, mapped to objects suitable for the store.
     */
    getAllActiveFoerderbereiche(unternehmenId: number): Observable<Foerderbereich[]> {
        return this.foerderbereichControllerSerivce.findAllActiveFoerderbereiche({ unternehmenId: unternehmenId })
          .pipe(map((foerderbereicheExt) => mapFoerderbereiche(foerderbereicheExt)));
    }

    /**
     * Service function to get the foerderarten the user can create an antrag for
     * @param unternehmenId the id of the selected company
     * @returns returns the Observable<Foerderart[]> from the API call mapped to the store friendly objects
     */
    getNutzerFoerderarten(unternehmenId: number): Observable<Foerderart[]> {
        return this.foerderartControllerService
            .findActiveFoerderartenForCurrentNutzer({ unternehmenId: unternehmenId })
            .pipe(map((foerderartenExt) => mapFoerderarten(foerderartenExt)));
    }
    /**
         * Service function to get the foerderarten the user has Antraege for
         * @param unternehmenId the id of the selected company
         * @returns returns the Observable<Foerderart[]> from the API call mapped to the store friendly objects
         */
    getFoerderartenInUse(unternehmenId: number): Observable<Foerderart[]> {
        return this.foerderartControllerService
            .findFoerderartenOfFoerderantraegeForCurrentNutzer({ unternehmenId })
            .pipe(map((foerderartenExt) => mapFoerderarten(foerderartenExt)));
    }
}
