import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { FoerderantragHistoryTableItem } from '../../model/foerderantrag-history-table.model';
import { SortParameter } from '../../model/store.model';

export const foerderantragHistoryTableActions = createActionGroup({
    source: 'FoerderantragHistoryTable',
    events: {
        changeTablePage: props<{ newPage: number }>(),
        changeTableSize: props<{ newSize: number }>(),
        changeSortingArray: props<{ newSortingParameter: SortParameter; isMultiSort: boolean }>(),
        changeFoerderantragHistoryTableContent: emptyProps(),
        setFoerderantragHistoryTableContent: props<{
            newFoerderantragHistoryTableContent: FoerderantragHistoryTableItem[];
            newTotalElements: number;
        }>(),
    },
});
