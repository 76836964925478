import { Component, OnInit, inject } from '@angular/core';
import { AuthenticatorService, translations } from '@aws-amplify/ui-angular';
import { I18n } from 'aws-amplify/utils';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { Store } from '@ngrx/store';
import { authActions } from '../../../store/auth/auth.actions';
import { Observable } from 'rxjs';
import { selectActiveNutzerFullname, selectLoggedIn } from '../../../store/auth/auth.selectors';
import { ConfirmSignUpInput, ConfirmSignUpOutput, SignUpInput, SignUpOutput, confirmSignUp, signUp } from 'aws-amplify/auth';
import { RegistrationApiService } from '../../../service/api/service/registration-api.service';
import { NutzerAnrede } from '../../../model/user.model';
import { formularActionsInt } from '../../../store/formular/formular.actions';
import { FormularHandlerService } from '../../../service/formular-handler.service';
import { rechtlicheInformationenActions } from '../../../store/rechtliche-informationen/rechtliche-informationen.action';

/**
 * translations for the amplify-authenticator component
 */
I18n.putVocabularies(translations);
I18n.putVocabulariesForLanguage('de', {
    'Network error': 'Netzwerk Fehler',

    //Login
    'Incorrect username or password.': 'Email oder Passwort ist falsch.',
    'Temporary password has expired and must be reset by an admin.':
        'Das temporäre Passwort ist abgelaufen und muss von einem*r Admin zurückgesetzt werden.',
    'User is disabled.': 'Nutzer*in ist deaktiviert.',
    'Unable to login because of security reasons': 'Aus Sicherheitsgründen wurde der Login blockiert.',

    //Passwort reset/forgot
    'Enter your email': 'Geben Sie Ihre E-Mail ein',
    'Password must have at least 8 characters': 'Das Passwort muss mindestends 8 Zeichen lang sein.',
    'Password does not conform to policy: Password must have symbol characters': 'Passwort muss mindestens ein Sonderzeichen enthalten.',
    'Password does not conform to policy: Password must have uppercase characters': 'Passwort muss mindestens einen Großbuchstaben enthalten.',
    'Password does not conform to policy: Password must have lowercase characters': 'Passwort muss mindestens einen Kleinbuchstaben enthalten.',
    'Password does not conform to policy: Password must have numeric characters': 'Passwort muss mindestens eine Zahl enthalten.',
    'Your passwords must match': 'Das Passwort muss übereinstimmen',
    'Attempt limit exceeded, please try after some time.': 'Versuchslimit überschritten. Bitte versuchen Sie es später noch einmal.',

    //Registration
    'Invalid phone number format.': 'Ungültiges Mobilnummernformat.',
    'Password did not conform with policy: Password must have numeric characters': 'Passwort muss mindestens eine Zahl enthalten.',
    'Password did not conform with policy: Password must have symbol characters': 'Passwort muss mindestens ein Sonderzeichen enthalten.',
    'Password did not conform with policy: Password must have uppercase characters': 'Passwort muss mindestens einen Großbuchstaben enthalten.',
    'Password did not conform with policy: Password must have lowercase characters': 'Passwort muss mindestens einen Kleinbuchstaben enthalten.',
    'The value of the attribute given_name must have a length less than or equal to 2048 characters':
        'Der Vorname darf nicht mehr als 2048 Zeichen haben.',
    'The value of the attribute custom:titel must have a length less than or equal to 2048 characters':
        'Der Titel darf nicht mehr als 2048 Zeichen haben.',
    'The value of the attribute family_name must have a length less than or equal to 2048 characters':
        'Der Nachname darf nicht mehr als 2048 Zeichen haben.',
    'The value of the attribute custom:phone_mobile must have a length less than or equal to 2048 characters': 'Ungültiges Mobilnummernformat.',
    "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must have length less than or equal to 128":
        'Die E-Mail darf nicht mehr als 128 Zeichen haben.',
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length less than or equal to 256":
        'Das Passwort darf nicht mehr als 256 Zeichen haben.',
    "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must have length less than or equal to 256; Value at 'username' failed to satisfy constraint: Member must have length less than or equal to 128":
        'Die Email darf nicht mehr als 128 Zeichen haben. Das Passwort darf nicht mehr als 256 Zeichen haben.',

    //Email confirmation
    'Invalid verification code provided, please try again.': 'Der angegebene Verifizierungscode ist ungültig, bitte versuchen Sie es erneut.',
});
I18n.setLanguage('de');

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
    public authenticator = inject(AuthenticatorService);
    private store = inject(Store);
    private registrationService = inject(RegistrationApiService);
    private formularHandlerService = inject(FormularHandlerService);

    passwordHintOptions: NgxTippyProps = { placement: 'bottom-start', theme: 'light-border' };
    passwordFieldTouched = false;

    passwordHintText = `Enthält mindestens 8 Zeichen
        Enthält mindestens eine Zahl
        Enthält mindestens einen Großbuchstaben
        Enthält mindestens einen Kleinbuchstaben
        Enthält mindestens eins der folgenden Sonderzeichen:
        ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < ' : ; | _ ~ \` + = `;

    loggedInUserFullname$: Observable<string>;
    isLoggedIn$: Observable<boolean>;

    anredeOptions: string[];

    /**
     * Get the user details and map the enums to the used string lists
     */
    ngOnInit(): void {
        this.loggedInUserFullname$ = this.store.select(selectActiveNutzerFullname);
        this.isLoggedIn$ = this.store.select(selectLoggedIn);

        this.anredeOptions = Object.values(NutzerAnrede).map((option) => String(option));
    }

    /**
     * dispatch the logout action
     */
    logoutUser(): void {
        this.store.dispatch(authActions.postChangeUserToLoggedOut({ timeout: false }));
    }

    // rule deactivated because the return value is individual, possibly the complete one is too complex
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    getServices() {
        const api = this.registrationService;
        const store = this.store;

        const services = {

            // rule deactivated because the return value is individual, possibly the complete one is too complex
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            async validateCustomSignUp(formData: Record<string, string>) {
                let errorTouched = false;
                const error: Record<string, string> = {};
                if (!formData['password'] || !formData['confirm_password'] || formData['password'].length < 7) {
                    error['passwordCheck'] = 'Bitte geben Sie ein gültiges Passwort ein.';
                    errorTouched = true;
                }
                if (formData['password'] !== formData['confirm_password']) {
                    error['passwordCheck'] = 'Die Passwörter stimmen nicht überein.';
                    errorTouched = true;
                }
                if (errorTouched) {
                    return error;
                }
                return;
            },

            /**
             * Function that is called when an email is confirmed.
             * If the confirmation is successful, the backend is informed about the new user.
             * @param formData of amplify containing confirmationCode, username and options
             * @returns promise with data of the confirmSignUp process
             */
            async handleConfirmSignUp(formData: ConfirmSignUpInput): Promise<ConfirmSignUpOutput> {
                const username = formData.username;
                return confirmSignUp(formData).then(async (data) => {
                    if (data.isSignUpComplete) {
                        api.confirmRegistration(username).subscribe(() => {
                            store.dispatch(authActions.updateRefreshToken());
                        });
                    }
                    return data;
                });
            },
            /**
             * Function that is called when a user enters their data for registration & validates the data.
             * @param formData of amplify containing username, password and options (includes userAttributes).
             * @returns promise with data of the signUp process
             */
            async handleSignUp(formData: SignUpInput): Promise<SignUpOutput> {
                const { username, password } = formData;
                let options = formData.options;
                const attributes = options?.userAttributes;
                if (attributes !== undefined) {
                    if (attributes.phone_number != null && attributes.phone_number.length > 20) {
                        throw Error('Die Mobilnummer darf nicht mehr als 20 Zeichen haben.')
                    }
                    if (attributes.given_name != null && attributes.given_name.length > 200) {
                        throw Error('Der Vorname darf nicht mehr als 200 Zeichen haben.')
                    }
                    if (attributes.family_name != null && attributes.family_name.length > 200) {
                        throw Error('Der Nachname darf nicht mehr als 200 Zeichen haben.')
                    }
                    if (attributes['custom:titel'] && attributes['custom:titel'].length > 100) {
                        throw Error('Der Titel darf nicht mehr als 100 Zeichen haben.')
                    }
                    if (attributes.gender && attributes.gender.length > 10) {
                        throw Error('Die Anrede darf nicht mehr als 10 Zeichen haben.')
                    }
                    // check if checkboxes acknowledgement & dataprivacy is checked
                    if (!attributes['custom:acknowledgement'] || !attributes['custom:dataprivacy']) {
                        throw Error('Sie müssen den AGBs und Datenschutz Richtlinien zustimmen.');
                    } else {
                        delete attributes['custom:acknowledgement'];
                        delete attributes['custom:dataprivacy'];
                    }
                    options = { ...options, userAttributes: attributes };
                }
                return signUp({
                    username,
                    password,
                    options: options,
                });
            },
        };
        return services;
    }

    /**
     * The function to create a new Unternehmen.
     */
    createUnternehmen(): void {
        this.formularHandlerService.clearFormularActionsInBus();
        this.formularHandlerService.navigateToFormular({ foerderart: 'Unternehmensregistrierung' });
        this.store.dispatch(formularActionsInt.changeCreateUnternehmen());
    }

    /**
     * Handles the touch event of the password field
     */
    passwordFieldTouchHandler(): void {
        this.passwordFieldTouched = true;
    }

    /**
     * Handles the download of the datenschutz and nutzungsbedingungen 
     * @param link the document that should be downloaded
     */
    handleLinkClick(link: string): void {
        if (link === 'datenschutz') {
            this.store.dispatch(rechtlicheInformationenActions.updateDatenschutz());
        } else if (link === 'nutzungsbedingungen') {
            this.store.dispatch(rechtlicheInformationenActions.updateNutzungsbedingungen());
        }
    }
}
