import { inject, Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { NutzerCommonApiService } from "../../service/api/service/nutzer-common.service";
import { GlobalExceptionHandlerService } from "../../service/api/service/global-exception-handler.service";
import { nutzerProfileActions } from "./nutzer-profile.actions";
import { catchError, map, of, switchMap } from "rxjs";
import { Store } from "@ngrx/store";
import { selectNutzerProfileChanges } from "./nutzer-profile.selectors";

/**
 * The effects for the nutzer profile store
 */
@Injectable()
export class NutzerProfileEffects {
    private actions$ = inject(Actions);
    private store = inject(Store);
    private nutzerCommonApiService = inject(NutzerCommonApiService);
    private globalExceptionHandlerService = inject(GlobalExceptionHandlerService);

    /**
     * The effect to load the nutzer profile data
     */
    changeNutzerProfile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nutzerProfileActions.changeNutzerProfile),
            switchMap(() => {
                return this.nutzerCommonApiService.getNutzerProfileData().pipe(
                    map(
                        (nutzerProfile) => nutzerProfileActions.setNutzerProfile({ newNutzerProfileData: nutzerProfile })
                    ),
                    catchError((error) => {
                        this.globalExceptionHandlerService.handleGeneratedApiError(error);
                        return of(nutzerProfileActions.resetNutzerProfile());
                    })
                );
            })
        );
    });

    /**
     * The effect to handle the triggering of the change request for the user profile data
     */
    changeNutzerProfileChanges$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nutzerProfileActions.changeNutzerProfileChanges),
            concatLatestFrom(() => [
                this.store.select(selectNutzerProfileChanges)
            ]),
            switchMap(([, nutzerProfileChanges]) => {
                return this.nutzerCommonApiService.putNutzerProfileChanges(nutzerProfileChanges).pipe(
                    map((nutzerProfile) => nutzerProfileActions.setNutzerProfile({ newNutzerProfileData: nutzerProfile })),
                    catchError((error) => {
                        this.globalExceptionHandlerService.handleGeneratedApiError(error);
                        return of(nutzerProfileActions.resetChangesToNutzerProfile());
                    })
                );
            })
        );
    });
}