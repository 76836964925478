import { Component, inject, OnInit } from '@angular/core';
import { RenderedTemplateDto } from '../../service/api/generated';
import { Store } from '@ngrx/store';
import { rechtlicheInformationenActions } from '../../store/rechtliche-informationen/rechtliche-informationen.action';
import { Observable } from 'rxjs';
import { selectError, selectImpressumHtml } from '../../store/rechtliche-informationen/rechtliche-informationen.selectors';

@Component({
    selector: 'app-impressum',
    templateUrl: './impressum.component.html',
})
export class ImpressumComponent implements OnInit {
    private store = inject(Store);
    impressumHtml$: Observable<RenderedTemplateDto | null>;
    error$: Observable<Error | null>;
    isLoading = true;

    ngOnInit(): void {
        this.store.dispatch(rechtlicheInformationenActions.loadImpressum());

        this.impressumHtml$ = this.store.select(selectImpressumHtml);
        this.error$ = this.store.select(selectError);
    }
}
