<div *ngIf="((unternehmenSelectionList$ | async)?.length || 0) > 0; then selectUnternehmenInfo; else noUnternehmenInfo"></div>
<ng-template #selectUnternehmenInfo>
    <h2
        class="text-h3 text-text-dark"
        data-testid="selectUnternehmenInfoTest">
        Wählen Sie ein Unternehmen aus, in dessen Auftrag Sie das Portal nutzen wollen:
    </h2>
    <app-unternehmen-selection></app-unternehmen-selection>
</ng-template>
<ng-template #noUnternehmenInfo>
    <p data-testid="noUnternehmenInfoTest">
        Sie sind derzeit keinem Unternehmen zugeordnet. Wenn Sie zu einem Unternehmen gehören sollten, kontaktieren Sie bitte Ihre*n
        Admin, damit er Sie dem Unternehmen hinzufügt.
    </p>
</ng-template>
