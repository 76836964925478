import { createFeatureSelector, createSelector } from "@ngrx/store";
import { FormField, FormularStore } from "../../model/formular.model";

export const selectFormularStore = createFeatureSelector<FormularStore>('formularStore');

export const selectFormularStoreFormularUrl = createSelector(
    selectFormularStore,
    (formularStore) => formularStore.formularUrl
);

export const selectFormularStorePartsMax = createSelector(
    selectFormularStore,
    (formularStore) => formularStore.partsMax
);

export const selectFormularStorePartsUploaded = createSelector(
    selectFormularStore,
    (formularStore) => formularStore.partsUploaded
);

export const selectFormularStoreProgressBarProgress = createSelector(
    selectFormularStorePartsMax, selectFormularStorePartsUploaded,
    (partsMax, partsUploaded) =>
        partsMax === 0 ? 0 : Math.round(partsUploaded / partsMax * 100)
);

export const selectFormularStoreShowProgressBar = createSelector(
    selectFormularStore,
    (formularStore) => formularStore.showProgressBar
);

export const selectFormularStoreActiveFormularAntragsnummer = createSelector(
    selectFormularStore,
    (formularStore) => formularStore.activeAntragsnummer
);

export const selectFormularStoreFormContent = createSelector(
    selectFormularStore,
    (formularStore) => formularStore.formContent
);

export const selectFormularStoreOnlyStammdaten = createSelector(
    selectFormularStore,
    (formularStore) => formularStore.loadOnlyStammdaten
);

export const selectFormularStoreIsFormularInitialized = createSelector(
    selectFormularStore,
    (formularStore) => formularStore.isFormularInitialized
);

export const selectFormularStoreIsNewUnternehmen = createSelector(selectFormularStore, (formularStore) => formularStore.isNewUnternehmen);

export const selectFormularStoreCombinedInitializedFormularState = createSelector(
    selectFormularStoreIsFormularInitialized,
    selectFormularStoreActiveFormularAntragsnummer,
    selectFormularStoreFormContent,
    selectFormularStoreIsNewUnternehmen,
    (isFormularInitialized, activeAntragsnummer, formContent, isNewUnternehmen) => {
        if (isFormularInitialized && activeAntragsnummer !== "" && formContent && formContent.length > 0) {
            return {
                activeAntragsnummer,
                formContent
            };
        } else if (isFormularInitialized && activeAntragsnummer !== "" && isNewUnternehmen) {
            return {
                activeAntragsnummer,
                formContent: [] as FormField[]
            };
        }
        return null;
    }
);

export const selectFormularStoreIsLoadingAFormular = createSelector(
    selectFormularStore,
    (formularStore) => formularStore.isLoadingAFormular
);

export const selectFormularStoreNumberOfClosedFileUploadsAndSucces = createSelector(
    selectFormularStore,
    (formularStore) => ({ numberOfClosedFileUploads: formularStore.numberOfClosedFileUploads, groupFileUploadsFailed: formularStore.groupFileUploadsFailed })
);

