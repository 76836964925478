import { TableAction } from "./data-table.model";

export enum SortDir {
  asc = 'asc',
  desc = 'desc',
}

export type PageableObject = {
  offset: number;
  sort: SortObject;
  unpaged: boolean;
  paged: boolean;
  pageNumber: number;
  pageSize: number;
};

export type SortObject = {
  empty: boolean;
  unsorted: boolean;
  sorted: boolean;
};

export type SortParameter = {
  column: string;
  sortDirection: SortDir;
};

export interface DataTableStorePageDefaults<T> {
  data: T[];
  totalElements: number;
  isLoading: boolean;
  sortingArray: SortParameter[];
  size: number;
  page: number;
  searchParameter: string;
  tableActions: TableAction[][];
}