import { DokumentUnternehmen } from "../../../../model/dokumente-unternehmen.model";
import { FfaDokumentDto } from "../../generated";

export const mapDokumentUnternehmen = (ffaDokument: Required<FfaDokumentDto>): DokumentUnternehmen => {
    return {
        'dokument.id': ffaDokument.id,
        'dokument.name': ffaDokument.name,
        'antrag.antragsnummer': ffaDokument.antragsnummer,
        type: ffaDokument.type,
        'dokument.erstelltAm': ffaDokument.datum,
        gelesen: ffaDokument.gelesen,
    };
}