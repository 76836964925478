import { Injectable, inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { FormContentDto, FormFieldDto, FormularUnternehmenControllerService } from '../generated';
import { FormField } from '../../../model/formular.model';
import { formFieldMapper } from '../mapper/form-content/form-field-dto.mapper';

@Injectable({
    providedIn: 'root'
})
export class FormularUnternehmenService {

    private formularUnternehmenControllerService = inject(FormularUnternehmenControllerService);

    /**
     * This function gets the form content for the given unternehmenId and antragsnummer. 
     * Form content is the data that is needed to fill the form and contains master data and previously entered data.
     * @param unternehmenId 
     * @param antragsnummer 
     * @returns 
     */
    getFormContent(unternehmenId: number, antragsnummer: string, onlyStammdaten: boolean): Observable<FormField[]> {
        return this.formularUnternehmenControllerService.getFormContentByAntragsnummer({ unternehmenId, antragsnummer, onlyStammdaten })
            .pipe(map((formContentDto) => {
                const formContent: Required<FormContentDto> = formContentDto as Required<FormContentDto>;
                return formContent.formContent.map((formField) => formFieldMapper(formField as Required<FormFieldDto>))
            }));
    }
} 