import { inject, Injectable } from "@angular/core";
import { Action, Store } from "@ngrx/store";

import { Foerderantrag, RUECKNAHME, Status, WIDERRUFFORMULAR } from "../../model/foerderantraege.model";
import { FormularHandlerService } from "../formular-handler.service";
import { formularActionsInt } from "../../store/formular/formular.actions";
import { AntragsType } from "../../model/formular.model";
import { DisplayedButtonActions } from "../../model/antrags-detail-tab.model";
import { DialogHelperService } from "./dialog.helper.service";
import { PortalRole } from "../../model/auth.model";
import { foerderantraegeActions } from "../../store/foerderantraege/foerderantraege.actions";
import { Nebenantrag, Nebenantragsart } from "../../model/nebenantraege.model";
import { TableAction } from "../../model/data-table.model";
import { dokumentContentActions } from "../../store/dokumente-unternehmen/dokumente-unternehmen.actions";

@Injectable({ providedIn: 'root' })
export class AntragHelperService {
    private store = inject(Store);
    private formularHandlerService = inject(FormularHandlerService);
    private dialogHelperService = inject(DialogHelperService);

    /**
     * A checker if a action is avaiable for the selected antrag in the status it is in
     * @param status the status of the antrag
     * @param buttonType the type of action that gets checked
     * @returns true if the action is avaiable
     */
    actionIsAvaiable = (status: Status, buttonType: string, inFrist?: boolean): boolean => {
        switch (buttonType) {
            case 'bearbeiten':
                return status === Status.ANTRAG_ANGELEGT
                    || (
                        (status === Status.ANTRAG_DATENNACHFORDERUNG
                            || status === Status.KOMMISSION_DATENNACHREICHUNG)
                        && (inFrist === undefined || inFrist));
            case 'herunterladen':
                return status !== Status.ANTRAG_ANGELEGT;
            case 'ruecknahme':
                return status === Status.ANTRAG_EINGEREICHT
                    || status === Status.PRUEFUNG
                    || status === Status.PRUEFUNG_ERNEUT
                    || status === Status.ANTRAG_DATENNACHFORDERUNG
                    || status === Status.KOMMISSION
                    || status === Status.KOMMISSION_DATENNACHREICHUNG
                    || status === Status.ZUSAGE_PRUEFUNG
                    || status === Status.ANTRAG_BEWILLIGT
                    || status === Status.VERWENDUNGSNACHWEISPRUEFUNG
                    || status === Status.DATENUEBERNAHME
                    || status === Status.DATENUEBERNAHME_ABGESCHLOSSEN
                    || status === Status.ABHOLBEREIT;
            case 'widerspruch':
                return status === Status.ABLEHNUNG
                    || status === Status.ZUSAGE_PRUEFUNG
                    || status === Status.ANTRAG_BEWILLIGT
                    || status === Status.VERWENDUNGSNACHWEISPRUEFUNG;
            case 'loeschen':
                return status === Status.ANTRAG_ANGELEGT;
            default:
                return false;
        }
    }

    isInFristTime = (fristBegin?: string, fristEnd?: string): number => {
        const timeNow = Date.now();
        if (fristBegin !== undefined) {
            const fristBeginDate = Date.parse(fristBegin);
            if (timeNow < fristBeginDate) {
                return -1;
            }
        }
        if (fristEnd !== undefined) {
            const fristEndDate = Date.parse(fristEnd);
            if (fristEndDate < timeNow) {
                return 1;
            }
        }
        return 0;
    }

    fristEditText = (inFrist: number, fristBegin?: string, fristEnd?: string): string => {
        if (inFrist === -1 && fristBegin !== undefined) {
            return 'Antrag bearbeitbar ab dem ' + this.formatTime(fristBegin);
        } else if (fristEnd !== undefined) {
            if (inFrist === 1) {
                return 'Bearbeitungsfrist abgelaufen am ' + this.formatTime(fristEnd);
            } else {
                return "Antrag bearbeitbar bis zum " + this.formatTime(fristEnd);
            }
        }
        return 'Antrag bearbeiten';
    }

    formatTime(time: string): string {
        const date = new Date(time);
        return date.toLocaleDateString() + ', um ' + date.toLocaleTimeString();
    }

    dispatchAction = (action: Action): void => {
        this.store.dispatch(action);
    }

    /**
     * Navigate to the formular
     * @param formularShortname for existing one
     * @param antragsnummer the antragsnummer of the foerderantrag
     * @param antragsArt the foerderart that should be placed in the url to keep an overview
     * @param buttonLabel the label of the button that was clicked
     * @param foerderAntragsId the id of the foerderantrag

    * @param nebenantrag
     */
    sendToFormular = (
        formularShortname: string,
        antragsnummer: string,
        antragsArt: string,
        buttonLabel: string,
        foerderAntragsId: number,
        nebenantrag: boolean,
    ): void => {
        this.formularHandlerService.clearFormularActionsInBus()
        if (buttonLabel === 'Widerspruch einlegen') {
            this.store.dispatch(formularActionsInt.postChangeCreateNewFormular({
                shortName: WIDERRUFFORMULAR.gatewayShortname,
                antragsArtId: WIDERRUFFORMULAR.id,
                antragsType: AntragsType.Nebenantrag,
                foerderAntragsId,
                antragsart: WIDERRUFFORMULAR.bezeichnung.replaceAll(" ", "")
            }));
        } else if (buttonLabel === 'Antrag zurückziehen') {
            this.store.dispatch(formularActionsInt.postChangeCreateNewFormular({
                shortName: RUECKNAHME.gatewayShortname,
                antragsArtId: RUECKNAHME.id,
                antragsType: AntragsType.Nebenantrag,
                foerderAntragsId,
                antragsart: RUECKNAHME.bezeichnung.replaceAll(" ", "")
            }));
        } else if (buttonLabel === 'Antrag bearbeiten' && !nebenantrag) {
            this.formularHandlerService.navigateToFormular({ foerderart: antragsArt.replaceAll(" ", "") });
            this.store.dispatch(formularActionsInt.postChangeExistingFormular({
                antragsnummer,
                antragsArt,
                formularShortname
            }));
        } else if (buttonLabel === 'Antrag bearbeiten' && nebenantrag) {
            this.formularHandlerService.navigateToFormular({ nebenantragsart: antragsArt.replaceAll(" ", "") });
            this.formularHandlerService.triggerAction(formularActionsInt.postChangeExistingFormular({
                antragsnummer,
                antragsArt,
                formularShortname
            }));
        }
    }

    /**
     * generate the nebenantrag actions
     * @param antraege the nebenantraege list for which the actions should be created
     * @param nebenantragsart the nebenantragsart of the selected list
     * @returns the table actions for the nebenantraege
     */
    generateNebenantragActions(antraege: Nebenantrag[], nebenantragsart: Nebenantragsart): TableAction[][] {
        const actions: TableAction[][] = [];
        for (let i = 0; i < antraege.length; i++) {
            const antrag = antraege[i];
            actions.push([]);
            if (this.actionIsAvaiable(antrag.status, 'bearbeiten')) {
                actions[i].push({
                    name: 'Antrag bearbeiten',
                    isLink: false,
                    isButton: true,
                    clickHandler: () => {
                        this.sendToFormular(
                            antrag.formularShortname,
                            antrag.antragsnummer,
                            nebenantragsart.bezeichnung,
                            'Antrag bearbeiten',
                            antrag.id,
                            true,
                        )
                    },
                });
            }
            if (this.actionIsAvaiable(antrag.status, 'herunterladen')) {
                actions[i].push({
                    name: 'Antrag herunterladen',
                    isLink: false,
                    isButton: true,
                    clickHandler: () => {
                        this.store.dispatch(dokumentContentActions.updateDownloadAntragDokument({ antragId: antrag.id }));
                    },
                });
            }
            if (this.actionIsAvaiable(antrag.status, 'loeschen')) {
                actions[i].push({
                    name: 'Entwurf löschen',
                    isLink: false,
                    isButton: true,
                    clickHandler: () => {
                        this.dialogHelperService.openNewDialog(this.dialogHelperService.createDeleteEntwurfDialogData(
                            foerderantraegeActions.changeAntragsStatusForDeletion({ antragsId: antrag.id })
                        ));
                    },
                });
            }
        }
        return actions;
    }

    /**
     * generate the foerderantrag actions
     * @param antraege the list of antraege that should be used to create the actions
     * @param userRole the PortalRole of the user in the company
     * @returns the DisplayedButtonActions as an array that then will be modified to either be displayed or transformed into tableActions
     */
    generateActions = (antraege: Foerderantrag[], userRole: PortalRole): DisplayedButtonActions[][] => {
        const actions: DisplayedButtonActions[][] = [];
        for (let i = 0; i < antraege.length; i++) {
            const antrag = antraege[i];
            actions.push([]);
            const inFrist = this.isInFristTime(antrag.fristFreischaltung, antrag.fristEnde);
            const editText = this.fristEditText(inFrist, antrag.fristFreischaltung, antrag.fristEnde);
            actions[i].push({
                name: editText,
                clickHandler: () => {
                    this.sendToFormular(
                        antrag.formularShortname,
                        antrag.antragsnummer,
                        antrag['foerderart.bezeichnung'],
                        'Antrag bearbeiten',
                        antrag.id,
                        false,
                    )
                },
                disabled: !this.actionIsAvaiable(antrag.status, 'bearbeiten', inFrist === 0),
            });
            actions[i].push({
                name: 'Antrag herunterladen',
                clickHandler: () => {
                    this.store.dispatch(dokumentContentActions.updateDownloadAntragDokument({ antragId: antrag.id }));
                },
                disabled: !this.actionIsAvaiable(antrag.status, 'herunterladen'),
            });
            actions[i].push({
                name: 'Antrag zurückziehen',
                clickHandler: () => {
                    this.sendToFormular(
                        antrag.formularShortname,
                        antrag.antragsnummer,
                        antrag['foerderart.bezeichnung'],
                        'Antrag zurückziehen',
                        antrag.id,
                        false
                    )
                },
                disabled: !this.actionIsAvaiable(antrag.status, 'ruecknahme'),
            });
            actions[i].push({
                name: 'Widerspruch einlegen',
                clickHandler: () => {
                    this.sendToFormular(
                        antrag.formularShortname,
                        antrag.antragsnummer,
                        antrag['foerderart.bezeichnung'],
                        'Widerspruch einlegen',
                        antrag.id,
                        false
                    )
                },
                disabled: !this.actionIsAvaiable(antrag.status, 'widerspruch'),
            });
            actions[i].push({
                name: 'Entwurf löschen',
                clickHandler: () => {
                    this.dialogHelperService.openNewDialog(this.dialogHelperService.createDeleteEntwurfDialogData(
                        foerderantraegeActions.changeAntragsStatusForDeletion({ antragsId: antrag.id })
                    ));
                },
                disabled: !this.actionIsAvaiable(antrag.status, 'loeschen'),
            });
            if (userRole === PortalRole.COMPANY_ADMIN) {
                actions[i].push({
                    name: 'Nutzer*innen verwalten',
                    clickHandler: (): void => { this.dialogHelperService.openNewDialog(this.dialogHelperService.createAllAntraegeTableActionDialogData(antrag)) },
                    disabled: false,
                });
            }
            //Change the List depending of the Förderart and type
            if (antrag['foerderart.bezeichnung'] === 'Stammdatenerhebung') {
                actions[i] = actions[i].filter(action => action.name === 'Antrag herunterladen');
            } else if (antrag.antragsnummer.includes('PLA_')) {
                actions[i] = actions[i].filter(action => action.name === 'Nutzer*innen verwalten');
            }
        }
        return actions;
    }

    /**
     * Get the label for the create button
     * @param nebenantragsart the nebenantragsart for which the label should be created
     * @returns the label
     */
    nebenantragCreateNewButtonLabel(nebenantragsart: Nebenantragsart): string {
        let returnString = 'Neuen Nebenantrag erstellen';
        if (nebenantragsart.id === WIDERRUFFORMULAR.id) {
            returnString = 'Widerspruch einlegen';
        } else if (nebenantragsart.id === RUECKNAHME.id) {
            returnString = 'Antrag zurückziehen';
        }
        return returnString
    }
}
