import { Geschaeftsfuehrer } from "../../../../model/stammdaten.model";
import { GeschaeftsfuehrerDto } from "../../generated";

/**
 * Map the geschäftsführer from the dto to the used type
 * @param geschaeftsfuehrer the dto coming from the backend
 * @returns the type we use in the frontend
 */
export const mapGeschaeftsfuehrer = (geschaeftsfuehrer: GeschaeftsfuehrerDto): Geschaeftsfuehrer => ({
    id: geschaeftsfuehrer.id,
    anrede: geschaeftsfuehrer.anrede,
    vorname: geschaeftsfuehrer.vorname,
    nachname: geschaeftsfuehrer.nachname,
    titel: geschaeftsfuehrer.titel,
})