import { AfterViewInit, Component, inject, OnDestroy } from '@angular/core';
import { Link } from '../../model/app-routing.model';
import { Store } from '@ngrx/store';
import { rechtlicheInformationenActions } from '../../store/rechtliche-informationen/rechtliche-informationen.action';
import { DokumenteHelperService } from '../../service/helper/dokument.helper.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss',
})
export class FooterComponent implements AfterViewInit, OnDestroy {
    private store = inject(Store);
    private dokumenteHelperService = inject(DokumenteHelperService);
    isWindowScrollable = false;

    footerItems: Link[] = [
        { label: 'Impressum', link: 'impressum' },
        { label: 'Datenschutz', link: 'datenschutz' },
        { label: 'Nutzungsbedingungen', link: 'nutzungsbedingungen' },
        // { label: 'FAQs', link: 'FAQs' },
    ];

    handleClick(link: string): void {
        if (link === 'datenschutz') {
            this.store.dispatch(rechtlicheInformationenActions.updateDatenschutz());
        } else if (link === 'nutzungsbedingungen') {
            this.store.dispatch(rechtlicheInformationenActions.updateNutzungsbedingungen());
        }
    }

    /**
     * It checks if the window is scrollable and adds event listeners for window resize and scroll events.
     */
    ngAfterViewInit(): void {
        this.checkIfWindowScrollable();
        window.addEventListener('resize', this.checkIfWindowScrollable.bind(this));
        window.addEventListener('scroll', this.checkIfWindowScrollable.bind(this));
    }

    /**
     * It removes the event listeners for window resize and scroll events.
     */
    ngOnDestroy(): void {
        window.removeEventListener('resize', this.checkIfWindowScrollable.bind(this));
        window.removeEventListener('scroll', this.checkIfWindowScrollable.bind(this));
    }

    private checkIfWindowScrollable(): void {
        this.isWindowScrollable = document.documentElement.scrollHeight > window.innerHeight;
    }

    /**
     * Function to scroll to the top of the page
     */
    scrollToTop(): void {
        (function smoothscroll(): void {
            const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

            if (currentScroll > 100) {
                window.requestAnimationFrame(smoothscroll);

                window.scrollTo(0, currentScroll - currentScroll / 2);
            } else if (currentScroll > 0) {
                window.scrollTo(0, 0);
            }
        })();
    }
}
