import { Component, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { QuickFilterMenuItem } from '../../model/data-table.model';

/**
 * Component for the quick filter 
 */

@Component({
    selector: 'app-quick-filter',
    templateUrl: './quick-filter.component.html',
    styleUrl: './quick-filter.component.scss',
})
export class QuickFilterComponent {
    @Input() quickFilterMenuItems: QuickFilterMenuItem[];

    /**
     * Handles the event emitter for the any quick filter menu element
     *
     * @param filterFormGroup the used formGroup to get the value of the filter
     * @param filterControllerName the controller name in the form group
     * @param filterEmitter the event emitter that should be used
     */

    triggerFilter(filterFormGroup: FormGroup, filterControllerName: string, filterEmitter: EventEmitter<string>): void {
        if (filterFormGroup.value[filterControllerName] === undefined) {
            filterFormGroup.patchValue({ [filterControllerName]: 'null' });
        }
        filterEmitter.emit(filterFormGroup.value[filterControllerName]);
    }
}
