import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, throwError, map, catchError, switchMap, tap, forkJoin, Observable, filter, concatMap, from } from 'rxjs';
import { formularActionsExt, formularActionsInt } from './formular.actions';
import { nutzerUnternehmenSelectionActions } from '../nutzer-unternehmen-selection/nutzer-unternehmen-selection.actions';
import { AwsControllerService } from '../../service/api/service/aws-controller.service';
import { EmptyBodyResponse, FileUploadDetails, GetPresignedUrlsResponse, PartEtag } from '../../model/aws-service.model';
import { selectFormularStoreActiveFormularAntragsnummer, selectFormularStoreIsNewUnternehmen, selectFormularStoreOnlyStammdaten } from './formular.selectors';
import { HttpResponse } from '@angular/common/http';
import { selectActiveUnternehmenId } from '../nutzer-unternehmen-selection/nutzer-unternehmen-selection.selectors';
import { UnternehmenFoerderantraegeApiService } from '../../service/api/service/foerderantraege-unternehmen.service';
import { selectNebenantragsFoerderantragsId } from '../nebenantraege/nebenantraege.selectors';
import { UnternehmenNebenantraegeAPIService } from '../../service/api/service/nebenantraege-unternehmen.service';
import { FoerderantraegeCommonService } from '../../service/api/service/foerderantraege-common.service';
import { FormularUnternehmenService } from '../../service/api/service/formular-unternehmen.service';
import { PersistenceService } from '../../service/persistence.service';
import { GlobalExceptionHandlerService } from '../../service/api/service/global-exception-handler.service';
import { FormularHandlerService } from '../../service/formular-handler.service';
import { AntragsType } from '../../model/formular.model';
import { STAMMDATEN } from '../../model/foerderantraege.model';
import { AuthorizeService } from '../../service/authorize.service';
import { selectToken } from '../auth/auth.selectors';

/**
 * The effects for the formular store
 */
@Injectable()
export class FormularEffects {
    private chunkSize = 5 * 1024 * 1024;

    private actions$ = inject(Actions);
    private awsControllerService = inject(AwsControllerService);
    private unternehmenFoerderantraegeApiService = inject(UnternehmenFoerderantraegeApiService);
    private unternehmenNebenantraegeAPIService = inject(UnternehmenNebenantraegeAPIService);
    private foerderantraegeCommonService = inject(FoerderantraegeCommonService);
    private formUnternehmenService = inject(FormularUnternehmenService);
    private persistenceService = inject(PersistenceService);
    private formularHandlerService = inject(FormularHandlerService);
    private globalExceptionHandlerService = inject(GlobalExceptionHandlerService);
    private store = inject(Store);
    private authService = inject(AuthorizeService);


    /**
     * The Effect for the creation of a new antrag either for a foerdeantrag or a nebenantrag.
     */
    createNewAntragsnummer$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(formularActionsInt.postChangeCreateNewFormular),
            concatLatestFrom(() => [this.store.select(selectActiveUnternehmenId),
            this.store.select(selectNebenantragsFoerderantragsId)
            ]),
            switchMap(([action, unternehmenId, hauptAntragsnummer]) => {
                this.formularHandlerService.clearFormularActionsInBus();
                this.persistenceService.writeToBus(formularActionsInt.setUpNewFormular({ shortName: action.shortName }));
                let returnAntragsnummer: Observable<string> = of("");
                let urlQueryParams: { [key: string]: string } = {};
                if (action.antragsType === AntragsType.Foerderantrag) {
                    returnAntragsnummer = this.unternehmenFoerderantraegeApiService.createFoerderantrag(action.antragsArtId, unternehmenId);
                    urlQueryParams = { foerderart: action.antragsart.replaceAll(" ", "") };
                } else if (action.antragsType === AntragsType.Nebenantrag) {
                    const foerderantragId = action.foerderAntragsId ?? hauptAntragsnummer
                    returnAntragsnummer = this.unternehmenNebenantraegeAPIService.createNebenantrag(action.antragsArtId, foerderantragId, unternehmenId);
                    urlQueryParams = { nebenantragsart: action.antragsart.replaceAll(" ", "") };
                }
                return returnAntragsnummer.pipe(
                    map((antragsnummer) => {
                        const returnAction = formularActionsInt.setActiveAntragsnummer({ antragsnummer });
                        this.persistenceService.writeToBus(returnAction);
                        this.formularHandlerService.navigateToFormular(urlQueryParams);
                        return returnAction;
                    }),
                );
            })
        )
    });

    /**
     * Effect that is triggered when the FE tab containing the form initializes.
     * Only for forms that are not the Unternehmensregistrierung form fetching form content is dispatched.
     */
    initializeNewTab$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(formularActionsInt.initializeNewTab),
            concatLatestFrom(() => [this.store.select(selectFormularStoreIsNewUnternehmen)]),
            filter(([, isNewUnternehmen]) => !isNewUnternehmen),
            switchMap(() => of(formularActionsInt.changeFormContent()))
        );
    });

    /**
     * Effect that updates the form content
     */
    updateFormContent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(formularActionsInt.changeFormContent),
            concatLatestFrom(() => [
                this.store.select(selectActiveUnternehmenId),
                this.store.select(selectFormularStoreActiveFormularAntragsnummer),
                this.store.select(selectFormularStoreOnlyStammdaten),
            ]),
            filter(([, unternehmenId, activeAntragsnummer]) => activeAntragsnummer.trim() !== '' && unternehmenId !== 0),
            switchMap(([, unternehmenId, activeAntragsnummer, onlyStammdaten]) => {
                return this.formUnternehmenService.getFormContent(unternehmenId, activeAntragsnummer, onlyStammdaten).pipe(
                    map((formContent) => formularActionsInt.setFormContent({ formContent })),
                );
            }),
            catchError((error) => {
                this.globalExceptionHandlerService.handleUnknownError(error);
                return of(
                    formularActionsInt.changeFormContentFailure()
                )
            })
        );
    });

    updateExistingFormular$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(formularActionsInt.postChangeExistingFormular),
            switchMap((action) => {
                this.formularHandlerService.clearFormularActionsInBus();
                const returnAction = formularActionsInt.setExistingFormular({ antragsnummer: action.antragsnummer, formularShortname: action.formularShortname });
                this.persistenceService.writeToBus(returnAction);
                return of(returnAction);
            })
        )
    });

    createUnternehmen$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(formularActionsInt.changeCreateUnternehmen),
            switchMap(() => this.foerderantraegeCommonService.createFoerderantragForUnternehmensregistrierung().pipe(
                map(antragsnummer => {
                    this.persistenceService.writeToBus(formularActionsInt.changeCreateUnternehmenSuccess({ antragsnummer }));
                    return formularActionsInt.changeCreateUnternehmenSuccess({ antragsnummer });
                }),
            ))
        )
    });

    updateUnternehmensname$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(formularActionsInt.updateUnternehmensname),
            concatLatestFrom(() => this.store.select(selectFormularStoreActiveFormularAntragsnummer)),
            switchMap(([action, antragsnummer]) =>
                this.foerderantraegeCommonService.updateUnternehmensnameAfterUnternehmensRegistrierung(antragsnummer, action.unternehmensname).pipe(
                    map(() => nutzerUnternehmenSelectionActions.updateNutzerUnternehmenSelectionList())
                )
            )
        );
    });

    //TODO: FFA-305 remove hardcoded Stammdaten Formular
    /**
     * Effect to create a Stammdaten form for an existing company
     */
    updateUnternehmenStammdaten$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(formularActionsInt.changeUnternehmenStammdaten),
            switchMap(() => {
                return of(formularActionsInt.postChangeCreateNewFormular({
                    shortName: STAMMDATEN.gatewayShortname,
                    antragsArtId: STAMMDATEN.id,
                    antragsType: AntragsType.Foerderantrag,
                    antragsart: STAMMDATEN.bezeichnung
                }));
            })
        );
    })

    /**
     * Effect that starts the upload of a file into the s3 bucket
     */
    uploadInitialization$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(formularActionsExt.changeInitializationFileUpload),
            concatLatestFrom(() => [
                this.store.select(selectFormularStoreActiveFormularAntragsnummer),
                this.store.select(selectToken),
            ]),
            concatMap(([action, formularAntragsnummer, authToken]) => {
                return from(this.authService.checkTokenValidity(authToken || '')).pipe(
                    switchMap((tokenStillValid) => {
                        if (tokenStillValid) {
                            let fileName = action.file.name;
                            if (action.formFileType === "attachment") {
                                fileName = formularAntragsnummer + "_" + fileName;
                            }
                            return this.awsControllerService.initializeUpload(fileName).pipe(
                                map((initializeResponse) => {
                                    const fileUploadDetails = {
                                        file: action.file,
                                        formFileType: action.formFileType,
                                        htmlFieldId: action.htmlFieldId,
                                        status: action.status,
                                        antragsnummer: formularAntragsnummer,
                                        fileDetails: initializeResponse,
                                        returnMessagePort: action.returnMessagePort,
                                    } satisfies FileUploadDetails;
                                    return formularActionsExt.updateGeneratePresignedUrls({ initializeResponse, fileUploadDetails });
                                }),
                                catchError(() => {
                                    this.formularHandlerService.handleReturnMessage(action.returnMessagePort, false);
                                    return of(formularActionsExt.setInitializationFileUploadFailure());
                                })
                            )
                        } else {
                            return of(formularActionsExt.awaitRefresh());
                        }
                    }),
                    catchError(() => {
                        return of(formularActionsExt.awaitRefresh());
                    })
                )
            })
        )
    });

    /**
     * The effect that triggers the generation of presigned urls and the upload of the file parts
     */
    initializeMultipartUpload$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(formularActionsExt.updateGeneratePresignedUrls),
            concatMap((action) => {
                const { fileId, fileKey } = action.initializeResponse;
                const parts = Math.ceil(action.fileUploadDetails.file.size / this.chunkSize);

                return this.awsControllerService.getPresignedUrls(fileKey, fileId, parts).pipe(
                    switchMap(presignedUrls => of(formularActionsExt.changeGeneratePresignedUrlsUpload({ presignedUrls, fileUploadDetails: action.fileUploadDetails, partsMax: parts + 1 }))),
                    catchError(() => {
                        this.formularHandlerService.handleReturnMessage(action.fileUploadDetails.returnMessagePort, false)
                        return of(formularActionsExt.setGeneratePresignedUrlsFailure());
                    }),
                );
            })
        )
    });

    /**
     * The effect that finalizes the upload of the file into the s3 bucket
     */
    finalizeUpload$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(formularActionsExt.changeGeneratePresignedUrlsUpload),
            concatMap(({ presignedUrls, fileUploadDetails }) =>
                this.uploadFileParts(fileUploadDetails.file, presignedUrls).pipe(
                    switchMap(partEtags =>
                        this.awsControllerService.finalizeUpload(
                            fileUploadDetails.formFileType,
                            fileUploadDetails.antragsnummer,
                            fileUploadDetails.fileDetails.fileKey,
                            fileUploadDetails.fileDetails.fileId,
                            partEtags,
                            fileUploadDetails.htmlFieldId,
                            fileUploadDetails.status,
                        ).pipe(
                            map(() => {
                                this.formularHandlerService.handleReturnMessage(fileUploadDetails.returnMessagePort, true)
                                return formularActionsExt.setFinializeUpload()
                            }),
                            catchError(() => {
                                this.formularHandlerService.handleReturnMessage(fileUploadDetails.returnMessagePort, false)
                                return of(formularActionsExt.setFinializeUploadFailure());
                            })
                        )
                    ),
                    catchError(() => {
                        this.formularHandlerService.handleReturnMessage(fileUploadDetails.returnMessagePort, false)
                        return of(formularActionsExt.setGeneratePresignedUrlsUploadFailure());
                    }),
                )
            )
        )
    });

    private uploadFileParts(file: File, presignedUrls: GetPresignedUrlsResponse): Observable<PartEtag[]> {
        const partUploads = presignedUrls.parts.map((partUrl, index) => {
            const start = index * this.chunkSize;
            const end = Math.min(start + this.chunkSize, file.size);
            const filePart = file.slice(start, end);
            const currentPartNumber = index + 1;

            return this.awsControllerService.uploadPartToS3(partUrl.signedUrl, filePart).pipe(
                map(response => ({
                    PartNumber: partUrl.PartNumber,
                    ETag: this.extractEtag(response)
                } as PartEtag)),
                catchError(() => {
                    return throwError(() => new Error(`Fehler beim Hochladen des  ${currentPartNumber} Parts der Datei`));
                }),
                tap(() => {
                    this.store.dispatch(formularActionsExt.incrementUploadedParts());
                })
            );
        });

        return forkJoin(partUploads);
    }

    private extractEtag(response: HttpResponse<EmptyBodyResponse>): string {
        return response.headers.get('ETag')?.replaceAll('"', '') ?? '';
    }

}