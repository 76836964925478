import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { routerNavigationAction } from '@ngrx/router-store';

import { UnternehmenNebenantraegeAPIService } from '../../service/api/service/nebenantraege-unternehmen.service';
import { FfaNebenantraegeAPIService } from '../../service/api/service/nebenantraege-ffa.service';
import { nebenantraegeActions } from './nebenantraege.actions';
import { Observable, catchError, exhaustMap, filter, forkJoin, map, of, switchMap } from 'rxjs';
import { selectIsUnternehmenRole } from '../auth/auth.selectors';
import { selectActiveUnternehmenId } from '../nutzer-unternehmen-selection/nutzer-unternehmen-selection.selectors';
import { selectDisplayedNebenantragsart, selectNebenantraegeContentPage, selectNebenantraegeTableContent, selectNebenantraegeTableModifiers, selectNebenantragsFoerderantragsId } from './nebenantraege.selectors';
import { NebenantraegeContentPage, Nebenantragsart } from '../../model/nebenantraege.model';
import { TableAction } from '../../model/data-table.model';
import { AntragHelperService } from '../../service/helper/antrag.helper.service';
import { AntragUnternehmenApiService } from '../../service/api/service/antrag-unternehmen.service';
import { GlobalExceptionHandlerService } from '../../service/api/service/global-exception-handler.service';

/**
 * Effects for the nebenantraege store
 */
@Injectable()
export class NebenantraegeEffects {
    private store = inject(Store);
    private actions$ = inject(Actions);
    private unternehmenNebenantraegeAPIService = inject(UnternehmenNebenantraegeAPIService);
    private ffaNebenantraegeAPIService = inject(FfaNebenantraegeAPIService);
    private antragHelperService = inject(AntragHelperService);
    private antragUnternehmenApiService = inject(AntragUnternehmenApiService);
    private globalExceptionHandlerService = inject(GlobalExceptionHandlerService);

    /**
     * update the createable and existing nebenantraege for the current foerderantrag
     */
    updateNebenantraegeCreateableAndExisting$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nebenantraegeActions.changeNebenantragsartenCreateableAndExisting),
            concatLatestFrom(() =>
                [this.store.select(selectIsUnternehmenRole),
                this.store.select(selectActiveUnternehmenId),
                this.store.select(selectNebenantragsFoerderantragsId)]
            ),
            switchMap(([, userHasUnternehmenRole, unternehmenId, foerderantragId]) => {
                let returnObservableCreateable$: Observable<Nebenantragsart[]>;
                let returnObservableExisting$: Observable<Nebenantragsart[]>;
                if (userHasUnternehmenRole) {
                    returnObservableCreateable$ = this.unternehmenNebenantraegeAPIService.getPermittedNebenantragsart(foerderantragId, unternehmenId);
                    returnObservableExisting$ = this.unternehmenNebenantraegeAPIService.getFilledNebenantragsart(foerderantragId, unternehmenId);
                } else {
                    returnObservableCreateable$ = this.ffaNebenantraegeAPIService.getPermittedNebenantragsart(foerderantragId);
                    returnObservableExisting$ = this.ffaNebenantraegeAPIService.getFilledNebenantragsart(foerderantragId);
                }
                return forkJoin({
                    nebenantragsartenCreateable: returnObservableCreateable$,
                    nebenantragsartenExisting: returnObservableExisting$,
                }).pipe(
                    map(
                        ({ nebenantragsartenCreateable, nebenantragsartenExisting }) => nebenantraegeActions.setNebenantragsartenCreateableAndExisting({
                            newNebenantragsartenCreateable: nebenantragsartenCreateable,
                            newNebenantragsartenExisting: nebenantragsartenExisting,
                        })
                        ,)
                );
            }),
        )
    });

    /**
     * update the displayed nebenantrage for one nebenantragart in the current foerderantrag
     */
    updateNebenantraegePage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nebenantraegeActions.changeNebenantraegeContentPage),
            concatLatestFrom(() => [
                this.store.select(selectIsUnternehmenRole),
                this.store.select(selectActiveUnternehmenId),
                this.store.select(selectNebenantragsFoerderantragsId),
                this.store.select(selectNebenantraegeTableModifiers),]
            ),
            switchMap(([, userHasUnternehmenRole, unternehmenId, foerderantragId, nebenantraegeTableModifiers]) => {
                let returnObservable$: Observable<NebenantraegeContentPage>;
                if (userHasUnternehmenRole) {
                    returnObservable$ = this.unternehmenNebenantraegeAPIService.getNebenantraegePage(nebenantraegeTableModifiers, foerderantragId, unternehmenId);
                } else {
                    returnObservable$ = this.ffaNebenantraegeAPIService.getNebenantraegePage(nebenantraegeTableModifiers, foerderantragId);
                }
                return returnObservable$.pipe(map((nebenantraegePage) => nebenantraegeActions.setNebenantraegeContentPage({ newNebenantraegeContentPage: nebenantraegePage })));
            }),
        );
    });

    updateNebenantraegeTableActions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nebenantraegeActions.setNebenantraegeContentPage),
            concatLatestFrom(() => [this.store.select(selectNebenantraegeTableContent), this.store.select(selectDisplayedNebenantragsart)]),
            map(([, nebenantraegeContent, displayedNebenantragsart]) => {
                const tableActions: TableAction[][] = this.antragHelperService.generateNebenantragActions(nebenantraegeContent, displayedNebenantragsart);
                return nebenantraegeActions.setNebenantraegeTableModifiersTableActions({ newTableActions: tableActions });
            }),
        );
    });

    /**
 * The effect to set a antrag up for deletion
 */
    updateAntragsStatusForDeletion$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nebenantraegeActions.updateAntragsStatusForDeletion),
            concatLatestFrom(() => [this.store.select(selectActiveUnternehmenId)]),
            switchMap(([action, unternehmenId]) => {
                return this.antragUnternehmenApiService.deleteAntragUnternehmen(action.antragsId, unternehmenId).pipe(
                    map(() => nebenantraegeActions.changeNebenantraegeContentPage()),
                    catchError((error) => {
                        this.globalExceptionHandlerService.handleGeneratedApiError(error);
                        return of(nebenantraegeActions.changeNebenantraegeContentPage());
                    }),
                );
            }),
        )
    });


    /**
     * The effect that triggers the loading of one foerderantrag when the route to the page is hit.
     */
    setRouteData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(routerNavigationAction),
            concatLatestFrom(() => this.store.select(selectNebenantraegeContentPage)),
            filter(
                ([{ payload }]) =>
                    payload.routerState.url.startsWith('/alle-antraege/details') && payload.routerState.url.includes('nebenantragsart'),
            ),
            switchMap(([{ payload }]) => {
                const urlArray = payload.event.url.split('/');
                const id = urlArray[urlArray.length - 1];
                return of(nebenantraegeActions.changeNebenantraegeTableModifiersAntragsId({ newNebenantragsartId: +id }));
            }),
        );
    });

    /**
     * start reloading the nebenantraege page for the listed actions by triggering the corresponding action
     */
    triggerNebenantraegePageReload = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                nebenantraegeActions.changeNebenantraegeTableModifiersPage,
                nebenantraegeActions.changeNebenantraegeTableModifiersSearchParameter,
                nebenantraegeActions.changeNebenantraegeTableModifiersSize,
                nebenantraegeActions.changeNebenantraegeTableModifiersSortingArray,
                nebenantraegeActions.changeNebenantraegeTableModifiersStatusFilter,
                nebenantraegeActions.changeNebenantraegeTableModifiersAntragsId,
            ),
            exhaustMap(() => of(nebenantraegeActions.changeNebenantraegeContentPage())),
        )
    });
}
