import { NutzerDto } from "../../generated";
import { NutzerProfile } from "../../../../model/nutzer-profile.model";
import { NutzerAnrede } from "../../../../model/user.model";

export const mapNutzerProfile = (nutzer: Required<NutzerDto>): NutzerProfile => {
    return {
        idpId: nutzer.idpId,
        anrede: nutzer.anrede as NutzerAnrede,
        titel: nutzer.titel,
        vorname: nutzer.vorname,
        nachname: nutzer.nachname,
        email: nutzer.email,
        telefon: nutzer.telefon,
    }
}