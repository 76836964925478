<div
    class="data-table__wrapper"
    *ngIf="tableConfig.tableSortingArray$ | async; let tableSortingArray">
    <table
        mat-table
        matSort
        class="data-table"
        matSortStart="desc"
        [matSortActive]="tableSortingArray[0].column"
        [matSortDirection]="tableSortingArray[0].sortDirection"
        [matSortDisableClear]="true"
        multiTemplateDataRows
        [dataSource]="tableConfig.tableContent$"
        [class]="'data-table__' + tableConfig.tableColumnClass"
        [ariaLabel]="tableConfig.tableLabel"
        (matSortChange)="sortHandler($event)">
        <ng-container
            *ngFor="let dynamicalColumn of tableConfig.columns; let i = index"
            [matColumnDef]="dynamicalColumn.columnKey">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                [attr.data-testid]="'data-table-' + dynamicalColumn.columnKey">
                <ng-container [ngSwitch]="dynamicalColumn.columnKey">
                    <span *ngSwitchCase="'rightManagementCheckbox'">
                        <div>{{ dynamicalColumn.display }}</div>
                    </span>
                    <span *ngSwitchDefault>
                        <div>{{ dynamicalColumn.display }}</div>
                        <div class="data-table__header__cell__sort">
                            <mat-icon
                                [class]="getClass(dynamicalColumn.columnKey, true, tableSortingArray[0])"
                                class="data-table__header__cell__sort__arrow"
                                fontIcon="arrow_drop_up"
                                [attr.data-testid]="'data-table-' + dynamicalColumn.columnKey + 'arrow-up'">
                            </mat-icon>
                            <mat-icon
                                [class]="getClass(dynamicalColumn.columnKey, false, tableSortingArray[0])"
                                class="data-table__header__cell__sort__arrow"
                                fontIcon="arrow_drop_down"
                                [attr.data-testid]="'data-table-' + dynamicalColumn.columnKey + 'arrow-down'">
                            </mat-icon>
                        </div>
                    </span>
                </ng-container>
            </th>
            <td
                mat-cell
                *matCellDef="let columnItem; let index = dataIndex"
                [class]="tableConfig.tableRowClassFunction ? tableConfig.tableRowClassFunction(index) : ''"
                (click)="rowClickHandler(index)">
                <ng-container [ngSwitch]="dynamicalColumn.columnKey">
                    <span *ngSwitchCase="'mitAnhang'">
                        <mat-icon
                            *ngIf="columnItem[dynamicalColumn.columnKey]"
                            fontIcon="attach_file"></mat-icon>
                    </span>
                    <span *ngSwitchCase="'rightManagementCheckbox'">
                        <mat-checkbox
                            class="text-accent-primary"
                            [checked]="columnItem[dynamicalColumn.columnKey]">
                        </mat-checkbox>
                    </span>

                    <span *ngSwitchDefault>{{ columnItem[dynamicalColumn.columnKey] }}</span>
                </ng-container>
            </td>
        </ng-container>
        <ng-container
            *ngIf="!tableConfig.disableTableActions"
            matColumnDef="actions">
            <th
                mat-header-cell
                *matHeaderCellDef>
                Aktionen
            </th>
            <td
                mat-cell
                *matCellDef="let columnItem; let i = dataIndex"
                class="data-table__body__cell--action">
                <app-data-table-action-menu
                    class="data-table__body__cell--action__icon"
                    *ngIf="tableConfig.tableActions$ | async; let tableActions"
                    [tableActions]="tableActions[i]"
                    [detailId]="columnItem[tableConfig.tableDetailId]">
                </app-data-table-action-menu>
            </td>
        </ng-container>
        <ng-container matColumnDef="isLoading">
            <td
                mat-footer-cell
                *matFooterCellDef
                [attr.colSpan]="displayedColumns.length">
                <ng-container *ngIf="tableIsLoadingStatus">
                    <div class="data-table__body--loading">
                        <mat-spinner></mat-spinner>
                        <p>Die Daten werden geladen</p>
                    </div>
                </ng-container>
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: tableConfig.headerIsSticky"></tr>

        <tr
            mat-row
            *matRowDef="let row; let index = index; columns: displayedColumns"
            [class]="tableIsLoading(tableIsLoadingStatus)"></tr>

        <tr
            *matNoDataRow
            [class]="tableIsLoading(tableIsLoadingStatus)">
            <td
                [attr.colSpan]="displayedColumns.length"
                class="data-table__body--empty">
                Es konnten keine {{ tableConfig.tableDataObjectsName }} gefunden werden
            </td>
        </tr>
        <tr
            mat-footer-row
            *matFooterRowDef="['isLoading']"
            [class]="tableIsLoading(!tableIsLoadingStatus)"></tr>
    </table>
</div>
<mat-paginator
    [pageSizeOptions]="[10, 20, 50, 100]"
    [pageSize]="tableConfig.tableDisplayedSize$ | async"
    [pageIndex]="tableConfig.tableDisplayedPage$ | async"
    [length]="tableConfig.tableTotalElements$ | async"
    showFirstLastButtons
    aria-label="Die Seite der Tabelle auswählen"
    [selectConfig]="paginatorSelectConfig"
    class="!text-text-dark !text-small data-table-paginator"
    (page)="pageHandler($event)">
</mat-paginator>
