import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';

import { selectFoerderantraegeStoreAllFoerderantraegeContent } from '../../store/foerderantraege/foerderantraege.selectors';
import { selectCurrentUrl } from '../../store/router-store/router-store.selectors';
import { Link } from '../../model/app-routing.model';

/**
 * the resolver for the alle-antraege/details/:id page that genereates the breadcrumb data for the page
 * @param route gets the active route snapshot from the router to get the id from it
 * @returns returns the breadcrumb data object that is used in the breadcrumb component to generate the link and display
 */
export const FoerderantragDetailResolver: ResolveFn<Link> = async (route: ActivatedRouteSnapshot) => {
    return new Promise((resolve, reject) => {
        const breadcrumb: Link = { label: '', link: '' };
        breadcrumb.link = 'details/' + route.params['antragsnummer'];
        const store = inject(Store);
        store
            .select(selectCurrentUrl)
            .pipe(take(1))
            .subscribe((currentUrl) => {
                if (currentUrl.endsWith(breadcrumb.link)) {
                    store
                        .select(selectFoerderantraegeStoreAllFoerderantraegeContent)
                        .pipe(take(1))
                        .subscribe((foerderantraege) => {
                            if (foerderantraege.length === 0) {
                                reject();
                            } else {
                                const projectName = foerderantraege.find(
                                    (foerderantrag) => foerderantrag.antragsnummer === route.params['antragsnummer'],
                                )?.projektname;
                                breadcrumb.label = 'Antragsdetails für "' + projectName + '"';
                                resolve(breadcrumb);
                            }
                        });
                } else {
                    reject('');
                }
            });
    });
};
