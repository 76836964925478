import { Injectable, inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, first, mergeMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectToken } from '../store/auth/auth.selectors';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private store = inject(Store);

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (req.headers.has('Skip-Auth-Interceptor')) {
            // We remove the header again as it is not relevant for the actual S3 request
            const headers = req.headers.delete('Skip-Auth-Interceptor');
            const newReq = req.clone({ headers });
            return next.handle(newReq);
        }
        return this.store.select(selectToken).pipe(
            first(),
            mergeMap((token: string | null) => {
                if (token) {
                    const authReq = req.clone({
                        setHeaders: { Authorization: `Bearer ${token}` },
                    });
                    return next.handle(authReq);
                }
                return next.handle(req);
            }),
        );
    }
}
