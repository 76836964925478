import { inject, Injectable } from "@angular/core";
import { FoerderantragCommonControllerService } from "../generated";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class FoerderantraegeCommonService {
    private foerderantragCommonService = inject(FoerderantragCommonControllerService);

    createFoerderantragForUnternehmensregistrierung(): Observable<string> {
        return this.foerderantragCommonService.createFoerderantragForUnternehmensregistrierung().pipe(
            map((antragsnummerDTO) => antragsnummerDTO.antragsnummer)
        );
    }

    updateUnternehmensnameAfterUnternehmensRegistrierung(antragsnummer: string, unternehmensname: string): Observable<boolean> {
        return this.foerderantragCommonService.updateUnternehmensnameAfterUnternehmensRegistrierung({ antragsnummer, requestBody: { unternehmensname } });
    }
}