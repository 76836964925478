
import { Niederlassung } from "../../../../model/stammdaten.model";
import { NiederlassungDto } from "../../generated";
import { mapAdresse } from "./adresse.mapper";

/**
 * Map the niederlassungDto to the niederlassung type
 * @param niederlassung the dto from the backend
 * @returns the type we use in the frontend
 */
export const mapNiederlassung = (niederlassung: NiederlassungDto): Niederlassung => ({
    id: niederlassung.id,
    name: niederlassung.name,
    adresse: niederlassung.adresse ? mapAdresse(niederlassung.adresse) : undefined,
});