import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { NutzerProfile } from "../../model/nutzer-profile.model";

export const nutzerProfileActions = createActionGroup({
    source: 'Nutzer Profile',
    events: {
        changeNutzerProfile: emptyProps(),
        changeNutzerProfileChanges: props<{ newNutzerProfileChanges: NutzerProfile }>(),
        setNutzerProfile: props<{ newNutzerProfileData: NutzerProfile }>(),
        resetChangesToNutzerProfile: emptyProps(),
        resetNutzerProfile: emptyProps(),
        setNutzerProfilePasswordChangeInitiated: emptyProps(),
        setNutzerProfilePasswordChangeSuccess: emptyProps(),
        setNutzerProfilePasswordChangeFailed: props<{ error: string }>(),
    }
})