import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { authActions } from '../../../store/auth/auth.actions';
import { passwordMatchValidator, passwordStrengthValidator } from '../../../service/helper/form-validators';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { selectPasswordChangeError, selectPasswordChangePasswordChanged } from '../../../store/nutzer-profile/nutzer-profile.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { nutzerProfileActions } from '../../../store/nutzer-profile/nutzer-profile.actions';

@Component({
    selector: 'app-change-password-dialog',
    templateUrl: './change-password-dialog.component.html',
    styleUrl: './change-password-dialog.component.scss',
})
export class ChangePasswordDialogComponent implements OnInit {
    private store = inject(Store);
    private destroyRef = inject(DestroyRef);
    private dialogRef: MatDialogRef<ChangePasswordDialogComponent> = inject(MatDialogRef);

    showPassword = { oldPassword: false, newPassword: false, newPasswordRepeat: false }

    passwordFormGroup = new FormGroup({
        oldPassword: new FormControl('', Validators.required),
        newPassword: new FormControl('', [Validators.required, passwordStrengthValidator()]),
        newPasswordRepeat: new FormControl('', [Validators.required, passwordStrengthValidator()]),
    }, { validators: passwordMatchValidator })

    serverError = '';

    passwordHintOptions: NgxTippyProps = { placement: 'bottom-start', theme: 'light-border' };

    passwordHintText = `Enthält mindestens 8 Zeichen
        Enthält mindestens eine Zahl
        Enthält mindestens einen Großbuchstaben
        Enthält mindestens einen Kleinbuchstaben
        Enthält mindestens eins der folgenden Sonderzeichen:
        ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < ' : ; | _ ~ \` + = `;


    ngOnInit(): void {
        this.store.dispatch(nutzerProfileActions.setNutzerProfilePasswordChangeInitiated());
        this.store.select(selectPasswordChangePasswordChanged).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
            (passwordChanged) => {
                if (passwordChanged) {
                    this.closeDialog();
                }
            }
        );
        this.store.select(selectPasswordChangeError).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
            (error) => {
                if (error) {
                    this.passwordFormGroup.setErrors({ 'serverError': true });
                    this.serverError = error;
                }
            }
        );
    }
    /**
     * close the dialog without changing something
     */
    closeDialog(): void {
        this.dialogRef.close();
    }

    /**
     * close the dialog when the password change is valid and send the change request
     */
    saveDialog(): void {
        if (this.passwordFormGroup.valid && this.passwordFormGroup.value.oldPassword && this.passwordFormGroup.value.newPassword) {
            const { oldPassword, newPassword } = this.passwordFormGroup.value
            this.store.dispatch(authActions.updatePasswordChange({ oldPassword, newPassword }));
        }
    }

    /**
     * Changes the status of the password input field to be shown
     * @param formControlName the name of the formcontrol that should be displayed
     */
    triggerShowPassword(formControlName: 'oldPassword' | 'newPassword' | 'newPasswordRepeat'): void {
        this.showPassword[formControlName] = !this.showPassword[formControlName]
    }

    /**
     * This method returns the error message for the given formcontrol
     * @param formControlName the name of the formcontrol
     * @returns the error message
     */
    showErrorMessage(formControlName: 'oldPassword' | 'newPassword' | 'newPasswordRepeat'): string {
        let errorMessage = '';
        if (this.passwordFormGroup.get(formControlName)?.invalid) {
            if (formControlName === 'oldPassword') {
                errorMessage = 'Das alte Passwort darf nicht leer sein';
            } else if (formControlName === 'newPassword') {
                const passwordStrength = this.passwordFormGroup.get('newPassword')?.errors?.['passwordStrength']?.rules;
                if (this.passwordFormGroup.get('newPassword')?.hasError('required')) {
                    errorMessage = 'Das neue Passwort darf nicht leer sein';
                } else if (!passwordStrength.hasUpperCase) {
                    errorMessage = 'Das neue Passwort muss mindestens einen Großbuchstaben enthalten';
                } else if (!passwordStrength.hasLowerCase) {
                    errorMessage = 'Das neue Passwort muss mindestens einen Kleinbuchstaben enthalten';
                } else if (!passwordStrength.hasNumber) {
                    errorMessage = 'Das neue Passwort muss mindestens eine Zahl enthalten';
                } else if (!passwordStrength.hasSpecialChar) {
                    errorMessage = 'Das neue Passwort muss mindestens eine Sonderzeichen enthalten';
                } else if (!passwordStrength.validLength) {
                    errorMessage = 'Das neue Passwort muss mindestens 8 Zeichen enthalten';
                }
            } else if (formControlName === 'newPasswordRepeat') {
                errorMessage = 'Die Wiederholung des neuen Passworts darf nicht leer sein';
            }
        }
        return errorMessage;
    }

}
