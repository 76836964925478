import { createReducer, on } from '@ngrx/store';
import { FoerderantraegeStore } from '../../model/foerderantraege.model';
import { foerderantraegeActions, displayedDetailsFoerderantragActions } from './foerderantraege.actions';
import { SortDir, SortParameter } from '../../model/store.model';
import { authActions } from '../auth/auth.actions';
import { nutzerRightManagementFoerderantragViewActions } from '../all-unternehmen-nutzer/all-unternehmen-nutzer.actions';

export const foerderantraegeInitialState: FoerderantraegeStore = {
    searchParameter: '',
    foerderartFilter: '',
    statusFilter: null,
    page: 0,
    size: 20,
    sortingArray: [{ column: 'aktualisiertAm', sortDirection: SortDir.desc }],
    allFoerderantraege: {
        totalElements: 0,
        totalPages: 0,
        size: 0,
        content: [],
        numberOfElements: 0,
        empty: true,
    },
    tableActions: [],
    displayedDetailsFoerderantrag: null,
    isLoading: false,
    foerderartenInUse: [],
};

export const foerderantraegeReducer = createReducer(
    foerderantraegeInitialState,
    on(
        foerderantraegeActions.changeSearchParameter,
        (state, action): FoerderantraegeStore => ({ ...state, searchParameter: action.newSearchParameter, page: 0 }),
    ),
    on(
        foerderantraegeActions.changeShownFoerderartFilter,
        (state, action): FoerderantraegeStore => ({
            ...state,
            foerderartFilter: action.newFoerderartFilter,
            page: 0,
        }),
    ),
    on(
        foerderantraegeActions.changeShownStatusFilter,
        (state, action): FoerderantraegeStore => ({ ...state, statusFilter: action.newStatusFilter, page: 0 }),
    ),
    on(foerderantraegeActions.changeShownPage, (state, action): FoerderantraegeStore => ({ ...state, page: action.newPage })),
    on(foerderantraegeActions.changeShownSize, (state, action): FoerderantraegeStore => ({ ...state, size: action.newSize, page: 0 })),
    on(foerderantraegeActions.changeSortingArray, (state, action): FoerderantraegeStore => {
        let sorting: SortParameter[];
        if (action.multiSort) {
            sorting = [...state.sortingArray];
            sorting.push(action.newSorting);
        } else {
            sorting = [action.newSorting];
        }
        return { ...state, sortingArray: sorting, page: 0 };
    }),
    on(
        foerderantraegeActions.changeAllFoerderantraege,
        (state): FoerderantraegeStore => ({
            ...state,
            isLoading: true,
            searchParameter: foerderantraegeInitialState.searchParameter,
            page: foerderantraegeInitialState.page,
            size: foerderantraegeInitialState.size,
            statusFilter: foerderantraegeInitialState.statusFilter,
            foerderartFilter: foerderantraegeInitialState.foerderartFilter,
            sortingArray: foerderantraegeInitialState.sortingArray,
            displayedDetailsFoerderantrag: null,
        }),
    ),
    on(
        foerderantraegeActions.changeAllFoerderantraegeIntern,
        (state): FoerderantraegeStore => ({
            ...state,
            isLoading: true,
        }),
    ),
    on(
        foerderantraegeActions.setAllFoerderantraege,
        (state, action): FoerderantraegeStore => ({
            ...state,
            allFoerderantraege: action.allFoerderantraege,
            tableActions: action.newTableActions,
            isLoading: false,
        }),
    ),
    on(foerderantraegeActions.changeAllFoerderantraegeFailed, (state): FoerderantraegeStore => ({ ...state, isLoading: false })),
    on(
        foerderantraegeActions.resetStore,
        (): FoerderantraegeStore => ({
            ...foerderantraegeInitialState,
        }),
    ),
    on(
        foerderantraegeActions.setFoerderartenInUse,
        (state, action): FoerderantraegeStore => ({
            ...state,
            foerderartenInUse: action.newFoerderartenInUse,
        }),
    ),
    on(
        foerderantraegeActions.resetFoerderartenInUse,
        (state): FoerderantraegeStore => ({
            ...state,
            foerderartenInUse: foerderantraegeInitialState.foerderartenInUse,
        }),
    ),
    on(displayedDetailsFoerderantragActions.setDisplayedDetailsFoerderantrag, (state, action): FoerderantraegeStore => ({
        ...state,
        displayedDetailsFoerderantrag: action.newFoerderantrag,
    })),
    on(
        authActions.changeUserToLoggedOut,
        (): FoerderantraegeStore => ({
            ...foerderantraegeInitialState,
        }),
    ),
    on(
        nutzerRightManagementFoerderantragViewActions.changeAllFoerderantraege,
        (state): FoerderantraegeStore => ({ ...state, size: 10, sortingArray: [{ column: 'antragsnummer', sortDirection: SortDir.desc }] }),
    ),
    on(foerderantraegeActions.changeAntragsStatusForDeletion, (state): FoerderantraegeStore => ({ ...state, isLoading: true }))
);
