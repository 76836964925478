import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UnternehmenSelectionStore } from '../../model/nutzer-unternehmen-selection.model';

export const selectNutzerUnternehmenSelection = createFeatureSelector<UnternehmenSelectionStore>('nutzerUnternehmenSelection');

export const selectActiveUnternehmen = createSelector(selectNutzerUnternehmenSelection, (nutzerUnternehmenSelection) => nutzerUnternehmenSelection.selectedUnternehmen);

export const selectUnternehmenSelectionList = createSelector(
    selectNutzerUnternehmenSelection,
    (userUnternehmenSelection) => userUnternehmenSelection.unternehmenSelectionList,
);

export const selectActiveUnternehmenId = createSelector(selectActiveUnternehmen, (selectedUnternehmen) => +selectedUnternehmen.unternehmenId);

export const selectActiveUnternehmenName = createSelector(selectActiveUnternehmen, (selectedUnternehmen) => selectedUnternehmen.name);

export const selectNutzerHasCompany = createSelector(selectUnternehmenSelectionList, (unternehmenSelectionList) => unternehmenSelectionList.length > 0)
