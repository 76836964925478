import { Pipe, PipeTransform, inject } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { environment } from "../../../environments/environment";

/**
 * A pipe that allows to open the passed link as Trusted URL
 */
@Pipe({ name: 'safeFormularUrl' })
export class SafeFormularUrlPipe implements PipeTransform {
    private domSanitzer = inject(DomSanitizer);

    /**
     * The transform function of the pipe that is called with the value the pipe is used on
     * @param url The url the pipe should pass. since it should be called with a value from an async pipe it can be null.
     * @returns Returns the as safe registered url.
     */
    transform(url: string | null): SafeUrl {
        if (url !== null && url !== undefined) {
            const urlObj = new URL(url);
            if (urlObj.hostname !== environment.FMS.origin) {
                return this.domSanitzer.bypassSecurityTrustResourceUrl(url);
            }
        }
        return this.domSanitzer.bypassSecurityTrustResourceUrl('');
    }
}