import { Foerderart } from "../../../../model/foerderbereich.model";
import { FoerderartDto } from "../../generated";

/**
 * Maps the generated FoerderartDto[] to the Foerderart[] that is used in the store.
 * @param foerderartenExt the API returned foerderarten array
 * @returns returns the store friendly foerderarten array
 */
export const mapFoerderarten = (foerderartenExt: FoerderartDto[]): Foerderart[] => {
    const newFoerderartenList: Foerderart[] = [];
    for (const foerderartExt of foerderartenExt) {
        newFoerderartenList.push({
            foerderbereichBezeichnung: foerderartExt.foerderbereichBezeichnung,
            bezeichnung: foerderartExt.bezeichnung,
            gatewayShortname: foerderartExt.gatewayShortname,
            id: foerderartExt.id,
            rechtsgrundlage: foerderartExt.rechtsgrundlage,
        });
    }
    return newFoerderartenList;
}