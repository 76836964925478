import { Component, HostListener, OnInit, inject } from "@angular/core";
import { MatSnackBarRef } from "@angular/material/snack-bar";
import { Observable } from "rxjs";

import { ErrorInformation } from "../../model/error-store.model";
import { Store } from "@ngrx/store";
import { selectErrorStoreHasError, selectErrorStoreShownError } from "../../store/error-store/error.selectors";

@Component({
    selector: 'app-snack-bar',
    templateUrl: 'snack-bar.component.html',
    styleUrl: 'snack-bar.component.scss'
})
export class SnackBarComponent implements OnInit {
    private snackBarRef = inject(MatSnackBarRef);
    private store = inject(Store);

    isError$: Observable<boolean>;
    data$: Observable<ErrorInformation>;

    ngOnInit(): void {
        this.data$ = this.store.select(selectErrorStoreShownError);
        this.isError$ = this.store.select(selectErrorStoreHasError);
    }
    @HostListener('document:keydown.esc')
    close(): void {
        this.snackBarRef.dismiss();
    }
}