import { createReducer, on } from '@ngrx/store';
import { NebenantraegeStore } from '../../model/nebenantraege.model';
import { nebenantraegeActions } from './nebenantraege.actions';
import { displayedDetailsFoerderantragActions, foerderantraegeActions } from '../foerderantraege/foerderantraege.actions';
import { SortDir, SortParameter } from '../../model/store.model';
import { authActions } from '../auth/auth.actions';

export const nebenantraegeStoreInitalState: NebenantraegeStore = {
    foerderantragsId: 0,
    isLoading: false,
    nebenantraegeContentPage: {
        empty: true,
        nebenantraegeDetails: [],
        numberOfElements: 0,
        totalElements: 0,
        totalPages: 0,

    },
    nebenantraegeTableModifiers: {
        page: 0,
        searchParameter: '',
        size: 20,
        sortingArray: [{ column: 'aktualisiertAm', sortDirection: SortDir.desc }],
        statusFilter: null,
        nebenantragsartId: -1,
        nebenantragsActions: []
    },
    nebenantragsartenCreateable: [],
    nebenantragsartenExisting: [],
    displayedNebenantragsart: {
        id: -1,
        bezeichnung: "",
        gatewayShortname: "",
    },
    shownTab: 0,
};

export const nebenantraegeReducer = createReducer(
    nebenantraegeStoreInitalState,
    on(nebenantraegeActions.changeNebenantraegeContentPage, (state): NebenantraegeStore => ({ ...state, isLoading: true })),
    on(nebenantraegeActions.setNebenantraegeContentPage, (state, action): NebenantraegeStore => ({
        ...state,
        nebenantraegeContentPage: action.newNebenantraegeContentPage,
        isLoading: false,
    })),
    on(nebenantraegeActions.changeNebenantraegeTableModifiersAntragsId, (state, action): NebenantraegeStore => ({
        ...state,
        nebenantraegeTableModifiers: { ...state.nebenantraegeTableModifiers, nebenantragsartId: action.newNebenantragsartId },
    })),
    on(nebenantraegeActions.changeNebenantraegeTableModifiersPage, (state, action): NebenantraegeStore => ({
        ...state,
        nebenantraegeTableModifiers: { ...state.nebenantraegeTableModifiers, page: action.newPage },
    })),
    on(nebenantraegeActions.changeNebenantraegeTableModifiersSearchParameter, (state, action): NebenantraegeStore => ({
        ...state,
        nebenantraegeTableModifiers: { ...state.nebenantraegeTableModifiers, searchParameter: action.newSearchParameter },
    })),
    on(nebenantraegeActions.changeNebenantraegeTableModifiersSize, (state, action): NebenantraegeStore => ({
        ...state,
        nebenantraegeTableModifiers: { ...state.nebenantraegeTableModifiers, size: action.newSize },
    })),
    on(nebenantraegeActions.changeNebenantraegeTableModifiersSortingArray, (state, action): NebenantraegeStore => {
        let sorting: SortParameter[];
        if (action.isMultiSort) {
            sorting = [...state.nebenantraegeTableModifiers.sortingArray];
            sorting.push(action.newSortingParameter);
        } else {
            sorting = [action.newSortingParameter];
        }
        return {
            ...state,
            nebenantraegeTableModifiers: { ...state.nebenantraegeTableModifiers, sortingArray: sorting },
        };
    }),
    on(nebenantraegeActions.changeNebenantraegeTableModifiersStatusFilter, (state, action): NebenantraegeStore => ({
        ...state,
        nebenantraegeTableModifiers: { ...state.nebenantraegeTableModifiers, statusFilter: action.newStatusFilter },
    })),
    on(nebenantraegeActions.setNebenantraegeTableModifiersTableActions, (state, action): NebenantraegeStore => ({
        ...state,
        nebenantraegeTableModifiers: { ...state.nebenantraegeTableModifiers, nebenantragsActions: action.newTableActions },
    })),

    on(nebenantraegeActions.resetNebenantraegeTable, (state): NebenantraegeStore => ({
        ...state,
        nebenantraegeTableModifiers: nebenantraegeStoreInitalState.nebenantraegeTableModifiers,
        nebenantraegeContentPage: nebenantraegeStoreInitalState.nebenantraegeContentPage
    })),
    on(nebenantraegeActions.changeNebenantragsartenCreateableAndExisting, (state): NebenantraegeStore => ({ ...state, isLoading: true, shownTab: 1 })),
    on(nebenantraegeActions.setNebenantragsartenCreateableAndExisting, (state, action): NebenantraegeStore => ({
        ...state,
        nebenantragsartenCreateable: action.newNebenantragsartenCreateable,
        nebenantragsartenExisting: action.newNebenantragsartenExisting,
        isLoading: false,
    })),
    on(nebenantraegeActions.setFoerderantragsId, (state, action): NebenantraegeStore => ({
        ...state,
        foerderantragsId: action.newFoerderantragsId,
    })),
    on(nebenantraegeActions.setDisplayedNebenantrag, (state, action): NebenantraegeStore => ({
        ...state,
        displayedNebenantragsart: action.newNebenantrag,
    })),
    on(foerderantraegeActions.changeAllFoerderantraege, (): NebenantraegeStore => ({ ...nebenantraegeStoreInitalState })),

    on(displayedDetailsFoerderantragActions.updateDisplayedDetailsFoerderantrag, (state): NebenantraegeStore => ({ ...state, shownTab: 0 })),
    on(authActions.changeUserToLoggedOut, (): NebenantraegeStore => ({ ...nebenantraegeStoreInitalState })),
);
