<button
    *ngIf="isButton"
    mat-raised-button
    [color]="buttonColor"
    class="general-button"
    [class]="buttonClass"
    [disableRipple]="true"
    [disabled]="disabled"
    (click)="clickEvent.emit($event)"
    data-testid="uiButton-button">
    {{ buttonLabel }}
</button>

<a
    *ngIf="!isButton && !isExternalLink"
    mat-raised-button
    [color]="buttonColor"
    class="general-button"
    [class]="buttonClass"
    [disableRipple]="true"
    [routerLink]="targetLink"
    [disabled]="disabled"
    data-testid="uiButton-link">
    {{ buttonLabel }}
</a>

<a
    *ngIf="!isButton && isExternalLink"
    mat-raised-button
    [color]="buttonColor"
    class="general-button"
    [class]="buttonClass"
    [href]="targetLink"
    [disableRipple]="true"
    [disabled]="disabled"
    target="_blank"
    data-testid="uiButton-link-extern"
    (click)="$event.stopPropagation()">
    {{ buttonLabel }}
</a>
