<div class="right-management-allantrag">
  <app-search class="right-management-allantrag__search-box" (searchEvent)="searchHandler($event)"></app-search>
  <div>
    <ng-container>
      <div class="right-management-allantrag__container">
        <app-data-table
          *ngIf="tableConfig"
          [tableConfig]="tableConfig"
          (pageEvent)="pageHandler($event)"
          (sortEvent)="sortHandler($event)"
          (rowClickEvent)="rowClickHandler($event)"></app-data-table>
      </div>
    </ng-container>
  </div>
</div>
