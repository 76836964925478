import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthStore, PortalRole } from '../../model/auth.model';
import { NutzerAnrede } from '../../model/user.model';

export const selectAuthStore = createFeatureSelector<AuthStore>('authStore');
/**
 * return the login flag
 * @param state injectd by the store.select function, caller needs Store injection with <{authStore: AuthStore }>
 * @returns boolean which determines whether a user is logged in
 */
export const selectLoggedIn = createSelector(selectAuthStore, (authStore) => authStore.loggedIn);

/**
 * return the access token from the logged in user
 * @param state injectd by the store.select function, caller needs Store injection with <{authStore: AuthStore }>
 * @returns access token as a string or null if no user is logged in
 */
export const selectToken = createSelector(selectAuthStore, (authStore) => authStore.token);

/**
 * return the current state of the auth store
 * @param state injectd by the store.select function, caller needs Store injection with <{authStore: AuthStore }>
 * @returns AuthStore which contains all stored informations about the logged in user
 */
export const selectLoginDetails = createSelector(selectAuthStore, (authStore) => authStore);

/**
 * return the PortalRole from the logged in user
 * @param state injectd by the store.select function, caller needs Store injection with <{authStore: AuthStore }>
 * @returns PortalRole of the logged in user or null if no is logged in
 */
export const selectRole = createSelector(selectAuthStore, (authStore) => authStore.role);

export const selectIsUnternehmenRole = createSelector(selectRole, (userRole) => {
    switch (userRole) {
        case PortalRole.COMPANY_ADMIN:
        case PortalRole.COMPANY_SELECT:
        case PortalRole.COMPANY_USER:
            return true;
        default:
            return false;
    }
});

export const selectIsAdminRole = createSelector(selectRole, (userRole) => {
    switch (userRole) {
        case PortalRole.COMPANY_ADMIN:
            return true;
        default:
            return false;
    }
});

export const selectActiveNutzerEmail = createSelector(selectAuthStore, (authStore) => authStore.email);

export const selectActiveNutzerFullname = createSelector(selectAuthStore, (authStore) => (authStore.vorname + ' ' + authStore.nachname));

export const selectActiveNutzerAnredeGreeting = createSelector(selectAuthStore, selectActiveNutzerFullname, (authStore, fullname) => {
    let greeting = '';
    switch (authStore.anrede) {
        case NutzerAnrede.Male:
            greeting = 'Sehr geehrter Herr ' + authStore.nachname;
            break;
        case NutzerAnrede.Female:
            greeting = 'Sehr geehrte Frau ' + authStore.nachname;
            break;
        default:
            greeting = 'Hallo ' + fullname;
    }
    return greeting;
});

export const selectRefreshAction = createSelector(selectAuthStore, (authStore) => authStore.actionAfterRefresh);