import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-amplify-selector',
    templateUrl: './custom-amplify-selector.component.html',
})
export class CustomAmplifySelectorComponent {
    @Input() items: string[];
    @Input() name: string;
    @Input() label: string;
    @Input() labelHidden = false;
    @Input() id: string;
    @Input() defaultValue: string;
}
