import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectDokumentContentIsLoading, selectDokumentContentURL } from '../../../store/dokumente-unternehmen/dokumente-unternehmen.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PDFDocumentProxy } from 'ng2-pdf-viewer/src/app/pdf-viewer/pdf-viewer.module';
import { MatDialogRef } from '@angular/material/dialog';
import { OpenDialogComponent } from '../open-dialog.component';
import { dokumentContentActions } from '../../../store/dokumente-unternehmen/dokumente-unternehmen.actions';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-lesebestaetigung-setzen',
    templateUrl: './lesebestaetigung-setzen.component.html',
    styleUrl: './lesebestaetigung-setzen.component.scss',
})
export class LesebestaetigungSetzenComponent implements OnInit {
    private store = inject(Store);
    private destroyRef = inject(DestroyRef);

    private dialogRef: MatDialogRef<OpenDialogComponent> = inject(MatDialogRef);

    dokumentURL: string;
    page = 1;
    totalPages: number;
    isLoading$: Observable<boolean>;


    ngOnInit(): void {
        this.store.select(selectDokumentContentURL).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((url) => {
            this.dokumentURL = url;
            if (url === 'failed') {
                this.dialogRef.close();
                this.store.dispatch(dokumentContentActions.resetDokumentContent());
            }
        });

        this.isLoading$ = this.store.select(selectDokumentContentIsLoading);
    }

    /**
     * The handler for the load complete event from the ng2-pdf-viewer
     * @param pdf a pdf proxy that holds the different information about the loaded pdf
     */
    afterLoadCompleteHandler(pdf: PDFDocumentProxy): void {
        this.totalPages = pdf.numPages;
    }

    /**
     * changes the page to the next pdf page
     */
    nextPage(): void {
        if (this.page < this.totalPages) {
            this.page++;
        }
    }

    /**
     * changes the page to the previous pdf page
     */
    prevPage(): void {
        if (this.page > 1) {
            this.page--;
        }
    }
}
