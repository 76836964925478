import { DokumentContent } from "../../../../model/dokumente-unternehmen.model";
import { DokumentContentDto } from "../../generated";

export const mapDokumentContent = (dokumentContentDto: DokumentContentDto): DokumentContent => {
    //The return content is no array but just a string so it has to be changed here to be useable
    const base64Content = dokumentContentDto.content as unknown as string;
    // Decode the base64 string into binary data
    const byteCharacters = window.atob(base64Content);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob from the byte array
    const contentBlob = new Blob([byteArray], { type: 'application/pdf' });
    return {
        name: dokumentContentDto.filename,
        content: contentBlob,
        documentId: dokumentContentDto.dokumentId
    }
}