import { Component, EventEmitter, Input, Output } from '@angular/core';
import { translate, ComponentClassName, classNames } from '@aws-amplify/ui';
import { nanoid } from 'nanoid';

@Component({
    selector: 'app-amplify-password-field',
    templateUrl: './custom-amplify-password-field.component.html',
})
export class CustomAmplifyPasswordFieldComponent {
    @Input() autocomplete = 'new-password';
    @Input() disabled = false;
    @Input() fieldId = `amplify-field-${nanoid(12)}`;
    @Input() initialValue = '';
    @Input() label = '';
    @Input() name: string;
    @Input() placeholder = '';
    @Input() required = true;
    @Input() labelHidden = false;
    @Input() hasError: boolean;
    @Input() describedBy: string;
    @Output() setBlur = new EventEmitter<Event>();

    public type: 'text' | 'password' = 'password';

    public showPassword = false;
    public showPasswordButtonlabel = translate('Show password');

    // re-export utilities so that template html can use them
    public classnames = classNames;
    public ComponentClassName = ComponentClassName;

    togglePasswordText(): void {
        this.showPassword = !this.showPassword;
        this.showPasswordButtonlabel = this.showPassword
            ? translate('Hide password')
            : translate('Show password');
        this.type = this.showPassword ? 'text' : 'password';
    }
}
