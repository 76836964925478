import { props, createActionGroup, emptyProps } from '@ngrx/store';
import { UnternehmenSelectionItem } from '../../model/nutzer-unternehmen-selection.model';

export const nutzerUnternehmenSelectionActions = createActionGroup({
    source: 'Nutzer Unternehmen Selection',
    events: {
        // change the selected company and trigger the changeUserRole action
        changeSelectedUnternehmen: props<{ newlySelectedUnternehmen: UnternehmenSelectionItem }>(),
        postChangeSelectedUnternehmen: props<{ newlySelectedUnternehmen: UnternehmenSelectionItem }>(),
        // reset selected company and clear the list of companies
        setInitialState: emptyProps(),
        // get all company assignments of the user and trigger changeNutzerUnternehmenSelectionList
        updateNutzerUnternehmenSelectionList: emptyProps(),
        // stores the company list and if the list contains only one element, trigger changeSelectedUnternehmen for this company
        changeNutzerUnternehmenSelectionList: props<{ loadedUnternehmenSelectionList: UnternehmenSelectionItem[] }>(),
    },
});
