import { Action } from "@ngrx/store";
import { NutzerAnrede } from "./user.model";

/**
 * State for the auth store, containing info about logged in user:
 * fullname, email, token(access token), role(portalRoel), loggedin
 * if no user is logged in all contains null or is empty, loggedin is then false
 */
export type AuthStore = {
    loggedIn: boolean;
    anrede: NutzerAnrede | '';
    vorname: string;
    nachname: string;
    email: string;
    token: string | null;
    role: PortalRole | null;
    actionAfterRefresh: Action | null;
};

/**
 * the user roles used in the portal
 * COMPANY_SELECT: company member, but no selected company
 */
export enum PortalRole {
    FFA_USER = 'FFA Mitarbeiter*in',
    FFA_ADMIN = 'FFA Admin',
    COMPANY_USER = 'Mitarbeiter*in',
    COMPANY_ADMIN = 'Admin',
    COMPANY_SELECT = 'Kein Unternehmen ausgewählt',
}

/**
 * the user roles that are stored in the identity provider
 */
export enum IdpUserRole {
    FFA_USER = 'FFA_USER',
    FFA_ADMIN = 'FFA_ADMIN',
    COMPANY_USER = 'COMPANY_USER',
}