import { Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { authActions } from '../../store/auth/auth.actions';

@Component({
    selector: 'app-logout',
    template: '<p>Logout in progress</p>',
})
export class LogoutComponent implements OnInit {
    private store = inject(Store);
    private router = inject(Router);

    /**
     * entering the '/logout' page will trigger the logout and redirect the user to the start page
     */
    ngOnInit(): void {
        this.store.dispatch(authActions.postChangeUserToLoggedOut({ timeout: false }));
        this.router.navigate(['/start']);
    }
}
