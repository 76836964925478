import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

/**
 * Component that creates search input.
 */
@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrl: './search.component.scss',
})
export class SearchComponent implements OnInit {
    @Output() searchEvent = new EventEmitter<string>();

    searchForm: FormGroup;

    ngOnInit(): void {
        this.searchForm = new FormGroup({
            searchControl: new FormControl(''),
        });
    }

    /**
     * emits the search value.
     */
    triggerSearch(): void {
        this.searchEvent.emit(this.searchForm.value.searchControl);
    }

    /**
     * Clears the search value and emits an empty search value if the search was not empty before
     */
    clearSearch(): void {
        if (this.searchForm.value.searchControl !== '') {
            this.searchForm.patchValue({ searchControl: '' });
            this.searchEvent.emit('');
        }
    }
}
