import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { nanoid } from 'nanoid';
import { countryDialCodes } from '@aws-amplify/ui';
import { NgxTippyProps } from 'ngx-tippy-wrapper';

@Component({
    selector: 'app-amplify-phone-number-field',
    templateUrl: './custom-amplify-phone-number-field.component.html',
})
export class CustomAmplifyPhoneNumberFieldComponent implements OnInit {
    @Input() autocomplete = 'new-password';
    @Input() disabled = false;
    @Input() defaultCountryCode: string;
    @Input() selectFieldId = `amplify-field-${nanoid(12)}`;
    @Input() textFieldId = `amplify-field-${nanoid(12)}`;
    @Input() initialValue = '';
    @Input() label = '';
    @Input() name: string;
    @Input() placeholder = '';
    @Input() required = true;
    @Input() type: string;
    @Input() labelHidden = false;
    @Input() dialCodeList: Array<string>;
    @Input() hasError: boolean;
    @Input() describedBy: string;

    @HostBinding('style.display') display = 'contents';

    public countryDialCodesValues: Array<string>;

    phoneNumberHintOptions: NgxTippyProps = { placement: 'bottom-start', theme: 'light-border' };

    phoneNumberHintText = `Bitte geben Sie Ihre Mobilnummer an. Diese wird (mittelfristig) für die 2-Faktor-Authentifizierung benötigt.`;

    ngOnInit(): void {
        this.countryDialCodesValues = this.dialCodeList ?? countryDialCodes;
    }
}
