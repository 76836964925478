import { Component, OnInit, inject } from '@angular/core';
import { DataTableConfig } from '../../../model/data-table.model';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import {
    selectFoerderantragHistoryTableContent,
    selectFoerderantragHistoryTableIsLoading,
    selectFoerderantragHistoryTablePage,
    selectFoerderantragHistoryTableSize,
    selectFoerderantragHistoryTableSortingArray,
    selectFoerderantragHistoryTableTotalElements
} from '../../../store/foerderantrag-history-table/foerderantrag-history-table.selectors';
import { foerderantragHistoryTableActions } from '../../../store/foerderantrag-history-table/foerderantrag-history-table.actions';
import { PageEvent } from '@angular/material/paginator';
import { SortParameter } from '../../../model/store.model';

@Component({
    selector: 'app-history-tab',
    templateUrl: './history-tab.component.html',
    styleUrl: './history-tab.component.scss',
})
/**
 * The tab component for the first tab of the antrags detail page.
 */
export class HistoryTabComponent implements OnInit {
    store = inject(Store);

    tableConfig: DataTableConfig;
    tableContent$: Observable<Record<string, string | number>[]>;
    tableIsLoading$: Observable<boolean>;
    tableSortingArray$: Observable<SortParameter[]>;
    tableDisplayedSize$: Observable<number>;
    tableDisplayedPage$: Observable<number>;
    tableTotalElements$: Observable<number>;
    pageElement = { page: 0, size: 20 };

    /**
     * get the observables from the store that are needed to configure the table
     */
    ngOnInit(): void {
        this.tableContent$ = this.store.select(selectFoerderantragHistoryTableContent);
        this.tableIsLoading$ = this.store.select(selectFoerderantragHistoryTableIsLoading);
        this.tableSortingArray$ = this.store.select(selectFoerderantragHistoryTableSortingArray);
        this.tableDisplayedSize$ = this.store.select(selectFoerderantragHistoryTableSize);
        this.tableDisplayedPage$ = this.store.select(selectFoerderantragHistoryTablePage);
        this.tableTotalElements$ = this.store.select(selectFoerderantragHistoryTableTotalElements);

        this.configChange();
    }


    /**
     * Configure the table with the store data
     */
    configChange(): void {
        this.tableConfig = {
            columns: [
                { columnKey: 'zeitstempel', display: 'Datum' },
                { columnKey: 'aenderungsart', display: 'Änderungsart' },
                { columnKey: 'ansprechpartner', display: 'Ansprechpartner*in' },
                { columnKey: 'kommentar', display: 'Kommentar' },
            ],
            disableTableActions: true,
            headerIsSticky: true,
            tableDetailId: '',
            tableDataObjectsName: 'Historieneinträge',
            tableLabel: 'Tabelle der Historieneinträge zum ausgewählten Förderantrag',
            tableColumnClass: 'history-tab',
            tableActions$: of([]),
            paginationNames: {
                itemsPerPageLabel: 'Historieneinträge pro Seite',
                nextPageLabel: 'Nächste Seite',
                previousPageLabel: 'Vorherige Seite',
                firstPageLabel: 'Erste Seite',
                lastPageLabel: 'Letzte Seite',
            },
            tableContent$: this.tableContent$,
            tableIsLoading$: this.tableIsLoading$,
            tableSortingArray$: this.tableSortingArray$,
            tableDisplayedSize$: this.tableDisplayedSize$,
            tableDisplayedPage$: this.tableDisplayedPage$,
            tableTotalElements$: this.tableTotalElements$,
        };
    }

    /**
     * Handle the sorting of a table column
     *
     * @param sortEvent holds the new sortParameter and if the sort parameter should be added to the end of the sorting array or if it should create a new list
     */
    sortHandler(sortEvent: { sort: SortParameter; isMultiSort: boolean }): void {
        this.store.dispatch(
            foerderantragHistoryTableActions.changeSortingArray({ newSortingParameter: sortEvent.sort, isMultiSort: sortEvent.isMultiSort }),
        );
        this.pageElement.page = 0;
    }

    /**
     *  Handle the use of the paginatior. The paginator triggers a new event for every change so only one of the two looked out values will change at a time.
     *
     * @param pageEvent holds the size and the active page of the paginator return.
     */
    pageHandler(pageEvent: PageEvent): void {
        if (pageEvent.pageSize !== this.pageElement.size) {
            this.store.dispatch(foerderantragHistoryTableActions.changeTableSize({ newSize: pageEvent.pageSize }));
            this.pageElement.size = pageEvent.pageSize;
            this.pageElement.page = 0;
        } else if (pageEvent.pageIndex !== this.pageElement.page) {
            this.store.dispatch(foerderantragHistoryTableActions.changeTablePage({ newPage: pageEvent.pageIndex }));
            this.pageElement.page = pageEvent.pageIndex;
        }
    }
}
