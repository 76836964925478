import { DokumentContent } from '../../../../model/dokumente-unternehmen.model';
import { FileContentDto } from '../../generated';

export const mapFileContentToDokumentContent = (fileContent: FileContentDto): DokumentContent => {
    const base64String = typeof fileContent.content === 'string' ? fileContent.content : '';
    const binaryString = window.atob(base64String);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    const blob = new Blob([bytes], { type: 'application/pdf' });

    return {
        documentId: 0, // Assigned as per requirement
        name: fileContent.filename,
        content: blob, // This is what we'll use for download
    };
};
