import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PageEvent } from '@angular/material/paginator';

import { DataTableConfig, TableAction } from '../../../model/data-table.model';
import { SortParameter } from '../../../model/store.model';
import {
    nutzerRightManagementNutzerViewActions,
    allUnternehmenNutzerActions
} from '../../../store/all-unternehmen-nutzer/all-unternehmen-nutzer.actions';
import {
    selectAllUnternehmenNutzerStoreFoerderantragContentRightManagement,
    selectAllUnternehmenNutzerIsLoading,
    selectAllUnternehmenNutzerPage,
    selectAllUnternehmenNutzerSize,
    selectAllUnternehmenNutzerSortingArray,
    selectAllUnternehmenNutzerTotalElements,
} from '../../../store/all-unternehmen-nutzer/all-unternehmen-nutzer.selectors';

@Component({
    selector: 'app-right-management-allantraege',
    templateUrl: './right-management-allantraege.component.html',
    styleUrl: './right-management-allantraege.component.scss',
})
export class RightManagementAllantraegeComponent implements OnInit {
    private store = inject(Store);
    private destroyRef = inject(DestroyRef);

    tableActions: TableAction[][] = [];
    tableConfig: DataTableConfig;
    tableContent$: Observable<Record<string, string | number | boolean>[]>;
    tableIsLoading$: Observable<boolean>;
    tableSortingArray$: Observable<SortParameter[]>;
    tableDisplayedSize$: Observable<number>;
    tableDisplayedPage$: Observable<number>;
    tableTotalElements$: Observable<number>;
    tableContent: { rightManagementCheckbox: boolean; id: string }[];

    searchValue = '';
    pageElement = { page: 0, size: 20 };

    ngOnInit(): void {
        this.store.dispatch(allUnternehmenNutzerActions.changeAllUnternehmenNutzer());

        this.tableContent$ = this.store.select(selectAllUnternehmenNutzerStoreFoerderantragContentRightManagement);
        this.tableIsLoading$ = this.store.select(selectAllUnternehmenNutzerIsLoading);
        this.tableSortingArray$ = this.store.select(selectAllUnternehmenNutzerSortingArray);
        this.tableDisplayedSize$ = this.store.select(selectAllUnternehmenNutzerSize);
        this.tableDisplayedPage$ = this.store.select(selectAllUnternehmenNutzerPage);
        this.tableTotalElements$ = this.store.select(selectAllUnternehmenNutzerTotalElements);

        this.tableContent$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((content) => {
            this.tableContent = content as { rightManagementCheckbox: boolean; id: string }[]; // check if string
        });

        this.configChange();
    }

    configChange(): void {
        this.tableConfig = {
            columns: [
                { columnKey: 'rightManagementCheckbox', display: 'Aktiv' },
                { columnKey: 'name', display: 'Name' },
                { columnKey: 'email', display: 'E-Mail-Adresse' },
            ],
            tableLabel: 'Tabelle aller Benutzer*innen des Unternehmens.',
            tableColumnClass: 'right-management-allantrag',
            tableActions$: of(this.tableActions),
            tableDataObjectsName: 'Benutzer*innen',
            tableDetailId: 'id',
            disableTableActions: true,
            paginationNames: {
                itemsPerPageLabel: 'Benutzer*innen pro Seite',
                nextPageLabel: 'Nächste Seite',
                previousPageLabel: 'Vorherige Seite',
                firstPageLabel: 'Erste Seite',
                lastPageLabel: 'Letzte Seite',
            },
            tableRowClassFunction: this.rowClassHandler,
            tableContent$: this.tableContent$,
            tableIsLoading$: this.tableIsLoading$,
            tableSortingArray$: this.tableSortingArray$,
            tableDisplayedSize$: this.tableDisplayedSize$,
            tableDisplayedPage$: this.tableDisplayedPage$,
            tableTotalElements$: this.tableTotalElements$,
            headerIsSticky: true,
        };
    }

    /**
     * Handle the sorting of a table column
     *
     * @param sortEvent holds the new sortParameter and if the sort parameter should be added to the end of the sorting array or if it should create a new list
     */
    sortHandler(sortEvent: { sort: SortParameter; isMultiSort: boolean }): void {
        this.store.dispatch(
            allUnternehmenNutzerActions.changeSorting({
                newSort: sortEvent.sort,
                isMultiSort: sortEvent.isMultiSort,
            }),
        );
        this.pageElement.page = 0;
    }

    /**
     *  Handle the use of the paginatior. The paginator triggers a new event for every change so only one of the two looked out values will change at a time.
     *
     * @param pageEvent holds the size and the active page of the paginator return.
     */
    pageHandler(pageEvent: PageEvent): void {
        if (pageEvent.pageSize !== this.pageElement.size) {
            this.store.dispatch(allUnternehmenNutzerActions.changeSize({ newSize: pageEvent.pageSize }));
            this.pageElement.size = pageEvent.pageSize;
            this.pageElement.page = 0;
        } else if (pageEvent.pageIndex !== this.pageElement.page) {
            this.store.dispatch(allUnternehmenNutzerActions.changePage({ newPage: pageEvent.pageIndex }));
            this.pageElement.page = pageEvent.pageIndex;
        }
    }

    rowClickHandler(index: number): void {
        if (this.tableContent.length > 0) {
            this.store.dispatch(
                nutzerRightManagementNutzerViewActions.changeNutzerId({
                    nutzerId: this.tableContent[index].id,
                    isSelected: !this.tableContent[index].rightManagementCheckbox,
                }),
            );
        }
    }

    /**
     * Handle the search input.
     *
     * @param searchEvent holds the search value
     */
    searchHandler(searchEvent: string): void {
        if (this.searchValue !== searchEvent) {
            this.searchValue = searchEvent;
            this.store.dispatch(allUnternehmenNutzerActions.changeSearchParameter({ newSearchParameter: searchEvent }));
            this.pageElement.page = 0;
        }
    }

    rowClassHandler = (index: number): string => {
        if (this.tableContent.length > 0 && this.tableContent[index].rightManagementCheckbox) {
            return 'data-table__right-management-allantrag--selected';
        }
        return '';
    };
}
