import { inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, Subject, switchMap, takeUntil } from "rxjs";

import { DokumenteUnternehmenApiService } from "../../service/api/service/dokumente-unternehmen.service";
import { dokumentContentActions, dokumenteUnternehmenActions } from "./dokumente-unternehmen.actions";
import { selectActiveUnternehmenId } from "../nutzer-unternehmen-selection/nutzer-unternehmen-selection.selectors";
import { selectDisplayedDetailsFoerderantragFoerderantragsId } from "../foerderantraege/foerderantraege.selectors";
import { selectDokumenteUnternehmenTableQuerryParams } from "./dokumente-unternehmen.selectors";
import { GlobalExceptionHandlerService } from "../../service/api/service/global-exception-handler.service";
import { TableAction } from "../../model/data-table.model";
import { DokumenteHelperService } from "../../service/helper/dokument.helper.service";
import { DialogHelperService } from "../../service/helper/dialog.helper.service";

/**
 * Effects class for the dokumente store
 */
@Injectable({
    providedIn: 'root'
})
export class DokumenteUnternehmenEffects {
    private store = inject(Store);
    private actions$ = inject(Actions);
    private dokumenteUnternehmenApiService = inject(DokumenteUnternehmenApiService);
    private globalExceptionHandlerService = inject(GlobalExceptionHandlerService);
    private dokumentHelperService = inject(DokumenteHelperService);
    private dialogHelperService = inject(DialogHelperService);

    private retryCount = 0;
    private cancel$ = new Subject<void>();

    /**
     * The effect to handle the loading of the list of dokumente and creating its table actions
     */
    changeDokumenteUnternehmenPage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dokumenteUnternehmenActions.changeDokumenteUnternehmenPage),
            concatLatestFrom(() => [
                this.store.select(selectActiveUnternehmenId),
                this.store.select(selectDisplayedDetailsFoerderantragFoerderantragsId),
                this.store.select(selectDokumenteUnternehmenTableQuerryParams),
            ]),
            switchMap(([, unternehmenId, foerderantragId, querryParams]) => {
                return this.dokumenteUnternehmenApiService.getFfaDokumenteForFoerderantrag(unternehmenId, foerderantragId, querryParams).pipe(
                    map((dokumenteUnternehmenTablePage) => {
                        const newTableActions: TableAction[][] = [];
                        for (const dokument of dokumenteUnternehmenTablePage.content) {
                            const newTableActionsNutzer: TableAction[] = [
                                {
                                    name: 'Download ',
                                    isLink: false,
                                    isButton: true,
                                    clickHandler: (): void => { this.dokumentHelperService.getDokumentActionHandler(dokument["dokument.id"], true) }
                                }
                            ]
                            if (dokument.type === 'FFA_BESCHEID' && !dokument.gelesen) {
                                newTableActionsNutzer.push({
                                    name: 'Lesebestätigung setzen',
                                    isLink: false,
                                    isDialog: true,
                                    dialogData: this.dialogHelperService.createLesebestaetigungSetzenDialogData(dokument["dokument.id"]),
                                })
                            }
                            newTableActions.push(newTableActionsNutzer);
                        }
                        return dokumenteUnternehmenActions.setDokumenteUnternehmenPage({ newDokumenteUnternehmenPage: dokumenteUnternehmenTablePage, newTableActions })
                    }),
                    catchError((error) => {
                        this.globalExceptionHandlerService.handleGeneratedApiError(error);
                        return of(dokumenteUnternehmenActions.setDokumenteUnternehmenPage({ newDokumenteUnternehmenPage: { content: [], totalElements: 0 }, newTableActions: [] }));
                    }),
                );
            })
        );
    });

    /**
     * The effect to handle the triggering of the new loading if the table parameters are changed
     */
    changeDokumenteUnternehmenTableParameter$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                dokumenteUnternehmenActions.changeTableDokumenteFilter,
                dokumenteUnternehmenActions.changeTablePage,
                dokumenteUnternehmenActions.changeTableSearchParameter,
                dokumenteUnternehmenActions.changeTableSize,
                dokumenteUnternehmenActions.changeTableSort,
            ),
            mergeMap(() => of(dokumenteUnternehmenActions.changeDokumenteUnternehmenPage()))
        );
    });

    /**
     * The effect to handle the loading of the dokument content
     */
    changeDokumentenContent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dokumentContentActions.changeDokumentContent),
            concatLatestFrom(() => [
                this.store.select(selectActiveUnternehmenId),
                this.store.select(selectDisplayedDetailsFoerderantragFoerderantragsId)
            ]),
            switchMap(([action, unternehmenId, foerderantragId]) => {
                return this.dokumenteUnternehmenApiService.getDokumentFile(unternehmenId, foerderantragId, action.dokumentId).pipe(
                    // takeUntil is used to cancles the ongoing request if the dialog is closed before it finished loading
                    takeUntil(this.cancel$),
                    map((dokumentContent) => {
                        let returnURL = '';
                        if (action.directDownload) {
                            this.dokumentHelperService.downloadDokument(dokumentContent.content, dokumentContent.name);
                        } else {
                            returnURL = this.dokumentHelperService.createDokumentObjectURL(dokumentContent.content);
                        }
                        this.retryCount = 0;
                        return dokumentContentActions.setDokumentContent({ dokumentURL: returnURL })
                    }),
                    catchError((error) => {
                        this.globalExceptionHandlerService.handleGeneratedApiError(error);
                        if (this.retryCount < 5 && error.status === 500) {
                            this.retryCount++;
                            return of(dokumentContentActions.changeDokumentContent({ directDownload: action.directDownload, dokumentId: action.dokumentId }));
                        }
                        this.retryCount = 0;
                        return of(dokumentContentActions.setDokumentContent({ dokumentURL: 'failed' }));
                    }),
                );

            })
        );
    });

    updateDownloadAntragDokument$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dokumentContentActions.updateDownloadAntragDokument),
            concatLatestFrom(() => [
                this.store.select(selectActiveUnternehmenId)
            ]),
            switchMap(([action, unternehmenId]) => {
                this.dokumentHelperService.openDownloadSpinner();
                return this.dokumenteUnternehmenApiService.getAntragsPDF(unternehmenId, action.antragId).pipe(
                    map((dokumentContent) => {
                        this.dokumentHelperService.downloadDokument(dokumentContent.content, dokumentContent.name);
                        return dokumentContentActions.resetDokumentContent();
                    }),
                    catchError((error) => {
                        this.globalExceptionHandlerService.handleGeneratedApiError(error);
                        return of(dokumentContentActions.resetDokumentContent());
                    }),
                )

            })
        )
    });

    updateLesebestaetigung$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dokumentContentActions.changeBescheidLesebestaetigung),
            concatLatestFrom(() => [
                this.store.select(selectActiveUnternehmenId),
            ]),
            switchMap(([action, unternehmenId]) => {
                this.cancel$.next()
                return this.dokumenteUnternehmenApiService.updateDokumentLesebestaetigung(unternehmenId, action.dokumentId).pipe(
                    map(() => {
                        return dokumenteUnternehmenActions.changeDokumenteUnternehmenPage();
                    }),
                    catchError((error) => {
                        this.globalExceptionHandlerService.handleGeneratedApiError(error);
                        return of(dokumentContentActions.resetDokumentContent());
                    }),
                );
            })
        );
    });

    changeHraOrGewerbescheinDownload$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dokumentContentActions.changeHraOrGewerbescheinDownload),
            concatLatestFrom(() => [
                this.store.select(selectActiveUnternehmenId),
            ]),
            switchMap(([action, unternehmenId]) => {
                this.cancel$.next();
                this.dokumentHelperService.openDownloadSpinner();
                return this.dokumenteUnternehmenApiService.getDokumentFile(unternehmenId, action.antragsId, action.dokumentId).pipe(
                    map((dokumentContent) => {
                        this.dokumentHelperService.downloadDokument(dokumentContent.content, dokumentContent.name);
                        return dokumentContentActions.resetDokumentContent();
                    }),
                    catchError((error) => {
                        this.globalExceptionHandlerService.handleGeneratedApiError(error);
                        return of(dokumentContentActions.resetDokumentContent());
                    }),
                );
            })
        );
    });
}