import { Nebenantrag } from "../../../../model/nebenantraege.model";
import { Status } from "../../../../model/foerderantraege.model";
import { NebenantragDto } from "../../generated";

/**
 * Maps the generated Required<NebenantragDto> (required because no fields are optional in the return) to the store used NebenantragDetail
 * @param nebenantragDto the API returned nebenantrags details
 * @returns returns the store friendly NebenantragDetail
 */
export const mapNebenantraegeDetail = (nebenantragDto: Required<NebenantragDto>): Nebenantrag => {
    return {
        id: nebenantragDto.id,
        antragsnummer: nebenantragDto.antragsnummer,
        status: nebenantragDto.status as Status,
        aktualisiertAm: nebenantragDto.aktualisiertAm,
        aktualisiertVon: nebenantragDto.aktualisiertVon,
        gatewayId: nebenantragDto.gatewayId,
        fmsUuid: nebenantragDto.fmsUuid,
        formularShortname: nebenantragDto.formularShortname,
    };
}