import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AsyncPipe } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';

import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { UiModule } from './ui/ui.module';
import { AppRoutingModule } from './app-routing.module';
import { nutzerUnternehmenSelectionReducer } from './store/nutzer-unternehmen-selection/nutzer-unternehmen-selection.reducer';
import { NutzerUnternehmenSelectionEffects } from './store/nutzer-unternehmen-selection/nutzer-unternehmen-selection.effects';
import { foerderantraegeReducer } from './store/foerderantraege/foerderantraege.reducer';
import { FoerderantraegeEffects } from './store/foerderantraege/foerderantraege.effects';
import { AuthInterceptor } from './service/auth-http-interceptor';
import { authReducer } from './store/auth/auth.reducer';
import { AuthEffects } from './store/auth/auth.effects';
import { foerderbereichReducer } from './store/foerderbereich/foerderbereich.reducer';
import { FoerderbereichEffects } from './store/foerderbereich/foerderbereich.effects';
import { foerderantragHistoryTableReducer } from './store/foerderantrag-history-table/foerderantrag-history-table.reducer';
import { FoerderantragHistoryTableEffects } from './store/foerderantrag-history-table/foerderantrag-history-table.effects';
import { nebenantraegeReducer } from './store/nebenantraege/nebenantraege.reducer';
import { NebenantraegeEffects } from './store/nebenantraege/nebenantraege.effects';
import { allUnternehmenNutzerReducer } from './store/all-unternehmen-nutzer/all-unternehmen-nutzer.reducer';
import { AllUnternehmenNutzerEffects } from './store/all-unternehmen-nutzer/all-unternehmen-nutzer.effects';
import { formularReducer } from './store/formular/formular.reducer';
import { FormularEffects } from './store/formular/formular.effects';
import { postkorbReducer } from './store/postkorb/postkorb.reducer';
import { PostkorbEffect } from './store/postkorb/postkorb.effects';
import { errorReducer } from './store/error-store/error.reducer';
import { ErrorEffects } from './store/error-store/error.effects';
import { dokumenteUnternehmenReducer } from './store/dokumente-unternehmen/dokumente-unternehmen.reducer';
import { DokumenteUnternehmenEffects } from './store/dokumente-unternehmen/dokumente-unternehmen.effects';
import { rechtlicheInformationenReducer } from './store/rechtliche-informationen/rechtliche-informationen.reducer';
import { RechtlicheInformationenEffects } from './store/rechtliche-informationen/rechtliche-informationen.effects';
import { GlobalExceptionHandlerService } from './service/api/service/global-exception-handler.service';
import { environment } from '../environments/environment';
import { nutzerProfileReducer } from './store/nutzer-profile/nutzer-profile.reducer';
import { NutzerProfileEffects } from './store/nutzer-profile/nutzer-profile.effects';
import { stammdatenReducer } from './store/stammdaten/stammdaten.reducer';
import { StammdatenEffects } from './store/stammdaten/stammdaten.effects';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        LayoutModule,
        UiModule,
        StoreModule.forRoot(
            {
                nutzerUnternehmenSelection: nutzerUnternehmenSelectionReducer,
                authStore: authReducer,
                allUnternehmenNutzerStore: allUnternehmenNutzerReducer,
                foerderantraegeStore: foerderantraegeReducer,
                router: routerReducer,
                foerderbereichStore: foerderbereichReducer,
                foerderantragHistoryTableStore: foerderantragHistoryTableReducer,
                nebenantraegeStore: nebenantraegeReducer,
                formularStore: formularReducer,
                postkorbStore: postkorbReducer,
                errorStore: errorReducer,
                dokumenteUnternehmenStore: dokumenteUnternehmenReducer,
                rechtlicheInformationen: rechtlicheInformationenReducer,
                nutzerProfileStore: nutzerProfileReducer,
                stammdatenStore: stammdatenReducer,
            },
            {},
        ),
        StoreRouterConnectingModule.forRoot(),
        EffectsModule.forRoot([
            NutzerUnternehmenSelectionEffects,
            AuthEffects,
            AllUnternehmenNutzerEffects,
            FoerderantraegeEffects,
            FoerderbereichEffects,
            NebenantraegeEffects,
            FoerderantragHistoryTableEffects,
            FormularEffects,
            PostkorbEffect,
            ErrorEffects,
            DokumenteUnternehmenEffects,
            RechtlicheInformationenEffects,
            NutzerProfileEffects,
            StammdatenEffects,
        ]),
        environment.imports,
        AsyncPipe,
        HttpClientModule,
        MatSnackBarModule,
    ],
    providers: [
        GlobalExceptionHandlerService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                panelClass: ['snack-bar-panel-default'],
            },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
