<header>
    <nav class="header">
        <a [routerLink]="['']">
            <img
                src="/assets/images/svgs/ffa-logo-weiss.svg"
                class="header__logo"
                alt="Logo der Filmförderungsanstalt" />
        </a>
        <div
            *ngIf="(loggedInUser$ | async)?.loggedIn"
            class="header__user-info-area"
            data-testid="userInfoArea">
            <div *ngIf="userHasUnternehmenRole$ | async">
                <app-unternehmen-selection></app-unternehmen-selection>
            </div>
            <a [routerLink]="['/postkorb']"
                ><img
                    src="/assets/images/svgs/mail-alt-3-svgrepo-com.svg"
                    class="header__svg"
                    alt="Menüpunkt Postkorb"
            /></a>
            <app-user-menu [loggedInUser]="loggedInUser$ | async"></app-user-menu>
        </div>
    </nav>
</header>
