import { Component, HostListener, inject } from "@angular/core";
import { MatSnackBarRef } from "@angular/material/snack-bar";
import { Router } from '@angular/router';

@Component({
    selector: 'app-logout-snack-bar',
    templateUrl: 'logout-snack-bar.component.html',
    styleUrl: 'logout-snack-bar.component.scss'
})
export class LogoutSnackBarComponent {
    private snackBarRef = inject(MatSnackBarRef);
    private router = inject(Router);

    @HostListener('document:keydown.esc')
    logout(): void {
        this.snackBarRef.dismiss();
        this.router.navigate(['/logout']);
    }
}