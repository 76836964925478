<section class="antrag-details-tab--top-section">
    <div class="antrag-details-tab--top-section__line">
        <p class="antrag-details-tab--top-section__line--title">Antragsnummer:</p>
        <p class="antrag-details-tab--top-section__line--value">{{ displayedFoerderantrag.antragsnummer }}</p>
    </div>
    <div class="antrag-details-tab--top-section__line">
        <p class="antrag-details-tab--top-section__line--title">Förderart:</p>
        <p class="antrag-details-tab--top-section__line--value">{{ displayedFoerderantrag['foerderart.bezeichnung'] }}</p>
    </div>
    <div class="antrag-details-tab--top-section__line">
        <p class="antrag-details-tab--top-section__line--title">Status:</p>
        <p class="antrag-details-tab--top-section__line--value">{{ displayStatus[displayedFoerderantrag.status] }}</p>
    </div>
    <div class="antrag-details-tab--top-section__line">
        <p class="antrag-details-tab--top-section__line--title">Zuletzt aktualisiert:</p>
        <p class="antrag-details-tab--top-section__line--value">
            {{ displayDate(displayedFoerderantrag.aktualisiertAm) }} von {{ displayedFoerderantrag.aktualisiertVon }}
        </p>
    </div>
    <div class="antrag-details-tab--top-section__line">
        <p class="antrag-details-tab--top-section__line--title">Eingereicht am:</p>
        <p class="antrag-details-tab--top-section__line--value">
            {{ displayDate(displayedFoerderantrag.eingereichtAm, true) }}
        </p>
    </div>
</section>

<section class="antrag-details-tab--bottom-section">
    <ng-container *ngFor="let actionButton of displayedButtonActions; let i = index">
        <div
            *ngIf="!actionButton?.hidden"
            [class]="'antrag-details-tab--bottom-section--button-spacer antrag-details-tab--bottom-section--button-spacer--' + getPosition(i)"
            [attr.data-testid]="'action-button-' + actionButton.name">
            <app-button
                buttonClass="antrag-details-tab--bottom-section--button-width"
                [isButton]="true"
                [buttonLabel]="actionButton.name"
                [disabled]="actionButton.disabled"
                (clickEvent)="actionButton.clickHandler()"></app-button>
        </div>
        <div
            *ngIf="actionButton.hidden"
            class="antrag-details-tab--bottom-section--button-spacer antrag-details-tab--bottom-section--button-spacer--hidden"
            hidden
            data-testid="action-button-hidden"></div>
    </ng-container>
</section>
