<section class="unternehmen-administration--top-section">
    <h1>{{ userGreeting$ | async }}</h1>
    <p>
        Sie sind als Admin für Ihr Unternehmen im FFA-Serviceportal registriert.<br />
        Ihnen stehen folgende Funktionen zur Verfügung:
    </p>
</section>
<section class="unternehmen-administration--bottom-section mt-5">
    <a routerLink="/alle-antraege">
        <app-card> <p>Anträge stellen und verwalten</p> </app-card>
    </a>
    <a routerLink="/unternehmensverwaltung/alle-benutzer">
        <app-card> <p>Alle Benutzer*innen</p> </app-card>
    </a>
    <a routerLink="/unternehmensverwaltung/unternehmensstammdaten">
        <app-card> <p>Stammdaten</p> </app-card>
    </a>
</section>
