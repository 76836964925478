import { Component, Input, OnChanges } from '@angular/core';
import { Link } from '../../../model/app-routing.model';
import { AuthStore, PortalRole } from '../../../model/auth.model';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrl: './user-menu.component.scss',
})
export class UserMenuComponent implements OnChanges {
    @Input()
    loggedInUser: AuthStore | null = null;
    userMenu: Link[];

    ngOnChanges(): void {
        if (this.loggedInUser?.role !== null) {
            this.userMenu = [
                { label: 'Nutzerprofil', link: 'nutzerprofil' },
                { label: 'Abmelden', link: 'logout' },
            ];
            if (this.loggedInUser?.role === PortalRole.COMPANY_ADMIN) {
                this.userMenu = [
                    { label: 'Unternehmensverwaltung', link: 'unternehmensverwaltung' },
                    { label: 'Alle Benutzer*innen', link: 'unternehmensverwaltung/alle-benutzer' },
                    ...this.userMenu,
                ];
            }
            if (this.loggedInUser?.role === PortalRole.COMPANY_USER || this.loggedInUser?.role === PortalRole.COMPANY_ADMIN) {
                this.userMenu = [
                    { label: 'Anträge stellen und verwalten', link: 'alle-antraege' },
                    { label: 'Stammdaten', link: 'unternehmensverwaltung/unternehmensstammdaten' },
                    ...this.userMenu,
                ];
            }
        }
    }
}
