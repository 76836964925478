import { NutzerAnrede } from "./user.model";

export type NutzerProfile = {
    idpId: string;
    anrede: NutzerAnrede | '';
    titel: string;
    vorname: string;
    nachname: string;
    email: string;
    telefon: string;
}

export type PasswordChange = {
    passwordChanged: boolean;
    error: string;
}

export type NutzerProfileStore = {
    profile: NutzerProfile;
    isLoading: boolean;
    changes: NutzerProfile;
    passwordChange: PasswordChange;
}

export const EMPTYNUTZERPROFILE: NutzerProfile = {
    idpId: '',
    anrede: '',
    titel: '',
    vorname: '',
    nachname: '',
    email: '',
    telefon: ''
}
