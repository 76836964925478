import { Component } from '@angular/core';


@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
})
export class PageNotFoundComponent {
}
//TODO: FFA-180 Design and fill with the correct contend
