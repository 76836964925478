import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Foerderart, Foerderbereich } from '../../model/foerderbereich.model';

/**
 * contains actions to load and set the foerderbereiche and foerderarten that a user has rights to create
 */
export const foerderbereichActions = createActionGroup({
    source: 'Foerderbereich Store',
    events: {
        changeFoerderbereicheUndFoerderarten: emptyProps(),
        setNutzerFoerderbereich: props<{ newNutzerFoerderbereiche: Foerderbereich[] }>(),
        setAllFoerderbereiche: props<{ newAllFoerderbereiche: Foerderbereich[] }>(),
        setFoerderbereicheUndFoerderarten: props<{ newNutzerFoerderbereiche: Foerderbereich[], newNutzerFoerderarten: Foerderart[] }>(),
        resetStore: emptyProps(),
    },
});
