import { Component, OnInit, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UnternehmenSelectionItem, } from '../../../../model/nutzer-unternehmen-selection.model';
import { selectUnternehmenSelectionList } from '../../../../store/nutzer-unternehmen-selection/nutzer-unternehmen-selection.selectors';

@Component({
    selector: 'app-login-unternehmen-selection',
    templateUrl: './login-unternehmen-selection.component.html',
})
export class LoginUnternehmenSelectionComponent implements OnInit {
    private store = inject(Store);
    unternehmenSelectionList$: Observable<UnternehmenSelectionItem[]>;

    /**
     * get the Unternehmen selection list observable
     */
    ngOnInit(): void {
        this.unternehmenSelectionList$ = this.store.select(selectUnternehmenSelectionList);
    }
}
