<section class="dokumente-view-tab--top-section">
    <p>Von der FFA zum Download freigegebene Dokumente:</p>
</section>
<section class="dokumente-view-tab--bottom-section">
    <app-card>
        <div class="dokumente-view-tab--bottom-section__card-content">
            <div class="dokumente-view-tab--bottom-section__card-content--top">
                <app-quick-filter
                    class="dokumente-view-tab--bottom-section__card-content--top__filter"
                    [quickFilterMenuItems]="quickFilterMenuItems"></app-quick-filter>
                <app-search
                    class="dokumente-view-tab--bottom-section__card-content--top__search"
                    (searchEvent)="searchHandler($event)"></app-search>
            </div>
            <app-data-table
                class="dokumente-view-tab--bottom-section__card-content--bottom__table"
                [tableConfig]="tableConfig"
                (sortEvent)="sortHandler($event)"
                (pageEvent)="pageHandler($event)"></app-data-table>
        </div>
    </app-card>
</section>
