<div class="impressum__container" *ngIf="impressumHtml$ | async as impressumHtml; else loading">
  <div [innerHTML]="impressumHtml.htmlContent"></div>
</div>

<ng-template #loading>
  <p>Loading Impressum...</p>
</ng-template>

<div *ngIf="error$ | async as error">
  <p class="error">{{ error }}</p>
</div>
