import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ErrorStore } from "../../model/error-store.model";

export const selectErrorStore = createFeatureSelector<ErrorStore>('errorStore');

export const selectErrorStoreShownError = createSelector(selectErrorStore, (errorStore) => errorStore.shownError);

export const selectErrorStoreHasError = createSelector(selectErrorStore, (errorStore) => errorStore.hasError);

export const selectErrorStoreLogTimer = createSelector(selectErrorStore, (errorStore) => errorStore.logTimer);

export const selectErrorStoreLogList = createSelector(selectErrorStore, (errorStore) => errorStore.logList);