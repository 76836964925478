import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Action, Store } from '@ngrx/store';
import {
    allUnternehmenNutzerActions,
    nutzerHinzufuegenActions,
    nutzerRightManagementFoerderantragViewActions,
    nutzerRightManagementNutzerViewActions,
    nutzerRolleManagement,
} from '../../store/all-unternehmen-nutzer/all-unternehmen-nutzer.actions';
import { inject, Injectable } from '@angular/core';
import { AllUnternehmenNutzer } from '../../model/all-unternehmen-nutzer.model';
import { OpenDialogComponent } from '../../ui/open-dialog/open-dialog.component';
import { DialogActionClickFunction, DialogActions, DialogData, DialogTitles } from '../../model/dialog.model';
import { TableAction } from '../../model/data-table.model';
import { Foerderantrag } from '../../model/foerderantraege.model';
import { dokumentContentActions } from '../../store/dokumente-unternehmen/dokumente-unternehmen.actions';
import { nutzerProfileActions } from '../../store/nutzer-profile/nutzer-profile.actions';

@Injectable({ providedIn: 'root' })
export class DialogHelperService {
    private store = inject(Store);
    private dialog = inject(MatDialog);
    private hasChanges = false;

    closeDialog(dialogRef?: MatDialogRef<unknown>): void {
        if (dialogRef) {
            dialogRef.close();
        }
    }

    removeUser(dialogRef: MatDialogRef<unknown>, userId: string): void {
        this.store.dispatch(allUnternehmenNutzerActions.updateRemoveUser({ userId }));
        this.closeDialog(dialogRef);
    }

    changeUserStatus(dialogRef: MatDialogRef<unknown>, nutzer: AllUnternehmenNutzer, newStatus: boolean): void {
        this.store.dispatch(allUnternehmenNutzerActions.changeUserStatus({ nutzer, status: newStatus }));
        this.closeDialog(dialogRef);
    }

    dispatchAction(action: Action): void {
        this.store.dispatch(action);
    }

    openNewDialog(data: DialogData, originalDialogRef?: MatDialogRef<unknown>): void {
        const dialogRef = this.dialog.open(OpenDialogComponent, {
            data: data,
            disableClose: true,
        });

        dialogRef.backdropClick().subscribe(() => {
            if (data.disableBackdropClick) {
                return;
            } else if (this.hasChanges) {
                const confirmDialogRef = this.dialog.open(OpenDialogComponent, {
                    data: {
                        title: 'Sind Sie sicher, dass Sie die Bearbeitung abbrechen möchten?',
                        actions: [
                            {
                                title: 'Weiter bearbeiten',
                                clickFunction: (ref?: MatDialogRef<unknown>): void => {
                                    this.closeDialog(ref);
                                },
                            },
                            {
                                title: 'Bearbeiten beenden',
                                clickFunction: ((ref?: MatDialogRef<unknown>): void => {
                                    this.setChangesMade(false);
                                    this.store.dispatch(nutzerRightManagementFoerderantragViewActions.resetRightLists());
                                    this.closeDialog(ref);
                                    this.closeDialog(dialogRef);
                                    if (originalDialogRef) {
                                        this.closeDialog(originalDialogRef);
                                    }
                                }) as DialogActionClickFunction,
                            },
                        ],
                    },
                    disableClose: true,
                });

                confirmDialogRef.backdropClick().subscribe(() => {
                    this.closeDialog(confirmDialogRef);
                });
            } else {
                this.closeDialog(dialogRef);
                if (originalDialogRef) {
                    this.closeDialog(originalDialogRef);
                }
            }
        });
    }

    setChangesMade = (hasChanges: boolean): void => {
        this.hasChanges = hasChanges;
    };

    getChangesMade = (): boolean => {
        return this.hasChanges;
    };

    /**
     * Foerderbereiche und Foerderantraege view dailog actions for a specific Nutzer
     * @param unternehmenNutzer
     * @returns
     */
    createRechteVerwaltenAction(unternehmenNutzer: AllUnternehmenNutzer): TableAction {
        return {
            name: 'Rechte verwalten',
            isLink: false,
            isDialog: true,
            dialogData: this.createFoerderbereichDialogData(unternehmenNutzer),
        };
    }

    createRolleVerwaltenAction(unternehmenNutzer: AllUnternehmenNutzer): TableAction {
        return {
            name: 'Rolle verwalten',
            isLink: false,
            isDialog: true,
            dialogData: {
                title: 'Rolle verwalten',
                contentTemplateName: 'rolleManagementTemplate',
                onLoad: [
                    {
                        type: 'setNutzer',
                        onLoad: (): void => {
                            this.dispatchAction(nutzerRolleManagement.setNutzerData({ newNutzer: unternehmenNutzer }));
                        },
                    },
                ],
                actions: [
                    this.createAbbrechenAction(nutzerRightManagementFoerderantragViewActions.resetRightLists()),
                    this.createSpeichernAction(nutzerRolleManagement.changeRolle({ nutzer: unternehmenNutzer })),
                ],
            },
        };
    }

    createFoerderbereichDialogData = (unternehmenNutzer: AllUnternehmenNutzer): DialogData => {
        return {
            onLoad: [
                {
                    type: 'loadFoerderbereich',
                    onLoad: (): void => {
                        this.dispatchAction(
                            nutzerRightManagementFoerderantragViewActions.changeNutzerFoerderbereichList({ nutzerId: unternehmenNutzer.id }),
                        );
                    },
                },
            ],
            title: 'Auswahl der Antragserstellungsrechte für Förderbereiche',
            contentTemplateName: 'rightManagementTemplate',
            actions: [
                this.createAbbrechenAction(nutzerRightManagementFoerderantragViewActions.resetRightLists()),
                this.createListeDerEinzelnenAntraegeAction(unternehmenNutzer),
                this.createSpeichernAction(nutzerRightManagementFoerderantragViewActions.updateNutzerRights({ nutzerId: unternehmenNutzer.id })),
            ],
        };
    };

    /**
     * Create abbrechen confirmation Dialog
     * @returns
     */
    createAbbrechenAction = (action: Action): DialogActions => {
        return this.createConfirmationDialog(
            'Abbrechen',
            {
                title: 'Sind Sie sich sicher, dass Sie die Bearbeitung abbrechen möchten?',
                cancleActionTitle: 'Weiter bearbeiten',
                proceedActionTitle: 'Bearbeiten abbrechen',
            },
            action,
            this.getChangesMade,
            this.setChangesMade,
        );
    };

    /**
     * Speichern confirmation dialog
     * @param unternehmenNutzer
     * @returns
     */
    createSpeichernAction = (action: Action): DialogActions => {
        return this.createConfirmationDialog(
            'Speichern',
            {
                title: 'Sind Sie sich sicher, dass Sie die Änderungen speichern möchten?',
                cancleActionTitle: 'Weiter bearbeiten',
                proceedActionTitle: 'Speichern',
            },
            action,
            this.getChangesMade,
            this.setChangesMade,
        );
    };

    private createListeDerEinzelnenAntraegeAction(unternehmenNutzer: AllUnternehmenNutzer): DialogActions {
        return {
            title: 'Liste der Förderanträge',
            clickFunction: (dialogRef?: MatDialogRef<unknown>): void => {
                if (this.hasChanges) {
                    this.openNewDialog({
                        title: 'Sind Sie sicher, dass Sie die Bearbeitung abbrechen möchten?',
                        actions: [
                            {
                                title: 'Weiter ohne speichern',
                                clickFunction: ((ref?: MatDialogRef<unknown>): void => {
                                    this.setChangesMade(false);
                                    this.store.dispatch(nutzerRightManagementFoerderantragViewActions.resetRightLists());
                                    this.closeDialog(ref);
                                    this.closeDialog(dialogRef);
                                    this.openNewDialog({
                                        title: 'Auswahl des Bearbeitungsrechts einzelner Anträge',
                                        contentTemplateName: 'foerderantragTemplate',
                                        data: {},
                                        actions: [
                                            this.createAbbrechenAction(nutzerRightManagementFoerderantragViewActions.resetRightLists()),
                                            this.createListeDerFoerderbereiche(unternehmenNutzer),
                                            this.createSpeichernAction(
                                                nutzerRightManagementFoerderantragViewActions.updateNutzerRights({ nutzerId: unternehmenNutzer.id }),
                                            ),
                                        ],
                                        onLoad: [
                                            {
                                                type: 'loadFoerderantraege',
                                                onLoad: (): void => {
                                                    this.store.dispatch(
                                                        nutzerRightManagementFoerderantragViewActions.updateNutzerFoerderantraegeList(),
                                                    );
                                                },
                                            },
                                        ],
                                    });
                                }) as DialogActionClickFunction,
                            },
                            {
                                title: 'Weiter bearbeiten',
                                clickFunction: (ref?: MatDialogRef<unknown>) => this.closeDialog(ref),
                            },
                            {
                                title: 'Speichern',
                                clickFunction: ((ref?: MatDialogRef<unknown>): void => {
                                    this.dispatchAction(
                                        nutzerRightManagementFoerderantragViewActions.updateNutzerRights({ nutzerId: unternehmenNutzer.id }),
                                    );
                                    this.setChangesMade(false);
                                    this.store.dispatch(nutzerRightManagementFoerderantragViewActions.resetRightLists());
                                    this.closeDialog(ref);
                                    this.closeDialog(dialogRef);
                                    this.openNewDialog({
                                        title: 'Auswahl des Bearbeitungsrechts einzelner Anträge',
                                        contentTemplateName: 'foerderantragTemplate',
                                        data: {},
                                        actions: [
                                            this.createAbbrechenAction(nutzerRightManagementFoerderantragViewActions.resetRightLists()),
                                            this.createListeDerFoerderbereiche(unternehmenNutzer),
                                            this.createSpeichernAction(
                                                nutzerRightManagementFoerderantragViewActions.updateNutzerRights({ nutzerId: unternehmenNutzer.id }),
                                            ),
                                        ],
                                        onLoad: [
                                            {
                                                type: 'loadFoerderantraege',
                                                onLoad: (): void => {
                                                    this.store.dispatch(
                                                        nutzerRightManagementFoerderantragViewActions.updateNutzerFoerderantraegeList(),
                                                    );
                                                },
                                            },
                                        ],
                                    });
                                }) as DialogActionClickFunction,
                            },
                        ],
                    });
                } else {
                    this.closeDialog(dialogRef);
                    this.openNewDialog({
                        title: 'Auswahl des Bearbeitungsrechts einzelner Anträge',
                        contentTemplateName: 'foerderantragTemplate',
                        data: {},
                        actions: [
                            this.createAbbrechenAction(nutzerRightManagementFoerderantragViewActions.resetRightLists()),
                            this.createListeDerFoerderbereiche(unternehmenNutzer),
                            this.createSpeichernAction(
                                nutzerRightManagementFoerderantragViewActions.updateNutzerRights({ nutzerId: unternehmenNutzer.id }),
                            ),
                        ],
                        onLoad: [
                            {
                                type: 'loadFoerderantraege',
                                onLoad: (): void => {
                                    this.store.dispatch(nutzerRightManagementFoerderantragViewActions.updateNutzerFoerderantraegeList());
                                },
                            },
                        ],
                    });
                }
            },
        };
    }

    private createListeDerFoerderbereiche(unternehmenNutzer: AllUnternehmenNutzer): DialogActions {
        return {
            title: 'Liste der Förderbereiche',
            clickFunction: (dialogRef?: MatDialogRef<unknown>): void => {
                if (this.hasChanges) {
                    this.openNewDialog({
                        title: 'Sind Sie sicher, dass Sie die Bearbeitung abbrechen möchten?',
                        actions: [
                            {
                                title: 'Weiter ohne speichern',
                                clickFunction: ((ref?: MatDialogRef<unknown>): void => {
                                    this.setChangesMade(false);
                                    this.store.dispatch(nutzerRightManagementFoerderantragViewActions.resetRightLists());
                                    this.closeDialog(ref);
                                    this.closeDialog(dialogRef);
                                    this.openNewDialog(this.createFoerderbereichDialogData(unternehmenNutzer));
                                }) as DialogActionClickFunction,
                            },
                            {
                                title: 'Weiter bearbeiten',
                                clickFunction: (ref?: MatDialogRef<unknown>) => this.closeDialog(ref),
                            },
                            {
                                title: 'Speichern',
                                clickFunction: ((ref?: MatDialogRef<unknown>): void => {
                                    this.dispatchAction(
                                        nutzerRightManagementFoerderantragViewActions.updateNutzerRights({ nutzerId: unternehmenNutzer.id }),
                                    );
                                    this.setChangesMade(false);
                                    this.store.dispatch(nutzerRightManagementFoerderantragViewActions.resetRightLists());
                                    this.closeDialog(ref);
                                    this.closeDialog(dialogRef);
                                    this.openNewDialog(this.createFoerderbereichDialogData(unternehmenNutzer));
                                }) as DialogActionClickFunction,
                            },
                        ],
                    });
                } else {
                    this.store.dispatch(nutzerRightManagementFoerderantragViewActions.resetRightLists());
                    this.closeDialog(dialogRef);
                    this.openNewDialog(this.createFoerderbereichDialogData(unternehmenNutzer));
                }
            },
        };
    }

    // Right Management - Nutzer for Foerderantrag View

    createAllAntraegeTableActionDialogData(foerderantrag: Foerderantrag): DialogData {
        return {
            title: `Wählen sie die Bearbeiter*innen mit Zugriff auf den Antrag ${foerderantrag.antragsnummer} "${foerderantrag.projektname}"`,
            contentTemplateName: 'allantraegeTemplate',
            actions: [
                this.createAbbrechenAction(nutzerRightManagementNutzerViewActions.resetNutzerLists()),
                this.createSpeichernAction(nutzerRightManagementNutzerViewActions.updateNutzerBearbeiter({ foerderantragId: foerderantrag.id })),
            ],
            onLoad: [
                {
                    type: 'loadFoerderantrag',
                    onLoad: (): void => {
                        this.store.dispatch(
                            nutzerRightManagementNutzerViewActions.updateFoerderantragNutzerList({ foerderantragId: foerderantrag.id }),
                        );
                    },
                },
            ],
        };
    }

    // Add New User Dialog
    createNewUserDialogData(): DialogData {
        return {
            title: 'Benutzer*in hinzufügen',
            contentTemplateName: 'addNewUserTemplate',
            actions: [
                this.createAbbrechenAction(nutzerRightManagementFoerderantragViewActions.resetRightLists()),
                {
                    title: 'Benutzer*in hinzufügen',
                    clickFunction: ((ref?: MatDialogRef<unknown>): void => {
                        this.dispatchAction(nutzerHinzufuegenActions.updateNewNutzer());
                        this.closeDialog(ref);
                    }) as DialogActionClickFunction,
                },
            ],
        };
    }

    private createConfirmationDialog = (
        title: string,
        dialogTitles: DialogTitles,
        action: Action,
        dialogHasChanges: () => boolean,
        setDialogChanges: (arg: boolean) => void,
    ): DialogActions => {
        return {
            title: title,
            clickFunction: (dialogRef?: MatDialogRef<unknown>): void => {
                const hasChanges = dialogHasChanges();
                if (hasChanges) {
                    this.openNewDialog({
                        title: dialogTitles.title,
                        actions: [
                            {
                                title: dialogTitles.cancleActionTitle,
                                clickFunction: (ref?: MatDialogRef<unknown>) => this.closeDialog(ref),
                            },
                            {
                                title: dialogTitles.proceedActionTitle,
                                clickFunction: ((ref?: MatDialogRef<unknown>): void => {
                                    this.store.dispatch(action);
                                    setDialogChanges(false);
                                    this.closeDialog(ref);
                                    this.closeDialog(dialogRef);
                                }) as DialogActionClickFunction,
                            },
                        ],
                    });
                } else {
                    this.closeDialog(dialogRef);
                }
            },
        };
    };

    createDeleteEntwurfDialogData = (action: Action): DialogData => {
        return {
            title: 'Sind Sie sicher, dass Sie diesen Entwurf löschen möchten?',
            actions: [
                this.createAbbrechenAction(action),
                {
                    title: 'Entwurf löschen',
                    clickFunction: (dialogRef?: MatDialogRef<unknown>): void => {
                        this.dispatchAction(action);
                        this.closeDialog(dialogRef);
                    },
                },
            ],
        };
    }

    createLesebestaetigungSetzenDialogData(dokumentId: number): DialogData {
        return {
            onLoad: [
                {
                    type: 'loadDokumentData',
                    onLoad: (): void => {
                        this.dispatchAction(
                            dokumentContentActions.changeDokumentContent({ dokumentId, directDownload: false }),
                        );
                    },
                },
            ],
            title: 'Lesebestätigung setzen',
            contentTemplateName: 'lesebestaetigungTemplate',
            actions: [{
                title: 'Lesebestätigung setzen',
                clickFunction: (dialogRef?: MatDialogRef<unknown>): void => {
                    this.closeDialog(dialogRef);
                    this.store.dispatch(dokumentContentActions.changeBescheidLesebestaetigung({ dokumentId }));
                },
            }],
            disableBackdropClick: true,
        };
    }

    createNutzerprofileCancleDialogData(callBack: () => void): DialogData {
        return {
            title: 'Es liegen ungespeicherte Änderungen vor, sind Sie sich sicher, dass Sie das bearbeiten beenden wollen?',
            actions: [
                {
                    title: 'Weiter bearbeiten',
                    clickFunction: (ref?: MatDialogRef<unknown>) => this.closeDialog(ref),
                },
                {
                    title: 'Bearbeiten beenden',
                    clickFunction: ((ref?: MatDialogRef<unknown>): void => {
                        this.store.dispatch(nutzerProfileActions.resetChangesToNutzerProfile());
                        callBack();
                        this.closeDialog(ref);
                    }) as DialogActionClickFunction,
                },
            ],
        }
    }
}
