<section class="antrag-details--top-section">
    <h1>Antrag "{{ projectName$ | async }}"</h1>
</section>

<section class="antrag-details--bottom-section">
    <app-card>
        <div class="antrag-details--bottom-section__card">
            <mat-tab-group
                class="antrag-details--bottom-section__tab-group"
                animationDuration="0ms"
                [selectedIndex]="shownTab"
                (selectedIndexChange)="tabChangeHandler($event)"
                data-testid="antrag-details-tab">
                <mat-tab data-testid="antrag-details-tab-0">
                    <ng-template mat-tab-label>
                        <div class="antrag-details--bottom-section__tab-group__active-indicator"></div>
                        <p>Aktueller Antrag</p>
                        <div class="antrag-details--bottom-section__tab-group__spacer-bottom"></div>
                    </ng-template>
                    <app-antrags-detail-tab></app-antrags-detail-tab>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="antrag-details--bottom-section__tab-group__active-indicator"></div>
                        <p>Nebenanträge</p>
                        <div class="antrag-details--bottom-section__tab-group__spacer-bottom"></div>
                    </ng-template>
                    <app-nebenantrag-list-tab></app-nebenantrag-list-tab>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <div class="antrag-details--bottom-section__tab-group__active-indicator"></div>
                        <p>FFA-Dokumente</p>
                        <div class="antrag-details--bottom-section__tab-group__spacer-bottom"></div>
                    </ng-template>
                    <app-dokumente-view-tab></app-dokumente-view-tab>
                </mat-tab>
                <mat-tab label="Historie ansehen">
                    <ng-template mat-tab-label>
                        <div class="antrag-details--bottom-section__tab-group__active-indicator"></div>
                        <p>Historie ansehen</p>
                        <div class="antrag-details--bottom-section__tab-group__spacer-bottom"></div>
                    </ng-template>
                    <app-history-tab></app-history-tab>
                </mat-tab>
            </mat-tab-group>
        </div>
    </app-card>
</section>
