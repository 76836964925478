import { PostkorbNachricht } from "../../../../model/postkorb.model"
import { PostkorbNachrichtDto } from "../../generated"

/**
 * Map the generated Dto to our frontend type
 * @param postkorbNachrichtDto the generated Type
 * @returns our internal type
 */
export const mapPostkorbNachricht = (postkorbNachrichtDto: Required<PostkorbNachrichtDto>): PostkorbNachricht => ({
    id: postkorbNachrichtDto.id,
    betreff: postkorbNachrichtDto.betreff,
    nachricht: postkorbNachrichtDto.nachricht,
    gelesen: postkorbNachrichtDto.gelesen,
    mitAnhang: postkorbNachrichtDto.mitAnhang,
    mitEmpfangsbestaetigung: postkorbNachrichtDto.mitEmpfangsbestaetigung,
    'foerderantrag.projektname': postkorbNachrichtDto.projektname,
    'foerderantrag.antragsnummer': postkorbNachrichtDto.antragsnummer,
    zustellzeitpunkt: postkorbNachrichtDto.zustellzeitpunkt
});