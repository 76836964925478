import { DestroyRef, inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { filter, fromEvent, merge, Observable, Subscription } from "rxjs";
import { authActions } from "../store/auth/auth.actions";
import { environment } from "../../environments/environment";
import { selectLoggedIn } from "../store/auth/auth.selectors";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormularHandlerService } from "./formular-handler.service";

@Injectable({
    providedIn: 'root'
})
export class IdleUserService {
    private store = inject(Store);
    private formularHandlerService = inject(FormularHandlerService);

    // This is the type the ffa-globals is setting for the postMessage event
    private postMessageTypeForActivity = 'setActiveTime';

    // The keys under which the idle information are stored in the local storage
    private idleKey = "__idle";
    private idleTriggerLogoutKey = this.idleKey + '__trigger-logout';
    private idleLastActiveTimeKey = this.idleKey + "__last-activ-time";
    private idleTabKey = this.idleKey + "__tab-";
    // The identifyer for this tab
    private idleUniqueTabIdentifierKey = this.idleTabKey + Date.now();

    // Array of the possible user actions, that count as not idle (They also need to be changed in the ffa-globals.js if they are changed)
    private userInputEvents = ['keypress', 'click', 'wheel', 'mousemove', 'ontouchstart', 'scroll', 'mousedown'];

    // The time in ms that should pass before the user is logged out if idle
    private idleTimeout = 1000 * 60 * environment.idleManagement.idleTimeoutMin;

    // The time in ms that should pass between updates of the last idle time
    private lastTimeActiveDebounceTime = 1000 * environment.idleManagement.lastTimeActiveDebounceTimeSec;

    // The subscription to the userInputEvents to remove the listeners on a logout
    private userInputEventsSubscription: Subscription;

    // The last time the user did an action
    private lastTimeActive: number | null;

    // The timer that handels the user loggout
    private loggoutTimer: number | null;

    // The subscription to the locale storage to remove the listener on a logout
    private idleStorageSubscription: Subscription[] = [];

    // If this tab needs to handle an action before the logout
    private triggerActionBeforeLogout = false;

    // If any tab needs to handle an action before the logout
    private waitForActionBeforeLogout: Record<string, boolean> = {};

    initIdleManagmentForTab(destroyRef: DestroyRef): void {
        localStorage.removeItem(this.idleTriggerLogoutKey);
        this.handleTriggerBeforeLogout(false);
        window.addEventListener('beforeunload', this.beforeUnloadHandler.bind(this), false);
        this.store.select(selectLoggedIn).pipe(takeUntilDestroyed(destroyRef)).subscribe(
            (isLoggedIn) => {
                if (isLoggedIn) {
                    this.onLoggedIn();
                } else {
                    this.onLoggedOut();
                }
            }
        );
    }

    shouldUserBeLoggedOutOnPageLoad(): boolean {
        const idleString = localStorage.getItem(this.idleLastActiveTimeKey);
        if (idleString) {
            const idleTime = +idleString;
            const now = Date.now();
            if (now - idleTime > this.idleTimeout) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    /**
     * Handels if the Tab should trigger an action before a logout is done
     * @param triggerBeforeLogout 
     */
    handleTriggerBeforeLogout(triggerBeforeLogout: boolean): void {
        if (triggerBeforeLogout) {
            localStorage.setItem(this.idleUniqueTabIdentifierKey, JSON.stringify(triggerBeforeLogout));
        }
        else {
            localStorage.removeItem(this.idleUniqueTabIdentifierKey);
        }

        this.triggerActionBeforeLogout = triggerBeforeLogout;
    }

    /**
     * The function for the event listener of closing the page
     */
    private beforeUnloadHandler(): void {
        localStorage.removeItem(this.idleUniqueTabIdentifierKey);
    }

    private onLoggedIn(): void {
        // Register the events that count as user input as one Observable
        this.userInputEventsSubscription = merge(...this.userInputEvents.map((eventName) => fromEvent(document, eventName))).subscribe(() => {
            const newDate = Date.now()
            if (this.lastTimeActive && (newDate - this.lastTimeActive > this.lastTimeActiveDebounceTime)) {
                this.lastTimeActive = newDate;
                this.setNewActiveTimeToStorage(newDate);
            }
        });

        // Register subscribe to the idleStorageObservable
        const idleLastActivTimeObservable$: Observable<StorageEvent> = fromEvent<StorageEvent>(window, 'storage').pipe(
            // Only handle local storage events with the key for the idle time. 
            filter(event => event.storageArea === localStorage && event.key === this.idleLastActiveTimeKey)
        );
        this.idleStorageSubscription.push(idleLastActivTimeObservable$.subscribe((event) => {
            if (event.newValue) {
                this.manageLogoutTimer(+event.newValue);
            }
        }));

        const idleStorageTriggerLogoutObservable$: Observable<StorageEvent> = fromEvent<StorageEvent>(window, 'storage').pipe(
            // Only handle local storage events with the key for the logout trigger. 
            filter(event => event.storageArea === localStorage && event.key === this.idleTriggerLogoutKey)
        );
        this.idleStorageSubscription.push(idleStorageTriggerLogoutObservable$.subscribe((event) => {
            if (this.triggerActionBeforeLogout && event.newValue === 'true') {
                this.handleActionBeforeLogout();
            }
        }));

        const idleStorageHasActionBeforeLogoutObservable$: Observable<StorageEvent> = fromEvent<StorageEvent>(window, 'storage').pipe(
            // Only handle local storage events where the key starts with the tab specific storage element. 
            filter(event => event.storageArea === localStorage && (event.key?.startsWith(this.idleTabKey) || false))
        );
        this.idleStorageSubscription.push(idleStorageHasActionBeforeLogoutObservable$.subscribe((event) => {
            if (event.key) {
                if (event.newValue === "true") {
                    this.waitForActionBeforeLogout[event.key] = true;
                }
                else {
                    delete this.waitForActionBeforeLogout[event.key];
                }
                if (Object.keys(this.waitForActionBeforeLogout).length === 0 && localStorage.getItem(this.idleTriggerLogoutKey) === 'true') {
                    this.manageLogout();
                }
            }
        }));
        // Register the postMessage listener for the forms
        window.addEventListener('message', this.handlePostMessageFromForm.bind(this), false);

        // Set opening this service as the last active action
        this.setNewActiveTimeToStorage(Date.now());
    }

    private onLoggedOut(): void {
        if (this.userInputEventsSubscription) {
            this.userInputEventsSubscription.unsubscribe();
        }
        if (this.idleStorageSubscription.length > 0) {
            this.idleStorageSubscription.forEach((subscription) => subscription.unsubscribe());
        }
        if (this.loggoutTimer) {
            window.clearTimeout(this.loggoutTimer);
            this.loggoutTimer = null;
        }
        window.removeEventListener('message', this.handlePostMessageFromForm.bind(this));
        this.lastTimeActive = null;
        localStorage.removeItem(this.idleKey);
    }

    private handlePostMessageFromForm = (event: MessageEvent): void => {
        if (event.origin !== environment.FMS.origin) {
            return;
        }
        if (event.data.type === this.postMessageTypeForActivity) {
            this.setNewActiveTimeToStorage(Date.now());
        }
    }

    private setNewActiveTimeToStorage = (activeTime: number): void => {
        const logoutTriggered = localStorage.getItem(this.idleTriggerLogoutKey);
        if (logoutTriggered === 'true') {
            return;
        }
        localStorage.setItem(this.idleLastActiveTimeKey, JSON.stringify(activeTime));
        this.manageLogoutTimer(activeTime)
    }

    private manageLogoutTimer(activeTime: number): void {
        this.lastTimeActive = activeTime;
        if (this.loggoutTimer) {
            window.clearTimeout(this.loggoutTimer);
        }
        this.loggoutTimer = window.setTimeout(() => {
            this.triggerLogout();
        }, this.idleTimeout);
    }

    private manageLogout(): void {
        this.store.dispatch(authActions.postChangeUserToLoggedOut({ timeout: true }));
        localStorage.removeItem(this.idleTriggerLogoutKey);
    }

    private async triggerLogout(): Promise<void> {
        const logoutAlreadyTriggered = localStorage.getItem(this.idleTriggerLogoutKey) === 'true';
        if (!logoutAlreadyTriggered) {
            if (this.triggerActionBeforeLogout) {
                await this.handleActionBeforeLogout();
            }
            localStorage.setItem(this.idleTriggerLogoutKey, JSON.stringify(true));
            if (Object.keys(this.waitForActionBeforeLogout).length > 0) {
                return;
            }
            this.manageLogout();
        }
    }

    private async handleActionBeforeLogout(): Promise<void> {
        await this.formularHandlerService.handleExternalSave('save', () => { return }).then(
            () => {
                localStorage.removeItem(this.idleUniqueTabIdentifierKey);
                this.triggerActionBeforeLogout = false;
            }
        )
    }
}