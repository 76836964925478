import { Component, DestroyRef, EventEmitter, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PageEvent } from '@angular/material/paginator';

import { DataTableConfig, QuickFilterMenuItem } from '../../model/data-table.model';
import { SortParameter } from '../../model/store.model';
import { postkorbActions } from '../../store/postkorb/postkorb.actions';
import { selectPostkorbStoreTableContent, selectPostkorbStoreTableIsLoading, selectPostkorbStoreTablePage, selectPostkorbStoreTableSize, selectPostkorbStoreTableSort, selectPostkorbStoreTableTotalElements } from '../../store/postkorb/postkorb.selectors';
import { NachrichtenTyp, PostkorbNachricht } from '../../model/postkorb.model';
import { selectActiveUnternehmenId, selectActiveUnternehmenName } from '../../store/nutzer-unternehmen-selection/nutzer-unternehmen-selection.selectors';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-postkorb',
    templateUrl: './postkorb.component.html',
    styleUrl: './postkorb.component.scss',
})
export class PostkorbComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    private store = inject(Store);

    searchValue = '';
    tableConfig: DataTableConfig;
    pageElement = { page: 0, size: 20 };

    quickFilterNachrichtenTypEvent: EventEmitter<string> = new EventEmitter<string>();
    quickFilterNachrichtenTypForm = new FormGroup({
        nachrichtenTypFilterControl: new FormControl('null'),
    })

    quickFilterMenuItems: QuickFilterMenuItem[] = [
        {
            name: 'Nachrichtenart',
            emitter: this.quickFilterNachrichtenTypEvent,
            formControllerName: 'nachrichtenTypFilterControl',
            formGroup: this.quickFilterNachrichtenTypForm,
            selectionList: ['Nur unternehmensbezogene Nachrichten', 'Nur allgemeine Nachrichten'],
        }
    ];

    tableContent$: Observable<Record<string, string | number | boolean>[]>;
    tableIsLoading$: Observable<boolean>;
    tableSortingArray$: Observable<SortParameter[]>;
    tableDisplayedSize$: Observable<number>;
    tableDisplayedPage$: Observable<number>;
    tableTotalElements$: Observable<number>;
    hasFoerderbereiche$: Observable<boolean>;

    unternehmenId$: Observable<number>;
    unternehmenName$: Observable<string>;

    tableContent: PostkorbNachricht[];


    /**
     * subscribe to the needed functions and set up a subscription array to easyly unsubscribe at the end
     */
    ngOnInit(): void {
        this.store.dispatch(postkorbActions.changePagePostkorbNachricht());

        this.tableContent$ = this.store.select(selectPostkorbStoreTableContent);
        this.tableIsLoading$ = this.store.select(selectPostkorbStoreTableIsLoading);
        this.tableSortingArray$ = this.store.select(selectPostkorbStoreTableSort);
        this.tableDisplayedSize$ = this.store.select(selectPostkorbStoreTableSize);
        this.tableDisplayedPage$ = this.store.select(selectPostkorbStoreTablePage);
        this.tableTotalElements$ = this.store.select(selectPostkorbStoreTableTotalElements);
        this.unternehmenId$ = this.store.select(selectActiveUnternehmenId);
        this.unternehmenName$ = this.store.select(selectActiveUnternehmenName);

        this.tableContent$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((content) => {
            this.tableContent = content as PostkorbNachricht[];
        });
        this.configChange();

        this.quickFilterNachrichtenTypEvent.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((eventValue) => {
            this.nachrichtenTypFilterHandler(eventValue);
        });
    }

    /**
     * Create the table configuration that is passed to the generic table component.
     */
    configChange(): void {
        this.tableConfig = {
            columns: [
                { columnKey: 'zustellzeitpunkt', display: 'Datum' },
                { columnKey: 'betreff', display: 'Betreff' },
                { columnKey: 'foerderantrag.projektname', display: 'Projektname' },
                { columnKey: 'foerderantrag.antragsnummer', display: 'Antragsnummer' },
                { columnKey: 'mitAnhang', display: 'Anhang' },
            ],
            headerIsSticky: true,
            tableLabel: 'Tabelle aller Postkorbbenachrichtigungen.',
            tableColumnClass: 'postkorb',
            tableActions$: of([]),
            paginationNames: {
                itemsPerPageLabel: 'Nachrichten pro Seite',
                nextPageLabel: 'Nächste Seite',
                previousPageLabel: 'Vorherige Seite',
                firstPageLabel: 'Erste Seite',
                lastPageLabel: 'Letzte Seite',
            },
            tableContent$: this.tableContent$,
            tableIsLoading$: this.tableIsLoading$,
            tableSortingArray$: this.tableSortingArray$,
            tableDisplayedSize$: this.tableDisplayedSize$,
            tableDisplayedPage$: this.tableDisplayedPage$,
            tableTotalElements$: this.tableTotalElements$,
            tableDataObjectsName: 'Nachrichten',
            tableDetailId: 'id',
            disableTableActions: true,
            tableRowClassFunction: this.rowClassHandler,
        };
    }

    rowClickHandler = (index: number): void => {
        if (this.tableContent.length > 0) {
            this.store.dispatch(postkorbActions.changePostkorbDisplayedPostkorbNachricht({ displayedPostkorbNachricht: this.tableContent[index] }));
        }
    }

    /**
     * Handle the row class for the table depending on the row the table ask about.
     *
     * @param index The index of the row that is asked for.
     * @returns the css class for the row.
     */
    rowClassHandler = (index: number): string => {
        if (this.tableContent.length > 0 && !this.tableContent[index].gelesen) {
            return 'data-table__postkorb__table-row__ungelesen';
        }
        return '';
    }

    /**
     * Handle the search input.
     *
     * @param searchEvent holds the search value
     */
    searchHandler(searchEvent: string): void {
        if (this.searchValue !== searchEvent) {
            this.searchValue = searchEvent;
            this.store.dispatch(postkorbActions.changePostkorbTableSearchparameter({ newSearchparameter: searchEvent }));
            this.pageElement.page = 0;
        }
    }

    /**
     * Handle the sorting of a table column
     *
     * @param sortEvent holds the new sortParameter and if the sort parameter should be added to the end of the sorting array or if it should create a new list
     */
    sortHandler(sortEvent: { sort: SortParameter; isMultiSort: boolean }): void {
        this.store.dispatch(postkorbActions.changePostkorbTableSort({ newSort: sortEvent.sort, isMultiSort: sortEvent.isMultiSort }));
        this.pageElement.page = 0;
    }

    /**
     *  Handle the use of the paginator. The paginator triggers a new event for every change so only one of the two looked out values will change at a time.
     *
     * @param pageEvent holds the size and the active page of the paginator return.
     */
    pageHandler(pageEvent: PageEvent): void {
        if (pageEvent.pageSize !== this.pageElement.size) {
            this.store.dispatch(postkorbActions.changePostkorbTableSize({ newSize: pageEvent.pageSize }));
            this.pageElement.size = pageEvent.pageSize;
            this.pageElement.page = 0;
        } else if (pageEvent.pageIndex !== this.pageElement.page) {
            this.store.dispatch(postkorbActions.changePostkorbTablePage({ newPage: pageEvent.pageIndex }));
            this.pageElement.page = pageEvent.pageIndex;
        }
    }

    /**
     * Handle the filter interaktion
     * @param eventValue the selected Filter
     */
    nachrichtenTypFilterHandler = (eventValue: string): void => {
        let newNachrichtenTypFilter: NachrichtenTyp = NachrichtenTyp.alle;
        if (eventValue === 'Nur unternehmensbezogene Nachrichten') {
            newNachrichtenTypFilter = NachrichtenTyp.unternehmen;
        } else if (eventValue === 'Nur allgemeine Nachrichten') {
            newNachrichtenTypFilter = NachrichtenTyp.allgemein;
        }
        this.store.dispatch(postkorbActions.changePostkorbTableNachrichtenTypFilter({ newNachrichtenTypFilter }));
        this.pageElement.page = 0;
    }
}
