import { Component } from '@angular/core';

/**
 * Component that wrapps the content in a mat-card that has the default stile. The size has to be added through the size of the content.
 */
@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrl: './card.component.scss',
})
export class CardComponent {}
