<div
    class="change-password-dialog"
    aria-labelledby="passwordChangeTitle">
    <mat-dialog-content class="change-password-dialog__content">
        <h1 id="passwordChangeTitle">Passwort ändern</h1>
        <form
            [formGroup]="passwordFormGroup"
            class="change-password-dialog__content__form">
            <div class="change-password-dialog__content__form--inner">
                <mat-form-field class="change-password-dialog__content--form-field">
                    <mat-label>Altes Passwort eingeben</mat-label>
                    <input
                        matInput
                        formControlName="oldPassword"
                        [type]="showPassword['oldPassword'] ? 'text' : 'password'" />
                    <button
                        mat-icon-button
                        matSuffix
                        (click)="triggerShowPassword('oldPassword')"
                        [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="showPassword['oldPassword']">
                        <mat-icon>{{ showPassword['oldPassword'] ? 'visibility_off' : 'visibility' }}</mat-icon>
                    </button>
                    <mat-error *ngIf="passwordFormGroup.get('oldPassword')?.hasError('required') && passwordFormGroup.get('oldPassword')?.touched">
                        {{ showErrorMessage('oldPassword') }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="change-password-dialog__content--form-field">
                    <mat-label>Neues Passwort eingeben</mat-label>
                    <input
                        matInput
                        formControlName="newPassword"
                        [type]="showPassword['newPassword'] ? 'text' : 'password'" />
                    <button
                        mat-icon-button
                        matSuffix
                        (click)="triggerShowPassword('newPassword')"
                        [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="showPassword['newPassword']">
                        <mat-icon>{{ showPassword['newPassword'] ? 'visibility_off' : 'visibility' }}</mat-icon>
                    </button>
                    <mat-error *ngIf="passwordFormGroup.get('newPassword')?.invalid && passwordFormGroup.get('newPassword')?.touched">
                        {{ showErrorMessage('newPassword') }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="change-password-dialog__content--form-field">
                    <mat-label>Wiederholung des neuen Passworts</mat-label>
                    <input
                        matInput
                        formControlName="newPasswordRepeat"
                        [type]="showPassword['newPasswordRepeat'] ? 'text' : 'password'" />
                    <button
                        mat-icon-button
                        matSuffix
                        (click)="triggerShowPassword('newPasswordRepeat')"
                        [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="showPassword['newPasswordRepeat']">
                        <mat-icon>{{ showPassword['newPasswordRepeat'] ? 'visibility_off' : 'visibility' }}</mat-icon>
                    </button>
                    <mat-error
                        *ngIf="
                            passwordFormGroup.get('newPasswordRepeat')?.hasError('required') && passwordFormGroup.get('newPasswordRepeat')?.touched
                        ">
                        {{ showErrorMessage('newPasswordRepeat') }}
                    </mat-error>
                </mat-form-field>
            </div>
            <p
                class="change-password-dialog__content__form__hint"
                tippyClassName="tippy__hint"
                ngxTippy="{{ this.passwordHintText }}"
                [tippyProps]="this.passwordHintOptions">
                Passwortvorgaben
            </p>
            <div
                *ngIf="this.passwordFormGroup.errors?.['passwordMismatch']  
                && passwordFormGroup.get('newPassword')?.touched 
                && passwordFormGroup.get('newPasswordRepeat')?.touched "
                class="change-password-dialog__content__form__error">
                Die neuen Passwörter stimmt nicht überein.
            </div>
            <div
                *ngIf="this.passwordFormGroup.errors?.['serverError']"
                class="change-password-dialog__content__form__error">
                {{ serverError }}
            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions class="change-password-dialog__actions">
        <app-button
            buttonLabel="Abbrechen"
            buttonColor="primary"
            [isButton]="true"
            (clickEvent)="closeDialog()">
        </app-button>
        <app-button
            buttonLabel="Speichern"
            buttonColor="accent"
            [isButton]="true"
            [disabled]="!passwordFormGroup.valid"
            (clickEvent)="saveDialog()">
        </app-button>
    </mat-dialog-actions>
</div>
