import { createReducer, on } from '@ngrx/store';
import { AuthStore } from '../../model/auth.model';
import { authActions } from './auth.actions';
import { NutzerAnrede } from '../../model/user.model';
import { formularActionsExt } from '../formular/formular.actions';

export const authStoreInitialState: AuthStore = {
    loggedIn: false,
    anrede: '',
    vorname: '',
    nachname: '',
    email: '',
    token: null,
    role: null,
    actionAfterRefresh: null,
};

export const authReducer = createReducer(
    authStoreInitialState,
    on(authActions.changeLoginDetails, (state, action): AuthStore => ({
        ...state,
        loggedIn: true,
        anrede: action.anrede as NutzerAnrede,
        vorname: action.vorname,
        nachname: action.nachname,
        email: action.email,
        token: action.token,
        role: action.role,
    })),
    on(authActions.changeLoginDetailsAndCompanyNoRedirect, (state, action): AuthStore => ({
        ...state,
        loggedIn: true,
        anrede: action.anrede as NutzerAnrede,
        vorname: action.vorname,
        nachname: action.nachname,
        email: action.email,
        token: action.token,
        role: action.role,
    })),
    on(authActions.changeUserRole, (state, action): AuthStore => ({ ...state, role: action.role })),
    on(authActions.setToken, (state, action): AuthStore => ({ ...state, token: action.token })),
    on(authActions.changeUserToLoggedOut, (): AuthStore => ({ ...authStoreInitialState })),

    on(formularActionsExt.changeInitializationFileUpload, (state, action): AuthStore => ({ ...state, actionAfterRefresh: action })),
    on(formularActionsExt.updateGeneratePresignedUrls, (state): AuthStore => ({ ...state, actionAfterRefresh: null })),
    on(formularActionsExt.setInitializationFileUploadFailure, (state): AuthStore => ({ ...state, actionAfterRefresh: null })),
);
