import { AntragsDokument, } from "../../../../model/dokumente-unternehmen.model";
import { AntragsDokumentDto } from "../../generated";

export const mapAntragsDokument = (antragsDokumentDto: AntragsDokumentDto): AntragsDokument => {
    //The return content is no array but just a string so it has to be changed here to be useable
    const base64Content = antragsDokumentDto.content as unknown as string;
    // Decode the base64 string into binary data
    const byteCharacters = window.atob(base64Content);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob from the byte array
    const contentBlob = new Blob([byteArray], { type: 'application/pdf' });
    return {
        name: antragsDokumentDto.name,
        content: contentBlob,
        antragsnummer: antragsDokumentDto.antragAntragsnummer,
        dateiTyp: antragsDokumentDto.dateityp,
    }
}