import { Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { PortalRole } from '../../../model/auth.model';
import { first, Observable } from 'rxjs';
import { selectAllUnternehmenNutzerRolleManagementNewRolle, selectAllUnternehmenNutzerRolleManagementRolle } from '../../../store/all-unternehmen-nutzer/all-unternehmen-nutzer.selectors';
import { nutzerRolleManagement } from '../../../store/all-unternehmen-nutzer/all-unternehmen-nutzer.actions';
import { UnternehmenRolle } from '../../../model/all-unternehmen-nutzer.model';

/**
 * Component to display role selection buttons inside the open-dialog component
 */
@Component({
    selector: 'app-rolle-management',
    templateUrl: './rolle-management.component.html',
    styleUrl: './rolle-management.component.scss',
})
export class RolleManagementComponent implements OnInit {
    private store = inject(Store);
    activeRolle: PortalRole;
    newRolle$: Observable<UnternehmenRolle>;
    PortalRole = PortalRole;

    ngOnInit(): void {
        this.store.select(selectAllUnternehmenNutzerRolleManagementRolle).pipe(first((rolle => rolle !== PortalRole.COMPANY_SELECT))).subscribe((rolle) => {
            this.activeRolle = rolle;
        });
        this.newRolle$ = this.store.select(selectAllUnternehmenNutzerRolleManagementNewRolle);
    }

    /**
     * The button activation
     * @param buttonRolle the role that is connected to the button
     * @param oldRolle the role that was saved as newRolle in the store
     */
    toggleButton(buttonRolle: PortalRole.COMPANY_ADMIN | PortalRole.COMPANY_USER, oldRolle: UnternehmenRolle): void {
        if (buttonRolle !== oldRolle) {
            this.store.dispatch(nutzerRolleManagement.changeNewRolle({ newRolle: buttonRolle }));
        }
    }

    /**
     * Check wich button is active
     * @param newRolle the role that was saved as newRolle in the store
     * @param buttonRolle the role connected to the button
     * @returns true if the button should be active
     */
    isSelected(newRolle: UnternehmenRolle, buttonRolle: PortalRole.COMPANY_ADMIN | PortalRole.COMPANY_USER): boolean {
        if (newRolle === PortalRole.COMPANY_SELECT) {
            return this.activeRolle === buttonRolle;
        }
        return newRolle === buttonRolle;
    }

}
