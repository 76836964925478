import { createReducer, on } from "@ngrx/store";
import { NachrichtenTyp, PostkorbStore } from "../../model/postkorb.model";
import { postkorbActions } from "./postkorb.actions";
import { SortDir } from "../../model/store.model";
import { authActions } from "../auth/auth.actions";

export const initialPostkorbState: PostkorbStore = {
    postkorbTable: {
        content: [],
        page: 0,
        size: 20,
        searchparameter: "",
        sort: [{ column: 'zustellzeitpunkt', sortDirection: SortDir.desc }],
        totalElements: 0,
        totalPages: 0,
        numberOfElements: 0,
        isLoading: false,
        nachrichtenTypFilter: NachrichtenTyp.alle,
    },
    displayedPostkorbNachricht: null
}

export const postkorbReducer = createReducer(
    initialPostkorbState,
    on(
        postkorbActions.changePagePostkorbNachricht,
        (state): PostkorbStore => (
            { ...state, postkorbTable: { ...state.postkorbTable, isLoading: true } }
        )
    ),
    on(
        postkorbActions.setPagePostkorbNachrichten,
        (state, action): PostkorbStore => (
            { ...state, postkorbTable: { ...state.postkorbTable, ...action.newPagePostkorbNachricht, isLoading: false } }
        )
    ),
    on(
        postkorbActions.setPagePostkorbNachrichtenFailed,
        (state): PostkorbStore => (
            { ...state, postkorbTable: { ...state.postkorbTable, isLoading: false } }
        )
    ),
    on(
        postkorbActions.changePostkorbTablePage,
        (state, action): PostkorbStore => (
            { ...state, postkorbTable: { ...state.postkorbTable, page: action.newPage } }
        )
    ),
    on(
        postkorbActions.changePostkorbTableSearchparameter,
        (state, action): PostkorbStore => (
            { ...state, postkorbTable: { ...state.postkorbTable, searchparameter: action.newSearchparameter, page: 0 } }
        )
    ),
    on(
        postkorbActions.changePostkorbTableSize,
        (state, action): PostkorbStore => (
            { ...state, postkorbTable: { ...state.postkorbTable, size: action.newSize, page: 0 } }
        )
    ),
    on(
        postkorbActions.changePostkorbTableSort,
        (state, action): PostkorbStore => {
            const sort = action.isMultiSort ? [...state.postkorbTable.sort, action.newSort] : [action.newSort];
            return { ...state, postkorbTable: { ...state.postkorbTable, sort: sort, page: 0 } };
        }
    ),
    on(
        postkorbActions.changePostkorbTableNachrichtenTypFilter,
        (state, action): PostkorbStore => (
            { ...state, postkorbTable: { ...state.postkorbTable, nachrichtenTypFilter: action.newNachrichtenTypFilter, page: 0 } }
        )
    ),
    on(
        postkorbActions.resetPostkorbTable,
        (state): PostkorbStore => (
            { ...state, postkorbTable: { ...initialPostkorbState.postkorbTable } }
        )
    ),
    on(
        postkorbActions.changePostkorbDisplayedPostkorbNachricht,
        (state, action): PostkorbStore => (
            { ...state, displayedPostkorbNachricht: action.displayedPostkorbNachricht }
        )
    ),
    on(
        postkorbActions.resetPostkorbDisplayedPostkorbNachricht,
        (state): PostkorbStore => (
            { ...state, displayedPostkorbNachricht: null }
        )
    ),
    on(authActions.changeUserToLoggedOut, (): PostkorbStore => ({ ...initialPostkorbState })),
);