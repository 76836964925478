import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectIsAdminRole } from '../../store/auth/auth.selectors';
import { stammdatenActions } from '../../store/stammdaten/stammdaten.actions';
import { formularActionsInt } from '../../store/formular/formular.actions';
import { Stammdaten } from '../../model/stammdaten.model';
import { selectStammdatenData, selectStammdatenIsLoading } from '../../store/stammdaten/stammdaten.selectors';
import { dokumentContentActions } from '../../store/dokumente-unternehmen/dokumente-unternehmen.actions';

@Component({
    selector: 'app-stammdaten',
    templateUrl: './stammdaten.component.html',
    styleUrl: './stammdaten.component.scss',
})
/**
 * The component to display the stammdaten page
 */
export class StammdatenComponent implements OnInit, OnDestroy {
    private store = inject(Store);
    nutzerIsAdmin$: Observable<boolean>;
    isLoadingStammdaten$: Observable<boolean>;
    stammdaten$: Observable<Stammdaten>;

    ngOnInit(): void {
        this.store.dispatch(stammdatenActions.changeLoadStammdaten());
        this.nutzerIsAdmin$ = this.store.select(selectIsAdminRole);
        this.isLoadingStammdaten$ = this.store.select(selectStammdatenIsLoading);
        this.stammdaten$ = this.store.select(selectStammdatenData);
    }

    ngOnDestroy(): void {
        this.store.dispatch(stammdatenActions.resetStammdatenStore());
    }

    /**
     * Handle the click on the link to the Stammdaten Formular
     */
    linkClickHandler(): void {
        this.store.dispatch(formularActionsInt.changeUnternehmenStammdaten());
    }

    /**
     * Download the HRA or Gewerbeschein
     *
     * @param dokumentId holds the id of the dokument
     * @param antragsId holds the id of the FFA_020 that the HRA or Gewerbeschein belongs to
     */
    downloadHraOrGewerbeschein(dokumentId: number, antragsId: number): void {
        this.store.dispatch(dokumentContentActions.changeHraOrGewerbescheinDownload({ dokumentId, antragsId }))
    }
}
