import { Component, inject, OnInit } from "@angular/core";
import { PostkorbNachricht } from "../../../model/postkorb.model";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { selectPostkorbStoreDisplayedPostkorbNachricht } from "../../../store/postkorb/postkorb.selectors";

@Component({
    selector: 'app-postkorb-nachricht-dialog',
    templateUrl: './postkorb-nachricht-dialog.component.html',
    styleUrl: './postkorb-nachricht-dialog.component.scss'
})
export class PostkorbNachrichtDialogComponent implements OnInit {
    private store = inject(Store);

    postkorbNachricht$: Observable<PostkorbNachricht | null>;

    ngOnInit(): void {
        this.postkorbNachricht$ = this.store.select(selectPostkorbStoreDisplayedPostkorbNachricht);
    }
}
