import { createReducer, on } from '@ngrx/store';
import { FoerderbereichStore } from '../../model/foerderbereich.model';
import { foerderbereichActions } from './foerderbereich.actions';
import { authActions } from '../auth/auth.actions';
import { nutzerUnternehmenSelectionActions } from '../nutzer-unternehmen-selection/nutzer-unternehmen-selection.actions';

export const foerderbereichInitalState: FoerderbereichStore = {
    nutzerFoerderbereiche: [],
    nutzerFoerderarten: [],
    allFoerderbereiche: [],
    isLoading: false,
};

export const foerderbereichReducer = createReducer(
    foerderbereichInitalState,
    on(foerderbereichActions.changeFoerderbereicheUndFoerderarten, (state): FoerderbereichStore => ({ ...state, isLoading: true })),
    on(foerderbereichActions.setFoerderbereicheUndFoerderarten, (state, action): FoerderbereichStore => ({
        ...state,
        isLoading: false,
        nutzerFoerderarten: action.newNutzerFoerderarten,
        nutzerFoerderbereiche: action.newNutzerFoerderbereiche,
    })),
    on(foerderbereichActions.setNutzerFoerderbereich, (state, action): FoerderbereichStore => ({ ...state, nutzerFoerderbereiche: action.newNutzerFoerderbereiche })),
    on(foerderbereichActions.resetStore, (): FoerderbereichStore => ({ ...foerderbereichInitalState })),
    on(foerderbereichActions.setAllFoerderbereiche, (state, action): FoerderbereichStore => ({ ...state, allFoerderbereiche: action.newAllFoerderbereiche })),
    on(authActions.changeUserToLoggedOut, (): FoerderbereichStore => ({ ...foerderbereichInitalState })),
    on(nutzerUnternehmenSelectionActions.changeSelectedUnternehmen, (): FoerderbereichStore => ({ ...foerderbereichInitalState }))
);
