import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Link as Breadcrumb } from '../../model/app-routing.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
})
/**
 * The breadcrumbs generation for the pages
 * Used the example from  https://blog.coodoo.io/dynamischer-breadcrumb-mithilfe-des-angular-router-tutorial-28c2e1c800b7 and rewrote it where necessary
 */
export class BreadcrumbComponent implements OnInit {
    private router = inject(Router);
    private activatedRoute = inject(ActivatedRoute);
    private destroyRef = inject(DestroyRef);

    breadcrumbs: Breadcrumb[] = [];

    ngOnInit(): void {
        if (this.router.navigated) {
            // The router has already completed initial navigation
            this.breadcrumbs = [];
            this.buildBreadcrumb(this.activatedRoute);
        }
        this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
                this.breadcrumbs = [];
                this.buildBreadcrumb(this.activatedRoute);
            }
        });
    }

    buildBreadcrumb(currentAR: ActivatedRoute): void {
        if (currentAR.snapshot.data['breadcrumb']) {
            // create the link for the breadcrumb by adding the current path entry to the link of the last breadcrumb link
            // get the last link:
            const lastBreadcrumbLink = this.breadcrumbs.length !== 0 ? this.breadcrumbs[this.breadcrumbs.length - 1].link : '';
            // get current path entry (or the resolved breadcrumb data if it is a dynamic rout)
            let currentBreadcrumbLink = '';
            if (currentAR?.routeConfig?.path?.startsWith(':') || currentAR?.routeConfig?.path?.includes(':')) {
                currentBreadcrumbLink = currentAR.snapshot.data['breadcrumb']['link'];
            } else {
                currentBreadcrumbLink = currentAR?.routeConfig?.path || '';
            }
            // add the current breadcrumb to the breadcrumb list.
            this.breadcrumbs.push({
                label: currentAR.snapshot.data['breadcrumb']['label'],
                link: lastBreadcrumbLink + '/' + currentBreadcrumbLink,
            } as Breadcrumb);
        }
        // Repeat for every child of the rout.
        if (currentAR.firstChild !== null && currentAR.firstChild.routeConfig?.path !== '') {
            this.buildBreadcrumb(currentAR.firstChild);
        }
    }
}

