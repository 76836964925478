<ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
    <a
        [routerLink]="breadcrumb.link"
        class="text-small text-primary"
        [ngClass]="{ '!text-strong ': last }">
        {{ breadcrumb.label }}
    </a>
    <span
        *ngIf="!last"
        class="text-primary">
        >
    </span>
</ng-container>
