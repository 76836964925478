import { props, createActionGroup, emptyProps } from '@ngrx/store';
import { AllFoerderantraege, Foerderantrag, Status } from '../../model/foerderantraege.model';
import { SortParameter } from '../../model/store.model';
import { Foerderart } from '../../model/foerderbereich.model';
import { TableAction } from '../../model/data-table.model';

/**
 * The actions for the all-antraege page and corresponding pages
 */
export const foerderantraegeActions = createActionGroup({
    source: 'Foerderantraege',
    events: {
        changeSearchParameter: props<{ newSearchParameter: string }>(),
        changeShownFoerderartFilter: props<{ newFoerderartFilter: string }>(),
        changeShownStatusFilter: props<{ newStatusFilter: Status | null }>(),
        changeShownPage: props<{ newPage: number }>(),
        changeShownSize: props<{ newSize: number }>(),
        changeSortingArray: props<{ newSorting: SortParameter; multiSort: boolean }>(),
        changeAllFoerderantraege: emptyProps(),
        // use this one if you trigger it internaly as a reaction on something
        changeAllFoerderantraegeIntern: emptyProps(),
        updateAllFoerderantraegeTableActions: props<{ allFoerderantraege: AllFoerderantraege }>(),
        setAllFoerderantraege: props<{ allFoerderantraege: AllFoerderantraege, newTableActions: TableAction[][] }>(),
        changeAllFoerderantraegeFailed: emptyProps(),
        resetStore: emptyProps(),
        setFoerderartenInUse: props<{ newFoerderartenInUse: Foerderart[] }>(),
        resetFoerderartenInUse: emptyProps(),
        changeAntragsStatusForDeletion: props<{ antragsId: number }>(),
    },
});

/**
 * The actions for the antrags detail page
 */
export const displayedDetailsFoerderantragActions = createActionGroup({
    source: 'Foerderantrags Detail Page',
    events: {
        updateDisplayedDetailsFoerderantrag: props<{ antragsId: number }>(),
        setDisplayedDetailsFoerderantrag: props<{ newFoerderantrag: Foerderantrag }>(),
    },
});

