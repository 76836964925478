import { inject, Injectable } from '@angular/core';
import { BearbeiterUnternehmenControllerService, CreateBearbeiterDto } from '../generated';
import { map, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RightManagementFoerderantraegeService {
    private bearbeiterUnternehmenControllerService = inject(BearbeiterUnternehmenControllerService);

    postBearbeiterUnternehmen(unternehmenId: number, requestBody: CreateBearbeiterDto): Observable<boolean> {
        return this.bearbeiterUnternehmenControllerService
            .makeNutzerBearbeiter({ unternehmenId, requestBody })
            .pipe(map((rights) => rights.length === requestBody.foerderantragIds.length));
    }

    deleteBearbeiterUnternehmen(unternehmenId: number, nutzerIdpId: string, requestBody: number[]): Observable<boolean> {
        return this.bearbeiterUnternehmenControllerService.removeNutzerAsBearbeiter({ unternehmenId, nutzerIdpId, requestBody });
    }
}
