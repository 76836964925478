import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Component for a button element or a link stiled like a button and annotated with the mat-raised-button attribute
 */
@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrl: './button.component.scss',
})
export class ButtonComponent {
    @Input()
    isButton = false;
    @Input()
    isExternalLink = false;
    //if the link is not a child of the active route the link has to start with a /
    @Input()
    targetLink: string;
    @Input()
    buttonLabel: string;
    @Input()
    buttonColor = 'accent';
    @Input()
    buttonClass = '';
    @Input()
    disabled = false;

    @Output() clickEvent = new EventEmitter<MouseEvent>();
}
