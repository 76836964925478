import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FoerderbereichStore } from '../../model/foerderbereich.model';

/**
 * MemoizedSelector that gives a type save FoerderbereichStore element to the other selectors
 */
export const selectFoerderbereichStore = createFeatureSelector<FoerderbereichStore>('foerderbereichStore');

/**
 * MemoizedSelector to trigger only changes when the foerderbereiche changes.
 */
export const selectFoerderbereichStoreComplete = createSelector(selectFoerderbereichStore, (foerderbereichStore) => foerderbereichStore);

/**
 * MemoizedSelector to trigger only changes when the foerderarten changes.
 */
export const selectNutzerFoerderbereicheFoerderarten = createSelector(
    selectFoerderbereichStore,
    (foerderbereichStore) => foerderbereichStore.nutzerFoerderarten,
);

/**
 * MemoizedSelector to trigger only changes when the foerderbereiche changes.
 */
export const selectNutzerFoerderbereicheFoerderbereiche = createSelector(
    selectFoerderbereichStore, selectNutzerFoerderbereicheFoerderarten,
    (foerderbereichStore, foerderarten) =>
        foerderbereichStore.nutzerFoerderbereiche.filter((foerderbereich) =>
            foerderarten.some((foerderart) =>
                foerderart.foerderbereichBezeichnung === foerderbereich.bezeichnung
            )
        )
);

export const selectNutzerFoerderbereicheHasFoerderbereich = createSelector(
    selectFoerderbereichStore,
    (foerderbereichStore) => foerderbereichStore.nutzerFoerderbereiche.length !== 0,
);

export const selectFoerderbereicheAllFoerderbereicheBezeichnungen = createSelector(
    selectFoerderbereichStore,
    (foerderbereichStore) => foerderbereichStore.allFoerderbereiche.map((foerderbereich) => foerderbereich.bezeichnung),
);

export const selectFoerderbereicheAllFoerderbereiche = createSelector(
    selectFoerderbereichStore,
    (foerderbereichStore) => foerderbereichStore.allFoerderbereiche,
);

export const selectNutzerFoerderbereicheOpened = createSelector(selectNutzerFoerderbereicheFoerderbereiche, ((foerderbereiche) => {
    const openFoerderbereiche: Record<string, boolean> = {};
    foerderbereiche.map(foerderbereich => {
        openFoerderbereiche[foerderbereich.bezeichnung] = false;
    })
    return openFoerderbereiche;
})

)
