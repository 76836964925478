import { createFeatureSelector, createSelector } from "@ngrx/store";
import { NachrichtenTyp, PostkorbStore } from "../../model/postkorb.model";
import { DEFAULT_DATE_STRING_CONFIG } from "../../model/data-table.model";

export const selectPostkorbStore = createFeatureSelector<PostkorbStore>('postkorbStore');

export const selectPostkorbStoreTable = createSelector(
    selectPostkorbStore,
    (postkorbStore) => postkorbStore.postkorbTable
);

export const selectPostkorbStoreTableContent = createSelector(
    selectPostkorbStoreTable,
    (postkorbTable) => {
        return postkorbTable.content.map((postkorbNachricht) => ({
            ...postkorbNachricht,
            zustellzeitpunkt: new Date(postkorbNachricht.zustellzeitpunkt).toLocaleString('de-DE', DEFAULT_DATE_STRING_CONFIG)
        }));
    });

export const selectPostkorbStoreTablePage = createSelector(
    selectPostkorbStoreTable,
    (postkorbTable) => postkorbTable.page
);

export const selectPostkorbStoreTableSize = createSelector(
    selectPostkorbStoreTable,
    (postkorbTable) => postkorbTable.size
);

export const selectPostkorbStoreTableSearchparameter = createSelector(
    selectPostkorbStoreTable,
    (postkorbTable) => postkorbTable.searchparameter
);

export const selectPostkorbStoreTableSort = createSelector(
    selectPostkorbStoreTable,
    (postkorbTable) => postkorbTable.sort
);

export const selectPostkorbStoreTableNachrichtenTypFilter = createSelector(
    selectPostkorbStoreTable,
    (postkorbTable) => postkorbTable.nachrichtenTypFilter
);

export const selectPostkorbStoreTableTotalElements = createSelector(
    selectPostkorbStoreTable,
    (postkorbTable) => postkorbTable.totalElements
);

export const selectPostkorbStoreTableTotalPages = createSelector(
    selectPostkorbStoreTable,
    (postkorbTable) => postkorbTable.totalPages
);

export const selectPostkorbStoreTableNumberOfElements = createSelector(
    selectPostkorbStoreTable,
    (postkorbTable) => postkorbTable.numberOfElements
);

export const selectPostkorbStoreTableIsLoading = createSelector(
    selectPostkorbStoreTable,
    (postkorbTable) => postkorbTable.isLoading
);

export const selectPostkorbStoreTableModifiers = createSelector(
    selectPostkorbStoreTablePage,
    selectPostkorbStoreTableSize,
    selectPostkorbStoreTableSearchparameter,
    selectPostkorbStoreTableSort,
    selectPostkorbStoreTableNachrichtenTypFilter,
    (page, size, search, sortingArray, nachrichtenTypFilter) => {
        const sort = sortingArray.map(sorting => sorting.column + ',' + sorting.sortDirection);
        let searchparameter: string | undefined = undefined;
        if (search !== '') {
            searchparameter = search;
        }
        const filter: Record<string, boolean> = {}
        if (nachrichtenTypFilter === NachrichtenTyp.allgemein) {
            filter['onlyShowAllgemeineNachrichten'] = true;
        } else if (nachrichtenTypFilter === NachrichtenTyp.unternehmen) {
            filter['hideAllgemeineNachrichten'] = true;
        }
        return { page, size, sort, searchparameter, filter };
    }
);

export const selectPostkorbStoreDisplayedPostkorbNachricht = createSelector(
    selectPostkorbStore,
    (postkorbStore) => postkorbStore.displayedPostkorbNachricht
);