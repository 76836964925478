import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import {
    allUnternehmenNutzerActions,
    nutzerHinzufuegenActions,
    nutzerRightManagementFoerderantragViewActions,
    nutzerRightManagementNutzerViewActions,
    nutzerRolleManagement,
} from './all-unternehmen-nutzer.actions';
import { catchError, exhaustMap, filter, map, of, switchMap, tap } from 'rxjs';
import { AllUnternehmenNutzerApiService } from '../../service/api/service/all-unternehmen-nutzer.service';
import { Store } from '@ngrx/store';
import { selectActiveUnternehmenId } from '../nutzer-unternehmen-selection/nutzer-unternehmen-selection.selectors';
import { selectFoerderbereicheAllFoerderbereiche } from '../foerderbereich/foerderbereich.selectors';
import {
    selectAllUnternehemenNutzerNewRights,
    selectAllUnternehemenNutzerNutzerId,
    selectAllUnternehemenNutzerRemoveRights,
    selectAllUnternehmenNutzerStore,
    selectAllUnternehemenNutzerIsFoerderbereichRightManagement,
    selectAllUnternehemenNutzerRemoveNutzer,
    selectAllUnternehemenNutzerAddNutzer,
    selectAllUnternehmenNutzerRolleManagementNewRolle,
    selectAllUnternehmenNutzerRolleManagementRolle,
    selectNewNutzerEmail,
    selectNewNutzerRolle,
} from './all-unternehmen-nutzer.selectors';
import { PortalRole } from '../../model/auth.model';
import { DialogHelperService } from '../../service/helper/dialog.helper.service';
import { MatDialogRef } from '@angular/material/dialog';
import { UnternehmenszuordnungApiService } from '../../service/api/service/unternehmenszuordnung-api.service';
import { BackendRole, NutzerRollen, PutUnternehmenszuordnungReqBody } from '../../model/all-unternehmen-nutzer.model';
import { TableAction } from '../../model/data-table.model';
import { NutzerFoerderbereicheApiService } from '../../service/api/service/nutzer-foerderbereiche.service';
import { RightManagementFoerderbereicheService } from '../../service/api/service/right-management-foerderbereiche.service';
import { GlobalExceptionHandlerService } from '../../service/api/service/global-exception-handler.service';
import { UnternehmenFoerderantraegeApiService } from '../../service/api/service/foerderantraege-unternehmen.service';
import { RightManagementFoerderantraegeService } from '../../service/api/service/right-management-foerderantraege.service';
import { RightManagementAlleAntraegeService } from '../../service/api/service/right-management-alleantraege.service';
import { CreateBearbeiterDto, ModifyBearbeiterWithMultipleNutzerDto } from '../../service/api/generated';
import { selectActiveNutzerEmail } from '../auth/auth.selectors';

@Injectable()
export class AllUnternehmenNutzerEffects {
    private actions$ = inject(Actions);
    private allUnternehmenNutzerService = inject(AllUnternehmenNutzerApiService);
    private store = inject(Store);
    private dialogHelperService = inject(DialogHelperService);
    private unternehmenszuordnungApiService = inject(UnternehmenszuordnungApiService);
    private nutzerFoerderbereicheService = inject(NutzerFoerderbereicheApiService);
    private rightManagementFoerderbereicheService = inject(RightManagementFoerderbereicheService);
    private globalExceptionHandlerService = inject(GlobalExceptionHandlerService);
    private unternehmenFoerderantraegeApiService = inject(UnternehmenFoerderantraegeApiService);
    private rightManagementFoerderantraegeService = inject(RightManagementFoerderantraegeService);
    private rightManagementAlleAntraegeService = inject(RightManagementAlleAntraegeService);

    getAllUnternehmenNutzer$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(allUnternehmenNutzerActions.changeAllUnternehmenNutzer),
            concatLatestFrom(() => [
                this.store.select(selectAllUnternehmenNutzerStore),
                this.store.select(selectActiveUnternehmenId),
                this.store.select(selectFoerderbereicheAllFoerderbereiche),
            ]),
            switchMap(([, allUnternehmenNutzerStore, selectedUnternehmenId, allFoerderbereiche]) => {
                return this.allUnternehmenNutzerService
                    .getAllUnternehmenNutzer(allUnternehmenNutzerStore, selectedUnternehmenId, allFoerderbereiche)
                    .pipe(
                        map((benutzerPage) =>
                            allUnternehmenNutzerActions.changeAllUnternehmenNutzerSuccess({ newAllUnternehmenNutzer: benutzerPage }),
                        ),
                        catchError((error) => {
                            this.globalExceptionHandlerService.handleGeneratedApiError(error);
                            return of(
                                allUnternehmenNutzerActions.changeAllUnternehmenNutzerSuccess({
                                    newAllUnternehmenNutzer: { allUnternehmenNutzer: [], totalElements: 0 },
                                }),
                            );
                        }),
                    );
            }),
        );
    });

    changeIsLoading$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                allUnternehmenNutzerActions.changePage,
                allUnternehmenNutzerActions.changeSize,
                allUnternehmenNutzerActions.changeSorting,
                allUnternehmenNutzerActions.changeSearchParameter,
                allUnternehmenNutzerActions.changeRoleFilter,
                allUnternehmenNutzerActions.changeActiveFilter,
                allUnternehmenNutzerActions.changeAllFoerderbereicheFilter,
                nutzerHinzufuegenActions.updateNewUserSuccess,
            ),
            exhaustMap(() => of(allUnternehmenNutzerActions.changeAllUnternehmenNutzer())),
        );
    });

    updateTableAction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(allUnternehmenNutzerActions.changeAllUnternehmenNutzerSuccess),
            concatLatestFrom(() => [this.store.select(selectActiveNutzerEmail)]),
            switchMap(([action, email]) => {
                const tableActions: TableAction[][] = [];

                for (const unternehmenNutzer of action.newAllUnternehmenNutzer.allUnternehmenNutzer) {
                    if (unternehmenNutzer.email === email) {
                        tableActions.push([{ name: 'Sie können sich nicht selbst verwalten', isLink: false, isButton: true }]);
                        continue;
                    }
                    const nutzerAction: TableAction[] = [];
                    if (unternehmenNutzer.role === PortalRole.COMPANY_USER) {
                        nutzerAction.push(this.dialogHelperService.createRechteVerwaltenAction(unternehmenNutzer));
                    }
                    nutzerAction.push(this.dialogHelperService.createRolleVerwaltenAction(unternehmenNutzer));
                    nutzerAction.push({
                        name: 'Benutzer*in aus dem Unternehmen entfernen',
                        isLink: false,
                        isDialog: true,
                        dialogData: {
                            title: 'Sind Sie sicher, dass Sie diese Entfernung aus dem Unternehmen durchführen wollen?',
                            actions: [
                                { title: 'Abbrechen', clickFunction: this.dialogHelperService.closeDialog },
                                {
                                    title: 'Entfernen',
                                    clickFunction: (dialogRef: MatDialogRef<unknown>): void =>
                                        this.dialogHelperService.removeUser(dialogRef, unternehmenNutzer.id),
                                },
                            ],
                        },
                    });
                    if (unternehmenNutzer.status) {
                        nutzerAction.push({
                            name: 'Benutzer*in auf inaktiv setzen',
                            isLink: false,
                            isDialog: true,
                            dialogData: {
                                title: 'Sind Sie sicher, dass Sie den Status auf inaktiv setzen wollen?',
                                actions: [
                                    { title: 'Abbrechen', clickFunction: this.dialogHelperService.closeDialog },
                                    {
                                        title: 'Benutzer*in auf inaktiv setzen',
                                        clickFunction: (dialogRef: MatDialogRef<unknown>): void =>
                                            this.dialogHelperService.changeUserStatus(dialogRef, unternehmenNutzer, false),
                                    },
                                ],
                            },
                        });
                    } else {
                        nutzerAction.push({
                            name: 'Benutzer*in auf aktiv setzen',
                            isLink: false,
                            isDialog: true,
                            dialogData: {
                                title: 'Sind Sie sicher, dass Sie den Status auf aktiv setzen wollen?',
                                actions: [
                                    { title: 'Abbrechen', clickFunction: this.dialogHelperService.closeDialog },
                                    {
                                        title: 'Benutzer*in auf aktiv setzen',
                                        clickFunction: (dialogRef: MatDialogRef<unknown>): void =>
                                            this.dialogHelperService.changeUserStatus(dialogRef, unternehmenNutzer, true),
                                    },
                                ],
                            },
                        });
                    }
                    tableActions.push(nutzerAction);
                }
                return of(allUnternehmenNutzerActions.setTableAction({ newTableAction: tableActions }));
            }),
        );
    });

    removeUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(allUnternehmenNutzerActions.updateRemoveUser),
            concatLatestFrom(() => this.store.select(selectActiveUnternehmenId)),
            exhaustMap(([action, unternehmenId]) => {
                return this.unternehmenszuordnungApiService.deleteNutzerUnternehmenZuordnung(unternehmenId, action.userId).pipe(
                    map(() => allUnternehmenNutzerActions.changeAllUnternehmenNutzer()),
                    catchError((error) => {
                        this.globalExceptionHandlerService.handleGeneratedApiError(error);
                        return of(allUnternehmenNutzerActions.changeAllUnternehmenNutzer());
                    }),
                );
            }),
        );
    });

    changeUserStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(allUnternehmenNutzerActions.changeUserStatus),
            concatLatestFrom(() => this.store.select(selectActiveUnternehmenId)),
            exhaustMap(([action, unternehmensId]) => {
                const requestBody: PutUnternehmenszuordnungReqBody = {
                    aktiv: action.status,
                    rolle: NutzerRollen[action.nutzer.role] as BackendRole,
                    nutzerIdpId: action.nutzer.id,
                };
                return this.unternehmenszuordnungApiService.putNutzerUnternehmenZuordnung(unternehmensId, requestBody).pipe(
                    map(() => allUnternehmenNutzerActions.changeAllUnternehmenNutzer()),
                    catchError((error) => {
                        this.globalExceptionHandlerService.handleGeneratedApiError(error);
                        return of(allUnternehmenNutzerActions.changeAllUnternehmenNutzer());
                    }),
                );
            }),
        );
    });

    // Right Management - Foerderantraege for Nutzer View

    getOtherNutzerFoerderbereichList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nutzerRightManagementFoerderantragViewActions.changeNutzerFoerderbereichList),
            concatLatestFrom(() => this.store.select(selectActiveUnternehmenId)),
            switchMap(([action, unternehmenId]) => {
                return this.nutzerFoerderbereicheService.getOtherNutzerFoerderbereiche(unternehmenId, action.nutzerId).pipe(
                    map((nutzerFoerderbereichList) =>
                        nutzerRightManagementFoerderantragViewActions.setNutzerFoerderbereichList({ nutzerFoerderbereichList }),
                    ),
                    catchError((error) => {
                        this.globalExceptionHandlerService.handleGeneratedApiError(error);
                        return of(nutzerRightManagementFoerderantragViewActions.setNutzerFoerderbereichList({ nutzerFoerderbereichList: [] }));
                    }),
                );
            }),
        );
    });

    updateNutzerRights$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nutzerRightManagementFoerderantragViewActions.updateNutzerRights),
            concatLatestFrom(() => [
                this.store.select(selectActiveUnternehmenId),
                this.store.select(selectAllUnternehemenNutzerNewRights),
                this.store.select(selectAllUnternehemenNutzerRemoveRights),
                this.store.select(selectAllUnternehemenNutzerIsFoerderbereichRightManagement),
            ]),
            switchMap(([action, unternehmenId, newRights, removeRights, isFoerderbereichRightManagement]) => {
                if (isFoerderbereichRightManagement) {
                    if (newRights.length > 0) {
                        this.rightManagementFoerderbereicheService
                            .postBeantragungserlaubnis(action.nutzerId, unternehmenId, newRights)
                            .pipe(
                                catchError((error) => {
                                    this.globalExceptionHandlerService.handleGeneratedApiError(error);
                                    return of(null);
                                }),
                            )
                            .subscribe();
                    }
                    if (removeRights.length > 0) {
                        this.rightManagementFoerderbereicheService
                            .deleteBeantragungserlaubnis(action.nutzerId, unternehmenId, removeRights)
                            .pipe(
                                catchError((error) => {
                                    this.globalExceptionHandlerService.handleGeneratedApiError(error);
                                    return of(null);
                                }),
                            )
                            .subscribe();
                    }
                } else {
                    if (newRights.length > 0) {
                        const requestBody: CreateBearbeiterDto = { nutzerIdpId: action.nutzerId, foerderantragIds: newRights };
                        this.rightManagementFoerderantraegeService.postBearbeiterUnternehmen(unternehmenId, requestBody).subscribe();
                    }
                    if (removeRights.length > 0) {
                        this.rightManagementFoerderantraegeService
                            .deleteBearbeiterUnternehmen(unternehmenId, action.nutzerId, removeRights)
                            .subscribe();
                    }
                }
                return of(nutzerRightManagementFoerderantragViewActions.resetRightLists());
            }),
        );
    });

    updateNutzerFoerderantraegeList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nutzerRightManagementFoerderantragViewActions.updateNutzerFoerderantraegeList),
            concatLatestFrom(() => [this.store.select(selectActiveUnternehmenId), this.store.select(selectAllUnternehemenNutzerNutzerId)]),
            switchMap(([, unternehmenId, nutzerId]) => {
                return this.unternehmenFoerderantraegeApiService
                    .getFoerderantrageForNutzerInUnternehmen(unternehmenId, nutzerId)
                    .pipe(
                        map((foerderantraege) =>
                            nutzerRightManagementFoerderantragViewActions.setNutzerFoerderantraegeList({ newNutzerFoerderantraege: foerderantraege }),
                        ),
                    );
            }),
        );
    });

    setChangesEffect$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(nutzerRightManagementFoerderantragViewActions.changeRightId),
                concatLatestFrom(() => [
                    this.store.select(selectAllUnternehemenNutzerNewRights),
                    this.store.select(selectAllUnternehemenNutzerRemoveRights),
                ]),
                map(([, newRights, removeRights]) => {
                    // Wenn newRights oder removedRights geändert wurden, setzen wir hasChanges auf true
                    if (newRights.length === 0 && removeRights.length === 0) {
                        this.dialogHelperService.setChangesMade(false);
                    } else {
                        this.dialogHelperService.setChangesMade(true);
                    }
                }),
            );
        },
        { dispatch: false },
    );

    resetChangesEffect$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(nutzerRightManagementFoerderantragViewActions.resetRightLists),
                map(() => {
                    // Wenn die Rechte zurückgesetzt werden, setzen wir hasChanges auf false
                    this.dialogHelperService.setChangesMade(false);
                }),
            );
        },
        { dispatch: false },
    );

    // Right Management - Nutzer for Foerderantrag View

    updateNutzerBearbeiter$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nutzerRightManagementNutzerViewActions.updateNutzerBearbeiter),
            concatLatestFrom(() => [
                this.store.select(selectActiveUnternehmenId),
                this.store.select(selectAllUnternehemenNutzerAddNutzer),
                this.store.select(selectAllUnternehemenNutzerRemoveNutzer),
            ]),
            switchMap(([action, unternehmenId, newNutzer, removedNutzer]) => {
                if (newNutzer.length > 0) {
                    const requestBody: ModifyBearbeiterWithMultipleNutzerDto = { nutzerIdpIds: newNutzer, foerderantragId: action.foerderantragId };
                    this.rightManagementAlleAntraegeService.postBearbeiterUnternehmenForFoerderantrag(unternehmenId, requestBody).subscribe();
                }
                if (removedNutzer.length > 0) {
                    const requestBody: ModifyBearbeiterWithMultipleNutzerDto = {
                        nutzerIdpIds: removedNutzer,
                        foerderantragId: action.foerderantragId,
                    };
                    this.rightManagementAlleAntraegeService.deleteBearbeiterForFoerderantrag(unternehmenId, requestBody).subscribe();
                }
                return of(nutzerRightManagementNutzerViewActions.resetNutzerLists());
            }),
        );
    });

    updateFoerderantragNutzerList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nutzerRightManagementNutzerViewActions.updateFoerderantragNutzerList),
            concatLatestFrom(() => [this.store.select(selectActiveUnternehmenId)]),
            switchMap(([action, unternehmenId]) => {
                return this.rightManagementAlleAntraegeService.getBearbeiterForFoerderantrag(unternehmenId, action.foerderantragId).pipe(
                    map((nutzer) => nutzerRightManagementNutzerViewActions.setFoerderantragNutzerList({ newFoerderantragNutzerList: nutzer })),
                    catchError((error) => {
                        this.globalExceptionHandlerService.handleGeneratedApiError(error);
                        return of(nutzerRightManagementNutzerViewActions.setFoerderantragNutzerList({ newFoerderantragNutzerList: [] }));
                    }),
                );
            }),
        );
    });

    setChangesNutzerViewEffect$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(nutzerRightManagementNutzerViewActions.changeNutzerId),
                concatLatestFrom(() => [
                    this.store.select(selectAllUnternehemenNutzerAddNutzer),
                    this.store.select(selectAllUnternehemenNutzerRemoveNutzer),
                ]),
                map(([, newNutzer, removedNutzer]) => {
                    // Wenn newRights oder removedRights geändert wurden, setzen wir hasChanges auf true
                    if (newNutzer.length === 0 && removedNutzer.length === 0) {
                        this.dialogHelperService.setChangesMade(false);
                    } else {
                        this.dialogHelperService.setChangesMade(true);
                    }
                }),
            );
        },
        { dispatch: false },
    );

    resetNutzerChangesEffect$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(nutzerRightManagementNutzerViewActions.resetNutzerLists),
                map(() => {
                    this.dialogHelperService.setChangesMade(false);
                }),
            );
        },
        { dispatch: false },
    );

    updateNutzerRolle$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nutzerRolleManagement.changeRolle),
            concatLatestFrom(() => [
                this.store.select(selectActiveUnternehmenId),
                this.store.select(selectAllUnternehmenNutzerRolleManagementNewRolle),
            ]),
            switchMap(([action, unternehmenId, newRolle]) => {
                if (newRolle === PortalRole.COMPANY_SELECT) {
                    return of(nutzerRolleManagement.resetRolleManagement());
                }
                return this.unternehmenszuordnungApiService
                    .putNutzerUnternehmenZuordnung(unternehmenId, {
                        nutzerIdpId: action.nutzer.id,
                        rolle: NutzerRollen[newRolle],
                        aktiv: action.nutzer.status,
                    })
                    .pipe(
                        map(() => allUnternehmenNutzerActions.changeAllUnternehmenNutzer()),
                        catchError((error) => {
                            this.globalExceptionHandlerService.handleGeneratedApiError(error);
                            return of(nutzerRolleManagement.resetRolleManagement());
                        }),
                    );
            }),
        );
    });

    changeNutzerRolle$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(nutzerRolleManagement.changeNewRolle),
                concatLatestFrom(() => [this.store.select(selectAllUnternehmenNutzerRolleManagementRolle)]),
                tap(([action, rolle]) => {
                    if (action.newRolle !== rolle) {
                        this.dialogHelperService.setChangesMade(true);
                    } else {
                        this.dialogHelperService.setChangesMade(false);
                    }
                }),
            );
        },
        { dispatch: false },
    );

    // Nutzer hinzufuegen Dialog

    updateNewNutzer$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nutzerHinzufuegenActions.updateNewNutzer),
            concatLatestFrom(() => [
                this.store.select(selectActiveUnternehmenId),
                this.store.select(selectNewNutzerEmail),
                this.store.select(selectNewNutzerRolle),
            ]),
            switchMap(([, unternehmenId, email, rolle]) => {
                return this.unternehmenszuordnungApiService
                    .postUnternehmenszuordnungen(unternehmenId, {
                        email: email,
                        rolle: NutzerRollen[rolle] as unknown as BackendRole,
                        aktiv: true,
                    })
                    .pipe(
                        map(() => {
                            return nutzerHinzufuegenActions.updateNewUserSuccess();
                        }),
                    );
            }),
        );
    });

    updateNewUserSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nutzerHinzufuegenActions.updateNewUserSuccess),
            concatLatestFrom(() => [
                this.store.select(selectActiveUnternehmenId),
                this.store.select(selectNewNutzerEmail),
                this.store.select(selectNewNutzerRolle),
            ]),
            filter(([, , , rolle]) => rolle === PortalRole.COMPANY_USER),
            switchMap(([, selectedUnternehmenId, email]) => {
                return this.allUnternehmenNutzerService.getNewNutzer(selectedUnternehmenId, email).pipe(
                    map((nutzer) => {
                        this.dialogHelperService.openNewDialog(this.dialogHelperService.createFoerderbereichDialogData(nutzer));
                        return nutzerHinzufuegenActions.resetNewNutzer();
                    }),
                );
            }),
        );
    });
}
