<div class="lesebestaetigung-setzen-dialog">
    <div *ngIf="(isLoading$ | async) === false; else isLoading">
        <pdf-viewer
            [src]="dokumentURL"
            [page]="page"
            [render-text]="true"
            [show-all]="false"
            [original-size]="true"
            class="lesebestaetigung-setzen-dialog__pdf-viewer"
            (after-load-complete)="afterLoadCompleteHandler($event)"></pdf-viewer>
        <div
            *ngIf="dokumentURL !== '' && totalPages > 1"
            class="lesebestaetigung-setzen-dialog__page-turner">
            <button
                (click)="prevPage()"
                [disabled]="page <= 1">
                < Vorherige Seite
            </button>
            <button
                (click)="nextPage()"
                [disabled]="page >= totalPages">
                Nächste Seite >
            </button>
        </div>
    </div>
</div>
<ng-template #isLoading>
    <div class="lesebestaetigung-setzen-dialog--loading">
        <mat-spinner></mat-spinner>
        <p>Das Dokment wird geladen</p>
    </div>
</ng-template>
