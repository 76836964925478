import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { MatExpansionModule } from '@angular/material/expansion';
import { AsyncPipe } from '@angular/common';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';

import { StartComponent } from './page/start/start.component';
import { PostkorbComponent } from './page/postkorb/postkorb.component';
import { appRoutes } from './model/app-routing.model';
import { AllAntraegeComponent } from './page/all-antraege/all-antraege.component';
import { PageNotFoundComponent } from './page/page-not-found/page-not-found.component';
import { UnternehmenAdministrationComponent } from './page/unternehmen-administration/unternehmen-administration.component';
import { AllUnternehmenNutzerComponent } from './page/all-unternehmen-nutzer/all-unternehmen-nutzer.component';
import { LoginComponent } from './page/start/login/login.component';
import { LogoutComponent } from './page/logout/logout.component';
import { AntragsDetailPageComponent } from './page/antrags-detail-page/antrags-detail-page.component';
import { AntragsDetailTabComponent } from './page/antrags-detail-page/antrags-detail-tab/antrags-detail-tab.component';
import { AntragCreationComponent } from './page/antrag-creation/antrag-creation.component';
import { LoginUnternehmenSelectionComponent } from './page/start/login/login-unternehmen-selection/login-unternehmen-selection.component';
import { LayoutModule } from './layout/layout.module';
import { UiModule } from './ui/ui.module';
import { NebenantragListTabComponent } from './page/antrags-detail-page/nebenantrag-list-tab/nebenantrag-list-tab.component';
import { NebenantragsDetailPageComponent } from './page/nebenantrags-detail-page/nebenantrags-detail-page.component';
import { CustomAmplifySelectorComponent } from './page/start/login/amplify-selection/custom-amplify-selector.component';
import { CustomAmplifyPasswordFieldComponent } from './page/start/login/amplify-password-field/custom-amplify-password-field.component';
import { CustomAmplifyPhoneNumberFieldComponent } from './page/start/login/amplify-phone-number-field/custom-amplify-phone-number-field.component';
import { HistoryTabComponent } from './page/antrags-detail-page/history-tab/history-tab.component';
import { FormularIframeComponent } from './page/formular-iframe/formular-iframe.component';
import { SafeFormularUrlPipe } from './service/pipe/safe-formular-url.pipe';
import { UploadProgressComponent } from './page/formular-iframe/upload-progress/upload-progress.component';
import { DokumenteViewTabComponent } from './page/antrags-detail-page/dokumente-view-tab/dokumente-view-tab.component';
import { StammdatenComponent } from './page/stammdaten/stammdaten.component';
import { ImpressumComponent } from './page/impressum/impressum.component';
import { NutzerProfileComponent } from './page/nutzer-profile/nutzer-profile.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordDialogComponent } from './page/nutzer-profile/change-password-dialog/change-password-dialog.component';

@NgModule({
    declarations: [
        StartComponent,
        PostkorbComponent,
        PageNotFoundComponent,
        AllAntraegeComponent,
        UnternehmenAdministrationComponent,
        AllUnternehmenNutzerComponent,
        LoginComponent,
        LogoutComponent,
        AntragsDetailPageComponent,
        AntragsDetailTabComponent,
        NebenantragListTabComponent,
        DokumenteViewTabComponent,
        HistoryTabComponent,
        AntragCreationComponent,
        LoginUnternehmenSelectionComponent,
        NebenantragsDetailPageComponent,
        CustomAmplifySelectorComponent,
        CustomAmplifyPasswordFieldComponent,
        CustomAmplifyPhoneNumberFieldComponent,
        FormularIframeComponent,
        SafeFormularUrlPipe,
        UploadProgressComponent,
        StammdatenComponent,
        ImpressumComponent,
        NutzerProfileComponent,
        ChangePasswordDialogComponent,
    ],
    imports: [
        RouterModule.forRoot(appRoutes),
        UiModule,
        AmplifyAuthenticatorModule,
        NgxTippyModule,
        AsyncPipe,
        MatTabsModule,
        MatExpansionModule,
        BrowserModule,
        LayoutModule,
        ReactiveFormsModule,
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
