import { createReducer, on } from "@ngrx/store";
import { DokumenteUnternehmenStore } from "../../model/dokumente-unternehmen.model";
import { dokumentContentActions, dokumenteUnternehmenActions } from "./dokumente-unternehmen.actions";
import { SortDir, SortParameter } from "../../model/store.model";
import { authActions } from "../auth/auth.actions";

export const dokumenteUnternehmenStoreInitialState: DokumenteUnternehmenStore = {
    dataTable: {
        data: [],
        totalElements: 0,
        dokumentenFilter: 'Alle',
        isLoading: false,
        sortingArray: [{ column: 'dokument.erstelltAm', sortDirection: SortDir.desc }],
        size: 20,
        page: 0,
        searchParameter: "",
        tableActions: []
    },
    dokumentContent: {
        isLoading: false,
        dokumentURL: '',
    }
};

export const dokumenteUnternehmenReducer = createReducer(
    dokumenteUnternehmenStoreInitialState,
    on(dokumenteUnternehmenActions.changeDokumenteUnternehmenPage, (state): DokumenteUnternehmenStore => {
        window.URL.revokeObjectURL(state.dokumentContent.dokumentURL);
        return { ...state, dataTable: { ...state.dataTable, isLoading: true }, dokumentContent: { ...dokumenteUnternehmenStoreInitialState.dokumentContent } }
    }),
    on(dokumenteUnternehmenActions.setDokumenteUnternehmenPage, (state, action): DokumenteUnternehmenStore => ({
        ...state,
        dataTable: {
            ...state.dataTable,
            data: action.newDokumenteUnternehmenPage.content,
            totalElements: action.newDokumenteUnternehmenPage.totalElements,
            tableActions: action.newTableActions,
            isLoading: false
        }
    })),
    on(dokumenteUnternehmenActions.changeTableSearchParameter, (state, action): DokumenteUnternehmenStore => ({ ...state, dataTable: { ...state.dataTable, searchParameter: action.newSearchParameter, page: 0 } })),
    on(dokumenteUnternehmenActions.changeTableDokumenteFilter, (state, action): DokumenteUnternehmenStore => ({ ...state, dataTable: { ...state.dataTable, dokumentenFilter: action.newDokumenteFilter, page: 0 } })),
    on(dokumenteUnternehmenActions.changeTableSort, (state, action): DokumenteUnternehmenStore => {
        let sorting: SortParameter[];
        if (action.isMultiSort) {
            sorting = [...state.dataTable.sortingArray];
            sorting.push(action.newSortingParameter);
        } else {
            sorting = [action.newSortingParameter];
        }
        return {
            ...state, dataTable: { ...state.dataTable, sortingArray: sorting, page: 0 }
        };
    }),
    on(dokumenteUnternehmenActions.changeTableSize, (state, action): DokumenteUnternehmenStore => ({ ...state, dataTable: { ...state.dataTable, size: action.newSize, page: 0 } })),
    on(dokumenteUnternehmenActions.changeTablePage, (state, action): DokumenteUnternehmenStore => ({ ...state, dataTable: { ...state.dataTable, page: action.newPage } })),
    on(dokumenteUnternehmenActions.resetTable, (state): DokumenteUnternehmenStore => ({ ...state, dataTable: { ...dokumenteUnternehmenStoreInitialState.dataTable } })),
    on(dokumentContentActions.changeDokumentContent, (state): DokumenteUnternehmenStore => ({ ...state, dokumentContent: { ...state.dokumentContent, isLoading: true } })),
    on(dokumentContentActions.setDokumentContent, (state, action): DokumenteUnternehmenStore => {
        window.URL.revokeObjectURL(state.dokumentContent.dokumentURL);
        return { ...state, dokumentContent: { isLoading: false, dokumentURL: action.dokumentURL } }
    }),
    on(dokumentContentActions.resetDokumentContent, (state): DokumenteUnternehmenStore => {
        window.URL.revokeObjectURL(state.dokumentContent.dokumentURL);
        return { ...state, dokumentContent: { ...dokumenteUnternehmenStoreInitialState.dokumentContent } }
    }),
    on(dokumentContentActions.changeBescheidLesebestaetigung, (state): DokumenteUnternehmenStore => ({ ...state, dokumentContent: { ...state.dokumentContent, isLoading: false } })),
    on(authActions.changeUserToLoggedOut, (): DokumenteUnternehmenStore => ({ ...dokumenteUnternehmenStoreInitialState })),
    on(dokumentContentActions.updateDownloadAntragDokument, (state): DokumenteUnternehmenStore =>
        ({ ...state, dokumentContent: { ...state.dokumentContent, isLoading: true } })),
    on(dokumentContentActions.changeHraOrGewerbescheinDownload, (state): DokumenteUnternehmenStore =>
        ({ ...state, dokumentContent: { ...state.dokumentContent, isLoading: true } })),
);