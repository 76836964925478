import { Component, inject, OnInit } from '@angular/core';
import { nutzerHinzufuegenActions } from '../../../store/all-unternehmen-nutzer/all-unternehmen-nutzer.actions';
import { Store } from '@ngrx/store';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { PortalRole } from '../../../model/auth.model';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';

export class EmailErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form?.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'app-add-new-benutzer',
    templateUrl: './add-new-benutzer.component.html',
    styleUrl: './add-new-benutzer.component.scss',
})
export class AddNewBenutzerComponent implements OnInit {
    private store = inject(Store);

    emailFormControl = new FormControl('', [Validators.required, Validators.email]);
    matcher = new EmailErrorStateMatcher();

    ngOnInit(): void {
        this.emailFormControl.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
            this.store.dispatch(nutzerHinzufuegenActions.setSelectedEmail({ email: value ?? '' }));
        });
    }

    onToggleChange(event: MatButtonToggleChange): void {
        this.store.dispatch(nutzerHinzufuegenActions.setSelectedRolle({ rolle: event.value }));
    }

    protected readonly PortalRole = PortalRole;
}
