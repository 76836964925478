import { createReducer, on } from "@ngrx/store";
import { ErrorStore, NOERROR } from "../../model/error-store.model";
import { errorActions } from "./error.actions";
import { authActions } from "../auth/auth.actions";



export const errorInitialState: ErrorStore = {
    shownError: NOERROR,
    hasError: false,
    errorHistory: [],
    logList: [],
    logTimer: 0,
};

export const errorReducer = createReducer(
    errorInitialState,
    on(errorActions.setLog, (state, action): ErrorStore => ({ ...state, logList: [...state.logList, action.logMessage] })),
    on(errorActions.setShownError, (state, action): ErrorStore => {
        const errorHistory = [...state.errorHistory, action.newError];
        const logList = [...state.logList, action.logMessage];
        return { ...state, shownError: action.newError, hasError: true, errorHistory, logList }
    }),
    on(errorActions.resetErrorStore, (): ErrorStore => ({ ...errorInitialState })),
    on(errorActions.resetShownError, (state): ErrorStore => ({ ...state, shownError: NOERROR, hasError: false })),
    on(authActions.changeUserToLoggedOut, (): ErrorStore => ({ ...errorInitialState })),
);