import { Foerderbereich } from "../../../../model/foerderbereich.model";
import { FoerderbereichDto } from "../../generated";

/**
 * Maps the generated FoerderbereichDto[] to the Foerderbereich[] that is used in the store
 * @param foerderbereicheExt the API returned foerderbeiche array
 * @returns returns the store friendly foerderbereiche array
 */
export const mapFoerderbereiche = (foerderbereicheExt: FoerderbereichDto[]): Foerderbereich[] => {
    const newFoerderbereicheList: Foerderbereich[] = [];
    for (const foerderbereichExt of foerderbereicheExt) {
        newFoerderbereicheList.push({ bezeichnung: foerderbereichExt.bezeichnung, id: foerderbereichExt.id });
    }
    return newFoerderbereicheList;
}
