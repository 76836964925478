<div class="logout-snack-bar">
    <ng-container>
        <div class="logout-snack-bar__content">
            <div class="logout-snack-bar__content__message">
                <h1 class="text-h2">Ihre Sitzung ist abgelaufen.</h1>
                <p class="text-plain">Bitte melden Sie sich erneut an.</p>
            </div>
            <app-button
                class="logout-snack-bar__content__logout-button"
                [isButton]="true"
                buttonLabel="Erneut anmelden"
                (clickEvent)="logout()"></app-button>
        </div>
    </ng-container>
</div>
