import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FoerderantraegeStore, Foerderantrag } from '../../model/foerderantraege.model';
import { selectRole } from '../auth/auth.selectors';
import { DEFAULT_DATE_STRING_CONFIG, DISPLAYSTATUS } from '../../model/data-table.model';

export const selectFoerderantraegeStore = createFeatureSelector<FoerderantraegeStore>('foerderantraegeStore');

export const selectFoerderantraegeStoreComplete = createSelector(selectFoerderantraegeStore, (foerderantraegeStore) => foerderantraegeStore);

export const selectFoerderantraegeStoreAllFoerderantraege = createSelector(
    selectFoerderantraegeStore,
    (foerderantraegeStore) => foerderantraegeStore.allFoerderantraege,
);

//changes the date string and the statusString to the displayed versions
export const selectFoerderantraegeStoreAllFoerderantraegeContent = createSelector(
    selectFoerderantraegeStoreAllFoerderantraege,
    (allFoerderantraege) => {
        const content: Foerderantrag[] = [];
        if (allFoerderantraege?.content) {
            allFoerderantraege.content.map((foerderantrag) => {
                content.push({
                    ...foerderantrag,
                    aktualisiertAm: new Date(foerderantrag.aktualisiertAm).toLocaleString('de-DE', DEFAULT_DATE_STRING_CONFIG),
                    statusString: DISPLAYSTATUS[foerderantrag.status],
                });
            });
        }
        return content;
    },
);

export const selectFoerderantraegeStoreAllFoerderantraegeTotalElements = createSelector(
    selectFoerderantraegeStoreAllFoerderantraege,
    (allFoerderantraege) => (allFoerderantraege ? allFoerderantraege.totalElements : 0),
);

export const selectFoerderantraegeStoreTablePage = createSelector(selectFoerderantraegeStore, (foerderantraegeStore) => foerderantraegeStore.page);

export const selectFoerderantraegeStoreTableSize = createSelector(selectFoerderantraegeStore, (foerderantraegeStore) => foerderantraegeStore.size);

export const selectFoerderantraegeStoreTableSortingArray = createSelector(
    selectFoerderantraegeStore,
    (foerderantraegeStore) => foerderantraegeStore.sortingArray.map((sortParameter) => {
        const sortParameterCopy = { ...sortParameter }
        if (sortParameterCopy.column === 'status') {
            sortParameterCopy.column = 'statusString';
        }
        return sortParameterCopy;
    })
);

export const selectFoerderantraegeStoreTableIsLoading = createSelector(selectFoerderantraegeStore, (foerderantraegeStore) => foerderantraegeStore.isLoading,);

export const selectFoerderantraegeStoreTableActions = createSelector(selectFoerderantraegeStore, (foerderantraegeStore) =>
    foerderantraegeStore.tableActions.map((tableAction) => [
        ...tableAction.map((action) => ({
            ...action,
            link: action.link ? [...action.link] : undefined
        }))
    ]),
);

export const selectFoerderartenInUse = createSelector(selectFoerderantraegeStore, (foerderantraegeStore) => {
    return foerderantraegeStore.foerderartenInUse;
});

export const selectFoerderartenBezeichnungInUse = createSelector(selectFoerderartenInUse, (foerderartenInUse) => {
    return foerderartenInUse.map((foerderart) => foerderart.bezeichnung);
});

export const selectDisplayedDetailsFoerderantrag = createSelector(
    selectFoerderantraegeStore,
    (foerderantraegeStore) => foerderantraegeStore.displayedDetailsFoerderantrag,
);

export const selectDisplayedDetailsFoerderantragProjektname = createSelector(selectDisplayedDetailsFoerderantrag, (foerderantrag) => {
    return foerderantrag ? foerderantrag.projektname : '';
});

export const selectDisplayedDetailsFoerderantragFoerderantragsId = createSelector(selectDisplayedDetailsFoerderantrag, (foerderantrag) => {
    return foerderantrag ? foerderantrag.id : 0;
});

export const selectDisplayedDetailsFoerderantragFoerderantragsStatus = createSelector(selectDisplayedDetailsFoerderantrag, (foerderantrag) => {
    return foerderantrag ? foerderantrag.status : null;
});

export const selectDisplayedDetailsFoerderantragFoerderantragsTabActionButtonModifiers = createSelector(
    selectRole,
    selectDisplayedDetailsFoerderantrag,
    (userRole, foerderantrag) => {
        return { userRole, foerderantrag };
    },
);

export const selectDisplayedDetailsFoerderantragFoerderantragsIdAndStatus = createSelector(selectDisplayedDetailsFoerderantrag, (foerderantrag) => {
    return foerderantrag ? { foerderantragId: foerderantrag.id, foerderantragStatus: foerderantrag.status } : { foerderantragId: 0, foerderantragStatus: null };
});
