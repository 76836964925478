import { inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { dokumentContentActions } from "../../store/dokumente-unternehmen/dokumente-unternehmen.actions";
import { DialogHelperService } from "./dialog.helper.service";

/**
 * Helper service for dokument handling
 */
@Injectable({
    providedIn: 'root'
})
export class DokumenteHelperService {
    private store = inject(Store);
    private dialogHelperService = inject(DialogHelperService);

    /**
     * Triggers the download of a dokument from an action
     * @param dokumentId The id of the dokument to download
     */
    getDokumentActionHandler(dokumentId: number, directDownload: boolean): void {
        this.openDownloadSpinner();
        this.store.dispatch(dokumentContentActions.changeDokumentContent({ dokumentId, directDownload }));
    }

    /**
     * The function that gets the data of the dokument and creates and triggers the download
     * @param dokumentContent The content of the dokument
     * @param dokumentName The name of the dokument
     */
    downloadDokument(dokumentContent: Blob, dokumentName: string): void {
        const url = this.createDokumentObjectURL(dokumentContent);
        const link = document.createElement('a');
        link.href = url;
        link.download = dokumentName;
        link.click();
        window.URL.revokeObjectURL(url);
    }

    /**
     * Creates the object url for the dokument
     * @param dokumentContent The content of the dokument
     * @returns The object url for the dokument
     */
    createDokumentObjectURL(dokumentContent: Blob): string {
        return window.URL.createObjectURL(dokumentContent);
    }

    /**
     * Opens the download spinner
     */
    openDownloadSpinner(): void {
        this.dialogHelperService.openNewDialog({
            title: "Download wird vorbereitet",
            actions: [],
            contentTemplateName: 'downloadSpinnerTemplate',
            disableBackdropClick: true,
        });
    }
}