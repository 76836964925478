<div class="snack-bar">
    <ng-container *ngIf="isError$ | async; else successSnackBar">
        <span
            matSnackBarLabel
            *ngIf="data$ | async; let error"
            data-testid="snack-bar-error">
            <h1 class="text-text-light">Es ist zu einem Fehler der folgenden Art gekommen: "{{ error.title }}"</h1>
            <p class="text-text-light">{{ error.detail }}</p>
            <p
                class="text-text-light"
                *ngIf="error.possibleSolution">
                {{ error.possibleSolution }}
            </p>
            <ng-container *ngFor="let solutionHint of error.possibleSolutionServerSend">
                <p class="text-text-light">
                    {{ solutionHint }}
                </p>
            </ng-container>
        </span>
        <span matSnackBarActions>
            <button
                matSnackBarAction
                (click)="close()">
                <mat-icon
                    class="text-text-light"
                    fontIcon="close"></mat-icon>
            </button>
        </span>
    </ng-container>
    <ng-template #successSnackBar>
        <span
            matSnackBarLabel
            *ngIf="data$ | async; let error"
            data-testid="snack-bar-success">
            <h1 class="text-accent-primary">Es wurde erfolgreich ein Retry beim {{ error.title }} durchgeführt</h1>
        </span>
        <span matSnackBarActions>
            <button
                matSnackBarAction
                (click)="close()">
                <mat-icon
                    class="text-text-dark"
                    fontIcon="close"></mat-icon>
            </button>
        </span>
    </ng-template>
</div>
