import { Adresse } from "../../../../model/stammdaten.model";
import { AdresseDto } from "../../generated";

/**
 * Map the returned DTO adress to our adresse type to and add if it is a german adress as field
 * @param adresse the dto from the backend
 * @returns the type we use in the frontend
 */
export const mapAdresse = (adresse: AdresseDto): Adresse => {
    if (adresse.land === 'Deutschland' || adresse.land === 'DEU') {
        return {
            id: adresse.id,
            strasse: adresse.strasse,
            hausnummer: adresse.hausnummer,
            plz: adresse.plz,
            ort: adresse.ort,
            land: adresse.land,
            zusatz: adresse.zusatz,
            bundesland: adresse.bundesland,
            isDeutscheAdresse: true,
        };
    } else {
        return {
            id: adresse.id,
            land: adresse.land,
            anschriftFreitext: adresse.anschriftFreitext,
            isDeutscheAdresse: false,
        }
    }
}