import { inject, Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { postkorbActions } from "./postkorb.actions";
import { catchError, exhaustMap, map, Observable, of, switchMap, tap } from "rxjs";
import { Store } from "@ngrx/store";
import { selectActiveUnternehmenId } from "../nutzer-unternehmen-selection/nutzer-unternehmen-selection.selectors";
import { PostkorbUnternehmenApiService } from "../../service/api/service/postkorb-unternehmen.service";
import { selectPostkorbStoreTableModifiers } from "./postkorb.selectors";
import { DialogHelperService } from "../../service/helper/dialog.helper.service";
import { DialogActionClickFunction, DialogData } from "../../model/dialog.model";
import { MatDialogRef } from "@angular/material/dialog";
import { GlobalExceptionHandlerService } from "../../service/api/service/global-exception-handler.service";
import { PagePostkorbNachricht } from "../../model/postkorb.model";
import { PostkorbCommonApiService } from "../../service/api/service/postkorb-common.service";

@Injectable({
    providedIn: 'root'
})
export class PostkorbEffect {
    private store = inject(Store)
    private actions$ = inject(Actions);
    private postkorbUnternehmenApiService = inject(PostkorbUnternehmenApiService);
    private postkorbCommonApiService = inject(PostkorbCommonApiService);
    private dialogHelperService = inject(DialogHelperService);
    private globalExceptionHandlerService = inject(GlobalExceptionHandlerService);

    changePagePostkorbNachricht$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(postkorbActions.changePagePostkorbNachricht),
            concatLatestFrom(() => [this.store.select(selectActiveUnternehmenId), this.store.select(selectPostkorbStoreTableModifiers)]),
            exhaustMap(([, unternehmenId, tableModifers]) => {
                let resultStream: Observable<PagePostkorbNachricht>;
                if (unternehmenId === 0) {
                    resultStream = this.postkorbCommonApiService.getAllInboxMessages(tableModifers.page, tableModifers.size, tableModifers.sort, tableModifers.searchparameter);
                } else {
                    resultStream = this.postkorbUnternehmenApiService.getAllInboxMessages(unternehmenId, tableModifers.page, tableModifers.size, tableModifers.sort, tableModifers.filter, tableModifers.searchparameter);
                }
                return resultStream.pipe(
                    map((newPagePostkorbNachricht) => postkorbActions.setPagePostkorbNachrichten({ newPagePostkorbNachricht })),
                    catchError((error) => {
                        this.globalExceptionHandlerService.handleGeneratedApiError(error);
                        return of(postkorbActions.setPagePostkorbNachrichtenFailed())
                    })
                )
            })
        )
    });

    triggerChangePagePostkorbNachricht$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                postkorbActions.changePostkorbTablePage,
                postkorbActions.changePostkorbTableSize,
                postkorbActions.changePostkorbTableSearchparameter,
                postkorbActions.changePostkorbTableSort,
                postkorbActions.changePostkorbTableNachrichtenTypFilter,
            ),
            exhaustMap(() => of(postkorbActions.changePagePostkorbNachricht())),
        )
    });

    updateGelesen$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(postkorbActions.updatePostkorbNachrichtGelesen),
            concatLatestFrom(() => this.store.select(selectActiveUnternehmenId)),
            switchMap(([{ id, gelesen }, unternehmenId]) => {
                let resultStream: Observable<boolean>;
                if (unternehmenId === 0) {
                    resultStream = this.postkorbCommonApiService.updateGelesenStatus(id, gelesen);
                } else {
                    resultStream = this.postkorbUnternehmenApiService.updateGelesenStatus(id, unternehmenId, gelesen)
                }
                return resultStream.pipe(
                    map((response) => {
                        if (response) {
                            return postkorbActions.changePagePostkorbNachricht();
                        } else {
                            return postkorbActions.setPagePostkorbNachrichtenFailed();
                        }
                    }),
                    catchError((error) => {
                        this.globalExceptionHandlerService.handleGeneratedApiError(error);
                        return of(postkorbActions.setPagePostkorbNachrichtenFailed());
                    })
                )
            })
        )
    });

    openPostkorbNachricht$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(postkorbActions.changePostkorbDisplayedPostkorbNachricht),
            tap((action) => {
                const dialogData: DialogData = {
                    title: 'Betreff: ' + action.displayedPostkorbNachricht.betreff,
                    actions: [{
                        title: 'Schließen', clickFunction: ((dialogRef: MatDialogRef<unknown>) => {
                            if (!action.displayedPostkorbNachricht.gelesen) {
                                this.dialogHelperService.dispatchAction(postkorbActions.updatePostkorbNachrichtGelesen({ id: action.displayedPostkorbNachricht.id, gelesen: true }));
                            }
                            this.dialogHelperService.closeDialog(dialogRef);
                        }) as DialogActionClickFunction
                    }],
                    contentTemplateName: 'postkorbNachrichtDialogTemplate',
                    panelClass: 'postkorb-nachricht-dialog'
                }
                this.dialogHelperService.openNewDialog(dialogData);
            })
        )
    }, { dispatch: false });
}