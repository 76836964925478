<div class="add-new-benutzer">
    <div class="add-new-benutzer__container">
        <form>
            <mat-form-field class="add-new-benutzer__container__email-input">
                <mat-label>E-Mail</mat-label>
                <input
                    type="email"
                    matInput
                    [formControl]="emailFormControl"
                    [errorStateMatcher]="matcher"
                    placeholder="user@example.com" />
                @if (emailFormControl.hasError('email') && !emailFormControl.hasError('required')) {
                <mat-error>Bitte geben Sie eine gültige E-Mail-Adresse ein</mat-error>
                } @if (emailFormControl.hasError('required')) {
                <mat-error>Eine E-Mail-Adresse ist <strong>erforderlich</strong></mat-error>
                }
                <mat-hint>Bitte beachten Sie, dass Benutzer*innen sich initial am Serviceportal registrieren müssen.</mat-hint>
            </mat-form-field>
        </form>

        <div class="add-new-benutzer__container__buttons-row">
            <mat-button-toggle-group
                class="add-new-benutzer__container__buttons-row"
                name=""
                aria-label=""
                (change)="onToggleChange($event)">
                <mat-button-toggle
                    class="add-new-benutzer__container__buttons-row__button--second"
                    [value]="PortalRole.COMPANY_ADMIN"
                    >Als Admin hinzufügen</mat-button-toggle
                >
                <mat-button-toggle
                    class="add-new-benutzer__container__buttons-row__button--third"
                    [value]="PortalRole.COMPANY_USER"
                    >Als Mitarbeiter*in hinzufügen</mat-button-toggle
                >
            </mat-button-toggle-group>
        </div>
    </div>
</div>
