import { Component, OnInit, inject } from '@angular/core';
import { selectActiveNutzerAnredeGreeting } from '../../store/auth/auth.selectors';
import { Store } from '@ngrx/store';
import { allUnternehmenNutzerActions } from '../../store/all-unternehmen-nutzer/all-unternehmen-nutzer.actions';

import { Observable } from 'rxjs';

@Component({
    selector: 'app-unternehmen-administration',
    templateUrl: './unternehmen-administration.component.html',
    styleUrl: './unternehmen-administration.component.scss',
})
export class UnternehmenAdministrationComponent implements OnInit {
    private store = inject(Store);

    userGreeting$: Observable<string>;

    /**
     * reset the benutzer store and get the user observable
     */
    ngOnInit(): void {
        this.store.dispatch(allUnternehmenNutzerActions.resetAllUnternehmenNutzerStore());

        this.userGreeting$ = this.store.select(selectActiveNutzerAnredeGreeting);
    }


}
