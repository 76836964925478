import { Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { nutzerUnternehmenSelectionActions } from '../../../store/nutzer-unternehmen-selection/nutzer-unternehmen-selection.actions';
import { UnternehmenSelectionItem } from '../../../model/nutzer-unternehmen-selection.model';
import { selectActiveUnternehmen, selectNutzerHasCompany, selectUnternehmenSelectionList } from '../../../store/nutzer-unternehmen-selection/nutzer-unternehmen-selection.selectors';

@Component({
    selector: 'app-unternehmen-selection',
    templateUrl: './unternehmen-selection.component.html',
    styleUrl: './unternehmen-selection.component.scss',
})
export class UnternehmenSelectionComponent implements OnInit {
    private store = inject(Store);

    selected$: Observable<UnternehmenSelectionItem>;
    unternehmenSelectionList$: Observable<UnternehmenSelectionItem[]>;
    hasCompany$: Observable<boolean>;

    menuOpen = false;

    ngOnInit(): void {
        this.selected$ = this.store.select(selectActiveUnternehmen);
        this.unternehmenSelectionList$ = this.store.select(selectUnternehmenSelectionList);
        this.hasCompany$ = this.store.select(selectNutzerHasCompany);
    }

    /**
     * called when the user change the Unternehmen.
     * dispatch action to change Unternehmen selection in store
     * @param selectedUnternehmenId id of the selected Unternehmen
     */
    onSelectedChanged(selectedUnternehmenId: string, selectedUnternehmen: UnternehmenSelectionItem, unternehmenSelectionList: UnternehmenSelectionItem[]): void {
        if (selectedUnternehmen.unternehmenId !== selectedUnternehmenId) {
            const selectedUnternehmen = unternehmenSelectionList.find((currentunternehmen) => currentunternehmen.unternehmenId === selectedUnternehmenId);
            if (selectedUnternehmen) {
                this.store.dispatch(nutzerUnternehmenSelectionActions.postChangeSelectedUnternehmen({ newlySelectedUnternehmen: selectedUnternehmen }));
            }
        }
    }

    onMenuOpen(): void {
        this.menuOpen = true;
    }
    onMenuClose(): void {
        this.menuOpen = false;
    }
}
