import { HttpClient, HttpHeaders } from '@angular/common/http';
import type { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { forkJoin, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import type { Observable } from 'rxjs';

import { ApiError } from './ApiError';
import type { ApiRequestOptions } from './ApiRequestOptions';
import type { ApiResult } from './ApiResult';
import type { OpenAPIConfig } from './OpenAPI';

export const isString = (value: unknown): value is string => {
    return typeof value === 'string';
};

export const isStringWithValue = (value: unknown): value is string => {
    return isString(value) && value !== '';
};

export const isBlob = (value: any): value is Blob => {
    return value instanceof Blob;
};

export const isFormData = (value: unknown): value is FormData => {
    return value instanceof FormData;
};

export const base64 = (str: string): string => {
    try {
        return btoa(str);
    } catch (err) {
        // @ts-ignore
        return Buffer.from(str).toString('base64');
    }
};

export const getQueryString = (params: Record<string, unknown>): string => {
    const qs: string[] = [];

    const append = (key: string, value: unknown) => {
        qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
    };

    const encodePair = (key: string, value: unknown) => {
        if (value === undefined || value === null) {
            return;
        }

        if (Array.isArray(value)) {
            value.forEach((v) => encodePair(key, v));
        } else if (typeof value === 'object') {
            Object.entries(value).forEach(([k, v]) => encodePair(`${key}[${k}]`, v));
        } else {
            append(key, value);
        }
    };

    Object.entries(params).forEach(([key, value]) => encodePair(key, value));

    return qs.length ? `?${qs.join('&')}` : '';
};

const getUrl = (config: OpenAPIConfig, options: ApiRequestOptions): string => {
    const encoder = config.ENCODE_PATH || encodeURI;

    const path = options.url.replace('{api-version}', config.VERSION).replace(/{(.*?)}/g, (substring: string, group: string) => {
        if (options.path?.hasOwnProperty(group)) {
            return encoder(String(options.path[group]));
        }
        return substring;
    });

    const url = config.BASE + path;
    return options.query ? url + getQueryString(options.query) : url;
};

export const getFormData = (options: ApiRequestOptions): FormData | undefined => {
    if (options.formData) {
        const formData = new FormData();

        const process = (key: string, value: unknown) => {
            if (isString(value) || isBlob(value)) {
                formData.append(key, value);
            } else {
                formData.append(key, JSON.stringify(value));
            }
        };

        Object.entries(options.formData)
            .filter(([, value]) => value !== undefined && value !== null)
            .forEach(([key, value]) => {
                if (Array.isArray(value)) {
                    value.forEach((v) => process(key, v));
                } else {
                    process(key, value);
                }
            });

        return formData;
    }
    return undefined;
};

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;

export const resolve = async <T>(options: ApiRequestOptions, resolver?: T | Resolver<T>): Promise<T | undefined> => {
    if (typeof resolver === 'function') {
        return (resolver as Resolver<T>)(options);
    }
    return resolver;
};

export const getHeaders = (config: OpenAPIConfig, options: ApiRequestOptions): Observable<HttpHeaders> => {
    return forkJoin({
        token: resolve(options, config.TOKEN),
        username: resolve(options, config.USERNAME),
        password: resolve(options, config.PASSWORD),
        additionalHeaders: resolve(options, config.HEADERS),
    }).pipe(
        map(({ token, username, password, additionalHeaders }) => {
            const headers = Object.entries({
                Accept: 'application/json',
                ...additionalHeaders,
                ...options.headers,
            })
                .filter(([, value]) => value !== undefined && value !== null)
                .reduce(
                    (headers, [key, value]) => ({
                        ...headers,
                        [key]: String(value),
                    }),
                    {} as Record<string, string>,
                );

            if (isStringWithValue(token)) {
                headers['Authorization'] = `Bearer ${token}`;
            }

            if (isStringWithValue(username) && isStringWithValue(password)) {
                const credentials = base64(`${username}:${password}`);
                headers['Authorization'] = `Basic ${credentials}`;
            }

            if (options.body !== undefined) {
                if (options.mediaType) {
                    headers['Content-Type'] = options.mediaType;
                } else if (isBlob(options.body)) {
                    headers['Content-Type'] = options.body.type || 'application/octet-stream';
                } else if (isString(options.body)) {
                    headers['Content-Type'] = 'text/plain';
                } else if (!isFormData(options.body)) {
                    headers['Content-Type'] = 'application/json';
                }
            }

            return new HttpHeaders(headers);
        }),
    );
};

export const getRequestBody = (options: ApiRequestOptions): unknown => {
    if (options.body) {
        if (options.mediaType?.includes('application/json') || options.mediaType?.includes('+json')) {
            return JSON.stringify(options.body);
        } else if (isString(options.body) || isBlob(options.body) || isFormData(options.body)) {
            return options.body;
        } else {
            return JSON.stringify(options.body);
        }
    }
    return undefined;
};

export const sendRequest = <T>(
    config: OpenAPIConfig,
    options: ApiRequestOptions,
    http: HttpClient,
    url: string,
    body: unknown,
    formData: FormData | undefined,
    headers: HttpHeaders,
): Observable<HttpResponse<T>> => {
    return http.request<T>(options.method, url, {
        headers,
        body: body ?? formData,
        withCredentials: config.WITH_CREDENTIALS,
        observe: 'response',
    });
};

export const getResponseHeader = <T>(response: HttpResponse<T>, responseHeader?: string): string | undefined => {
    if (responseHeader) {
        const value = response.headers.get(responseHeader);
        if (isString(value)) {
            return value;
        }
    }
    return undefined;
};

export const getResponseBody = <T>(response: HttpResponse<T>): T | undefined => {
    if (response.status !== 204 && response.body !== null) {
        return response.body;
    }
    return undefined;
};

export const catchErrorCodes = (options: ApiRequestOptions, result: ApiResult): void => {
    const errors: Record<number, string> = {
        400: 'Bad Request',
        401: 'Unauthorized',
        402: 'Payment Required',
        403: 'Forbidden',
        404: 'Not Found',
        405: 'Method Not Allowed',
        406: 'Not Acceptable',
        407: 'Proxy Authentication Required',
        408: 'Request Timeout',
        409: 'Conflict',
        410: 'Gone',
        411: 'Length Required',
        412: 'Precondition Failed',
        413: 'Payload Too Large',
        414: 'URI Too Long',
        415: 'Unsupported Media Type',
        416: 'Range Not Satisfiable',
        417: 'Expectation Failed',
        418: 'Im a teapot',
        421: 'Misdirected Request',
        422: 'Unprocessable Content',
        423: 'Locked',
        424: 'Failed Dependency',
        425: 'Too Early',
        426: 'Upgrade Required',
        428: 'Precondition Required',
        429: 'Too Many Requests',
        431: 'Request Header Fields Too Large',
        451: 'Unavailable For Legal Reasons',
        500: 'Internal Server Error',
        501: 'Not Implemented',
        502: 'Bad Gateway',
        503: 'Service Unavailable',
        504: 'Gateway Timeout',
        505: 'HTTP Version Not Supported',
        506: 'Variant Also Negotiates',
        507: 'Insufficient Storage',
        508: 'Loop Detected',
        510: 'Not Extended',
        511: 'Network Authentication Required',
        ...options.errors,
    };

    const error = errors[result.status];
    if (error) {
        throw new ApiError(options, result, error);
    }

    if (!result.ok) {
        const errorStatus = result.status ?? 'unknown';
        const errorStatusText = result.statusText ?? 'unknown';
        const errorBody = (() => {
            try {
                return JSON.stringify(result.body, null, 2);
            } catch (e) {
                return undefined;
            }
        })();

        throw new ApiError(options, result, `Generic Error: status: ${errorStatus}; status text: ${errorStatusText}; body: ${errorBody}`);
    }
};

/**
 * Request method
 * @param config The OpenAPI configuration object
 * @param http The Angular HTTP client
 * @param options The request options from the service
 * @returns Observable<T>
 * @throws ApiError
 */
export const request = <T>(config: OpenAPIConfig, http: HttpClient, options: ApiRequestOptions): Observable<T> => {
    const url = getUrl(config, options);
    const formData = getFormData(options);
    const body = getRequestBody(options);

    return getHeaders(config, options).pipe(
        switchMap((headers) => {
            return sendRequest<T>(config, options, http, url, body, formData, headers);
        }),
        switchMap(async (response) => {
            for (const fn of config.interceptors.response._fns) {
                response = await fn(response);
            }
            const responseBody = getResponseBody(response);
            const responseHeader = getResponseHeader(response, options.responseHeader);
            return {
                url,
                ok: response.ok,
                status: response.status,
                statusText: response.statusText,
                body: responseHeader ?? responseBody,
            } as ApiResult;
        }),
        catchError((error: HttpErrorResponse) => {
            if (!error.status) {
                return throwError(() => error);
            }
            return of({
                url,
                ok: error.ok,
                status: error.status,
                statusText: error.statusText,
                body: error.error ?? error.statusText,
            } as ApiResult);
        }),
        map((result) => {
            catchErrorCodes(options, result);
            return result.body as T;
        }),
        catchError((error: ApiError) => {
            return throwError(() => error);
        }),
    );
};
