import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrl: './progress-bar.component.scss',
})
/**
 * The component that displays the progress of an upload
 */
export class ProgressBarComponent {
    @Input() progressValue: number;
}
