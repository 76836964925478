<section class="nebenantrag-detail-page--top-section">
    <div class="nebenantrag-detail-page--top-section--top-half">
        <h1>Nebenantrag für "{{ projectName | async }}"</h1>
    </div>

    <div class="nebenantrag-detail-page--top-section--bottom-half">
        <app-card>
            <div class="nebenantrag-detail-page--top-section--bottom-half__art-card">
                <p class="text-accordeon-card-title">{{ displayedNebenantragsart.bezeichnung }}</p>
                <p class="text-plain">Anzahl bisheriger gestellten Nebenanträge: {{ displayedNebenantragsart.amount }}</p>
            </div>
        </app-card>
        <app-button
            *ngIf="isNebenantragCreateable$ | async"
            buttonClass="nebenantrag-detail-page--top-section--top-half--button-width"
            [buttonLabel]="getNewAntragLabel(displayedNebenantragsart)"
            (clickEvent)="navigateToFormular()"
            [isButton]="true"></app-button>
    </div>
</section>
<section class="nebenantrag-detail-page--bottom-section">
    <app-card>
        <div class="nebenantrag-detail-page--bottom-section__card-content">
            <div class="nebenantrag-detail-page--bottom-section__card-content--top">
                <app-quick-filter
                    class="nebenantrag-detail-page--bottom-section__card-content--top__filter"
                    [quickFilterMenuItems]="quickFilterMenuItems">
                </app-quick-filter>
                <app-search
                    class="nebenantrag-detail-page--bottom-section__card-content--top__search"
                    (searchEvent)="searchHandler($event)">
                </app-search>
            </div>
            <app-data-table
                class="nebenantrag-detail-page--bottom-section__card-content--bottom"
                [tableConfig]="tableConfig"
                (sortEvent)="sortHandler($event)"
                (pageEvent)="pageHandler($event)">
            </app-data-table>
        </div>
    </app-card>
</section>
