<img
    src="/assets/images/svgs/three-dots-vertical-svgrepo-com.svg"
    [matMenuTriggerFor]="threeDotMenu"
    class="data-table-action-menu__svg"
    alt="Aktionsmenüknopf"
    aria-label="Aktionsmenüknopf"
    data-testid="actionsmenu-button" />
<mat-menu
    #threeDotMenu="matMenu"
    ariaLabel="Aktionsmenü"
    xPosition="before">
    <ng-container *ngFor="let menuItem of tableActions">
        <a
            *ngIf="menuItem.isLink && !menuItem.isExternalLink"
            mat-menu-item
            class="!text-text-dark"
            data-testid="actionsmenu-link"
            [routerLink]="menuItem.link">
            {{ menuItem.name }}
        </a>

        <a
            *ngIf="menuItem.isLink && menuItem.isExternalLink"
            mat-menu-item
            class="!text-text-dark"
            data-testid="actionsmenu-link-extern"
            [href]="menuItem.link ? menuItem.link[0] : ''"
            target="_blank"
            rel="noopener"
            (click)="$event.stopPropagation(); menuItem.clickHandler ? menuItem.clickHandler() : ''">
            {{ menuItem.name }}
        </a>

        <a
            *ngIf="!menuItem.isLink && menuItem.isDialog"
            mat-menu-item
            class="!text-text-dark"
            data-testid="actionsmenu-dialog"
            (click)="$event.stopPropagation(); openDialog(menuItem.dialogData)">
            {{ menuItem.name }}
        </a>
        <a
            *ngIf="!menuItem.isLink && menuItem.isButton"
            mat-menu-item
            class="!text-text-dark"
            data-testid="actionsmenu-button"
            (click)="$event.stopPropagation(); menuItem.clickHandler ? menuItem.clickHandler() : ''">
            {{ menuItem.name }}</a
        >
        <div class="data-table-action-menu__horizontal-line"></div>
    </ng-container>
</mat-menu>
