<div class="fixed inset-0 flex items-center justify-center z-50">
    <div class="bg-black opacity-50 absolute inset-0"></div>
    <div class="bg-white rounded-lg shadow-xl w-3/4 md:w-1/2 lg:w-1/3 relative z-10 p-6">
        <h2 class="text-2xl font-bold mb-4">{{ header }}</h2>

        <mat-dialog-content class="mb-6">
            <ng-content></ng-content>
        </mat-dialog-content>

        <mat-dialog-actions
            class="flex justify-end"
            *ngIf="closeButtonText && confirmButtonText">
            <button
                mat-button
                mat-dialog-close
                class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l">
                {{ closeButtonText }}
            </button>
            <button
                mat-button
                mat-dialog-close
                cdkFocusInitial
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r">
                {{ confirmButtonText }}
            </button>
        </mat-dialog-actions>
    </div>
</div>
