<div class="amplify-flex amplify-field amplify-textfield amplify-phonenumberfield">
    <div class="amplify-flex">
        <label
            class="amplify-label"
            [class.amplify-visually-hidden]="labelHidden"
            [for]="textFieldId">
            {{ label }}
        </label>
        <span>
            <p
                class="amplify-hint"
                tippyClassName="tippy__hint"
                ngxTippy="{{ this.phoneNumberHintText }}"
                [tippyProps]="this.phoneNumberHintOptions">
                <img
                    src="/assets/images/svgs/question-mark-in-a-circle-outline-svgrepo-com.svg"
                    class="amplify-icon"
                    alt="Hinweis zur Mobilnummer" />
            </p>
        </span>
    </div>
    <div class="amplify-flex amplify-field-group amplify-field-group--horizontal">
        <div class="amplify-field-group__outer-start">
            <div
                class="amplify-flex amplify-field amplify-selectfield amplify-dialcodeselect"
                style="flex-direction: column">
                <app-amplify-selector
                    name="country_code"
                    label=""
                    [labelHidden]="true"
                    [id]="selectFieldId"
                    [items]="countryDialCodesValues"
                    [defaultValue]="defaultCountryCode"></app-amplify-selector>
            </div>
        </div>

        <div class="amplify-field-group__field-wrapper amplify-field-group__field-wrapper--horizontal">
            <input
                class="amplify-input amplify-field-group__control"
                [id]="textFieldId"
                [type]="type"
                [name]="name"
                [placeholder]="placeholder"
                [required]="required"
                [value]="initialValue"
                [autocomplete]="autocomplete"
                [attr.disabled]="disabled ? '' : null"
                [attr.aria-invalid]="hasError ? 'true' : 'false'"
                [attr.aria-describedby]="describedBy" />
        </div>
    </div>
</div>
