import { Nebenantragsart } from "../../../../model/nebenantraege.model";
import { NebenantragsartDto } from "../../generated";

/**
 * Maps the generated NebenantragsartDto to the store used NebenantragInfo
 * @param nebenantragsartDto the API returned nebenantragsart
 * @returns returns the store friendly NebenantragInfo
 */
export const mapNebenantragInfo = (nebenantragsartDto: NebenantragsartDto): Nebenantragsart => {
    return {
        bezeichnung: nebenantragsartDto.bezeichnung,
        gatewayShortname: nebenantragsartDto.gatewayShortname,
        id: nebenantragsartDto.id,
    };
}