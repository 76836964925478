import { inject, Injectable } from '@angular/core';
import { BearbeiterUnternehmenControllerService, ModifyBearbeiterWithMultipleNutzerDto, NutzerDto } from '../generated';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RightManagementAlleAntraegeService {
    private bearbeiterUnternehmenControllerService = inject(BearbeiterUnternehmenControllerService);

    /**
     * Wrapper method to call createBearbeiterForFoerderantrag
     * @returns boolean Bearbeiter were created
     * @throws ApiError
     */
    postBearbeiterUnternehmenForFoerderantrag(unternehmenId: number, requestBody: ModifyBearbeiterWithMultipleNutzerDto): Observable<boolean> {
        return this.bearbeiterUnternehmenControllerService.createBearbeiterForFoerderantrag({ unternehmenId, requestBody });
    }

    /**
     * Delete multiple Bearbeiter for a given Foerderantrag
     * @returns boolean Bearbeitungsrechte were deleted for list of Nutzer
     * @throws ApiError
     */
    deleteBearbeiterForFoerderantrag(unternehmenId: number, requestBody: ModifyBearbeiterWithMultipleNutzerDto): Observable<boolean> {
        return this.bearbeiterUnternehmenControllerService.deleteBearbeiterForFoerderantrag({ unternehmenId, requestBody });
    }

    getBearbeiterForFoerderantrag(unternehmenId: number, foerderantragId: number): Observable<NutzerDto[]> {
        return this.bearbeiterUnternehmenControllerService.getAllBearbeiterForOneFoerderantrag({ unternehmenId, foerderantragId });
    }
}
