import { AfterViewInit, Component, DestroyRef, EventEmitter, Injector, Input, OnChanges, OnInit, Output, ViewChild, inject } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, MatPaginatorSelectConfig, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { DataTableConfig } from '../../model/data-table.model';
import { SortDir, SortParameter } from '../../model/store.model';
import { CustomMatPaginatorIntl } from '../../service/custom-provider/custom-mat-pagination-intl-provider.service';

/**
 * Component that creates a generic table from a passed configuration.
 */
@Component({
    selector: 'app-data-table',
    templateUrl: './data-table.component.html',
    styleUrl: './data-table.component.scss',
    providers: [{
        provide: MatPaginatorIntl,
        useClass: CustomMatPaginatorIntl,
    }]
})
export class DataTableComponent implements OnInit, AfterViewInit, OnChanges {
    private destroyRef = inject(DestroyRef);
    private paginatorIntl: CustomMatPaginatorIntl;

    @Input() tableConfig: DataTableConfig;

    @Output() sortEvent = new EventEmitter<{ sort: SortParameter; isMultiSort: boolean }>();
    @Output() pageEvent = new EventEmitter<PageEvent>();
    @Output() rowClickEvent = new EventEmitter<number>();

    @ViewChild(MatPaginator) paginator: MatPaginator;

    tableIsLoadingStatus = false;

    displayedColumns: string[];
    paginatorSelectConfig: MatPaginatorSelectConfig = { panelClass: 'data-table-paginator__panel' };

    /**
     * On initialization create the needed component from the table configuration
     * Here we use the
     */
    ngOnInit(): void {
        this.tableSetUp();
        this.tableConfig.tableIsLoading$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isLoading) => {
            this.tableIsLoadingStatus = isLoading;
        });
    }

    /**
     * after the initialization the labeling for the paginator must be changed.
     */
    ngAfterViewInit(): void {
        // Create a unique instance of CustomMatPaginatorIntl for this component
        this.paginatorIntl = Injector.create({
            providers: [
                { provide: CustomMatPaginatorIntl, useClass: CustomMatPaginatorIntl, deps: [] }
            ]
        }).get(CustomMatPaginatorIntl);
        this.paginatorIntl.setLabels(this.tableConfig.paginationNames);
        this.paginator._intl = this.paginatorIntl;
    }

    /**
     * Everytime something changes the table should be rerendered
     */
    ngOnChanges(): void {
        this.tableSetUp();
    }

    /**
     * When the table configuration changes reset the displayed columns and add the actions.
     */
    tableSetUp(): void {
        this.displayedColumns = this.tableConfig.columns.map((value) => value.columnKey);
        if (!this.tableConfig.disableTableActions) {
            this.displayedColumns.push('actions');
        }
    }
    /**
     * adds the needed css to the sorting arrow class to display if the sorting arrow is active
     *
     * @param columnName the name of the column that is evaluated
     * @param isUp if the arrow is facing up (the ascending sorting arrow)
     * @returns returns the opacity level that should be used for the arrow.
     */
    getClass(columnName: string, isUp: boolean, tableSorting: SortParameter): string {
        let sortDirection: SortDir;
        isUp ? (sortDirection = SortDir.asc) : (sortDirection = SortDir.desc);
        return `${tableSorting.sortDirection === sortDirection && tableSorting.column === columnName ? 'opacity-100' : 'opacity-30'}`;
    }

    /**
     * disables the view of the footer if the table is not loading and the view of everything else if it is loading
     * @param tableIsLoading if the table is loading
     * @returns the class to set display:none;
     */
    tableIsLoading(tableIsLoading: boolean): string {
        return tableIsLoading ? 'data-table__body--is-loading' : '';
    }

    /**
     * Handles the sorting of a column
     *
     * @param sortEvent holds the new sortParameter and if the sort parameter should be added to the end of the sorting array or if it should create a new list
     */
    sortHandler(sortEvent: Sort): void {
        this.sortEvent.emit({ sort: { column: sortEvent.active, sortDirection: sortEvent.direction as SortDir }, isMultiSort: false });
    }

    /**
     * Handles the page event from the paginator
     *
     * @param pageEvent holds the pageIndex and the size of a page
     */
    pageHandler(pageEvent: PageEvent): void {
        this.pageEvent.emit(pageEvent);
    }

    /**
     * Handles the click on a row
     *
     * @param index the index of the clicked row
     */
    rowClickHandler(index: number): void {
        this.rowClickEvent.emit(index);
    }
}
