import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { Environment } from "../app/model/environment.model";

export const environment: Environment = {
    name: 'TEST',
    cognitoConfig: {
        Auth: {
            Cognito: {
                userPoolId: "eu-central-1_BlUm4NpcW",
                userPoolClientId: "25u804sa8t6engtblo7m24pd6b",
                signUpVerificationMethod: "code"
            }
        }
    },
    FE: {
        url: "https://ffa.formular-demo.de"
    },
    FMS: {
        origin: "https://formular-demo.de",
        formularServerPath: "/TestForms",
        areaShortname: "FFA",
        formTecId: "7",
    },
    AWS: {
        apiGatewayUrl: "https://ffa-api.formular-demo.de"
    },
    imports: [
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: false, // Restrict extension to log-only mode
        })
    ],
    idleManagement: {
        // The max time a user can be idle before he gets logged out in minutes
        idleTimeoutMin: 15,
        // The time that should pass between updates to the active status in seconds. 
        lastTimeActiveDebounceTimeSec: 1,
    },
}