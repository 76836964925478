import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DokumentContent } from '../../model/dokumente-unternehmen.model';
import { RenderedTemplateDto } from '../../service/api/generated';

export const rechtlicheInformationenActions = createActionGroup({
    source: 'Rechtliche Informationen',
    events: {
        updateDatenschutz: emptyProps(),
        updateDatenschutzSuccess: props<{ fileContent: DokumentContent }>(),
        updateNutzungsbedingungen: emptyProps(),
        updateNutzungsbedingungenSuccess: props<{ fileContent: DokumentContent }>(),
        updateFailure: props<{ error: Error }>(),
        loadImpressum: emptyProps(),
        setImpressumSuccess: props<{ impressumHtml: RenderedTemplateDto }>(),
    },
});
