import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { nutzerProfileActions } from '../../store/nutzer-profile/nutzer-profile.actions';
import { Observable } from 'rxjs';
import { NutzerProfile } from '../../model/nutzer-profile.model';
import { selectNutzerProfile, selectNutzerProfileChanges, selectNutzerProfileIsLoading } from '../../store/nutzer-profile/nutzer-profile.selectors';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NutzerAnrede } from '../../model/user.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';
import { DialogHelperService } from '../../service/helper/dialog.helper.service';

/**
 * Nutzer Profile Page
 */
@Component({
    selector: 'app-nutzer-profile',
    templateUrl: './nutzer-profile.component.html',
    styleUrl: './nutzer-profile.component.scss',
})
export class NutzerProfileComponent implements OnInit {
    private store = inject(Store);
    private destroyRef = inject(DestroyRef);
    private dialog = inject(MatDialog);
    private dialogHelperSercice = inject(DialogHelperService);

    phoneNumberHintOptions: NgxTippyProps = { placement: 'bottom-start', theme: 'light-border' };

    phoneNumberHintText = `Bitte geben Sie Ihre Mobilnummer an. Diese wird demnächst für die 2-Faktor-Authentifizierung benötigt.`;

    nutzerProfile$: Observable<NutzerProfile>;
    isLoading$: Observable<boolean>;

    anreden = Object.values(NutzerAnrede);

    initialFormState: Record<string, string | NutzerAnrede> = {
        emailFormController: '',
        anredeFormController: '',
        vornameFormController: '',
        nachnameFormController: '',
        telefonFormController: '',
        titelFormController: '',
    };

    nutzerProfileFormGroup = new FormGroup({
        emailFormController: new FormControl(''),
        anredeFormController: new FormControl<NutzerAnrede | ''>(''),
        vornameFormController: new FormControl('', Validators.required),
        nachnameFormController: new FormControl('', Validators.required),
        telefonFormController: new FormControl('', Validators.required),
        titelFormController: new FormControl(''),
    });

    isEditingNutzerData = false;

    ngOnInit(): void {
        this.store.dispatch(nutzerProfileActions.changeNutzerProfile());
        this.nutzerProfile$ = this.store.select(selectNutzerProfile);
        this.isLoading$ = this.store.select(selectNutzerProfileIsLoading);
        this.store.select(selectNutzerProfileChanges).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
            (nutzerProfileChanges) => {
                this.updateNutzerProfileFormGroup(nutzerProfileChanges);
            }
        );
    }

    /**
     * This method updates the form group with the changes from the store if there are changes in comparison to the form group
     * @param nutzerProfileChanges The nutzer profile changes that are saved in the store
     */
    updateNutzerProfileFormGroup(nutzerProfileChanges: NutzerProfile): void {
        const currentFormValues = this.nutzerProfileFormGroup.value as Record<string, string | NutzerAnrede>;
        const newFormValues: Record<string, string | NutzerAnrede> = {
            emailFormController: nutzerProfileChanges.email,
            anredeFormController: nutzerProfileChanges.anrede,
            vornameFormController: nutzerProfileChanges.vorname,
            nachnameFormController: nutzerProfileChanges.nachname,
            telefonFormController: nutzerProfileChanges.telefon,
            titelFormController: nutzerProfileChanges.titel,
        };
        const hasChanges = Object.keys(newFormValues).some(key => {
            return currentFormValues[key] !== newFormValues[key];
        });
        if (hasChanges) {
            this.nutzerProfileFormGroup.patchValue(newFormValues);
            this.initialFormState = newFormValues;
        }
    }

    /**
     * This method saves the changes made to the form group to the store
     */
    saveEdit(): void {
        if (this.nutzerProfileFormGroup.invalid) {
            this.nutzerProfileFormGroup.markAllAsTouched();
        }
        if (this.nutzerProfileFormGroup.valid) {
            const currentFormValues = this.nutzerProfileFormGroup.value as Record<string, string | NutzerAnrede>;
            const newNutzerProfileChanges: NutzerProfile = {
                idpId: '',
                email: currentFormValues['emailFormController'] as string,
                anrede: currentFormValues['anredeFormController'] as NutzerAnrede,
                vorname: currentFormValues['vornameFormController'] as string,
                nachname: currentFormValues['nachnameFormController'] as string,
                telefon: currentFormValues['telefonFormController'] as string,
                titel: currentFormValues['titelFormController'] as string,
            };
            this.store.dispatch(nutzerProfileActions.changeNutzerProfileChanges({ newNutzerProfileChanges }));
            this.isEditingNutzerData = false;
        }
    }

    /**
     * This method cancels the changes made to the form group and resets the changes in the store
     */
    cancleEdit(): void {
        const currentFormValues = this.nutzerProfileFormGroup.value as Record<string, string | NutzerAnrede>;
        const hasChanges = Object.keys(this.initialFormState).some(key => {
            return currentFormValues[key] !== this.initialFormState[key];
        });
        if (hasChanges) {
            const callBackFunction = (): void => {
                this.isEditingNutzerData = false;
            }
            this.dialogHelperSercice.openNewDialog(this.dialogHelperSercice.createNutzerprofileCancleDialogData(callBackFunction))
        } else {
            this.store.dispatch(nutzerProfileActions.resetChangesToNutzerProfile());
            this.isEditingNutzerData = false;
        }
    }

    /**
     * This method enables the editing of the nutzer profile data
     */
    nutzerDataEdit(): void {
        this.isEditingNutzerData = true;
    }

    /**
     * This method triggers the password change process
     */
    triggerPasswordChange(): void {
        this.dialog.open(ChangePasswordDialogComponent);
    }
}
