import { createReducer, on } from '@ngrx/store';
import { FoerderantragHistoryTableStore } from '../../model/foerderantrag-history-table.model';
import { foerderantragHistoryTableActions } from './foerderantrag-history-table.actions';
import { SortDir, SortParameter } from '../../model/store.model';
import { foerderantraegeActions } from '../foerderantraege/foerderantraege.actions';
import { authActions } from '../auth/auth.actions';

export const foerderantragHistoryTableInitialState: FoerderantragHistoryTableStore = {
    foerderantragHistoryTableContent: [],
    isLoading: false,
    totalElements: 0,
    page: 0,
    size: 20,
    sortingArray: [{ column: 'zeitstempel', sortDirection: SortDir.asc }],
};

export const foerderantragHistoryTableReducer = createReducer(
    foerderantragHistoryTableInitialState,
    on(foerderantragHistoryTableActions.changeFoerderantragHistoryTableContent, (state): FoerderantragHistoryTableStore => {
        return { ...state, isLoading: true };
    }),
    on(foerderantragHistoryTableActions.setFoerderantragHistoryTableContent, (state, action): FoerderantragHistoryTableStore => {
        return {
            ...state,
            isLoading: false,
            foerderantragHistoryTableContent: action.newFoerderantragHistoryTableContent,
            totalElements: action.newTotalElements,
        };
    }),
    on(foerderantragHistoryTableActions.changeSortingArray, (state, action): FoerderantragHistoryTableStore => {
        let sorting: SortParameter[];
        if (action.isMultiSort) {
            sorting = [...state.sortingArray];
            sorting.push(action.newSortingParameter);
        } else {
            sorting = [action.newSortingParameter];
        }
        return { ...state, sortingArray: sorting, page: 0 };
    }),
    on(foerderantragHistoryTableActions.changeTablePage, (state, action): FoerderantragHistoryTableStore => {
        return { ...state, page: action.newPage };
    }),
    on(foerderantragHistoryTableActions.changeTableSize, (state, action): FoerderantragHistoryTableStore => {
        return { ...state, page: 0, size: action.newSize };
    }),
    on(foerderantraegeActions.changeAllFoerderantraege, (): FoerderantragHistoryTableStore => {
        return { ...foerderantragHistoryTableInitialState };
    }),

    on(authActions.changeUserToLoggedOut, (): FoerderantragHistoryTableStore => ({ ...foerderantragHistoryTableInitialState })),
);
