import { Injectable, inject } from '@angular/core';
import { HistorieDto, HistorieUnternehmenControllerService, PageHistorieDto } from '../generated';
import { Observable, map } from 'rxjs';
import { FoerderantragHistoryTableApiReturn, FoerderantragHistoryTableItem } from '../../../model/foerderantrag-history-table.model';

@Injectable({
    providedIn: 'root',
})
/**
 * The service to interact with the historie-unternehmen-controller
 */
export class UnternehmenFoerderantragHistoryTableApiService {
    private historieUnternehmenControllerService = inject(HistorieUnternehmenControllerService);

    /**
     * make the api call to get the table page of the history entrys
     * @param foerderantragId id of the foerderantrag
     * @param unternehmenId id of the company the user selected
     * @param querryParams the sort, size and page for the querry request
     * @returns the mapped content and total elements
     */
    getFoerderantragHistoryTableContent(
        foerderantragId: number,
        unternehmenId: number,
        querryParams: { size: number; page: number; sort: string[] },
    ): Observable<FoerderantragHistoryTableApiReturn> {
        return this.historieUnternehmenControllerService
            .getHistorieneintraegeForFoerderantrag({ foerderantragId, unternehmenId, ...querryParams })
            .pipe(map((pageHistorieDto) => this.mapFoerderantragHistoryTable(pageHistorieDto as Required<PageHistorieDto>)));
    }

    /**
     * maps the PageHistorieDto zo to store used FoerderantragHistoryTableApiReturn
     * @param pageHistorieDto it can be set to required since the API will always return all fields filled
     * @returns the total elements and content mapped to the FoerderantragHistoryTableItem list.
     */
    private mapFoerderantragHistoryTable(pageHistorieDto: Required<PageHistorieDto>): FoerderantragHistoryTableApiReturn {
        return {
            totalElements: pageHistorieDto.totalElements,
            foerderantragHistoryTableContent: pageHistorieDto.content.map((item) =>
                this.mapFoerderantragHistoryTableItem(item as Required<HistorieDto>),
            ),
        };
    }

    /**
     * maps the HistorieDto to the FoerderantragHistoryTableItem
     * @param historieDto it can be set to required since the API will always return all fields filled
     * @returns one converted FoerderantragHistoryTableItem
     */
    private mapFoerderantragHistoryTableItem(historieDto: Required<HistorieDto>): FoerderantragHistoryTableItem {
        return {
            id: historieDto.id,
            aenderungsart: historieDto.aenderungsart,
            zeitstempel: historieDto.zeitstempel,
            ansprechpartner: historieDto.ansprechpartner,
            kommentar: historieDto.kommentar,
        };
    }
}
