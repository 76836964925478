import { inject, Injectable } from "@angular/core";
import { AntragUnternehmenControllerService } from "../generated";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class AntragUnternehmenApiService {
    private antragUnternehmenControllerService = inject(AntragUnternehmenControllerService);

    deleteAntragUnternehmen(antragId: number, unternehmenId: number): Observable<void> {
        return this.antragUnternehmenControllerService.updateAntragsStatusForDeletion({ antragId, unternehmenId });
    }
}