import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { NebenantraegeContentPage, Nebenantragsart } from '../../model/nebenantraege.model';
import { SortParameter } from '../../model/store.model';
import { Status } from '../../model/foerderantraege.model';
import { TableAction } from '../../model/data-table.model';

export const nebenantraegeActions = createActionGroup({
    source: 'Nebenantraege',
    events: {
        changeNebenantraegeContentPage: emptyProps(),
        setNebenantraegeContentPage: props<{ newNebenantraegeContentPage: NebenantraegeContentPage }>(),
        changeNebenantraegeTableModifiersAntragsId: props<{ newNebenantragsartId: number }>(),
        changeNebenantraegeTableModifiersPage: props<{ newPage: number }>(),
        changeNebenantraegeTableModifiersSearchParameter: props<{ newSearchParameter: string }>(),
        changeNebenantraegeTableModifiersSize: props<{ newSize: number }>(),
        changeNebenantraegeTableModifiersSortingArray: props<{ newSortingParameter: SortParameter; isMultiSort: boolean }>(),
        changeNebenantraegeTableModifiersStatusFilter: props<{ newStatusFilter: Status | null }>(),
        setNebenantraegeTableModifiersTableActions: props<{ newTableActions: TableAction[][] }>(),
        resetNebenantraegeTable: emptyProps(),
        changeNebenantragsartenCreateableAndExisting: emptyProps(),
        updateNebenantragsartenCreateableAndExisting: props<{
            newNebenantragsartenCreateable: Nebenantragsart[];
        }>(),
        setNebenantragsartenCreateableAndExisting: props<{
            newNebenantragsartenCreateable: Nebenantragsart[];
            newNebenantragsartenExisting: Nebenantragsart[];
        }>(),
        setFoerderantragsId: props<{ newFoerderantragsId: number }>(),
        setDisplayedNebenantrag: props<{ newNebenantrag: Nebenantragsart }>(),
        updateAntragsStatusForDeletion: props<{ antragsId: number }>(),
    },
});
