import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { NachrichtenTyp, PagePostkorbNachricht, PostkorbNachricht } from "../../model/postkorb.model";
import { SortParameter } from "../../model/store.model";

export const postkorbActions = createActionGroup({
    source: 'Postkorb',
    events: {
        changePagePostkorbNachricht: emptyProps(),
        setPagePostkorbNachrichten: props<{ newPagePostkorbNachricht: PagePostkorbNachricht }>(),
        setPagePostkorbNachrichtenFailed: emptyProps(),
        changePostkorbTablePage: props<{ newPage: number }>(),
        changePostkorbTableSize: props<{ newSize: number }>(),
        changePostkorbTableSearchparameter: props<{ newSearchparameter: string }>(),
        changePostkorbTableSort: props<{ newSort: SortParameter, isMultiSort: boolean }>(),
        changePostkorbTableNachrichtenTypFilter: props<{ newNachrichtenTypFilter: NachrichtenTyp }>(),
        resetPostkorbTable: emptyProps(),
        updatePostkorbNachrichtGelesen: props<{ id: number, gelesen: boolean }>(),
        changePostkorbDisplayedPostkorbNachricht: props<{ displayedPostkorbNachricht: PostkorbNachricht }>(),
        resetPostkorbDisplayedPostkorbNachricht: emptyProps(),
    }
});