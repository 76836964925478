import { Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    selectDisplayedDetailsFoerderantragFoerderantragsIdAndStatus,
    selectDisplayedDetailsFoerderantragProjektname,
} from '../../store/foerderantraege/foerderantraege.selectors';
import { Observable, first } from 'rxjs';
import { displayedDetailsFoerderantragActions } from '../../store/foerderantraege/foerderantraege.actions';
import { foerderantragHistoryTableActions } from '../../store/foerderantrag-history-table/foerderantrag-history-table.actions';
import { nebenantraegeActions } from '../../store/nebenantraege/nebenantraege.actions';
import { selectNebenantragsShownTab } from '../../store/nebenantraege/nebenantraege.selectors';
import { dokumenteUnternehmenActions } from '../../store/dokumente-unternehmen/dokumente-unternehmen.actions';

@Component({
    selector: 'app-antrags-detail-page',
    templateUrl: './antrags-detail-page.component.html',
    styleUrl: './antrags-detail-page.component.scss',
})
/**
 * Creates the page for the antrags details page.
 */
export class AntragsDetailPageComponent implements OnInit {
    private store = inject(Store);

    antragsId: number;
    projectName$: Observable<string>;
    shownTab: number;

    /**
     * subscribe to the needed functions and set up a subscription array to easyly unsubscribe at the end
     */
    ngOnInit(): void {
        this.store.select(selectDisplayedDetailsFoerderantragFoerderantragsIdAndStatus).pipe(first((value) => !!value.foerderantragStatus)).subscribe((antragsData) => {
            this.antragsId = antragsData.foerderantragId;
            this.store.dispatch(nebenantraegeActions.setFoerderantragsId({ newFoerderantragsId: antragsData.foerderantragId }));
        });
        this.store.select(selectNebenantragsShownTab).pipe(first()).subscribe(value => {
            this.shownTab = value
        });
        this.projectName$ = this.store.select(selectDisplayedDetailsFoerderantragProjektname);
        this.store.dispatch(nebenantraegeActions.resetNebenantraegeTable());
    }

    /**
     * Everytime the tab is changed a new action should be triggered that loads the data needed in the tab.
     * @param event The tab index of the new tab that is now displayed
     */
    tabChangeHandler(event: number): void {
        const TAB_THIS_ANTRAG = 0;
        const TAB_NEBENANTRAEGE = 1;
        const TAB_FFA_DOKUMENTE = 2;
        const TAB_HISTORY = 3;
        switch (event) {
            case TAB_THIS_ANTRAG:
                this.store.dispatch(displayedDetailsFoerderantragActions.updateDisplayedDetailsFoerderantrag({ antragsId: this.antragsId }));
                break;
            case TAB_NEBENANTRAEGE:
                this.store.dispatch(nebenantraegeActions.changeNebenantragsartenCreateableAndExisting());
                break;
            case TAB_FFA_DOKUMENTE:
                this.store.dispatch(dokumenteUnternehmenActions.changeDokumenteUnternehmenPage());
                break;
            case TAB_HISTORY:
                this.store.dispatch(foerderantragHistoryTableActions.changeFoerderantragHistoryTableContent());
                break;
            default:
                return;
        }
    }
}
