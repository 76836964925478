import { ElementRef, Injectable, inject } from "@angular/core";
import { Action, Store } from "@ngrx/store";
import { selectFormularStoreActiveFormularAntragsnummer } from "../store/formular/formular.selectors";
import { first } from "rxjs";
import { Router, UrlSerializer } from "@angular/router";
import { PersistenceService } from "./persistence.service";
import { formularActionsInt } from "../store/formular/formular.actions";
import { ExternalSaveClick, FormJson, PayloadType } from "../model/formular.model";
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FormularHandlerService {
    private store = inject(Store);
    private router = inject(Router);
    private urlSerializer = inject(UrlSerializer);
    private persistenceService = inject(PersistenceService);

    private iFrameFormular: ElementRef;
    private antragsnummer: string;

    /**
     * creates a JSON file from the given JSON of the form
     * @param fileContentJSON the JSON from which the file should be created
     * @returns the JSON file
     */
    createJsonFile(fileContentJSON: string): File {
        this.store.select(selectFormularStoreActiveFormularAntragsnummer).pipe(first()).subscribe((antragsnummer) => {
            this.antragsnummer = antragsnummer;
        });
        const fileContent = new Blob([fileContentJSON], { type: 'application/json' });
        const fileName = this.antragsnummer + '.json';
        return new File([fileContent], fileName, { type: 'application/json' });
    }

    /**
     * clears the formular actions in the bus
     */
    clearFormularActionsInBus(): void {
        this.persistenceService.removeFromBus(formularActionsInt.setUpNewFormular.type);
        this.persistenceService.removeFromBus(formularActionsInt.setActiveAntragsnummer.type);
        this.persistenceService.removeFromBus(formularActionsInt.setExistingFormular.type);
        this.persistenceService.removeFromBus(formularActionsInt.changeCreateUnternehmenSuccess.type);
    }

    /**
     * navigates to the formular with the given params
     * @param params the params the url should be called with
     */
    navigateToFormular(params: Record<string, string>): void {
        const tree = this.router.createUrlTree(['/formular'], { queryParams: params });
        const url = this.urlSerializer.serialize(tree);
        window.open(url, '_blank');

    }

    /**
     * triggers the given action since the actions are created inside an effect and there direct triggering of actions is not allowed or wished for
     * @param action the action to be triggered
     */
    triggerAction(action: Action): void {
        this.store.dispatch(action);
    }

    /**
     * Post a success message back to the message port given for the upload
     * @param messagePort The MessageChanel port that was send with the upload request
     * @param success the status if the upload was successfull or not
     */
    handleReturnMessage(messagePort: MessagePort, success: boolean): void {
        messagePort.postMessage({ success });
    }

    setIframeRef(iFrameRef: ElementRef): void {
        this.iFrameFormular = iFrameRef;
    }

    /**
     * This functions triggers the save of a JSON in the opened formular iframe
     * @returns a promise that resolves to true if the save was successful
     */
    handleExternalSave(content: "save" | "submit", callback: () => void): Promise<boolean> {
        if (this.iFrameFormular === undefined) {
            return Promise.reject(new Error('No Iframe registered'));
        }
        return new Promise((resolve, reject) => {
            const channel = new MessageChannel();
            const payload = { type: PayloadType.ExternalSaveClick, content } satisfies ExternalSaveClick;
            this.iFrameFormular.nativeElement.contentWindow?.postMessage(payload, environment.FMS.origin, [channel.port2]);
            channel.port1.onmessage = async (event): Promise<void> => {
                if (event.data.success) {
                    callback();
                    resolve(true);
                } else {
                    reject(new Error('External save failed'));
                }
            };
        });
    }

    isValidJsonForSubmit(formJson: string): boolean {
        const jsonObject = JSON.parse(formJson) as unknown as FormJson;
        const formContent = jsonObject.bolForm.formContent;

        const typeFileContent = formContent.filter((content) => content.type === "file" && content.value !== "");

        return typeFileContent.length === 0;
    }
}