import { createReducer, on } from "@ngrx/store";
import { StammdatenStore } from "../../model/stammdaten.model";
import { stammdatenActions } from "./stammdaten.actions";
import { authActions } from "../auth/auth.actions";

export const stammdatenInitialStore: StammdatenStore = {
    stammdaten: {
        id: 0,
        ffaid: "",
        unternehmensname: "",
        rechtsform: "",
        geschaeftsfuehrer: [],
        adresse: {
            id: 0,
            land: "",
            bundesland: undefined,
            plz: undefined,
            ort: undefined,
            strasse: undefined,
            hausnummer: undefined,
            zusatz: undefined,
            anschriftFreitext: undefined,
            isDeutscheAdresse: true,
        },
        idOfRegistrationForm: 0
    },
    isLoading: false
}

export const stammdatenReducer = createReducer(
    stammdatenInitialStore,
    on(stammdatenActions.changeLoadStammdaten, (state): StammdatenStore => ({ ...state, isLoading: true, })),
    on(stammdatenActions.setLoadStammdatenSuccess, (state, action): StammdatenStore =>
        ({ ...state, isLoading: false, stammdaten: action.newStammdaten })),
    on(stammdatenActions.resetStammdatenStore, (): StammdatenStore => ({ ...stammdatenInitialStore })),
    on(authActions.changeUserToLoggedOut, (): StammdatenStore => ({ ...stammdatenInitialStore })),
)
