<ng-container *ngIf="hasCompany$ | async">
    <ng-container *ngIf="selected$ | async; let selectedUnternehmen">
        <button
            class="unternehmen-selection"
            aria-label="Unternehmensauswahlfeld"
            mat-button
            [matMenuTriggerFor]="unternehmenSelectionMenu"
            (menuOpened)="onMenuOpen()"
            data-testid="unternehmen-selection-dropdown-trigger">
            <div>{{ selectedUnternehmen.name }}</div>
            <img
                [ngClass]="{ 'unternehmen-selection__close-arrow': menuOpen }"
                src="../../../../assets/images/svgs/arrow-down_grey.svg"
                alt="Unternehmensauswahl öffnen Pfeil" />
        </button>
        <mat-menu
            #unternehmenSelectionMenu="matMenu"
            class="unternehmen-selection__menu__panel"
            [ariaLabel]="'Unternehmensauswahlliste'"
            (closed)="onMenuClose()">
            <button
                *ngFor="let unternehmen of unternehmenSelectionList$ | async as unternehmenSelectionList"
                mat-menu-item
                class="unternehmen-selection__menu__item"
                [disabled]="!unternehmen.active"
                (click)="onSelectedChanged(unternehmen.unternehmenId, selectedUnternehmen, unternehmenSelectionList)">
                {{ unternehmen.name }}
            </button>
        </mat-menu>
    </ng-container>
</ng-container>
