import { AllUnternehmenNutzer, NutzerRollen } from '../../../../model/all-unternehmen-nutzer.model';
import { PortalRole } from '../../../../model/auth.model';
import { NutzerAdminResponseDto } from "../../generated";


export const mapAllUnternehmenNutzer = (adminNutzerDto: Required<NutzerAdminResponseDto>): AllUnternehmenNutzer => {
    const benutzer: AllUnternehmenNutzer = {
        id: adminNutzerDto.idpId,
        vorname: adminNutzerDto.vorname,
        nachname: adminNutzerDto.nachname,
        role: getKeyByValue(adminNutzerDto.rolle) as PortalRole,
        status: adminNutzerDto.aktiv,
        email: adminNutzerDto.email,
    }
    return benutzer;
};
const getKeyByValue = (value: string): string => {
    for (const key in NutzerRollen) {
        if (NutzerRollen[key as keyof typeof NutzerRollen] === value) {
            return key;
        }
    }
    return '';
};
