<div class="right-management-foerderbereich__container">
    <app-search
        class="right-management-foerderbereich__container__search-box"
        (searchEvent)="filterFoerderbereiche($event)"></app-search>
    <div class="right-management-foerderbereich__container__buttons">
        <ng-container *ngFor="let foerderbereich of filteredFoerderbereichList">
            <app-button
                [buttonLabel]="foerderbereich.bezeichnung"
                [isButton]="true"
                [ngClass]="{ selected: isSelected(foerderbereich.id) }"
                (clickEvent)="toggleButton(foerderbereich.id)"
                buttonClass="right-management-foerderbereich__container__buttons__dialog-button"></app-button>
        </ng-container>
    </div>
</div>
