<section class="postkorb--top-section">
    <h1>Postkorb</h1>
    <p
        class="text-h2-slim"
        *ngIf="(unternehmenId$ | async) !== 0; else noCompanySelected">
        Posteingang für das Unternehmen: {{ unternehmenName$ | async }}
    </p>
    <ng-template #noCompanySelected>
        <p class="text-h2-slim">Allgemeiner Postkorb</p>
    </ng-template>
</section>
<section class="postkorb--bottom-section">
    <app-card>
        <div class="postkorb--bottom-section__card-content">
            <div class="postkorb--bottom-section__card-content--top">
                <app-quick-filter
                    *ngIf="(unternehmenId$ | async) !== 0"
                    class="postkorb--bottom-section__card-content--top__filter"
                    [quickFilterMenuItems]="quickFilterMenuItems"></app-quick-filter>
                <app-search
                    class="postkorb--bottom-section__card-content--top__search"
                    (searchEvent)="searchHandler($event)"></app-search>
            </div>
            <app-data-table
                [tableConfig]="tableConfig"
                (sortEvent)="sortHandler($event)"
                (pageEvent)="pageHandler($event)"
                (rowClickEvent)="rowClickHandler($event)"></app-data-table>
        </div>
    </app-card>
</section>
