import { Component, inject, OnInit } from '@angular/core';
import { first, skip, take } from 'rxjs';
import { Foerderbereich } from '../../../model/foerderbereich.model';
import { Store } from '@ngrx/store';
import { selectAllUnternehemenNutzerNutzerFoerderbereichList } from '../../../store/all-unternehmen-nutzer/all-unternehmen-nutzer.selectors';
import { nutzerRightManagementFoerderantragViewActions } from '../../../store/all-unternehmen-nutzer/all-unternehmen-nutzer.actions';
import { selectFoerderbereicheAllFoerderbereiche } from '../../../store/foerderbereich/foerderbereich.selectors';

@Component({
    selector: 'app-right-management-foerderbereich',
    templateUrl: './right-management-foerderbereich.component.html',
    styleUrl: './right-management-foerderbereich.component.scss',
})
export class RightManagementFoerderbereichComponent implements OnInit {
    private store = inject(Store);
    allFoerderbreichList: Foerderbereich[];
    filteredFoerderbereichList: Foerderbereich[];
    nutzerFoerderbereichList: Foerderbereich[];
    selectedButtons: number[] = [];

    ngOnInit(): void {
        this.store
            .select(selectAllUnternehemenNutzerNutzerFoerderbereichList)
            .pipe(skip(1), take(1))
            .subscribe((data) => {
                this.nutzerFoerderbereichList = data;
                this.nutzerFoerderbereichList.forEach((value) => {
                    this.selectedButtons.push(value.id);
                });
            });

        this.store
            .select(selectFoerderbereicheAllFoerderbereiche)
            .pipe(first())
            .subscribe((data) => {
                this.allFoerderbreichList = data;
                this.filteredFoerderbereichList = data;
            });
    }

    toggleButton(foerderbereichId: number): void {
        if (this.selectedButtons.find((value) => value === foerderbereichId)) {
            this.selectedButtons = this.selectedButtons.filter((value) => value !== foerderbereichId);
            this.store.dispatch(nutzerRightManagementFoerderantragViewActions.changeRightId({ rightId: foerderbereichId, isSelected: false }));
        } else {
            this.selectedButtons.push(foerderbereichId);
            this.store.dispatch(nutzerRightManagementFoerderantragViewActions.changeRightId({ rightId: foerderbereichId, isSelected: true }));
        }
    }

    isSelected(foerderbereichId: number): boolean {
        return !!this.selectedButtons.find((value) => value === foerderbereichId);
    }

    filterFoerderbereiche(search: string): void {
        if (!search) {
            this.filteredFoerderbereichList = this.allFoerderbreichList;
        } else {
            this.filteredFoerderbereichList = this.allFoerderbreichList.filter((foerderbereich) =>
                foerderbereich.bezeichnung.toLowerCase().includes(search.toLowerCase()),
            );
        }
    }
}
